import Console from '@/console'
import store from '@/store'

export function addAuthGuard(router) {
  router.beforeEach(async (to, from, next) => {
    if (store.getters.debugGuards)
      Console.debug('Auth guard (to, from)', to, from)
    // check if authentication is (not) required
    const authenticated = store.getters.authenticated
    const userIsSetUp = store.state.users?.currentUser?.is_setup || false

    let routeAuthentication = true // routes required authentication by default
    if ('authentication' in to.meta) {
      routeAuthentication = to.meta.authentication
      Console.warning(
        'Found route with authentication requirements (from, to, authenticated)',
        from,
        to,
        authenticated
      )
    } else {
      to.meta.authentication = true
    }
    // Check if authentication status and route authentication field match up
    if (authenticated !== routeAuthentication) {
      if (authenticated) {
        // Redirect to discover if authenticated and attempting to access a page for unauthenticated users
        Console.warning(
          'Attempting to access route with authenticated user, redirecting to discover'
        )
        if (!userIsSetUp) next({ name: 'onboarding' })
        else next({ name: 'home' })
      } else {
        // Redirect to login page if unauthenticated and attempting to access auth-only page
        // Store invitation code in localstorage
        const invitationCode = to.query.invitation_code
        if (invitationCode) {
          localStorage.setItem('invitationCode', invitationCode)
        }
        Console.warning(
          'Attempting to access protected route with authentication, redirecting to login',
          authenticated,
          from,
          to
        )

        // Store route with queries and params, to redirect to after logged in
        const routeCopy = {
          ...(to.name ? { name: to.name } : undefined),
          ...(to.path ? { path: to.path } : undefined),
          ...(to.query ? { query: to.query } : undefined),
          ...(to.params ? { params: to.params } : undefined)
        }
        localStorage.setItem('loginRedirect', JSON.stringify(routeCopy))
        next({ name: 'login' })
      }
    } else if (to.name === 'search' || to.name === 'home') {
      if (!userIsSetUp) {
        next({ name: 'onboarding' })
      } else if (localStorage.getItem('loginRedirect')) {
        const loginRedirect = JSON.parse(localStorage.getItem('loginRedirect'))
        const redirect = {
          ...loginRedirect
        }
        localStorage.removeItem('loginRedirect')
        next(redirect)
      } else {
        next()
      }
    } else if (to.name === 'onboarding') {
      if (userIsSetUp) {
        next({ name: 'home' })
      } else {
        next()
      }
    } else {
      next()
    }
  })
}
