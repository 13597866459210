



















































import FileAppIntegrations from '@c/features/workspace-settings-modal/organisms/FileAppIntegrations.vue'
import {
  filestorageIntegrations,
  Integration,
  messagingIntegrations,
  crmIntegrations
} from '@c/integrations'
import Workspace from '@c/models/Workspace'
import ContentWithHeader from '@c/shared/molecules/structural/blocks/ContentWithHeader.vue'
// import MessageBlock from '@c/shared/molecules/structural/blocks/MessageBlock.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'ThirdPartyTab',
  components: {
    FileAppIntegrations,
    ContentWithHeader
    // MessageBlock,
  },
  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    },
    activeIntegrations: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    chatIntegrations(): Integration[] {
      return Object.values(messagingIntegrations).filter((x) => x.active)
    },
    fileIntegrations(): Integration[] {
      return Object.values(filestorageIntegrations).filter((x) => x.active)
    },
    crmIntegrations(): Integration[] {
      return Object.values(crmIntegrations).filter((x) => x.active)
    }
  }
})
