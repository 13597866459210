import { render, staticRenderFns } from "./RoleSelect.vue?vue&type=template&id=154498e8&scoped=true&"
import script from "./RoleSelect.vue?vue&type=script&lang=js&"
export * from "./RoleSelect.vue?vue&type=script&lang=js&"
import style0 from "./RoleSelect.vue?vue&type=style&index=0&id=154498e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154498e8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('154498e8')) {
      api.createRecord('154498e8', component.options)
    } else {
      api.reload('154498e8', component.options)
    }
    module.hot.accept("./RoleSelect.vue?vue&type=template&id=154498e8&scoped=true&", function () {
      api.rerender('154498e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/library/RoleSelect.vue"
export default component.exports