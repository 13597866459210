<template>
  <div class="link-preview-wrapper" @click="onResourceClick">
    <img
      :src="subresource.preview"
      class="link-preview"
      @load="$emit('load')"
      @error="$emit('cantLoad')"
    />
    <div class="link-preview-hover">
      <div class="link-preview-hover-icon-wrapper">
        <img
          src="@/assets/icons/external-bold.svg"
          alt=""
          class="link-preview-hover-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightSubresourceLink',
  props: {
    subresource: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.link-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;

  &-wrapper {
    width: 100%;
    aspect-ratio: 16 / 9;
    vertical-align: middle;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgba(#000, 8%);
    background: #f5f5f5;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:hover > .link-preview-hover {
      opacity: 1;
    }
  }

  &-hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 32%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 400ms ease;

    &-icon {
      height: 1.5rem;
      filter: brightness(0) invert(1);
      &-wrapper {
        height: 3rem;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999rem;
        background: rgba(#000, 16%);
      }
    }
  }
}
</style>
