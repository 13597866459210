var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "status-orb-wrapper" },
    [
      _c(
        "b-tooltip",
        {
          attrs: {
            active: !_vm.showText && !!_vm.text,
            label: _vm.text,
            position: "is-" + _vm.alignTooltip,
            type: "is-dark"
          }
        },
        [
          _c("img", {
            staticClass: "status-orb",
            class: ((_obj = {}), (_obj[_vm.size] = true), _obj),
            attrs: {
              src: require("@/assets/icons/status_" + _vm.type + ".svg"),
              alt: ""
            }
          })
        ]
      ),
      _vm.showText && _vm.text
        ? _c("p", { staticClass: "status-orb-text" }, [
            _vm._v(_vm._s(_vm.text))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }