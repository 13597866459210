var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stat-list-container" },
    [
      _c("p", { staticClass: "body bold mb-3" }, [_vm._v("Sync statistics")]),
      _vm._l(_vm.sortedStatNames, function(stat, index) {
        return _c("div", { key: index, staticClass: "stat-row pl-4" }, [
          _c("p", { staticClass: "stat-bold" }, [_vm._v(_vm._s(stat))]),
          _c(
            "div",
            { staticClass: "stat-count" },
            [
              _c("p", [_vm._v(_vm._s(_vm.syncStatus[stat]))]),
              _vm.showSyncStatus(stat)
                ? _c("SyncStatusComponent", { attrs: { status: stat } })
                : _vm._e()
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }