<template>
  <div class="quality-labels">
    <div
      v-for="label in labels"
      :key="label.uuid"
      class="quality-labels-label"
      :class="{ bordered: bordered || big, pill: big }"
      @mouseenter="(e) => handleHoverEnter(e, label)"
      @mouseleave="handleHoverLeave"
    >
      <img
        :src="require(`@/assets/icons/${label.type}QualityLabel.svg`)"
        alt=""
        class="quality-labels-label-img"
      />
      <span v-if="big" class="quality-labels-label-text">{{ label.name }}</span>
      <QualityLabelInfo
        v-if="showHoverInfo"
        :label="label"
        :hovered="labelHovered === label.uuid"
        :align-left="infoLeftAligned"
      />
    </div>
  </div>
</template>

<script>
import QualityLabelInfo from './QualityLabelInfo.vue'

export default {
  name: 'QualityLabelGroup',
  components: { QualityLabelInfo },
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    big: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    showHoverInfo: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    labelHovered: '',
    hoverX: -1
  }),
  computed: {
    infoLeftAligned() {
      return this.hoverX === -1 || this.hoverX < window.innerWidth / 2
    }
  },
  methods: {
    handleHoverEnter(e, label) {
      this.labelHovered = label.uuid
      this.hoverX = e.clientX
    },
    handleHoverLeave() {
      this.labelHovered = ''
    }
  }
}
</script>

<style scoped lang="scss">
.quality-labels {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;

  &-label {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
    background: white;
    border-radius: 999rem;
    padding: 0.25rem;
    user-select: none;

    &.pill {
      width: unset;
      padding: 0.5rem 1rem;
    }

    &.bordered {
      border: 1px solid rgba(#000, 8%);
    }

    &-img {
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 1800px) {
  .quality-labels-label {
    height: 2rem;
    width: 2rem;
  }
}
</style>
