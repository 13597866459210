<template>
  <div class="curate">
    <div class="curate-header-wrapper">
      <p class="curate-header">Curate this document</p>
      <p class="curate-subheader">
        Select the appropriate label for this document
      </p>
    </div>
    <div class="curate-labels">
      <p
        v-for="qlabel in qualityLabels"
        :key="qlabel.name"
        class="curate-labels-label"
        :class="{ active: selectedLabel.name === qlabel.name }"
        @click="handleLabelSelect(qlabel)"
      >
        <img
          :src="require(`@/assets/icons/${qlabel.type}QualityLabel.svg`)"
          alt=""
          class="curate-labels-label-icon"
        />
        {{ qlabel.name }}
      </p>
    </div>
    <div class="curate-comment">
      <img
        v-if="currentUser.avatar && !avatarError"
        :src="currentUser.avatar"
        alt=""
        class="curate-comment-avatar"
        @error="avatarError = true"
      />
      <p
        v-else
        class="curate-comment-avatar"
        :style="`background: ${avatarColor};`"
      >
        {{ initials }}
      </p>
      <textarea
        id="comment"
        v-model="comment"
        name="comment"
        maxlength="200"
        placeholder="Write a remark..."
        :disabled="inputsDisabled"
        class="curate-comment-input"
      ></textarea>
      <p class="curate-comment-count">{{ comment.length }}/200</p>
    </div>
    <div class="curate-btns">
      <p
        class="curate-btns-clear"
        :class="{ disabled: inputsDisabled }"
        @click="handleClear"
      >
        Clear
      </p>
      <p class="curate-btns-save" @click="saveLabel">Save curation</p>
    </div>
  </div>
</template>

<script>
import { updateResourceQualityLabel } from '@/services/qualityLabelService'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ResourceModalCuration',
  props: {
    label: {
      type: Object,
      required: true
    },
    resourceId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    avatarError: false,
    selectedLabel: {},
    comment: ''
  }),
  computed: {
    ...mapGetters(['qualityLabels', 'currentUser']),
    initials() {
      return this.$umodel.initials(this.currentUser).slice(0, 2)
    },
    avatarColor() {
      return this.$umodel.user_color(this.currentUser)
    },
    inputsDisabled() {
      return isEmpty(this.selectedLabel)
    }
  },
  mounted() {
    this.selectedLabel = this.label
  },
  methods: {
    handleLabelSelect(label) {
      this.selectedLabel = label.name === this.selectedLabel.name ? {} : label
    },
    handleClear() {
      this.selectedLabel = {}
      this.comment = ''
    },
    async saveLabel() {
      try {
        const isDelete = !isEmpty(this.label) && isEmpty(this.selectedLabel)
        await updateResourceQualityLabel(
          this.$route.params.workspace_id,
          this.resourceId,
          this.$route.query.act_as,
          ...(isDelete ? [] : [this.selectedLabel.uuid, this.comment])
        )
        this.$toast.success(
          'Updated labels',
          'Labels saved successfully, changes will apply within one minute.'
        )
      } catch (error) {
        this.$console.log(error)
        this.$toast.error(error, 'saving the labels')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.curate {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;

  &-header {
    font-size: 1.5rem;
    font-weight: 700;
    color: #303032;

    &-wrapper {
      text-align: center;
    }
  }

  &-subheader {
    color: #60666b;
  }

  &-labels {
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
    gap: 0.5rem;

    &-label {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      border: 1px solid #dddfe2;
      border-radius: 999rem;
      padding: 0.5rem 1rem;
      color: #60666b;
      cursor: pointer;

      &.active {
        border: 1px solid $primary;
        color: $primary;
      }

      &-icon {
        height: 1rem;
      }
    }
  }

  &-comment {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    width: 60%;
    position: relative;

    &-avatar {
      height: 2rem;
      aspect-ratio: 1 / 1;
      border-radius: 100%;
      color: white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
    }

    &-input {
      background: #f1f2f3;
      resize: none;
      border-radius: 4px;
      border: 1px solid #e9ebed;
      padding: 0.5rem 3rem 0.5rem 0.5rem;
      width: 100%;

      &:focus,
      &:focus-within {
        background: white;
      }
    }

    &-count {
      align-self: flex-end;
      color: rgba(#000, 32%);
      font-size: 0.8rem;
      padding: 0.2rem;
      user-select: none;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem 2rem;
    width: 100%;

    &-clear {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 999rem;
      border: 1px solid #dddfe2;
      user-select: none;

      &.disabled {
        pointer-events: none;
        opacity: 48%;
        filter: none; // overwrite buefy styling
      }
    }

    &-save {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 999rem;
      background: $primary;
      color: white;
      user-select: none;
    }
  }
}
</style>
