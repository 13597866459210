import '@/assets/scss/styles.scss'
import Console from '@/console'
import { fetchUserData } from '@/hydrate'
import injection from '@/injection'
import store, { Mutations } from '@/store/index'
import { extensionInstalled } from '@/util'
import { configureScope, init as initSentry } from '@sentry/browser'
import { Vue as IntegrationsVue } from '@sentry/integrations'
import Buefy from 'buefy'
import Vue from 'vue'
import VueCalendarHeatmap from 'vue-calendar-heatmap'
import infiniteScroll from 'vue-infinite-scroll'
import LoadScript from 'vue-plugin-load-script'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'
import { Promised } from 'vue-promised'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { getVersion } from './util.js'

Vue.use(LoadScript)
Vue.use(Vuex)
Vue.use(Buefy)
Vue.component('Promised', Promised)
Vue.use(VueCalendarHeatmap)
Vue.use(LottieVuePlayer)
Vue.use(infiniteScroll)
injection(Vue)

// const config = store.dispatch("getConfig")

if (
  'VUE_APP_SENTRY_ENABLED' in process.env &&
  process.env.VUE_APP_SENTRY_ENABLED == '1'
) {
  Console.info('Sentry error reporting enabled')
  let env = process.env.NODE_ENV || 'production'
  initSentry({
    dsn: 'https://1bdf5e123afe4be3b9653bf42638f598@o1108422.ingest.sentry.io/6137488',
    integrations: [new IntegrationsVue({ Vue, attachProps: true })],
    environment: env,
    sampleRate: 0.1
  })
}

Vue.config.productionTip = false

function getSidebarIFrame() {
  const iframe = document.getElementById('umanai-extension-frame')
  if (iframe) {
    return iframe.contentWindow
  }
  Console.debug('No umanai extension found')
  return null
}

function postMessageToExtension(message) {
  if (extensionInstalled()) {
    const frame = getSidebarIFrame()
    if (frame) {
      Console.debug('Posting message to extension', message)
      frame.postMessage(message, '*')
      return true
    }
  }
  return false
}

function startSendRefreshTokenCycle() {
  const intervalFunc = () => {
    postMessageToExtension({
      event: 'update:refreshToken',
      payload: store.state.authentication.refreshToken
    })
  }
  intervalFunc()
}

function sendActiveWorkspace({ uuid }) {
  postMessageToExtension({
    event: 'update:activeWorkspace',
    payload: uuid
  })
}

async function main() {
  // check version and clear old keys if required
  if (localStorage.getItem('version') == null) {
    localStorage.clear()
    localStorage.setItem('version', getVersion())
  }

  // watcher for setting workspace data
  // Needs to become before next function
  // because await fetchinitialData blocks this watcher
  store.watch(
    (state) => state.workspaces.activeWorkspace,
    async (workspace) => {
      sendActiveWorkspace(workspace)
      if (workspace != null) {
        configureScope(function (scope) {
          scope.setTag('workspace-id', workspace.uuid)
          scope.setTag('workspace-name', workspace.name)
        })
      } else {
        configureScope(function (scope) {
          scope.setTag('workspace-id', null)
          scope.setTag('workspace-name', null)
        })
        router.push({ name: 'createWorkspace' })
      }
    }
  )

  const refreshToken = localStorage.getItem('refreshToken')
  store.commit(Mutations.SET_REFRESH_TOKEN, refreshToken)
  try {
    await store.dispatch('refresh')
    await fetchUserData(store)
  } catch (err) {
    store.dispatch('logout')
  }

  startSendRefreshTokenCycle()

  store.watch(
    (state) => state.authentication.refreshToken,
    (newVal) => {
      if (newVal != null) {
        localStorage.setItem('refreshToken', newVal)
      } else {
        localStorage.removeItem('refreshToken')
      }
      postMessageToExtension({
        event: 'update:refreshToken',
        payload: newVal
      })
    }
  )

  // watcher for setting sentry user
  store.watch(
    (state) => state.users.currentWorkspaceMember,
    (user) => {
      if (user != null) {
        configureScope(function (scope) {
          scope.setUser({
            id: user.uuid,
            username: user.user.username,
            email: user.user.username
          })
        })
      } else {
        configureScope(function (scope) {
          scope.setUser(null)
        })
      }
    }
  )

  const instance = new Vue({
    router,
    store,
    methods: {
      toast({ type, ...args }) {
        this.$refs.app.toast(type, args)
      }
    },
    render: function (createElement) {
      return createElement(App, { ref: 'app' })
    }
  })
  instance.$mount('#uman-app')

  window.onerror = (err) => {
    Console.error(err)
  }
}

main()
