<template>
  <div class="columns no-margin is-mobile">
    <div class="column no-padding">
      <div class="u-modal" :class="{ 'pb-6': !noPadding, 'pa-5': !noPadding }">
        <ModalHeader :no-icon="noIcon" :no-title="noTitle">
          <template v-slot:icon>
            <slot name="icon" />
          </template>
          <template v-slot:title>
            <slot name="title" />
          </template>
          <template v-slot:right>
            <slot name="title-right" />
          </template>
        </ModalHeader>
        <div :class="{ 'mt-5': !noPadding }" style="position: relative">
          <slot />
          <b-loading :active="loading" :is-full-page="false" />
        </div>
      </div>
    </div>
    <div
      v-if="withCloseIcon"
      class="column no-padding is-narrow modal-close-icon"
    >
      <div class="pl-2">
        <b-button
          class="u-fab light transparent"
          icon-right="close-circle"
          size="is-large"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from './ModalHeader.vue'

export default {
  name: 'DefaultModal',
  components: { ModalHeader },
  props: {
    withCloseIcon: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
