


























import { SubResourceUnits } from '@c/mimetypes'
import { SubResource } from '@c/models/Resource'
import CollectSlideButton from '@c/shared/molecules/object-visualisations/resource/subcomponents/CollectSlideButton.vue'
import Vue from 'vue'
import QualityLabels from './QualityLabels.vue'
import SlideDeck from '@c/library/SlideDeck.vue'

export default Vue.extend({
  name: 'SubResourceSlideDeck',
  components: {
    CollectSlideButton,
    QualityLabels,
    SlideDeck
  },
  props: {
    subresources: {
      type: Array as () => SubResource[],
      default: []
    },
    subresourceAmount: {
      type: Number as () => number,
      default: -1
    },
    selectedPage: {
      type: Number as () => number,
      default: -1
    },
    subresourcesLoading: {
      type: Boolean as () => boolean,
      default: false
    },
    fallbackSubresources: {
      type: Array as () => SubResource[],
      default: []
    },
    selectable: {
      type: Boolean as () => boolean,
      default: false
    },
    resourceId: {
      type: String,
      default: undefined
    },
    resourceTraceId: {
      type: String,
      default: undefined
    },
    unit: {
      type: String,
      default: SubResourceUnits.SLIDE
    },
    mimetype: {
      type: String,
      required: true
    },
    label: {
      type: Object,
      default: undefined
    },
    forceShowCollect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      forcePage: 0,
      subresourcesLoaded: false
    }
  },
  watch: {
    selectedPage(newVal) {
      this.setCurrentPage(newVal)
    },
    subresourcesLoading(newVal) {
      if (!newVal && !this.subresourcesLoaded) {
        this.subresourcesLoaded = true
        if (this.subresources?.length) this.setCurrentPage(this.selectedPage)
      }
    }
  },
  methods: {
    setCurrentPage(page: number) {
      this.forcePage =
        (this.subresources || []).findIndex((x) => x.page === page) || 0
    }
  }
})
