




























import FileToImageConvertMixin from '@c/mixins/FileToImageConvertMixin'
import { isString } from 'lodash-es'
import Vue from 'vue'
export default Vue.extend({
  name: 'ImageFilePicker',
  mixins: [FileToImageConvertMixin],
  props: {
    value: {
      type: String,
      default: undefined
    },
    round: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'xxl'
    },
    onlyHover: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mappedImage: undefined
    }
  },
  computed: {
    image() {
      return this.mappedImage || this.value
    }
  },
  watch: {
    async value(newVal: string | File) {
      if (isString(this.value)) {
        this.mappedImage = newVal
      }
      this.mappedImage = await this.asyncImageToURL(newVal)
    }
  },
  methods: {
    openUpload() {
      this.$refs.upload.getElement().click()
    }
  }
})
