import Console from '@/console'
import store from '@/store'

export function addInviteGuard(router) {
  router.beforeEach(async (to, from, next) => {
    if (store.getters.debugGuards)
      Console.debug('Invite guard (to, from)', to, from)
    if (to.name !== 'InviteRedirect') return next()
    const currentUser = store.getters.currentUser
    if (currentUser && currentUser.username === to.query.email)
      return next({
        path: `/invitation/accept/${to.params.workspace_id}/${to.query.code}`
      })
    if (currentUser && currentUser.username !== to.query.email)
      await store.dispatch('logout')
    if (to.query.exists === 'false')
      return next({
        path: `/signup`,
        query: {
          email: to.query.email,
          code: to.query.code,
          expires: to.query.expires
        }
      })
    next({
      path: `/login`,
      query: {
        email: to.query.email,
        redirect: `/invitation/accept/${to.params.workspace_id}/${to.query.code}`
      }
    })
  })
}
