// @ts-nocheck
import DialogModal from '@c/shared/molecules/structural/modals/DialogModal.vue'
import DoubleActionDialog from '@c/shared/molecules/structural/modals/DoubleActionDialogModal.vue'
import NoShowAgainModal from '@c/shared/molecules/structural/modals/NoShowAgainDialogModal.vue'

export function deleteDialog(
  parent: any,
  objectType: string,
  message: string,
  name?: string,
  onConfirm: (object?: any) => boolean | Promise<boolean>,
  object?: any,
  propsOverwrites?: any,
  modalPropsOverwrites?: any
): any {
  return parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: DialogModal,
    width: 400,
    trapFocus: true,
    props: {
      object,
      objectType,
      message,
      name,
      onConfirm,
      modificationType: 'delete',
      ...(propsOverwrites || {})
    },
    events: {},
    ...(modalPropsOverwrites || {})
  })
}

export function doubleActionDialog(
  parent: any,
  objectType: string,
  message: string,
  name: string,
  onConfirmAction1: (object?: any) => boolean | Promise<boolean>,
  onConfirmAction2: (object?: any) => boolean | Promise<boolean>,
  action1: string,
  action2: string,
  object?: any,
  propOverwrites?: any
): any {
  return parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: DoubleActionDialog,
    trapFocus: true,
    props: {
      object,
      objectType,
      message,
      name,
      action1,
      action2,
      onConfirmAction1,
      onConfirmAction2,
      modificationType: 'delete',
      ...propOverwrites
    },
    events: {}
  })
}

export function doubleActionDialogWithTitleAndHeader(
  parent: any,
  title: string,
  header: string,
  message: string,
  onConfirmAction1: (object?: any) => boolean | Promise<boolean>,
  onConfirmAction2: (object?: any) => boolean | Promise<boolean>,
  action1: string,
  action2: string,
  propOverwrites?: any
): any {
  return parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: DoubleActionDialog,
    trapFocus: true,
    props: {
      title,
      header,
      message,
      action1,
      action2,
      onConfirmAction1,
      onConfirmAction2,
      modificationType: 'delete',
      ...propOverwrites
    },
    events: {}
  })
}

export function leaveDialog(
  parent: any,
  objectType: string,
  message: string,
  name: string,
  onConfirm: (object?: any) => boolean | Promise<boolean>,
  object?: any,
  propsOverwrites?: any
): any {
  return parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: DialogModal,
    width: 500,
    trapFocus: true,
    props: {
      object,
      objectType,
      message,
      name,
      onConfirm,
      modificationType: 'leave',
      ...(propsOverwrites || {})
    },
    events: {}
  })
}

export function noShowAgainDialog(
  parent,
  title,
  subtitle,
  message,
  onConfirmText,
  onConfirm,
  storeKey,
  propsOverwrites?: any
) {
  return parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: NoShowAgainModal,
    width: 500,
    trapFocus: true,
    props: {
      title,
      subtitle,
      message,
      onConfirm,
      storeKey,
      onConfirmText,
      ...(propsOverwrites || {})
    },
    events: {}
  })
}
