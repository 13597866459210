

























import { WORKSPACE_ROLES } from '@c/models/Workspace'
import SplitInput from '@c/shared/molecules/inputs/SplitInput.vue'
import WorkspaceRoleSelect from '@c/shared/molecules/inputs/workspace-specific/WorkspaceRoleSelect.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'EmailInputWithAccessType',
  components: {
    SplitInput,
    WorkspaceRoleSelect
  },
  props: {
    email: {
      type: String as PropType<string>,
      default: undefined
    },
    accessType: {
      type: String as PropType<WORKSPACE_ROLES | undefined>,
      default: undefined
    },
    noAccessType: {
      type: Boolean,
      default: false
    },
    currentUserRole: {
      type: String as PropType<WORKSPACE_ROLES | undefined>,
      required: true
    }
  }
})
