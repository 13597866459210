


















import {
  allModalComponents,
  CreateStepsEnum,
  modalComponents,
  stepTitles
} from '@c/features/create-workspace-modal/interfaces'
import DefaultModal from '@c/shared/molecules/structural/modals/DefaultModal.vue'
import Vue from 'vue'

const components = { DefaultModal, ...allModalComponents }

export default Vue.extend<any, any, any, any>({
  name: 'CreateWorkspaceModal',
  components: components as any,
  props: {
    step: {
      type: String as () => CreateStepsEnum,
      default: CreateStepsEnum.CREATE
    },
    stepData: {
      type: Object as () => any,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    currentComponent() {
      return modalComponents[this.step as CreateStepsEnum]
    },
    title() {
      return stepTitles[this.step as CreateStepsEnum]
    }
  }
})
