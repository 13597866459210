import { IntegrationProvider } from '@c/integrations'
import { SYNC_STATUS } from '@c/models/Integration'
import { getAppURL } from '../../../util'
import { Tag } from './Tag'
import { UserMinimal } from './User'
import { WorkspaceMemberMinimal } from './WorkspaceMember'

export interface ActivitySignal {
  type: string
  user: UserMinimal
  date: Date
  count: number
}

export interface IntegrationData {
  uuid: string
  status: SYNC_STATUS
  status_message?: string
  last_sync: string
  integration: IntegrationProvider
  folder_url?: string
  external_modified_by?: WorkspaceMemberMinimal | null
  external_created_by: WorkspaceMemberMinimal | null
  external_modified_date?: Date
  external_created_date: Date
  mimetype: string
  path: string[]
  activity: ActivitySignal[]
  permissions: IntegrationPermissions
}

export interface IntegrationPermissions {
  is_public: Boolean
}

export interface Highlight {
  field: string
  highlight: string
}

export interface SubResource {
  page: number
  preview: string
  tags: Tag[]
}

export interface SimpleTopic {
  name: string
  description: string
  uuid: string
}

export enum RESOURCE_TYPE {
  WEBPAGE = 'webpage',
  IMAGE = 'image',
  PRESENTATION = 'presentation',
  DOCUMENT = 'document',
  TABLE = 'table',
  DATABASE = 'database',
  VIDEO = 'video',
  CODE_REPOSITORY = 'code repository',
  PROCESS_CHANGE = 'process change',
  OTHER = 'other'
}

export default interface Resource {
  uuid: string
  image: string
  url: string
  date_created: Date
  date_updated: Date
  title: string
  workspace_id: string
  integrationfile: IntegrationData | null
  topics: SimpleTopic[]
  highlights?: Highlight[]
  type?: RESOURCE_TYPE
  content_class?: string
  organisations: string[]
  industries: string[]
  language: string
  subresources?: {
    highlights: Highlight[]
    subresource: SubResource
  }[]
  total_subresources: number
}

export enum VISUALISATION_TYPES_ENUM {
  CARD = 'Card',
  USER_CARD = 'UserCard',
  TILE = 'Tile',
  LIST = 'List',
  LIST_DENSE = 'ListDense',
  IMAGE = 'Image',
  VIDEO = 'Video',
  SLIDE = 'Slide',
  SNIPPET = 'Snippet'
}

export const CARD = VISUALISATION_TYPES_ENUM.CARD
export const TILE = VISUALISATION_TYPES_ENUM.TILE
export const LIST = VISUALISATION_TYPES_ENUM.LIST
export const VISUALISATION_TYPES = [
  CARD,
  TILE,
  LIST,
  VISUALISATION_TYPES_ENUM.USER_CARD,
  VISUALISATION_TYPES_ENUM.LIST_DENSE,
  VISUALISATION_TYPES_ENUM.IMAGE,
  VISUALISATION_TYPES_ENUM.VIDEO,
  VISUALISATION_TYPES_ENUM.SLIDE,
  VISUALISATION_TYPES_ENUM.SNIPPET
]

export const VISUALISATION_TYPE_ICON_MAP: { [key: string]: string } = {
  [CARD]: 'view-grid',
  [TILE]: 'view-compact',
  [LIST]: 'format-list-bulleted'
}

export function getLink(resource: Resource): string {
  if (
    !resource.integrationfile ||
    resource.integrationfile.mimetype === '' ||
    resource.integrationfile.mimetype !== 'text/html'
  )
    return resource.url

  return `${getAppURL()}/html_renderer?blob=${encodeURIComponent(
    resource.url!!
  )}`
}

export const INTERSECTION_TYPES = {
  duplicate: 'duplicate',
  intersection: 'intersection',
  lsh: 'lsh'
}
