var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WorkspaceHeaderModal",
    {
      attrs: {
        workspace: _vm.workspace,
        subtitle: "Connect " + _vm.integration.title
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title-right",
          fn: function() {
            return [
              _c("img", {
                staticStyle: { width: "20px", height: "auto" },
                attrs: { src: _vm.integration.image }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("BackButton", { on: { click: _vm.openWorkspaceSettings } }),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(_vm.integrationComponent, {
            tag: "component",
            attrs: { integration: _vm.integration },
            on: {
              save: function(x) {
                return _vm.$emit("save", x)
              },
              close: function($event) {
                return _vm.$emit("close")
              },
              back: _vm.openWorkspaceSettings
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }