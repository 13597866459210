import { render, staticRenderFns } from "./IntegrationLink.vue?vue&type=template&id=4916fb23&scoped=true&"
import script from "./IntegrationLink.vue?vue&type=script&lang=ts&"
export * from "./IntegrationLink.vue?vue&type=script&lang=ts&"
import style0 from "./IntegrationLink.vue?vue&type=style&index=0&id=4916fb23&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4916fb23",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4916fb23')) {
      api.createRecord('4916fb23', component.options)
    } else {
      api.reload('4916fb23', component.options)
    }
    module.hot.accept("./IntegrationLink.vue?vue&type=template&id=4916fb23&scoped=true&", function () {
      api.rerender('4916fb23', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/shared/molecules/object-visualisations/resource/subcomponents/IntegrationLink.vue"
export default component.exports