<template>
  <div class="u-columns is-vcentered pv-2" v-on="$listeners">
    <div class="u-column mh-2 is-narrow">
      <slot name="avatar" />
    </div>
    <div class="u-column mh-2 is-narrow">
      <slot name="text" />
      <slot name="subtext" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarWithText'
}
</script>

<style scoped></style>
