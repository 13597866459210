var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideDeck",
    _vm._g(
      {
        attrs: { items: _vm.subresources, "force-index": _vm.forcePage },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function(ref) {
              var index = ref.index
              return [
                _c(
                  "div",
                  { staticClass: "buttons-container" },
                  [
                    _vm.label
                      ? _c("QualityLabels", {
                          attrs: {
                            "active-label": _vm.label,
                            "source-id": _vm.resourceId,
                            "small-labels": false,
                            "show-hover-info": false,
                            "show-edit-labels": false,
                            "bordered-icons": true
                          }
                        })
                      : _vm._e(),
                    _vm.subresources.length > 0 && _vm.subresources[index]
                      ? _c("CollectSlideButton", {
                          attrs: {
                            subresource: _vm.subresources[index],
                            rank: _vm.selectedPage,
                            mimetype: _vm.mimetype,
                            "resource-trace-id": _vm.resourceTraceId,
                            "always-show": _vm.forceShowCollect,
                            big: ""
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }