var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "links", attrs: { id: "homeinsightsblock" } },
    [
      _vm.currentWorkspace
        ? _c("p", { staticClass: "links-header" }, [
            _vm._v(
              " What's happening at " + _vm._s(_vm.currentWorkspace.name) + " "
            )
          ])
        : _vm._e(),
      !_vm.showLoadingSkeleton
        ? _c(
            "b-tabs",
            {
              attrs: {
                position: "is-centered",
                "has-drag": true,
                autoplay: false
              },
              model: {
                value: _vm.carouselPage,
                callback: function($$v) {
                  _vm.carouselPage = $$v
                },
                expression: "carouselPage"
              }
            },
            _vm._l(_vm.carouselPages, function(page, i) {
              return _c(
                "b-tab-item",
                {
                  key: i,
                  attrs: { label: _vm.pageHeaders[page] },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c(
                              "p",
                              {
                                class: {
                                  "links-item-header": true,
                                  active: _vm.carouselPage === i
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "links-item-header-icon",
                                  attrs: {
                                    src: require("@/assets/icons/" +
                                      _vm.pageHeaderIcons[page] +
                                      ".svg"),
                                    alt: ""
                                  }
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.pageHeaders[page]) + " "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("div", { staticClass: "links-item" }, [
                    !_vm.contentEmpty[page]
                      ? _c("div", { staticClass: "links-item-columns" }, [
                          _c(
                            "div",
                            { staticClass: "links-item-cols-col-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "links-item-columns-col-header"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "links-item-columns-col-header-text"
                                    },
                                    [_vm._v("Topics")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: {
                                    "links-item-columns-col": true,
                                    "col-overflow": page === "top"
                                  }
                                },
                                _vm._l(
                                  _vm.contentMapping[page].topics,
                                  function(item) {
                                    return _c(
                                      "router-link",
                                      {
                                        key: item.name,
                                        attrs: { to: _vm.insightRoute(item) }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "links-item-columns-col-item",
                                            attrs: { title: item.name },
                                            on: {
                                              click: function($event) {
                                                return _vm.searchInsight(page)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "links-item-columns-col-item-text"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "links-item-cols-col-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "links-item-columns-col-header"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "links-item-columns-col-header-text"
                                    },
                                    [_vm._v("Industries")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: {
                                    "links-item-columns-col": true,
                                    "col-overflow": page === "top"
                                  }
                                },
                                _vm._l(
                                  _vm.contentMapping[page].industries,
                                  function(item) {
                                    return _c(
                                      "router-link",
                                      {
                                        key: item.name,
                                        attrs: { to: _vm.insightRoute(item) }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "links-item-columns-col-item",
                                            attrs: { title: item.name },
                                            on: {
                                              click: function($event) {
                                                return _vm.searchInsight(page)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "links-item-columns-col-item-text"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "links-item-cols-col-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "links-item-columns-col-header"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "links-item-columns-col-header-text"
                                    },
                                    [_vm._v("Customers")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  class: {
                                    "links-item-columns-col": true,
                                    "col-overflow": page === "top"
                                  }
                                },
                                _vm._l(
                                  _vm.contentMapping[page].organisations,
                                  function(item) {
                                    return _c(
                                      "router-link",
                                      {
                                        key: item.name,
                                        attrs: { to: _vm.insightRoute(item) }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "links-item-columns-col-item",
                                            attrs: { title: item.name },
                                            on: {
                                              click: function($event) {
                                                return _vm.searchInsight(page)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "links-item-columns-col-item-text"
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ]
                          )
                        ])
                      : _c("div", { staticClass: "links-item-empty" }, [
                          _c("p", { staticClass: "links-item-empty-message" }, [
                            _vm._v(" " + _vm._s(_vm.emptyMessage[page]) + " ")
                          ])
                        ])
                  ])
                ]
              )
            }),
            1
          )
        : _c("div", { staticClass: "links-loading" }, [
            _c(
              "div",
              { staticClass: "links-loading-header" },
              [_c("b-skeleton", { attrs: { width: "20rem", height: "100%" } })],
              1
            ),
            _c(
              "div",
              { staticClass: "links-loading-cols" },
              [
                _c("b-skeleton", { attrs: { width: "16rem", height: "100%" } }),
                _c("b-skeleton", { attrs: { width: "16rem", height: "100%" } }),
                _c("b-skeleton", { attrs: { width: "16rem", height: "100%" } })
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }