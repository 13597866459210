<template>
  <div class="loading-dots" :class="{ [animation]: true }" :style="style">
    <div class="loading-dots-dot"></div>
    <div class="loading-dots-dot"></div>
    <div class="loading-dots-dot"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingDots',
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    animation: {
      type: String,
      default: 'bounce',
      validator: (value) => {
        return ['bounce', 'flicker'].includes(value)
      }
    }
  },
  computed: {
    style() {
      return `--dot-color: ${this.color}`
    }
  }
}
</script>

<style scoped lang="scss">
.loading-dots {
  height: 1rem;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.3rem;
  width: fit-content;
  display: inline-flex;

  &.bounce {
    align-items: flex-end;
    & > .loading-dots-dot {
      animation-name: bounce;
    }
  }

  &.flicker {
    align-items: center;
    & > .loading-dots-dot {
      animation-name: flicker;
    }
  }

  &-dot {
    height: 3px;
    width: 3px;
    border-radius: 100%;
    background: var(--dot-color);
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &:nth-of-type(2) {
      animation-delay: 150ms;
    }

    &:nth-of-type(3) {
      animation-delay: 300ms;
    }
  }
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-4px);
  }
}

@keyframes flicker {
  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}
</style>
