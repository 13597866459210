import { getClient } from 'umanai-vuex/src/axios'

const client = getClient('/workspaces')

export async function getQualityLabels(workspaceId) {
  try {
    const { data } = await client.get(`/${workspaceId}/labels/`)
    return data.results
  } catch (error) {
    //TODO log error?
  }
}

export async function updateResourceQualityLabel(
  workspaceId,
  resourceId,
  impersonatedMember,
  label = null,
  comment = ''
) {
  const requestBody = {
    uuid: label,
    comment
  }
  const headers = {
    ...(impersonatedMember
      ? {
          params: {
            act_as: impersonatedMember
          }
        }
      : {})
  }
  await client.put(
    `/${workspaceId}/resources/${resourceId}/label/`,
    requestBody,
    headers
  )
}
