var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WorkspaceHeaderModal",
    {
      attrs: { workspace: _vm.workspace, subtitle: "Settings" },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "ThirdPartyTab",
        _vm._g(
          {
            attrs: {
              workspace: _vm.workspace,
              "active-integrations": _vm.activeIntegrations
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }