var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        mouseenter: function($event) {
          return _vm.mouseOver()
        },
        mouseleave: function($event) {
          return _vm.mouseLeave()
        }
      }
    },
    [
      _c(
        "b-dropdown",
        {
          ref: "dropdown",
          attrs: {
            triggers: ["hover"],
            "mobile-modal": false,
            position: "is-top-right"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "fit-content",
                height: "fit-content",
                position: "relative"
              },
              attrs: { slot: "trigger" },
              on: {
                click: function($event) {
                  return _vm.$emit("click")
                }
              },
              slot: "trigger"
            },
            [
              _vm.orb
                ? _vm._t("orb", function() {
                    return [
                      _c("div", {
                        staticClass: "orb",
                        style: {
                          width: _vm.width / 3 + "px",
                          height: _vm.height / 3 + "px"
                        }
                      })
                    ]
                  })
                : _vm._e(),
              _c(
                "DefaultImage",
                {
                  staticClass: "avatar-container",
                  style: { width: _vm.width + "px", height: _vm.height + "px" },
                  attrs: { src: _vm.avatar, fallback: _vm.fallback }
                },
                [_vm._t("default")],
                2
              )
            ],
            2
          ),
          _c("b-dropdown-item", { attrs: { custom: "", paddingless: "" } }, [
            _c(
              "div",
              {
                staticClass: "fit-width",
                staticStyle: { "margin-bottom": "16px" }
              },
              [_vm._t("dropdown")],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }