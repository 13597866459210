var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultModal",
    _vm._g(
      {
        staticClass: "dialog-modal",
        attrs: { "no-padding": "", "with-close-icon": "" },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _vm._t("title", function() {
                    var _obj
                    return [
                      _c("div", { staticClass: "pt-5 pl-5" }, [
                        _c(
                          "p",
                          {
                            staticClass: "body xs bold modal-title",
                            class:
                              ((_obj = {}),
                              (_obj["has-text-" + _vm.type] = true),
                              _obj)
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.title ||
                                    _vm.$umodel.capitalize(
                                      _vm.modificationType
                                    ) +
                                      " " +
                                      _vm.objectType
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "mv-5" }, [
        _c("div", { staticClass: "divider no-margin semi-hard" })
      ]),
      _c(
        "div",
        { staticClass: "ph-5 pb-5" },
        [
          _vm._t("default", function() {
            return [
              _c("p", { staticClass: "header bold" }, [
                _vm.header
                  ? _c("span", [_vm._v(" " + _vm._s(_vm.header) + " ")])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$umodel.capitalize(_vm.modificationType)) +
                          " "
                      ),
                      _vm.name
                        ? _c("span", [_vm._v('"' + _vm._s(_vm.name) + '"')])
                        : _vm._e()
                    ])
              ]),
              _c("p", {
                staticClass: "mt-2 body body-text",
                domProps: { innerHTML: _vm._s(_vm.message) }
              })
            ]
          }),
          _vm._t("actions", function() {
            return [
              _c("div", { staticClass: "pt-6 u-columns space-between" }, [
                _c(
                  "div",
                  { staticClass: "u-column is-narrow" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "confirm",
                        attrs: { type: "is-" + _vm.type, loading: _vm.loading },
                        on: { click: _vm.confirm }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.action ||
                                _vm.$umodel.capitalize(_vm.modificationType) +
                                  " " +
                                  _vm.objectType
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "u-column is-narrow" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "cancel",
                        attrs: { loading: _vm.loading },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_vm._v(" Cancel ")]
                    )
                  ],
                  1
                )
              ])
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }