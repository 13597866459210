var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language-select", class: { short: _vm.short } },
    [
      _vm.title
        ? _c("span", { staticClass: "language-select-title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _c("Dropdown", {
        attrs: {
          items: _vm.options,
          "full-width": true,
          "position-fixed": _vm.positionFixed,
          "content-style": _vm.contentStyle
        },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function() {
              return [
                _c("div", { staticClass: "language-select-trigger" }, [
                  _vm._v(
                    " " + _vm._s(_vm.short ? _vm.value : _vm.valueName) + " "
                  ),
                  _c("img", {
                    staticClass: "language-select-trigger-icon",
                    attrs: {
                      src: require("@/assets/icons/chevron-down-medium.svg"),
                      alt: ""
                    }
                  })
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }