<template>
  <div id="carousel-container" @click.stop>
    <div
      v-for="direction in showArrows ? ['left', 'right'] : []"
      :id="`${direction}-chevron`"
      :key="direction"
      class="arrow-position cursor-pointer"
      :class="{
        [`arrow-${direction}`]: true,
        hidden: direction === 'left' ? isStart : isEnd,
        vertical
      }"
      @click="scroll(direction === 'left' ? -1 : 1)"
      @mouseover="arrowHovered[direction] = true"
      @mouseleave="arrowHovered[direction] = false"
    >
      <div
        class="arrow-scrim"
        :class="{ vertical, [`arrow-scrim-${direction}`]: true }"
      >
        <div class="arrow-scrim-container">
          <img
            :class="{
              'arrow-scrim-img': true,
              [`arrow-scrim-dropshadow-${direction}`]: !arrowHovered[direction]
            }"
            :src="arrowHovered[direction] ? RightArrowHover : RightArrow"
            alt=">"
          />
        </div>
      </div>
    </div>
    <div
      v-for="side in dragging
        ? vertical
          ? ['top', 'bottom']
          : ['left', 'right']
        : []"
      :key="`dragging-${side}`"
      class="carousel-scroll-hover"
      :class="{ [side]: true }"
    ></div>
    <div
      id="items"
      ref="items"
      :class="{ vertical, scrollable: showArrows, textual: textItems }"
      @touchdrag.prevent
      @touchend="handleScrollEnd"
      @wheel="handleScroll"
      @click.stop
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :ref="itemId(item)"
        class="carousel-item"
        :class="{ vertical, draggable, textual: textItems }"
        @mousedown="(e) => startDrag(e, index)"
        @click="() => endDrag(false)"
        @dragstart.prevent
      >
        <div
          class="carousel-item-dropzone-anchor"
          :class="{
            hovered: dragging && index === dragOverIdx,
            active: dragging,
            vertical
          }"
          :style="anchorDimensions"
        >
          <div
            :id="`carousel-dropzone-${index}-${carouselKey}`"
            :class="{
              'carousel-item-dropzone': true,
              vertical
            }"
            :style="dropzoneDimensions"
          ></div>
        </div>
        <div ref="slotitem">
          <slot :item="item" :idx="index" />
        </div>
        <div
          v-if="dragging && dragIdx === index"
          :id="`preview-${index}`"
          class="drag-preview"
          @mouseup="endDrag()"
          @mousemove="handleMouseMove"
        >
          <p
            v-if="textItems"
            class="drag-preview-text"
            :style="{ width: `${itemWidth}px` }"
          >
            {{ itemText(item) }}
          </p>
          <img
            v-else
            :src="previewKey(item)"
            alt=""
            class="drag-preview-img"
            :style="{ height: `${itemHeight}px` }"
          />
        </div>
      </div>
      <div
        v-if="dragging"
        class="carousel-item-dropzone-anchor"
        :class="{
          active: dragging,
          hovered: dragging && items.length === dragOverIdx,
          vertical
        }"
        :style="anchorDimensions"
      >
        <div
          :id="`carousel-dropzone-${items.length}-${carouselKey}`"
          :class="{
            'carousel-item-dropzone': true,
            vertical
          }"
          :style="dropzoneDimensions"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import RightArrowHover from '@c/assets/icons/carousel-arrow-right-hover.svg'
import RightArrow from '@c/assets/icons/carousel-arrow-right.svg'
import Vue from 'vue'

const contains2D = (mouse, el) => {
  return (
    mouse.x > el.x &&
    mouse.x < el.x + el.width &&
    mouse.y > el.y &&
    mouse.y < el.y + el.height
  )
}

export default Vue.extend({
  name: 'DraggableCarousel',
  components: {},
  props: {
    items: {
      type: Array,
      default: undefined
    },
    marginBetween: {
      type: Number,
      default: 4
    },
    carouselKey: {
      type: String,
      default: 'carousel'
    },
    scrollTrigger: {
      type: [Number, String, Boolean],
      default: undefined
    },
    showArrows: {
      type: Boolean,
      default: true
    },
    vertical: {
      type: Boolean,
      default: false
    },
    previewKey: {
      type: Function,
      default: (item) => item.preview
    },
    draggable: {
      type: Boolean,
      default: true
    },
    textItems: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      RightArrow: RightArrow,
      RightArrowHover: RightArrowHover,
      arrowHovered: {
        left: false,
        right: false
      },
      isStart: true,
      isEnd: true,
      container: undefined,
      dragging: false,
      dragIdx: -1,
      dragOverIdx: -1,
      dragOffset: undefined,
      itemWidth: 0,
      itemHeight: 0,
      dimensionPoller: undefined,
      scrollInterval: undefined
    }
  },
  computed: {
    anchorDimensions() {
      return {
        minWidth: `${this.vertical ? '' : '3px'}`,
        minHeight: `${this.vertical ? 3 : this.itemHeight}px`,
        margin: `${this.vertical ? this.marginBetween : '0'}px ${
          this.vertical ? '0' : this.marginBetween
        }px`
      }
    },
    dropzoneDimensions() {
      const delta = (this.vertical ? this.itemHeight : this.itemWidth) / 10
      const distance =
        (this.vertical ? this.itemHeight : this.itemWidth) / 2 - delta
      return {
        top: `-${this.vertical ? distance : 5}px`,
        left: `-${this.vertical ? 5 : distance}px`,
        right: `-${this.vertical ? 5 : distance}px`,
        bottom: `-${this.vertical ? distance : 5}px`
      }
    }
  },
  watch: {
    items(newVal, oldVal) {
      if ((newVal?.length || 0) > (oldVal?.length || 0)) {
        this.scrollToEnd()
        this.checkScroll()
      }
      this.$nextTick(() => {
        this.checkItemDimensions()
      })
    },
    scrollTrigger() {
      this.scrollToEnd()
      this.checkScroll()
    }
  },
  mounted() {
    this.container = this.$refs.items
    this.dimensionPoller = setInterval(() => {
      this.checkItemDimensions()
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.dimensionPoller)
  },
  methods: {
    itemId(item) {
      return (
        item.uuid ||
        item.name ||
        item[
          Object.keys(item).find((k) => typeof item[k] === 'string') ||
            Object.keys(item)[0]
        ].toString()
      )
    },
    itemText(item) {
      return item.name || item.title || item.text || item.toString()
    },
    innerKey(idx, innerIdx) {
      return `${idx}-${innerIdx}`
    },
    checkItemDimensions() {
      if (this.itemWidth !== 0) {
        clearInterval(this.dimensionPoller)
        this.dimensionPoller = undefined
        return
      }
      const item = this.$refs.slotitem[0]
      if (!item) return
      const { width, height } = item.getBoundingClientRect()
      this.itemWidth = width
      this.itemHeight = height
    },
    checkScroll() {
      const start = `scroll${this.vertical ? 'Top' : 'Left'}`
      const distance = `${this.vertical ? 'Height' : 'Width'}`
      this.isStart = this.container[start] <= 3
      this.isEnd =
        this.container[start] >=
        this.container[`scroll${distance}`] -
          this.container[`client${distance}`] -
          3
    },
    scroll(direction) {
      this.container.scrollBy({
        top: this.vertical ? direction * 750 : 0,
        left: this.vertical ? 0 : direction * 750,
        behavior: 'smooth'
      })
      setTimeout(() => this.checkScroll(), 500)
    },
    scrollToEnd() {
      this.$nextTick(() => {
        this.container.scrollTo({
          top: this.vertical ? this.container.scrollHeight : 0,
          left: this.vertical ? 0 : this.container.scrollWidth,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkScroll(this.container)
        }, 500)
      })
    },
    handleScrollEnd() {
      var container = this.$el.querySelector('#items')
      if (!container) return

      setTimeout(() => this.checkScroll(container), 500)
    },
    handleScroll(event) {
      if (
        (!this.vertical && (event.shiftKey || event.wheelDeltaX !== 0)) ||
        (this.vertical && (!event.shiftKey || event.wheelDeltaY !== 0))
      ) {
        this.handleScrollEnd()
      }
    },
    startDrag(e, index) {
      if (!this.draggable) return
      this.dragging = true
      this.dragIdx = index

      this.dragOffset = {
        x: e.offsetX,
        y: e.offsetY
      }

      document.addEventListener('mousemove', this.handlePreviewMove)
      return
    },

    endDrag(emit = true) {
      this.dragging = false
      if (
        emit &&
        this.dragIdx !== -1 &&
        this.dragOverIdx !== -1 &&
        this.dragOverIdx !== this.dragIdx &&
        this.dragOverIdx !== this.dragIdx + 1
      ) {
        this.$emit(
          'input',
          this.items[this.dragIdx],
          this.dragOverIdx > this.dragIdx
            ? this.dragOverIdx - 1
            : this.dragOverIdx
        )
      } else {
        this.$emit('click', this.dragIdx)
      }
      this.dragIdx = -1
      this.dragOverIdx = -1
      document.removeEventListener('mousemove', this.handlePreviewMove)
      this.dragOffset = undefined
    },

    handlePreviewMove(e) {
      let preview = document.getElementById(`preview-${this.dragIdx}`)
      preview.style.left = `${e.pageX - this.dragOffset.x}px`
      preview.style.top = `${e.pageY - this.dragOffset.y}px`
    },

    handleMouseMove(event) {
      const coords = {
        x: event.pageX,
        y: event.pageY
      }

      let overlaps = false
      for (let index = 0; index < this.items.length + 1; index++) {
        const dropzone = document
          .getElementById(`carousel-dropzone-${index}-${this.carouselKey}`)
          ?.getBoundingClientRect()
        if (contains2D(coords, dropzone)) {
          this.dragOverIdx = index
          overlaps = true
          break
        }
      }
      if (!overlaps) this.dragOverIdx = -1

      // const scrollTriggers = Array.from(
      //   document.getElementsByClassName('carousel-scroll-hover')
      // ).map((el) => el.getBoundingClientRect())
      // if (
      //   contains2D(coords, scrollTriggers[0]) ||
      //   contains2D(coords, scrollTriggers[1])
      // ) {
      //   if (!this.scrollInterval) {
      //     this.scrollInterval = setInterval(() => {
      //       this.scroll(contains2D(coords, scrollTriggers[0]) ? -0.2 : 0.2)
      //     }, 100)
      //   }
      // } else {
      //   clearInterval(this.scrollInterval)
      //   this.scrollInterval = undefined
      // }
    }
  }
})
</script>

<style lang="scss" scoped>
#carousel-container {
  position: relative;
  min-width: 100%;

  & * {
    user-select: none;
  }
}

.carousel-item {
  flex-shrink: 0;
  flex-grow: 0;

  &.vertical {
    display: flex;
    flex-flow: column nowrap;

    &:not(.textual) {
      width: fit-content;
    }

    &.textual {
      width: 100%;
    }
  }

  &:not(.vertical) {
    display: flex;
    flex-flow: row nowrap;

    &:not(.textual) {
      height: fit-content;
    }

    &.textual {
      width: 100%;
    }
  }

  &.draggable {
    cursor: grab;
  }

  &:not(.draggable) {
    cursor: pointer;
  }

  &-dropzone {
    position: absolute;
    z-index: 900;

    &-anchor {
      background: transparent;
      position: relative;
      border-radius: 999px;
      transition: all 200ms ease;
      pointer-events: none;

      .active {
        pointer-events: all;
      }

      &.hovered {
        background: $primary;
      }
    }
  }
}

.drag-preview {
  position: fixed;
  z-index: 999;
  cursor: grabbing;

  &-img {
    border: 1px solid rgba(#000, 0.08);
    border-radius: 5px;
  }

  &-text {
    background: rgba(white, 0.8);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
}

.arrow-hidden {
  display: none;
}

.arrow-position {
  position: absolute;
  &.vertical {
    width: 100%;
    height: 42px;
  }

  &:not(.vertical) {
    height: 100%;
    width: 42px;
  }
}

.arrow-left {
  &.vertical {
    top: 0;
  }
  &:not(.vertical) {
    left: 0;
  }
  z-index: 15;
}

.arrow-right {
  z-index: 15;
  &.vertical {
    bottom: 0;
  }
  &:not(.vertical) {
    right: 0;
  }
}

.arrow-scrim {
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.987259) 8.59%,
    rgba(255, 255, 255, 0.951407) 17.48%,
    rgba(255, 255, 255, 0.896) 26.53%,
    rgba(255, 255, 255, 0.824593) 35.61%,
    rgba(255, 255, 255, 0.740741) 44.59%,
    rgba(255, 255, 255, 0.648) 53.34%,
    rgba(255, 255, 255, 0.549926) 61.73%,
    rgba(255, 255, 255, 0.450074) 69.63%,
    rgba(255, 255, 255, 0.352) 76.9%,
    rgba(255, 255, 255, 0.259259) 83.41%,
    rgba(255, 255, 255, 0.175407) 89.03%,
    rgba(255, 255, 255, 0.104) 93.63%,
    rgba(255, 255, 255, 0.0485926) 97.08%,
    rgba(255, 255, 255, 0.0127407) 99.25%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  height: inherit;

  &.vertical {
    transform: rotate(90deg);
    width: 42px;
  }
}

.arrow-scrim-left {
  transform: rotate(180deg);
  z-index: 15;

  &.vertical {
    transform: rotate(270deg);
  }
}

.arrow-scrim-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.arrow-scrim-img {
  width: 30px;

  &.vertical {
    transform: rotate(90deg);
  }
}

.arrow-scrim-dropshadow-right {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7));
}

.arrow-scrim-dropshadow-left {
  -webkit-filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.7));
}

.carousel-scroll-hover {
  position: absolute;

  &.left {
    left: 0;
    top: 0;
    bottom: 0;
    width: 7rem;
  }

  &.right {
    right: 0;
    top: 0;
    bottom: 0;
    width: 7rem;
  }

  &.top {
    top: 0;
    left: 0;
    right: 0;
    height: 7rem;
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    height: 7rem;
  }
}

#items {
  &.vertical {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;

    &:not(.textual) {
      width: fit-content;
    }

    &.scrollable {
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: none;
    }
  }

  &:not(.vertical) {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;

    &:not(.textual) {
      height: fit-content;
    }

    &.scrollable {
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
    }
  }
}

#items::-webkit-scrollbar {
  display: none;
}

::v-deep .modal-content {
  overflow: hidden;
}

.hidden {
  visibility: hidden;
}
</style>
