import { uniqBy } from 'lodash-es'
import moment from 'moment'
import Vue from 'vue'
import {
  getClient,
  getPaginatedData,
  getResponseData,
  getWorkspaceClientWithFallback,
  Paginator
} from '../../axios'
import Console from '../../console'
import Mutations from '../mutation-types'

const baseClient = getClient('/')
const client = getClient('/resources')
const createClient = (rootState, workspaceId) =>
  getWorkspaceClientWithFallback(rootState, workspaceId, `/resources`)

const getDefaultState = () => {
  return {
    resources: {},
    subresources: {},
    orderedSubresources: []
  }
}

export const SearchFacet = {
  topics: 'topics',
  experts: 'experts',
  types: 'types',
  content_classes: 'content_classes',
  languages: 'languages',
  industries: 'industries',
  organisations: 'organisations',
  created_dates: 'created_dates',
  modified_dates: 'modified_dates',
  mimetypes: 'mimetypes',
  labels: 'labels',
  bookmarks: 'bookmarks',
  resolutions: 'resolutions',
  authors: 'authors',
  editors: 'editors',
  insights: 'insights',
  file_locations: 'file_locations'
}

export const DateRangeMapping = {
  'last year': (today) => moment(today).subtract(1, 'years').toDate(),
  'last quarter': (today) => moment(today).subtract(1, 'quarters').toDate(),
  'last month': (today) => moment(today).subtract(1, 'months').toDate(),
  'last week': (today) => moment(today).subtract(1, 'weeks').toDate()
}

export default {
  state: getDefaultState(),

  mutations: {
    [Mutations.UPSERT_RESOURCES](state, data) {
      const resources = (data || { resources: undefined }).resources || data
      for (const res of resources) {
        Vue.set(state.resources, res.uuid, {
          ...state.resources[res.uuid],
          ...res
        })
      }
    },
    upsertSubresources(state, data) {
      const subresources =
        (data || { subresources: undefined }).subresources || data
      for (const subres of subresources) {
        Vue.set(state.subresources, subres.uuid, {
          ...state.subresources[subres.uuid],
          ...subres
        })
      }
    },

    EDIT_RESOURCE_FIELD(state, { resource_uuid, key, value }) {
      Vue.set(state.resources, resource_uuid, {
        ...(state.resources[resource_uuid] || {}),
        [key]: value
      })
    },

    [Mutations.RESET_RESOURCES_STATE](state) {
      Object.assign(state, getDefaultState())
    },

    DELETE_RESOURCE(state, { uuid }) {
      Vue.delete(state.resources, uuid)
    },

    ADD_RESOURCE_COMMENTS(state, { resource_id, comments }) {
      Vue.set(
        state.comments,
        resource_id,
        uniqBy(
          [...comments, ...(state.comments[resource_id] || [])],
          (x) => x.id
        )
      )
    },
    REMOVE_RESOURCE_COMMENTS(state, { resource_id, comment, softDelete }) {
      if (softDelete) {
        const index = state.comments[resource_id].findIndex(
          (x) => x.id === comment.id
        )
        if (index === -1) {
          return
        }
        Vue.set(state.comments[resource_id], index, comment)
      }
      Vue.set(state.comments, resource_id, [
        ...state.comments[resource_id].filter((x) => x.id !== comment.id)
      ])
    },

    ADD_RESOURCE_OPENS(state, { resource_id, opens }) {
      if (!(resource_id in state.opens)) {
        state.opens[resource_id] = []
      }
      state.opens[resource_id].push(...opens)
    }
  },

  actions: {
    async searchResources({ commit }, { data, params, noCommit }) {
      // Currently data = {query, relevancy, user, nlp, workspace}
      // Relevancy: 'high' | 'low'

      const res = getResponseData(
        await client.post(
          '/search/',
          { relevancy: 'medium', nlp: false, ...data },
          { params }
        )
      )

      if (!noCommit) {
        commit(Mutations.UPSERT_RESOURCES, res)
      }

      return res
    },

    async patchResource({ commit, rootState }, { id, workspaceId, data }) {
      const client = createClient(rootState, workspaceId)
      const res = await client.patch(`/${id}/`, data)
      const resData = getResponseData(res)
      commit(Mutations.UPSERT_RESOURCES, [resData])
      return resData
    },

    async deleteResource({ commit, rootState }, { id, workspaceId }) {
      const client = createClient(rootState, workspaceId)
      const res = await client.delete(`/${id}/`)
      commit('DELETE_RESOURCE', { uuid: id })
      return getResponseData(res)
    },

    async analyzeResource(_, data) {
      try {
        const res = await client.post('/analyze/', data)
        return getResponseData(res)
      } catch (e) {
        Console.debug(e)
        return undefined
      }
    },

    // Get subresources for a specific resource
    async getSubResourcePaginator(_, { workspace_id, resource_id, params }) {
      return new Paginator(
        client,
        baseClient.get(
          `workspaces/${workspace_id}/resources/${resource_id}/subresources/`,
          { params }
        ),
        () => {}
      )
    },

    async getSubResourcePage(_, { workspace_id, resource_id, params }) {
      const res = await baseClient.get(
        `workspaces/${workspace_id}/resources/${resource_id}/subresources/`,
        { params }
      )
      return getResponseData(res)
    },

    async getSimilarSlidesPaginator(
      _,
      { workspace_id, slide_id, params, act_as, callback }
    ) {
      return new Paginator(
        client,
        baseClient.get(
          `workspaces/${workspace_id}/subresources/${slide_id}/similar/`,
          { params: {
            ...(params || {}),
            ...(act_as ? { act_as } : {})
          } }
        ),
        callback,
        'results'
      )
    },

    async getSlidesPaginator(_, { workspaceId, params }) {
      return new Paginator(
        client,
        baseClient.get(`workspaces/${workspaceId}/subresources`, { params }),
        () => {}
      )
    },

    async getResourceInfo(
      { commit },
      { workspaceId, resourceId, impersonatedMember }
    ) {
      const res = await baseClient.get(
        `workspaces/${workspaceId}/resources/${resourceId}/`,
        {
          ...(impersonatedMember
            ? { params: { act_as: impersonatedMember } }
            : {})
        }
      )
      const resData = getResponseData(res) || {}
      commit(Mutations.UPSERT_RESOURCES, [resData])
      return resData
    },

    async getSubresourceInfo({ commit }, { workspaceId, subresourceId }) {
      const res = await baseClient.get(
        `workspaces/${workspaceId}/subresources/${subresourceId}/`
      )
      const resData = getResponseData(res)?.subresources?.[0] || {}
      commit('upsertSubresources', [resData])
      return resData
    },

    // Other resource data
    async getResourceOpens(
      { commit, rootState },
      { resource_id, workspaceId, maxPage }
    ) {
      const client = createClient(rootState, workspaceId)
      const opens = await getPaginatedData(
        client,
        `/${resource_id}/opens/`,
        {
          clearCacheEntry: true
        },
        maxPage
      )
      commit('ADD_RESOURCE_OPENS', { resource_id, opens })
      return opens
    }
  }
}
