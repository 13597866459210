





































import DialogModal from '@c/shared/molecules/structural/modals/DialogModal.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'DoubleActionDialogModal',
  components: { DialogModal },
  props: {
    action1: {
      type: String,
      required: true
    },
    action2: {
      type: String,
      required: true
    },
    onConfirmAction1: {
      type: Function as PropType<(object?: any) => boolean | Promise<boolean>>,
      required: true
    },
    onConfirmAction2: {
      type: Function as PropType<(object?: any) => boolean | Promise<boolean>>,
      required: true
    }
  },
  data() {
    return { loading: false }
  },
  methods: {
    async onClick(f) {
      this.loading = true
      await Promise.resolve(f())
      this.loading = false
    }
  }
})
