var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resource-footer-container mb-2" }, [
    _c("div", { staticClass: "full-width wrap flex resource-footer-text" }, [
      _vm.resourceIntegration &&
      _vm.resourceIntegration.folder_url &&
      !(_vm.hideIntegrationPath || _vm.condensed)
        ? _c(
            "div",
            {
              staticClass: "fit-height is-narrow",
              attrs: { id: "integration-link" }
            },
            [
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: _vm.resourceIntegration.folder_url
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("on-path-click")
                      }
                    }
                  },
                  [
                    _c("IntegrationLink", {
                      attrs: {
                        hover: _vm.hover,
                        integration: _vm.resourceIntegration.integration,
                        "integration-path": _vm.integrationPath,
                        "no-integration-path-tooltip":
                          _vm.noIntegrationPathTooltip,
                        condensed: _vm.condensed
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      !(_vm.hideIntegrationPath || _vm.hideUser || _vm.condensed)
        ? _c("span", [_vm._v("—")])
        : _vm._e(),
      !_vm.hideUser
        ? _c(
            "div",
            {
              staticClass: "fit-height flex is-narrow pr-4",
              attrs: { id: "created-date" }
            },
            [
              _c("p", { staticClass: "body body-text is-narrow pr-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.showLastModified ? "Last modified" : "Created") +
                    " " +
                    _vm._s(_vm.showUsername ? " by" : "") +
                    " "
                )
              ]),
              _c("div", { staticClass: "user-wrapper" }, [
                _vm.showUsername
                  ? _c(
                      "div",
                      {
                        staticClass: "created-owner body black is-narrow pr-2",
                        on: {
                          click: _vm.gotoUser,
                          mouseenter: function($event) {
                            _vm.userHovered = true
                          },
                          mouseleave: function($event) {
                            _vm.userHovered = false
                          }
                        }
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.username))])]
                    )
                  : _vm._e(),
                _vm.userHovered
                  ? _c("div", { staticClass: "user-hover" }, [
                      _vm.userAvatar
                        ? _c("img", {
                            staticClass: "user-hover-image",
                            attrs: { src: _vm.userAvatar, alt: "" }
                          })
                        : _c("p", { staticClass: "user-hover-image" }, [
                            _vm._v(_vm._s(_vm.userInitials))
                          ]),
                      _c("div", { staticClass: "user-hover-info" }, [
                        _c("span", [_vm._v(_vm._s(_vm.username))]),
                        _vm.userRole
                          ? _c(
                              "span",
                              { staticClass: "user-hover-info-role" },
                              [_vm._v(_vm._s(_vm.userRole))]
                            )
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("p", { staticClass: "body body-text u-column is-narrow" }, [
                _vm._v("on " + _vm._s(_vm.date) + ".")
              ]),
              _c(
                "div",
                { staticClass: "fit-height flex is-narrow mh-2" },
                [
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: _vm.isPublic ? "Public" : "Synced permissions",
                        type: "is-white",
                        position: "is-right"
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "body body-text is-narrow",
                        attrs: {
                          size: "is-small",
                          icon: _vm.isPublic ? "earth" : "account-multiple"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }