<template>
  <div v-show="highlights.length > 0" class="overview">
    <div class="overview-header">
      <span class="overview-header-text">Highlighted content</span>
      <div
        v-if="highlights && highlights.length > 3"
        class="overview-header-btns"
      >
        <span class="overview-header-btns-showmore" @click="handleShowMore">{{
          showMore ? 'Show less' : 'Show all'
        }}</span>
        <Button
          v-if="!showMore"
          icon="chevron-left-medium"
          type="white"
          @click="handleArrowClick(-1)"
        />
        <Button
          v-if="!showMore"
          icon="chevron-right-medium"
          type="white"
          @click="handleArrowClick(1)"
        />
      </div>
    </div>
    <div class="overview-grid">
      <div
        v-for="(highlight, idx) in visibleHighlights"
        :key="highlight.uuid"
        class="overview-grid-item"
      >
        <HighlightVisualisation
          :resource="highlight"
          :rank="idx + 1"
          @gotoResource="$emit('gotoResource', highlight)"
          @removeHighlight="removeHighlight(highlight)"
          @pinHighlight="pinHighlight(highlight)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  pinHighlightedResource,
  removeHighlightFromResource,
  removePinFromHighlight
} from '@/services/highlightService'
import { Mutations } from '@/store'
import Button from '@c/library/Button.vue'
import { mapActions, mapMutations } from 'vuex'
import HighlightVisualisation from './highlight/HighlightVisualisation.vue'

export default {
  name: 'HighlightOverview',
  components: { HighlightVisualisation, Button },
  data: () => ({
    highlights: [],
    showMore: false,
    currentPage: 0
  }),
  computed: {
    impersonatedMember() {
      return this.$route.query.act_as
    },
    workspace() {
      return this.$route.params.workspace_id
    },
    visibleHighlights() {
      return this.showMore
        ? this.highlights
        : this.highlights.slice(this.currentPage * 3, this.currentPage * 3 + 3)
    }
  },
  watch: {
    impersonatedMember() {
      this.getHighlights()
    },
    workspace() {
      this.getHighlights()
    }
  },
  async created() {
    await this.getHighlights()
  },
  methods: {
    ...mapActions(['getHighlightedResources']),
    ...mapMutations([Mutations.UPSERT_RESOURCES]),
    async getHighlights() {
      const highlights = await this.getHighlightedResources({
        workspace_id: this.$route.params.workspace_id,
        act_as: this.$route.query.act_as
      })
      this.$emit('hasHighlights', (highlights || []).length > 0)
      this.highlights = highlights || []
      this.UPSERT_RESOURCES(this.highlights)
    },
    async removeHighlight(highlight) {
      try {
        await removeHighlightFromResource(
          this.$route.params.workspace_id,
          highlight.uuid,
          this.$route.query.act_as
        )
        this.highlights = this.highlights.filter(
          (h) => h.uuid !== highlight.uuid
        )
        if (this.visibleHighlights?.length === 0) this.currentPage = 0
      } catch (e) {
        this.$console.error(
          'Something went wrong when removing a highlight on the homepage',
          e
        )
        this.$toast.error(e, 'removing a highlight')
      }
    },
    async pinHighlight(highlight) {
      const pinned = highlight?.highlight?.pinned
      try {
        if (pinned) {
          await removePinFromHighlight(
            this.$route.params.workspace_id,
            highlight.uuid,
            this.$route.query.act_as
          )
          this.highlights = [
            ...this.highlights.filter((h) => h.uuid !== highlight.uuid),
            highlight
          ]
          this.highlights[this.highlights.length - 1].highlight.pinned = false
        } else {
          await pinHighlightedResource(
            this.$route.params.workspace_id,
            highlight.uuid,
            this.$route.query.act_as
          )
          this.highlights = [
            highlight,
            ...this.highlights.filter((h) => h.uuid !== highlight.uuid)
          ]
          this.highlights[0].highlight.pinned = true
        }
        this.$toast.success(
          `Highlight ${pinned ? 'un' : ''}pinned`,
          `The highlight has been ${
            pinned ? 'unpinned from' : 'pinned to'
          } the top of the list.`
        )
      } catch (e) {
        this.$console.error(
          'Something went wrong when changing a pinned highlight on the homepage',
          e
        )
        this.$toast.error(e, `${pinned ? 'un' : ''}pinning a highlight`)
      }
    },
    handleShowMore() {
      this.showMore = !this.showMore
      if (!this.showMore) this.currentPage = 0
    },
    handleArrowClick(delta) {
      this.currentPage =
        (this.currentPage + delta) % Math.ceil(this.highlights.length / 3)
    }
  }
}
</script>

<style scoped lang="scss">
.overview {
  padding-top: 5vh;
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &-text {
      font-size: 1.75rem;
      font-weight: 700;
      color: #303032;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &-showmore {
        cursor: pointer;
        text-decoration: underline;
        margin-right: 0.5rem;
      }
    }
  }

  &-grid {
    gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &-item {
      width: 100%;
      height: 100%;
      list-style: none;
    }
  }
}
</style>
