var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DraggableList", {
    staticClass: "accordion",
    attrs: {
      items: _vm.items,
      "item-key": _vm.itemKey,
      "item-style": {
        padding: "0 0 0 0.5rem",
        borderRadius: "4px",
        border: _vm.bordered ? "1px solid rgba(0, 0, 0, 0.12)" : "none"
      },
      gap: _vm.gap
    },
    on: {
      reorder: function(order) {
        return _vm.$emit("reorder", order)
      },
      "item-click": function(i) {
        return _vm.toggleOpen(i)
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "div",
                {
                  staticClass: "accordion-item-header",
                  class: { opened: _vm.opened === item.id }
                },
                [
                  _c("img", {
                    staticClass: "accordion-item-header-icon",
                    attrs: {
                      src: require("@/assets/icons/chevron-down-medium.svg"),
                      alt: ""
                    }
                  }),
                  _vm._t(
                    "header",
                    function() {
                      return [_vm._v(" " + _vm._s(item.name) + " ")]
                    },
                    { header: item }
                  ),
                  _c(
                    "div",
                    { staticClass: "accordion-item-header-buttons" },
                    [_vm._t("buttons", null, { header: item })],
                    2
                  )
                ],
                2
              )
            ]
          }
        },
        {
          key: "after",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "div",
                {
                  staticClass: "accordion-item-content",
                  class: { opened: _vm.opened === item.id }
                },
                [_vm._t("content", null, { item: item })],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }