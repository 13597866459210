var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "header bold" }, [_vm._v("Profile image")]),
      _c("div", { staticClass: "u-columns is-vcentered pt-3" }, [
        _c("div", { staticClass: "u-column is-narrow" }, [
          _c(
            "div",
            {
              staticClass: "user-avatar",
              style: {
                "background-image":
                  "url('" + (_vm.imageUrl || _vm.DefaultProfileImage) + "')"
              }
            },
            [
              _c(
                "b-field",
                {
                  staticClass: "upload-field full",
                  class: { always: !_vm.user.avatar }
                },
                [
                  _c("b-upload", {
                    ref: "upload",
                    staticClass: "full",
                    attrs: {
                      expanded: "",
                      native: "",
                      "drag-drop": "",
                      loading: _vm.parsing
                    },
                    on: {
                      input: function(val) {
                        return (_vm.image = val)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "u-column is-narrow ml-3" }, [
          _c(
            "p",
            {
              staticClass: "header link blue bold",
              on: {
                click: function($event) {
                  _vm.$refs.upload.getElement().click()
                }
              }
            },
            [_vm._v(" Upload a new profile picture ")]
          ),
          _c("p", { staticClass: "body body-text" }, [
            _vm._v(" Photo's help your team recognize you faster ")
          ])
        ])
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "columns is-mobile no-margin is-multiline" }, [
          _c(
            "div",
            { staticClass: "column is-12-mobile is-6-tablet no-padding" },
            [
              _c(
                "b-field",
                { staticClass: "mr-3 mt-5", attrs: { label: "First name" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.first_name,
                      callback: function($$v) {
                        _vm.first_name = $$v
                      },
                      expression: "first_name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column is-12-mobile is-6-tablet no-padding" },
            [
              _c(
                "b-field",
                { staticClass: "mr-3 mt-5", attrs: { label: "Last name" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.last_name,
                      callback: function($$v) {
                        _vm.last_name = $$v
                      },
                      expression: "last_name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column is-12 no-padding" },
            [
              _c(
                "b-field",
                { staticClass: "mr-3 mt-5", attrs: { label: "Your email" } },
                [
                  _c("b-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.username,
                      callback: function($$v) {
                        _vm.username = $$v
                      },
                      expression: "username"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column is-12 no-padding" },
            [
              _c(
                "b-field",
                { staticClass: "mr-3 mt-5", attrs: { label: "Your role" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.role,
                      callback: function($$v) {
                        _vm.role = $$v
                      },
                      expression: "role"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "column is-12 no-padding" },
            [
              _c(
                "b-field",
                { staticClass: "mr-3 mt-5", attrs: { label: "Your language" } },
                [
                  _c("LanguageSelect", {
                    model: {
                      value: _vm.language,
                      callback: function($$v) {
                        _vm.language = $$v
                      },
                      expression: "language"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "b-button",
        {
          staticClass: "mt-5",
          attrs: { type: "is-primary", disabled: _vm.disabled },
          on: {
            click: function($event) {
              return _vm.$emit("saveProfile", _vm.returnValue)
            }
          }
        },
        [_vm._v(" Save changes ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }