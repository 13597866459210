<template>
  <div class="quality-labels">
    <div
      v-if="smallLabels && !isEmpty(label)"
      class="label subresource"
      :class="{ bordered: borderedIcons }"
      @mouseenter="onHover"
      @mouseleave="onHoverLeave"
    >
      <img
        class="subresource"
        :src="require(`@c/assets/icons/${label.type}QualityLabel.svg`)"
      />
      <QualityLabelInfo
        v-if="showHoverInfo"
        :label="label"
        :hovered="hovered"
        :align-left="hoverX < windowWidth / 2"
      />
    </div>
    <div
      v-else-if="!isEmpty(label)"
      class="label"
      :class="{ bordered: borderedIcons }"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
    >
      <img :src="require(`@c/assets/icons/${label.type}QualityLabel.svg`)" />
      <p>{{ label.name }}</p>
      <QualityLabelInfo
        v-if="showHoverInfo"
        :label="label"
        :hovered="hovered"
      />
    </div>
    <transition v-if="canWriteLabels" name="fade">
      <EditLabels
        v-show="showEditLabels"
        :hovering="isHovering"
        :active-label="label"
        :source-id="sourceId"
        @label-updated="updateLabel"
      >
        <template #trigger="{ onClick }">
          <div
            class="label-edit-trigger-wrapper"
            :class="{ visible: isHovering }"
            @click.stop="onClick"
          >
            <p
              v-if="isEmpty(label)"
              class="label-edit-trigger"
              :class="{ bordered: borderedIcons }"
            >
              <img
                src="@/assets/icons/label.svg"
                alt=""
                class="label-edit-trigger-icon"
              />
              <span v-if="!hideButtonText">Curate</span>
            </p>
            <p
              v-else
              class="label-edit-trigger"
              :class="{ bordered: borderedIcons }"
            >
              <img
                src="@/assets/icons/pencil-grey.svg"
                alt=""
                class="label-edit-trigger-icon"
              />
            </p>
          </div>
        </template>
      </EditLabels>
    </transition>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import EditLabels from './EditLabels.vue'
import QualityLabelInfo from './QualityLabelInfo.vue'

export default {
  name: 'QualityLabels',
  components: {
    EditLabels,
    QualityLabelInfo
  },
  props: {
    smallLabels: {
      type: Boolean,
      default: false
    },
    isHovering: {
      type: Boolean,
      default: true
    },
    activeLabel: {
      type: Object,
      default: () => {}
    },
    sourceId: {
      type: String,
      default: undefined
    },
    showEditLabels: {
      type: Boolean,
      default: true
    },
    borderedIcons: {
      type: Boolean,
      default: false
    },
    hideButtonText: {
      type: Boolean,
      default: false
    },
    showHoverInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      label: this.activeLabel || {},
      hovered: false,
      hoverX: -1
    }
  },
  computed: {
    ...mapGetters(['canWriteLabels']),
    windowWidth() {
      return window.innerWidth
    }
  },
  created() {
    this.$root.$on('label-updated', this.updateLabel)
  },
  methods: {
    updateLabel(labelUpdateInfo) {
      if (this.sourceId !== labelUpdateInfo.id) return
      this.label = !isEmpty(labelUpdateInfo.label)
        ? {
            ...labelUpdateInfo.label,
            comment: labelUpdateInfo.comment,
            member: this.currentUser
          }
        : {}
    },
    isEmpty(el) {
      return isEmpty(el)
    },
    onHover(e) {
      this.hovered = true
      this.hoverX = e.clientX
    },
    onHoverLeave() {
      this.hovered = false
    }
  }
}
</script>

<style lang="scss" scoped>
.quality-labels {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem 0;
  .label {
    display: flex;
    align-items: center;
    border-radius: 180px;
    padding: 0.3rem 0.6rem;
    margin-right: 0.5rem;
    background: white;
    white-space: nowrap;
    margin-bottom: 0;
    position: relative;
    &.subresource {
      padding: 0.3rem 0.3rem;
    }

    &.bordered {
      border: 1px solid hsl(0deg, 0%, 86%);
    }

    img {
      margin-right: 0.5rem;
      &.subresource {
        margin-right: 0;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }

    &-edit-trigger {
      height: 2rem;
      border-radius: 999rem;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;
      background: white;

      &.bordered {
        border: 1px solid rgba(#000, 6%);
      }

      &:not(.bordered) {
        border: none;
      }

      &:hover {
        background: darken(white, 6%);
      }

      &-wrapper {
        opacity: 0;
        pointer-events: none;

        &.visible {
          opacity: 1;
          pointer-events: all;
        }
      }

      span {
        white-space: nowrap;
        color: grey;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
