var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat" }, [
    _c(
      "ul",
      { ref: "chatlog", staticClass: "chat-log" },
      [
        _vm.answerLoading
          ? _c(
              "li",
              { staticClass: "chat-log-item answer" },
              [
                _c("img", {
                  staticClass: "chat-log-item-avatar",
                  attrs: { src: _vm.avatarByAuthor.bot, alt: "" }
                }),
                _c("LoadingDots", {
                  staticClass: "chat-log-item-loading",
                  attrs: { color: "white" }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.history, function(item, idx) {
          return _c(
            "li",
            {
              key: item.author + "-" + idx,
              staticClass: "chat-log-item",
              class: {
                question: item.author.toLowerCase() === "user",
                answer: item.author.toLowerCase() === "bot"
              }
            },
            [
              _vm.avatarByAuthor[item.author.toLowerCase()] &&
              !_vm.avatarErrored
                ? _c("img", {
                    staticClass: "chat-log-item-avatar",
                    attrs: {
                      src: _vm.avatarByAuthor[item.author.toLowerCase()],
                      alt: ""
                    },
                    on: {
                      error: function($event) {
                        _vm.avatarErrored = true
                      }
                    }
                  })
                : _c(
                    "div",
                    {
                      staticClass: "chat-log-item-avatar",
                      style: {
                        background: _vm.$umodel.user_color(_vm.currentUser)
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$umodel.initials(_vm.currentUser)) +
                          " "
                      )
                    ]
                  ),
              item.author.toLowerCase() === "user"
                ? _c("span", { staticClass: "chat-log-item-text" }, [
                    _vm._v(_vm._s(item.content))
                  ])
                : _c("MarkdownEdit", {
                    staticClass: "chat-log-item-text answer",
                    attrs: { value: item.content }
                  })
            ],
            1
          )
        })
      ],
      2
    ),
    _c("div", { staticClass: "chat-input" }, [
      _c("span", {
        ref: "questioninput",
        staticClass: "chat-input-input is-textarea",
        attrs: {
          role: "textbox",
          contenteditable: !_vm.answerLoading,
          placeholder: "Ask anything about this document..."
        },
        on: {
          input: _vm.handleQuestionInput,
          paste: function($event) {
            $event.preventDefault()
            return _vm.handleQuestionPaste.apply(null, arguments)
          },
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleEnter.apply(null, arguments)
          }
        }
      }),
      _c("img", {
        staticClass: "chat-input-icon",
        attrs: { src: require("@/assets/icons/send.svg"), alt: "" },
        on: { click: _vm.sendMessage }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }