



















import SyncFolderSelectModal from '@c/features/sync-folder-select/pages/SyncFolderSelectModal.vue'
import ResourceGridMixin from '@c/mixins/hydration/ResourceGridMixin'
import { infoDialog } from '@c/shared/logic/dialogs/info/BaseInfoDialogs'
import {
  FileMetadata,
  TreeNode
} from '@c/shared/molecules/structural/trees/interfaces'
import {
  findBranches,
  traverseUpward
} from '@c/shared/molecules/structural/trees/tree-utils'
import { differenceBy, flatten, sortBy } from 'lodash-es'
import Vue from 'vue'
import allIntegrations from '@c/integrations'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'HydratedSyncFolderSelectModal',
  components: { SyncFolderSelectModal },
  mixins: [ResourceGridMixin],

  props: {
    workspaceObject: {
      type: Object,
      required: true
    },

    integration: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      directoryStructure: [],
      syncStats: {},
      syncStatsInterval: {},
      selection: [],
      loadingSyncs: false,
      loadingDirs: false,
      activeSyncsInterval: undefined,
      baseSelection: []
    }
  },

  computed: {
    ...mapGetters(['currentWorkspaceMember']),

    currentSelection() {
      return flatten(
        findBranches((n) => n?.metadata?.checked === true, {
          children: this.directoryStructure
        })
      )
    },

    integrationObject() {
      return allIntegrations[this.integration.type]
    },

    integrationName() {
      return this.integrationObject.title
    }
  },

  async mounted() {
    this.loadingDirs = true
    this.loadingSyncs = true
    this.syncStatsInterval = setInterval(async () => {
      this.$set(
        this,
        'syncStats',
        await this.getIntegrationSyncStats({
          workspace_id: this.workspaceObject.uuid,
          integration_id: this.integration.id
        })
      )
    }, 10 * 1000)
    this.$set(
      this,
      'syncStats',
      await this.getIntegrationSyncStats({
        workspace_id: this.workspaceObject.uuid,
        integration_id: this.integration.id
      })
    )
    this.loadingSyncs = false
    this.directoryStructure = await this.fetchSubNodes(undefined)
    this.loadingDirs = false
  },
  beforeDestroy() {
    clearInterval(this.syncStatsInterval)
  },
  methods: {
    ...mapActions([
      'getIntegrationFiles',
      'postIntegrationFiles',
      'getIntegrationSyncStats'
    ]),

    onExpand({ node }) {
      Vue.set(node, 'expanded', true)
      Vue.set(node, 'children', this.childrenPromise(node))
    },

    mapDirectoryStructure(data, parent) {
      return sortBy(
        data
          .filter((x) => (parent ? x : x.is_directory))
          .map((x) => ({
            children: undefined,
            expandable: x.is_directory,
            expanded: false,
            path: x.path,
            id: x.path,
            metadata: {
              ...x,
              checked: parent?.metadata?.checked || Boolean(x.sync) || false,
              is_public: Boolean(x.is_public),
              original_is_public: Boolean(x.is_public)
            },
            parent: parent
          })),
        [(x) => !x.metadata.is_directory]
      )
    },

    async fetchSubNodes(parent): Promise<any> {
      try {
        const integrationFiles = await this.getIntegrationFiles({
          workspace_id: this.workspaceObject.uuid,
          integration_id: this.integration.id,
          path: parent?.path
        })
        const dirs = this.mapDirectoryStructure(integrationFiles, parent)
        return this.updateBaseSelection(parent, dirs)
      } catch (e) {
        this.$toast.danger(e, 'getting your files and folders')
      }
    },

    async allSome(nodes: TreeNode<FileMetadata>[]) {
      return (await nodes).reduce(
        ({ allChecked, someChecked, allPublic, somePublic }, y) => ({
          allChecked: allChecked && !!y.metadata.checked,
          someChecked: someChecked || !!y.metadata.checked,
          allPublic: allPublic && !!y.metadata.is_public,
          somePublic: somePublic || !!y.metadata.is_public
        }),
        {
          allChecked: true,
          someChecked: false,
          allPublic: true,
          somePublic: false
        }
      )
    },

    async updateBaseSelection(
      parent: TreeNode<FileMetadata>,
      nodes: TreeNode<FileMetadata>[]
    ): Promise<TreeNode<FileMetadata>[]> {
      const newFiles = nodes.filter((n) => n.metadata.checked)
      this.baseSelection = this.baseSelection.concat(newFiles)
      if (newFiles.length > 0)
        traverseUpward(
          async (n) => {
            const { allChecked, someChecked } = await this.allSome(n.children)
            this.$set(n, 'metadata', {
              ...n.metadata,
              checked:
                n.metadata.checked ||
                (someChecked && !allChecked ? 'indeterminate' : allChecked),
              is_public: n.metadata.original_is_public
            })
          },
          parent,
          true
        )
      return nodes
    },

    childrenPromise(node: TreeNode<FileMetadata>) {
      return this.fetchSubNodes(node)
        .then((x) => {
          Promise.resolve(node).then(() => Vue.set(node, 'children', x))
          Promise.resolve(node).then(async () => {
            const { allChecked, someChecked } = await this.allSome(x)

            Vue.set(node, 'metadata', {
              ...node.metadata,
              checked:
                node.metadata.checked ||
                (someChecked && !allChecked
                  ? 'indeterminate'
                  : node.children.length === 0
                  ? false
                  : allChecked),
              is_public: node.metadata.original_is_public
            })
          })
          return x
        })
        .catch((e) => {
          this.$console.error(e)
        })
    },

    getSyncDiff(newlySyncedFiles: TreeNode<FileMetadata>[]): {
      myAddedFiles: TreeNode<FileMetadata>[]
      myRemovedFiles: TreeNode<FileMetadata>[]
      myUnsyncedFiles: TreeNode<FileMetadata>[]
      myUpdatedPermissionFiles: TreeNode<FileMetadata>[]
    } {
      const myPreviousSyncedFiles = this.baseSelection
      const myNewlySyncedFiles = flatten(newlySyncedFiles)

      let myRemovedFiles = differenceBy(
        myPreviousSyncedFiles,
        myNewlySyncedFiles,
        (x: TreeNode<FileMetadata>) => x.id
      ) as TreeNode<FileMetadata>[]
      const myAddedFiles = differenceBy(
        myNewlySyncedFiles,
        myPreviousSyncedFiles,
        (x: TreeNode<FileMetadata>) => x.id
      ) as TreeNode<FileMetadata>[]

      // don't delete files if they are covered by a parent directory
      const myUnsyncedFiles = myRemovedFiles
        .filter((f) => f.metadata.checked === 'indeterminate')
        .map((x) => {
          const unsyncedFile = { ...x }
          unsyncedFile.metadata.checked = false
          return unsyncedFile
        })
      myRemovedFiles = myRemovedFiles.filter(
        (f) => f.metadata.checked === false
      )

      const myUpdatedPermissionFiles = myPreviousSyncedFiles.filter((file) => {
        return (
          file.metadata.is_public !== file.metadata.original_is_public &&
          !myRemovedFiles.includes(file) &&
          !myAddedFiles.includes(file)
        )
      })

      return {
        myRemovedFiles,
        myAddedFiles,
        myUnsyncedFiles,
        myUpdatedPermissionFiles
      }
    },

    async postSync({ selectedFiles }) {
      const {
        myRemovedFiles,
        myAddedFiles,
        myUnsyncedFiles,
        myUpdatedPermissionFiles
      } = this.getSyncDiff(this.currentSelection)
      this.$console.debug(
        selectedFiles,
        this.baseSelection,
        myRemovedFiles,
        myAddedFiles
      )
      const title = 'Update sync'
      const header = `You're about to update your ${this.integrationName} sync`
      if (
        myRemovedFiles.length === 0 &&
        myAddedFiles.length === 0 &&
        myUnsyncedFiles.length === 0 &&
        myUpdatedPermissionFiles.length === 0
      ) {
        this.$toast.danger(
          'Nothing changed',
          'You tried to save your syncs without any changes. Please make some changes before saving.'
        )
        return
      }
      let message = `You're about to add ${myAddedFiles.length} ${this.integrationObject.folderName}s to your ${this.integrationName} sync. Are you sure you want to add these ${this.integrationObject.folderName}s to your sync?`

      if (myRemovedFiles.length > 0) {
        const addMsg = `add ${myAddedFiles.length}`
        const deleteMsg = `remove ${myRemovedFiles.length} from`
        const msgs = []
        if (myAddedFiles.length > 0) {
          msgs.push(addMsg)
        }
        msgs.push(deleteMsg)
        message = `You're about to ${msgs.join(' and ')} folders your ${
          this.integrationName
        } sync.`

        // add siblings of removedFiles to myAddedFiles, otherwise they won't be set to sync
        myUnsyncedFiles.forEach((unsyncedFile) => {
          myAddedFiles.push(
            ...unsyncedFile.children.filter(
              (childFile) => !myRemovedFiles.includes(childFile)
            )
          )
        })
      }

      const dialog = infoDialog(
        this,
        title,
        header,
        message,
        'Confirm',
        async () => {
          const success = await this.updateSelectedIntegrationFiles([
            ...myAddedFiles,
            ...myUpdatedPermissionFiles.map((x) => {
              return { ...x, updatedPermission: true }
            }),
            ...myRemovedFiles,
            ...myUnsyncedFiles
          ])
          if (success) {
            // update the base selection
            this.baseSelection = this.currentSelection

            dialog.close()
            this.$emit('close')
          }
          return success
        }
      )
    },

    async updateSelectedIntegrationFiles(
      selectedFiles: TreeNode<FileMetadata>[]
    ): Promise<boolean> {
      if (selectedFiles.length === 0) {
        return true
      }

      const payload = selectedFiles.map((x) => {
        const integrationfile_object: any = {
          integrationfile_id: x.metadata.uuid,
          sync: x.metadata.checked,
          is_public: x.metadata.is_public
        }
        if (x.updatedPermission) {
          integrationfile_object.permission_updated = true
        }
        return integrationfile_object
      })

      try {
        await this.postIntegrationFiles({
          workspace_id: this.workspaceObject.uuid,
          integration_id: this.integration.id,
          payload
        })
        this.$toast.success(
          'Syncs updated',
          'Your syncs have been succesfully updated!'
        )
        return true
      } catch (e) {
        this.$console.log(e)
        this.$toast.error(e, 'saving your syncs')
      }
      return false
    }
  }
})
