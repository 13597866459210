






















import { CreateStepsEnum } from '@c/features/create-workspace-modal/interfaces'
import EditTab from '@c/features/workspace-settings-modal/pages/EditTab.vue'
import FileToImageConvertMixin from '@c/mixins/FileToImageConvertMixin'
export default FileToImageConvertMixin.extend({
  name: 'WorkspaceCreateView',
  components: { EditTab },
  props: {
    creating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      avatar: undefined as File | undefined,
      avatarURL: undefined as string | undefined,
      next: CreateStepsEnum.INVITE
    }
  },
  methods: {
    newAvatar(val: File): void {
      if (val.size > 2 * 1000 * 1000) {
        this.$toast.danger(
          'Workspace image too large',
          'We support images up to 2MB, your image exceeded this amount. Please upload a smaller workspace avatar.'
        )
        return
      }
      this.avatar = val
      this.imageToURL(val, (url?: string) =>
        url ? (this.avatarURL = url) : {}
      )
    }
  }
})
