var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "subresource",
      on: {
        mouseenter: function($event) {
          _vm.hovered = true
        },
        mouseleave: function($event) {
          _vm.hovered = false
        }
      }
    },
    [
      _vm.showLink
        ? _c("HighlightSubresourceLink", {
            staticClass: "top-rounded",
            attrs: { subresource: _vm.subresources[0] }
          })
        : _c("HighlightSubresourcesPreview", {
            staticClass: "clickable",
            attrs: {
              subresources: _vm.subresources,
              "resource-type": _vm.resourceType
            },
            nativeOn: {
              click: function($event) {
                return _vm.$emit("inspectResource")
              }
            }
          }),
      _c(
        "div",
        { staticClass: "subresource-buttons" },
        [
          _c("QualityLabels", {
            attrs: {
              "active-label": _vm.subresources[0].label,
              "source-id": _vm.subresources[0].uuid,
              "small-labels": false,
              "show-hover-info": false,
              "show-edit-labels": false,
              "bordered-icons": true
            }
          }),
          _c(
            "div",
            {
              staticClass: "subresource-buttons-highlight",
              class: {
                clickable: _vm.canWriteLabels
              },
              on: {
                click: _vm.onHighlightClick,
                mouseenter: _vm.onHighlightHover,
                mouseleave: function($event) {
                  _vm.highlightHovered = false
                }
              }
            },
            [
              _c("img", {
                staticClass: "subresource-buttons-highlight-icon",
                attrs: {
                  src: require("@/assets/icons/star-filled.svg"),
                  alt: ""
                }
              }),
              _c("HighlightHoverInfo", {
                attrs: {
                  highlight: _vm.highlight,
                  hovered: _vm.highlightHovered,
                  "align-left": _vm.highlightHoveredX < _vm.windowWidth / 2
                }
              })
            ],
            1
          ),
          _c("CollectSlideButton", {
            attrs: {
              subresource: _vm.subresources[0],
              rank: 1,
              mimetype: _vm.mimetype,
              "resource-trace-id": _vm.resourceTraceId
            }
          }),
          _vm.canWriteLabels
            ? _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.pinned
                      ? "Unpin content"
                      : "Pin at start of highlights",
                    position: "is-top",
                    type: "is-dark"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "subresource-buttons-pin",
                      on: { click: _vm.pinHighlight }
                    },
                    [
                      _c("img", {
                        staticClass: "subresource-buttons-pin-icon",
                        attrs: {
                          src: require("@/assets/icons/pin" +
                            (_vm.pinned ? "-filled" : "") +
                            ".svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }