var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.browserNotSupported
        ? _c("div", { staticClass: "browser-not-supported" }, [
            _c("img", {
              staticClass: "browser-not-supported-img",
              attrs: { src: _vm.logo, alt: "" }
            }),
            _c("p", { staticClass: "browser-not-supported-header" }, [
              _vm._v(" We currently don't support this browser ")
            ]),
            _c("p", { staticClass: "browser-not-supported-subtext" }, [
              _vm._v(
                " Install one of these modern browsers to use the uman.ai app "
              )
            ]),
            _c("div", { staticClass: "modern-browsers" }, [
              _c(
                "a",
                { attrs: { href: "https://www.google.com/chrome/" } },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#d94734" },
                    attrs: { icon: "google-chrome", size: "is-large" }
                  })
                ],
                1
              ),
              _c(
                "a",
                {
                  attrs: { href: "https://www.mozilla.org/en-US/firefox/new/" }
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#f6710b" },
                    attrs: { icon: "firefox", size: "is-large" }
                  })
                ],
                1
              ),
              _c(
                "a",
                { attrs: { href: "https://www.microsoft.com/en-us/edge" } },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#0881c6" },
                    attrs: { icon: "microsoft-edge", size: "is-large" }
                  })
                ],
                1
              ),
              _c(
                "a",
                { attrs: { href: "https://www.opera.com/" } },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#e21327" },
                    attrs: { icon: "opera", size: "is-large" }
                  })
                ],
                1
              )
            ])
          ])
        : _c(
            "router-view",
            { attrs: { name: "shell" } },
            [
              [
                _c(
                  "div",
                  {
                    staticClass: "full-height",
                    staticStyle: { position: "relative" }
                  },
                  [
                    _vm.loading
                      ? _c("b-loading", {
                          attrs: { "is-full-page": false, "can-cancel": false }
                        })
                      : _vm._e(),
                    _vm.isHomePage
                      ? _c("HomePage")
                      : _vm.$route.name !== "search" ||
                        (!!_vm.resourceTypeFilter && !!_vm.workspaceId)
                      ? _c("router-view", {
                          key: _vm.routerViewKey,
                          attrs: { name: "default" }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            ],
            2
          ),
      _c("Toast", { ref: "roottoastcomponent" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }