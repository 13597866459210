var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ProfileSettingsModal",
        _vm._g(
          {
            attrs: {
              user: _vm.currentUser,
              "initial-active-tab": _vm.initialActiveTab,
              "loading-password-reset": _vm.loadingPasswordReset,
              "loading-settings": _vm.loadingSettings
            },
            on: {
              newPassword: _vm.setPasswordValue,
              saveProfile: _vm.setNewData
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }