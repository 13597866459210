<template>
  <div
    v-if="!!title"
    class="toast"
    :style="`--bar-color: ${color}; --bar-timeout: ${timeout}ms;`"
  >
    <div class="toast-bar">
      <div class="toast-bar-inner"></div>
    </div>
    <div class="toast-content">
      <span class="toast-content-title">{{ title }}</span>
      <HTMLRenderer
        v-if="description"
        :value="description"
        class="toast-content-description"
      />
    </div>
  </div>
</template>

<script>
import HTMLRenderer from '@c/library/HTMLRenderer.vue'

export default {
  name: 'Toast',
  components: { HTMLRenderer },
  data: () => ({
    title: '',
    description: '',
    color: '',
    timeout: 5000,
    timer: null
  }),
  methods: {
    show(t, d, c, time = 5000) {
      this.hide()
      this.$nextTick(() => {
        this.title = t
        this.description = d
        this.color = c
        this.timeout = time
        this.timer = setTimeout(this.hide, this.timeout)
      })
    },
    hide() {
      this.title = ''
      this.description = ''
      this.color = ''
      clearTimeout(this.timer)
      this.timer = null
    },
    danger(t, d, time = 5000) {
      this.show(t, d, '#eb5757', time)
    },
    success(t, d, time = 5000) {
      this.show(t, d, '#68c6be', time)
    },
    info(t, d, time = 5000) {
      this.show(t, d, '#2f80ed', time)
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  width: 280px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);

  &-bar {
    height: 7px;
    background: color-mix(in srgb, var(--bar-color), #0000 75%);

    &-inner {
      height: 100%;
      background: var(--bar-color);
      animation: slide var(--bar-timeout) linear;
    }
  }

  &-content {
    padding: 1.5rem 1rem;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-title {
      font-weight: 600;
      font-size: 1.2rem;
    }

    &-description {
      color: #60666b;
    }
  }

  &::v-deep a {
    color: $primary;
  }
}

@keyframes slide {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
