
















import Vue from 'vue'
import SyncStatusComponent from './SyncStatusComponent.vue'
import { SYNC_STATUS } from '@c/models/Integration'

export default Vue.extend({
  name: 'SyncStatusList',
  components: { SyncStatusComponent },
  props: {
    syncStatus: {
      type: Object as () => object,
      default: () => {}
    }
  },
  computed: {
    sortedStatNames() {
      const names = Object.keys(this.syncStatus)
      names.sort()

      const statusIdx = names.findIndex((x) => x.toLowerCase() === 'status')
      let status: string[] = []
      if (statusIdx > 0) {
        status = [names.splice(statusIdx, 1)[0]]
      }

      const lastSyncIdx = names.findIndex(
        (x) => x.toLowerCase() === 'last_sync'
      )
      if (lastSyncIdx !== -1) {
        names.splice(lastSyncIdx, 1)
      }

      return [...status, ...names]
    }
  },
  methods: {
    showSyncStatus(stat: string) {
      return Object.values(SYNC_STATUS).includes(stat)
    }
  }
})
