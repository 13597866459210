var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "body xs bold black mb-3" }, [
        _vm._v(
          " Authenticate your " + _vm._s(_vm.integration.title) + " account "
        )
      ]),
      _c("p", { staticClass: "body xxs mb-5" }, [
        _vm._v(_vm._s(_vm.integration.setup.instructions))
      ]),
      _c("ModalActionFooter", {
        attrs: { action: "Connect " + _vm.integration.title },
        on: {
          action: _vm.goToIntegration,
          close: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }