




































import { getDocumentDownloadLink } from '@/services/presentationService'
import Button from '@c/library/Button.vue'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import { IntegrationData } from '@c/models/Resource'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ResourceInfo from './ResourceInfo.vue'

export default Vue.extend({
  name: 'ResourceDetailHeader',
  components: {
    DocumentTag,
    ResourceInfo,
    UploadDownloadDropdown,
    Button
  },

  props: {
    resourceId: {
      type: String,
      default: undefined
    },

    mimetype: {
      type: String as () => string,
      default: undefined
    },

    title: {
      type: String as () => string,
      required: true
    },

    resourceIntegration: {
      type: Object as () => IntegrationData,
      default: undefined
    },

    bookmark: {
      type: [String, Boolean],
      default: null
    }
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['downloadFilesEnabled']),
    linkText() {
      return this.resourceIntegration
        ? `Open on ${this.resourceIntegration.integration
            .split('_')
            .map((w) => w[0].toUpperCase() + w.slice(1).toLowerCase())
            .join(' ')}`
        : 'Open'
    }
  },
  methods: {
    async getDownloadProps() {
      return getDocumentDownloadLink(
        this.$route.params.workspace_id,
        this.resourceId,
        this.$route.query?.act_as
      )
    }
  }
})
