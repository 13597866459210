var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "IconHeaderModal",
    {
      attrs: {
        icon: (_vm.workspace || { image: undefined }).image,
        "with-close-icon": !_vm.noCloseIcon,
        title: _vm.titlePrefix + " " + (_vm.workspace || { name: "" }).name,
        subtitle: _vm.subtitle,
        "no-padding": _vm.noPadding,
        loading: _vm.loading
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title-right",
            fn: function() {
              return [_vm._t("title-right")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }