var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    {
      staticClass: "tag",
      class:
        ((_obj = {}),
        (_obj[_vm.type] = true),
        (_obj[_vm.size] = true),
        (_obj.rounded = _vm.rounded),
        (_obj.overflowing = _vm.overflowing),
        (_obj["icon-only"] = !_vm.text),
        _obj)
    },
    [
      _vm.icon && _vm.iconLeft
        ? _c("img", {
            staticClass: "tag-icon",
            class: { "apply-filter": _vm.filterIcon },
            attrs: {
              src: require("@/assets/icons/" + _vm.icon + ".svg"),
              alt: ""
            }
          })
        : _vm._e(),
      _vm._t("left"),
      _vm.text
        ? _c("span", { staticClass: "tag-text" }, [_vm._v(_vm._s(_vm.text))])
        : _vm._e(),
      _vm._t("right"),
      _vm.icon && !_vm.iconLeft
        ? _c("img", {
            staticClass: "tag-icon",
            class: { "apply-filter": _vm.filterIcon },
            attrs: {
              src: require("@/assets/icons/" + _vm.icon + ".svg"),
              alt: ""
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }