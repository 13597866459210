











































import { SYNC_STATUS } from '@c/models/Integration'
import Vue from 'vue'

const statusIconMap: Record<SYNC_STATUS, string> = {
  [SYNC_STATUS.SUCCESS]: 'check-circle-outline',
  [SYNC_STATUS.FAILURE_EXTRACTION]: 'alert',
  [SYNC_STATUS.FAILURE_INDEXING]: 'alert',
  [SYNC_STATUS.PENDING]: 'sync',
  [SYNC_STATUS.UNSUPPORTED]: 'content-save-alert',
  [SYNC_STATUS.REQUESTED]: 'progress-upload',
  [SYNC_STATUS.IDENTIFIED]: 'content-save-alert'
}

const statusTooltipTypeMap: Record<SYNC_STATUS, string> = {
  [SYNC_STATUS.SUCCESS]: 'is-dark',
  [SYNC_STATUS.FAILURE_EXTRACTION]: 'is-dark',
  [SYNC_STATUS.FAILURE_INDEXING]: 'is-dark',
  [SYNC_STATUS.PENDING]: 'is-dark',
  [SYNC_STATUS.UNSUPPORTED]: 'is-dark',
  [SYNC_STATUS.REQUESTED]: 'is-dark',
  [SYNC_STATUS.IDENTIFIED]: 'is-dark'
}

const statusTooltipLabel: Record<SYNC_STATUS, string> = {
  [SYNC_STATUS.SUCCESS]: 'success',
  [SYNC_STATUS.FAILURE_EXTRACTION]: 'failed on extraction',
  [SYNC_STATUS.FAILURE_INDEXING]: 'failed on indexing',
  [SYNC_STATUS.PENDING]: '(re)syncing',
  [SYNC_STATUS.UNSUPPORTED]: 'unsupported',
  [SYNC_STATUS.REQUESTED]: 'requested',
  [SYNC_STATUS.IDENTIFIED]: 'identified'
}

const iconColorMap: Record<SYNC_STATUS, string> = {
  [SYNC_STATUS.SUCCESS]: 'has-text-success',
  [SYNC_STATUS.FAILURE_EXTRACTION]: 'has-text-danger',
  [SYNC_STATUS.FAILURE_INDEXING]: 'has-text-danger',
  [SYNC_STATUS.PENDING]: 'has-text-primary',
  [SYNC_STATUS.UNSUPPORTED]: 'has-text-info',
  [SYNC_STATUS.REQUESTED]: 'has-text-primary',
  [SYNC_STATUS.IDENTIFIED]: 'has-text-info'
}

export default Vue.extend({
  name: 'SyncStatusComponent',
  props: {
    status: {
      type: String,
      default: ''
    },
    statusMessage: {
      type: String,
      default: undefined
    },
    prefixMessage: {
      type: String,
      default: ''
    },
    suffixMessage: {
      type: String,
      default: ''
    },
    tooltipProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    icon(): string {
      return statusIconMap[this.status as SYNC_STATUS] || this.status
    },
    tooltipType(): string {
      return statusTooltipTypeMap[this.status as SYNC_STATUS]
    },
    tooltipLabel(): string {
      return (
        this.statusMessage || statusTooltipLabel[this.status as SYNC_STATUS]
      )
    },
    iconColor(): string {
      return iconColorMap[this.status as SYNC_STATUS]
    },
    syncStatus() {
      return SYNC_STATUS
    }
  }
})
