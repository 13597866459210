export function getToastEngine() {
  return {
    component: null,
    register(component) {
      this.component = component
    },
    danger(...args) {
      ;(this.component?.danger || (() => {}))(...args)
    },
    success(...args) {
      ;(this.component?.success || (() => {}))(...args)
    },
    info(...args) {
      ;(this.component?.info || (() => {}))(...args)
    },
    error(e, context) {
      if ((e.status === 400 || e.status === 404) && !!e.data.detail)
        this.danger(`Error ${context}`, e.data.detail)
      else
        this.danger(
          `Error ${context}`,
          `Something went wrong while ${context}. Please try again later or contact support.`
        )
    }
  }
}
