import * as moment from 'moment'
import { relativeTime as globalRelativeTime } from '@c/util'

export interface DatedModel {
  date_created?: string
  date_updated?: string
}

interface DatedModelDates {
  date_created?: Date
  date_updated?: Date
}

export function mapDateString(dateString?: string): Date | undefined {
  if (dateString) {
    return moment.default(dateString as string).toDate()
  }
  return undefined
}

export function mapDatedModelDates(model: DatedModel): DatedModelDates {
  return {
    date_created: mapDateString(model.date_created),
    date_updated: mapDateString(model.date_updated)
  }
}

export function relativeTime(date: string | Date | undefined) {
  if (date instanceof String) {
    date = mapDateString(date as string)
  }
  return date ? globalRelativeTime(date as Date) : '-'
}

export default {}
