interface Set<T> {
  intersection: (otherSet: Set<T>) => Set<T>
  difference: (otherSet: Set<T>) => Set<T>
  union: (otherSet: Set<T>) => Set<T>
}

;(Set.prototype as any).intersection = function <T>(otherSet: Set<T>): Set<T> {
  return new Set([...this].filter((x) => otherSet.has(x)))
}
;(Set.prototype as any).difference = function <T>(otherSet: Set<T>): Set<T> {
  return new Set([...this].filter((x) => !otherSet.has(x)))
}
;(Set.prototype as any).union = function <T>(otherSet: Set<T>): Set<T> {
  return new Set([...this, ...otherSet])
}
;(Set.prototype as any).elementsEqual = function <T>(
  otherSet: Set<T>
): boolean {
  return (
    this.difference(otherSet).size === 0 && otherSet.difference(this).size === 0
  )
}
