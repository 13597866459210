<template>
  <div class="html-viewer">
    <!-- eslint-disable -->
    <div class="html-viewer-view">
      <div
        ref="htmlcontent"
        class="html-viewer-view-content"
        v-html="html"
      ></div>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import DOMPurify from 'dompurify'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export default {
  name: 'HTMLRenderer',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    preview: false,
    text: ''
  }),
  computed: {
    html() {
      return DOMPurify.sanitize(this.text)
    },
    htmlText() {
      return this.$refs.htmlcontent?.innerText
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.text = this.value
        this.preview = false
      }
    }
  },
  mounted() {
    this.text = this.value
  }
}
</script>

<style scoped lang="scss">
.html-viewer {
  height: 100%;
  width: 100%;

  &-view {
    &-content {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }
}

// Return default styles
.html-viewer-view-content {
  &::v-deep h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em 0;
    line-height: unset;
  }

  &::v-deep h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.83em 0;
    line-height: unset;
  }

  &::v-deep h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
    line-height: unset;
  }

  &::v-deep h4 {
    display: block;
    font-weight: bold;
    margin: 1.33em 0;
    line-height: unset;
  }

  &::v-deep h5 {
    display: block;
    font-size: 0.83em;
    margin: 1.67em 0;
    line-height: unset;
  }

  &::v-deep h6 {
    display: block;
    font-size: 0.83em;
    margin: 2.33em 0;
    line-height: unset;
  }

  &::v-deep p {
    display: block;
    margin: 1em 0;
    line-height: unset;
  }

  &::v-deep ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  &::v-deep ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  &::v-deep blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#000, 8%);
  }

  &::v-deep a {
    color: $primary;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
