var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "slide-modal",
      staticClass: "modal-container pa-5 scrollbar-visible"
    },
    [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _c("QualityLabelGroup", {
            attrs: {
              labels: _vm.subresource.labels,
              big: true,
              "show-hover-info": false
            }
          }),
          _c("CollectSlideButton", {
            attrs: {
              big: true,
              subresource: _vm.subresource,
              rank: _vm.rank,
              "has-tooltip": false,
              "always-show": _vm.forceShowCollect,
              location: "slide modal"
            }
          })
        ],
        1
      ),
      _c("img", {
        staticClass: "modal-image",
        attrs: { src: _vm.subresource.preview }
      }),
      _c(
        "div",
        { ref: "references", staticClass: "title-container" },
        [
          _c("div", { staticClass: "modal-subheader" }, [
            _vm._v(
              " Appears in " +
                _vm._s(_vm.subresource.total_references) +
                " " +
                _vm._s(
                  _vm.subresource.total_references === 1
                    ? "document"
                    : "documents"
                ) +
                " "
            )
          ]),
          _c(
            "b-tooltip",
            {
              attrs: {
                label: _vm.isBookmarked ? "Remove bookmark" : "Add bookmark",
                position: "is-top",
                type: "is-dark",
                "append-to-body": ""
              }
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-bookmark btn-bookmark-modal",
                  attrs: { rounded: "", size: "is-small" },
                  on: {
                    click: function($event) {
                      return _vm.toggleBookmark()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@c/assets/icons/bookmark" +
                        (_vm.isBookmarked ? "-filled" : "") +
                        ".svg"),
                      alt: ""
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "references" },
        [
          _vm._l(_vm.visibleReferences, function(reference, idx) {
            return _c("div", { key: idx }, [
              _c(
                "div",
                { staticClass: "reference mt-3" },
                [
                  _c("DocumentTag", {
                    staticClass: "u-column is-narrow",
                    attrs: {
                      mimetype: reference.resource.integrationfile.mimetype
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onFileNameClick(reference)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(reference.resource.title) + " ")]
                  ),
                  _c("QualityLabels", {
                    attrs: {
                      "small-labels": true,
                      "active-label": reference.resource.label,
                      "source-id": reference.resource.uuid,
                      "bordered-icons": false
                    }
                  }),
                  _vm.downloadFilesEnabled
                    ? _c("UploadDownloadDropdown", {
                        attrs: {
                          "props-call": function() {
                            return _vm.getDownloadProps(reference.resource)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "integration-container" },
                [
                  _c("ResourceInfo", {
                    attrs: {
                      "resource-integration":
                        reference.resource.integrationfile,
                      hover: true
                    },
                    on: {
                      "on-path-click": function($event) {
                        return _vm.onPathClick(reference)
                      }
                    }
                  })
                ],
                1
              )
            ])
          }),
          _vm.showShowMoreReferences
            ? _c(
                "div",
                {
                  staticClass: "blue link bold references-showmore mt-3",
                  on: {
                    click: function($event) {
                      _vm.showMoreReferences = !_vm.showMoreReferences
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showMoreReferences ? "Show less" : "Show more"
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm.showSimilarSlides
        ? _c(
            "div",
            { ref: "similar", staticClass: "modal-subheader mt-5 mb-3" },
            [_vm._v(" Similar slides ")]
          )
        : _vm._e(),
      _vm.showSimilarSlides
        ? _c("SimilarSlides", {
            attrs: { "slide-id": _vm.subresource.uuid },
            on: {
              noSimilarSlides: function($event) {
                _vm.noSimilarSlides = true
              },
              ready: function($event) {
                _vm.similarSlidesReady = true
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }