var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "number-input", style: _vm.inputPadding }, [
    _vm.title
      ? _c("p", { staticClass: "number-input-title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e(),
    _c("div", { staticClass: "number-input-wrapper" }, [
      _c("div", { staticClass: "number-input-left" }, [
        _vm.leftIcon
          ? _c("img", {
              ref: "leftgroupicon",
              staticClass: "number-input-left-icon",
              attrs: {
                src: require("@/assets/icons/" + _vm.leftIcon + ".svg"),
                alt: ""
              },
              on: { load: _vm.handleIconLoad }
            })
          : _vm._e()
      ]),
      _c(
        "input",
        _vm._b(
          {
            staticClass: "number-input-input",
            class:
              ((_obj = { rounded: _vm.rounded }),
              (_obj[_vm.size] = true),
              _obj),
            style: Object.assign(
              {},
              _vm.background ? { background: _vm.background } : {}
            ),
            attrs: {
              id: "",
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              type: "number",
              name: ""
            },
            domProps: { value: _vm.value },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSubmit.apply(null, arguments)
              },
              input: _vm.handleInput,
              blur: function($event) {
                return _vm.$emit("blur", $event)
              }
            }
          },
          "input",
          Object.assign(
            {},
            _vm.min !== undefined && { min: _vm.min },
            _vm.max !== undefined && { max: _vm.max }
          ),
          false
        )
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }