<template>
  <div
    class="uavatar"
    :class="{ [size]: true, resizable }"
    :style="`--fallback-bg: ${fallbackBg};`"
  >
    <img
      v-if="$umodel.avatar(user) && !error"
      :src="$umodel.avatar(user)"
      class="uavatar-img"
      draggable="false"
      @error="() => (error = true)"
    />
    <div v-else-if="fallbackIcon" class="uavatar-img">
      <img
        :src="require(`@/assets/icons/${fallbackIcon}.svg`)"
        alt=""
        class="uavatar-img-icon"
        draggable="false"
        :style="filterFallback"
      />
    </div>
    <div
      v-else
      class="uavatar-img"
      :style="{ background: $umodel.user_color(user) }"
    >
      {{ $umodel.initials(user) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    fallbackIcon: {
      type: String,
      default: ''
    },
    fallbackBackground: {
      type: String,
      default: ''
    },
    filterFallback: {
      type: Object,
      default: () => ({ opacity: 0.5 })
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l'].includes(s)
    },
    resizable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    error: false
  }),
  computed: {
    fallbackBg() {
      return (
        this.fallbackBackground ||
        (this.fallbackIcon ? '#f0f0f0' : this.$umodel.user_color(this.user))
      )
    }
  }
}
</script>

<style scoped lang="scss">
.uavatar {
  &:not(.resizable) {
    &.xxs {
      width: 1.2rem;
      height: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      font-size: 0.75rem;
    }

    &.xs {
      width: 1.85rem;
      height: 1.85rem;
      min-width: 1.85rem;
      min-height: 1.85rem;
      font-size: 0.75rem;
    }

    &.s {
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
      font-size: 0.875rem;
    }

    &.m {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
      font-size: 1.125rem;
    }

    &.l {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
      font-size: 1.375rem;
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    border-radius: 999rem;
    object-fit: cover;
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    user-select: none;

    &:has(img) {
      background: var(--fallback-bg);
    }

    &-icon {
      height: 50%;
      width: 50%;
    }
  }
}

img.uavatar-img {
  display: inline;
}
</style>
