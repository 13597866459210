import Vue from 'vue'
import Mutations from '../mutation-types'

export default {
  state: {
    loading: false,
    initialized: false,
    isMobile: false,
    snake: false,
    cardType: 'listItem',
    rememberDialogKeys:
      JSON.parse(window.localStorage.getItem('rememberDialogKeys')) || {},
    modalOpen: false,
    windowWidth: 0,
    forceAppRefresh: false,
    sendingEvent: false,
    sidebarWidth: 65,
    breadcrumbs: [],
    debugGuards: false, // Set to true to log debug info for router guards
    supportedLanguages: [
      { name: 'Deutsch', code: 'de' },
      { name: 'English', code: 'en' },
      { name: 'Español', code: 'es' },
      { name: 'Français', code: 'fr' },
      { name: 'Hrvatski', code: 'hr' },
      { name: 'Italiano', code: 'it' },
      { name: 'Nederlands', code: 'nl' },
      { name: 'Português', code: 'pt' },
      { name: 'Türkçe', code: 'tr' },
      { name: '中文', code: 'zh-hans' }
    ]
  },
  mutations: {
    [Mutations.START_LOADING](state) {
      state.loading = true
    },

    [Mutations.STOP_LOADING](state) {
      state.loading = false
    },

    [Mutations.SET_INITIALIZED](state, initialized) {
      state.initialized = initialized
    },

    [Mutations.SET_IS_MOBILE](state, isMobile) {
      state.isMobile = isMobile
    },

    [Mutations.SET_MODAL_OPEN](state, modalOpen) {
      state.modalOpen = modalOpen
    },

    [Mutations.SET_CARD_TYPE](state, cardType) {
      state.cardType = cardType
    },

    [Mutations.SET_REMEMBER_DIALOG_KEY](state, key) {
      Vue.set(state.rememberDialogKeys, key, true)
      window.localStorage.setItem(
        'rememberDialogKeys',
        JSON.stringify(state.rememberDialogKeys)
      )
    },
    setWindowWidth(state, width) {
      state.windowWidth = width
    },
    forceAppRefresh(state, force) {
      state.forceAppRefresh = force
    },
    setSendingEvent(state, sending) {
      state.sendingEvent = sending
    },
    setSidebarWidth(state, width) {
      state.sidebarWidth = width
    },
    pushBreadCrumb(state, breadcrumb) {
      state.breadcrumbs.push(breadcrumb)
    },
    resetBreadCrumbs(state) {
      state.breadcrumbs = []
    },
    popBreadCrumb(state) {
      state.breadcrumbs.pop()
    },
    restartBreadCrumbs(state, breadcrumb) {
      state.breadcrumbs = [breadcrumb]
    }
  },

  getters: {
    clientName() {
      return 'webapp'
    },
    windowWidth: (state) => state.windowWidth,
    sendingEvent: (state) => state.sendingEvent,
    sidebarWidth: (state) => state.sidebarWidth,
    debugGuards: (state) => state.debugGuards,
    breadcrumbs: (state) => state.breadcrumbs,
    supportedLanguages: (state) => state.supportedLanguages
  },
  actions: {
    setWindowWidth({ commit }, width) {
      commit('setWindowWidth', width)
    },
    setIsHomePage({ commit }, isHome) {
      commit('setIsHomePage', isHome)
    },
    setSendingEvent({ commit }, sending) {
      commit('setSendingEvent', sending)
    },
    setSidebarWidth({ commit }, width) {
      commit('setSidebarWidth', width)
    },
    pushBreadCrumb({ commit }, breadcrumb) {
      commit('pushBreadCrumb', breadcrumb)
    },
    resetBreadCrumbs({ commit }) {
      commit('resetBreadCrumbs')
    },
    popBreadCrumb({ commit }) {
      commit('popBreadCrumb')
    },
    restartBreadCrumbs({ commit }, breadcrumb) {
      commit('restartBreadCrumbs', breadcrumb)
    }
  }
}
