/* eslint-disable no-console */

import Console from '@/console'
import { register } from 'register-service-worker'

if (
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'development'
) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      Console.info('Service worker ready')
    },
    registered() {
      Console.info('Service worker has been registered.')
    },
    cached() {
      Console.info('Content has been cached for offline use.')
    },
    updatefound() {
      Console.info('New content is downloading.')
    },
    updated() {
      Console.info('New content is available; please refresh.')
    },
    offline() {
      Console.info(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      Console.error('Error during service worker registration:', error)
    }
  })
}
