





















import Vue from 'vue'
export default Vue.extend({
  props: {
    action: {
      type: String,
      default: ''
    },
    parentRef: {
      type: Object as any,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    closeText: {
      type: String,
      default: 'Cancel'
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
})
