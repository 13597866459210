var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "link-preview-wrapper", on: { click: _vm.onResourceClick } },
    [
      _c("img", {
        staticClass: "link-preview",
        attrs: { src: _vm.subresource.preview },
        on: {
          load: function($event) {
            return _vm.$emit("load")
          },
          error: function($event) {
            return _vm.$emit("cantLoad")
          }
        }
      }),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link-preview-hover" }, [
      _c("div", { staticClass: "link-preview-hover-icon-wrapper" }, [
        _c("img", {
          staticClass: "link-preview-hover-icon",
          attrs: { src: require("@/assets/icons/external-bold.svg"), alt: "" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }