















































































import FileToImageConvertMixin from '@c/mixins/FileToImageConvertMixin'
import User from '@c/models/User'
import DefaultProfileImage from '@c/assets/profile-account.png'
import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import { UserFormData } from '../interfaces'
import { mapGetters } from 'vuex'
import LanguageSelect from '@c/library/LanguageSelect.vue'

interface Data extends UserFormData {
  disabled: boolean
  imageUrl?: string
  parsing: boolean
}

export default mixins(FileToImageConvertMixin).extend({
  name: 'ProfileEditTab',
  components: {
    LanguageSelect
},
  mixins: [],
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    initialData: {
      type: Object as PropType<UserFormData>,
      default: function (): UserFormData {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function (): Data {
    return Object.assign(
      {
        username: undefined,
        first_name: undefined,
        last_name: undefined,
        image: undefined,
        disabled: true,
        imageUrl: this.user.avatar,
        parsing: false,
        role: undefined,
        language: undefined
      },
      this.initialData
    ) as Data
  },
  computed: {
    ...mapGetters(['currentWorkspaceMember']),
    returnValue(): any {
      return {
        first_name: this.first_name,
        last_name: this.last_name,
        image: this.image,
        role: this.role,
        language: this.language
      }
    },
    DefaultProfileImage() {
      return DefaultProfileImage
    }
  },
  watch: {
    email(): void {
      this.checkForChanges()
    },
    first_name(): void {
      this.checkForChanges()
    },
    last_name(): void {
      this.checkForChanges()
    },
    role(): void {
      this.checkForChanges()
    },
    language(): void {
      this.checkForChanges()
    },
    image(newVal: File): void {
      this.parsing = true
      this.imageToURL(newVal, (val?: string) => {
        this.imageUrl = val
        this.parsing = false
      })
      this.checkForChanges(true)
    }
  },
  mounted() {
    this.role = this.currentWorkspaceMember.role
  },
  methods: {
    checkForChanges(forceChanged?: boolean): void {
      const hasChanged =
        this.username !== this.initialData.username ||
        this.first_name !== this.initialData.first_name ||
        this.last_name !== this.initialData.last_name ||
        this.language !== this.initialData.language ||
        this.role !== this.currentWorkspaceMember.role
      this.disabled = !(hasChanged || forceChanged)
    }
  }
})
