var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns no-margin is-mobile" }, [
    _c("div", { staticClass: "column no-padding" }, [
      _c(
        "div",
        {
          staticClass: "u-modal",
          class: { "pb-6": !_vm.noPadding, "pa-5": !_vm.noPadding }
        },
        [
          _c("ModalHeader", {
            attrs: { "no-icon": _vm.noIcon, "no-title": _vm.noTitle },
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function() {
                    return [_vm._t("icon")]
                  },
                  proxy: true
                },
                {
                  key: "title",
                  fn: function() {
                    return [_vm._t("title")]
                  },
                  proxy: true
                },
                {
                  key: "right",
                  fn: function() {
                    return [_vm._t("title-right")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          }),
          _c(
            "div",
            {
              class: { "mt-5": !_vm.noPadding },
              staticStyle: { position: "relative" }
            },
            [
              _vm._t("default"),
              _c("b-loading", {
                attrs: { active: _vm.loading, "is-full-page": false }
              })
            ],
            2
          )
        ],
        1
      )
    ]),
    _vm.withCloseIcon
      ? _c(
          "div",
          { staticClass: "column no-padding is-narrow modal-close-icon" },
          [
            _c(
              "div",
              { staticClass: "pl-2" },
              [
                _c("b-button", {
                  staticClass: "u-fab light transparent",
                  attrs: { "icon-right": "close-circle", size: "is-large" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }