import { Paginator, getClient, getResponseData } from '../../axios'

const client = getClient('/workspaces')

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async searchInspirations(
      _,
      {
        workspace_id,
        search,
        attribute_value_ids,
        ordering,
        page_size,
        owner_id,
        callback = () => {}
      }
    ) {
      const res = await client.get(`/${workspace_id}/inspirations/`, {
        params: {
          search,
          ordering: ordering || '-date_updated',
          attribute_value_ids: attribute_value_ids?.length
            ? attribute_value_ids.join(',')
            : null,
          page_size: page_size || 100,
          owner_id
        }
      })
      callback(res)
      return getResponseData(res)
    },

    async getSearchInspirationsPaginator(
      _,
      {
        workspace_id,
        search,
        attribute_value_ids,
        ordering,
        page_size,
        owner_id
      }
    ) {
      return new Paginator(
        client,
        await client.get(`/${workspace_id}/inspirations/`, {
          params: {
            search,
            ordering: ordering || '-date_updated',
            attribute_value_ids: attribute_value_ids?.length
              ? attribute_value_ids.join(',')
              : null,
            page_size: page_size || 10,
            owner_id
          }
        })
      )
    },

    async searchInspirationsExtended(
      _,
      {
        workspace_id,
        query,
        statuses,
        owner_ids,
        has_offerings,
        page_size,
        export_filetype,
        ...attributes
      }
    ) {
      const res = await client.post(
        `/${workspace_id}/inspirations/search/`,
        {
          ...(query ? { query } : {}),
          ...(statuses?.length ? { statuses } : {}),
          ...(owner_ids?.length ? { owner_ids } : {}),
          ...(has_offerings ? { has_offerings } : {}),
          ...(Object.keys(attributes || {}).length
            ? {
                attribute_value_ids: Object.keys(attributes).reduce(
                  (acc, curr) => [...acc, ...attributes[curr]],
                  []
                )
              }
            : {})
        },
        {
          params: {
            page_size: page_size || 10,
            ...(export_filetype ? { export: export_filetype } : {})
          }
        }
      )
      return getResponseData(res)
    },

    async getSearchInspirationsExtendedPaginator(
      _,
      {
        workspace_id,
        query,
        sort_by,
        statuses,
        owner_ids,
        has_offerings,
        page_size,
        callback = () => {},
        ...attributes
      }
    ) {
      const body = {
        ...(query ? { query } : {}),
        ...(sort_by ? { sort_by } : {}),
        ...(statuses?.length ? { statuses } : {}),
        ...(owner_ids?.length ? { owner_ids } : {}),
        ...(has_offerings ? { has_offerings } : {}),
        ...(Object.keys(attributes || {}).length
          ? {
              attribute_value_ids: Object.keys(attributes).reduce(
                (acc, curr) => [...acc, ...attributes[curr]],
                []
              )
            }
          : {})
      }

      const res = await client.post(
        `/${workspace_id}/inspirations/search/`,
        body,
        {
          params: {
            page_size: page_size || 10
          }
        }
      )
      callback(res)
      return new Paginator(
        client,
        res,
        () => {},
        undefined,
        (url) => client.post(url, body)
      )
    },

    async createInspiration(
      _,
      { workspace_id, name, custom_id, owner_ids, offering_ids, act_as }
    ) {
      const res = await client.post(
        `/${workspace_id}/inspirations/`,
        {
          name,
          custom_id,
          owner_ids,
          offering_ids
        },
        { params: { act_as } }
      )
      return getResponseData(res)
    },

    async finishInspirationDraft(_, { workspace_id, ori_id, draft, act_as }) {
      const res = await client.patch(
        `/${workspace_id}/inspirations/${ori_id}/`,
        { draft },
        { params: { act_as } }
      )
      return getResponseData(res)
    },

    async editInspiration(
      _,
      {
        workspace_id,
        ori_id,
        name,
        custom_id,
        author,
        owner_ids,
        offering_ids,
        url
      }
    ) {
      const res = await client.patch(
        `/${workspace_id}/inspirations/${ori_id}/`,
        {
          name,
          custom_id,
          author,
          owner_ids,
          offering_ids,
          url
        }
      )
      return getResponseData(res)
    },

    async getInspirationInfo(_, { workspace_id, ori_id, act_as }) {
      const res = await client.get(`/${workspace_id}/inspirations/${ori_id}/`, {
        params: { act_as }
      })
      return getResponseData(res)
    },

    async getInspirations(_, { workspace_id, act_as, extended = false }) {
      const res = await client.get(`/${workspace_id}/inspirations/`, {
        params: {
          ...(act_as ? { act_as } : {}),
          ...(extended ? { extended } : {})
        }
      })
      return getResponseData(res)
    },

    async generateInspirationImage(_, { workspace_id, ori_id, amount }) {
      const res = await client.post(
        `/${workspace_id}/inspirations/${ori_id}/image/generate/`,
        {},
        { params: { amount } }
      )
      return getResponseData(res)
    },

    async setInspirationImage(_, { workspace_id, ori_id, image }) {
      let formData = null
      if (image instanceof File) {
        formData = new FormData()
        formData.append('image', image)
      }
      const res = await client.put(
        `/${workspace_id}/inspirations/${ori_id}/image/`,
        formData
      )
      return getResponseData(res)
    },

    async setInspirationStatus(_, { workspace_id, ori_id, status }) {
      const res = await client.patch(
        `/${workspace_id}/inspirations/${ori_id}/`,
        { status }
      )
      return getResponseData(res)
    },

    async deleteInspiration(_, { workspace_id, ori_id }) {
      const res = await client.delete(
        `/${workspace_id}/inspirations/${ori_id}/`
      )
      return getResponseData(res)
    },

    async editInspirationResourceLink(
      _,
      {
        workspace_id,
        ori_id,
        resource_id,
        attribute_value_ids,
        position,
        act_as
      }
    ) {
      const res = await client.put(
        `/${workspace_id}/inspirations/${ori_id}/resources/${resource_id}/`,
        {
          ...(attribute_value_ids ? { attribute_value_ids } : {}),
          ...(position ? { position } : {})
        },
        { params: { act_as } }
      )
      return getResponseData(res)
    },

    async regenerateInspirationResource(
      _,
      { workspace_id, ori_id, template_id, act_as }
    ) {
      const res = await client.post(
        `/${workspace_id}/inspirations/${ori_id}/templates/${template_id}/regenerate/`,
        {},
        { params: { act_as } }
      )
      return getResponseData(res)
    },

    async unlinkResourceFromInspiration(
      _,
      { workspace_id, ori_id, resource_id }
    ) {
      const res = await client.delete(
        `/${workspace_id}/inspirations/${ori_id}/resources/${resource_id}/`
      )
      return getResponseData(res)
    },

    async getInspirationResourceUploadProps(
      _,
      { workspace_id, ori_id, name, mimetype, content_length, act_as }
    ) {
      const res = await client.post(
        `/${workspace_id}/inspirations/${ori_id}/assets/upload/`,
        { name, mimetype, content_length },
        { params: { act_as } }
      )
      return getResponseData(res)
    },

    async processInspirationResourceUpload(
      _,
      { workspace_id, ori_id, state, integration_file_id }
    ) {
      const res = await client.post(
        `/${workspace_id}/inspirations/${ori_id}/assets/process/`,
        { state, integration_file_id }
      )
      return getResponseData(res)
    },

    async editInspirationAttributes(
      _,
      { workspace_id, ori_id, attribute_value_ids }
    ) {
      const res = await client.patch(
        `/${workspace_id}/inspirations/${ori_id}/`,
        {
          attribute_value_ids
        }
      )
      return getResponseData(res)
    }
  }
}
