<template>
  <DraggableCarousel
    :items="selectedSlides"
    :scroll-trigger="slideCollectorOpen"
    :preview-key="previewKey"
    @input="handleOrderChange"
  >
    <template v-slot="{ item: slide, idx }">
      <div
        class="selected-slide ma-2"
        @mouseenter="hovered = slide.uuid"
        @mouseleave="hovered = ''"
      >
        <img :src="previewKey(slide)" alt="" class="slide-img" />
        <div class="selected-slide-btns">
          <QualityLabels
            v-if="slide.label || slide.labels"
            :active-label="slide.label || slide.labels[0]"
            :source-id="slide.uuid"
            :small-labels="true"
            :show-hover-info="false"
            :show-edit-labels="false"
            :bordered-icons="true"
          />
          <Button
            v-if="hovered === slide.uuid && !isDownloading"
            icon="close"
            type="white"
            size="xs"
            @click.native.stop="onDelete(slide, idx)"
            @mousedown.native.stop
          />
        </div>
      </div>
    </template>
  </DraggableCarousel>
</template>

<script>
import { sendCollectEvent } from '@/services/feedbackService'
import DraggableCarousel from '@c/library/DraggableCarousel.vue'
import { mapActions, mapGetters } from 'vuex'
import QualityLabels from './QualityLabels.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'CustomSlideCreatorCarousel',
  components: { DraggableCarousel, QualityLabels, Button },
  props: {
    isDownloading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hovered: ''
  }),
  computed: {
    ...mapGetters(['selectedSlides', 'slideCollectorOpen', 'currentCollection'])
  },
  methods: {
    ...mapActions(['deleteSlide', 'moveSlide']),
    onDelete(slide, idx) {
      sendCollectEvent(this.$route.params.workspace_id, {
        subresource_trace_id: slide.trace_id || slide.subresource_trace_id,
        resource_trace_id:
          slide.references?.[0]?.resource?.trace_id || slide.resource_trace_id,
        tab: this.$route.params.tab || 'all',
        comment: 'remove collected from presentation creator',
        collection_id: this.currentCollection?.uuid,
        collection_page_id: this.currentCollection?.pages?.[idx]?.uuid
      })
      this.deleteSlide(slide.uuid)
    },
    previewKey(item) {
      return item.preview || item.signed_url
    },
    handleOrderChange(item, newIndex) {
      this.moveSlide({ uuid: item.uuid, newIndex })
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-slide {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(#000, 0.08);

  padding: 0;
  margin: 0;

  &-btns {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
  }
}

.slide-img {
  height: 126px;
  width: 100%;

  padding: 0;
  margin: 0;
  display: block;
}
</style>
