var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invites-container" },
    [
      _vm.invites && _vm.invites.length > 0
        ? _c("Table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.invites,
              sortable: false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var header = ref.header
                    var item = ref.item
                    return [
                      header.id === "email"
                        ? _c("p", { staticClass: "invites-item-email" }, [
                            _vm._v(" " + _vm._s(header.key(item)) + " ")
                          ])
                        : header.id === "invited_by"
                        ? _c("div", [
                            header.key(item)
                              ? _c(
                                  "div",
                                  { staticClass: "invites-item-inviter" },
                                  [
                                    _c("Avatar", {
                                      attrs: { user: item.sender.user }
                                    }),
                                    _vm._v(" " + _vm._s(header.key(item)) + " ")
                                  ],
                                  1
                                )
                              : _c(
                                  "p",
                                  { staticClass: "invites-item-inviter" },
                                  [_vm._v("unknown")]
                                )
                          ])
                        : header.id === "roles"
                        ? _c(
                            "div",
                            { staticClass: "invites-item-roles" },
                            [
                              _c("RoleSelect", {
                                attrs: {
                                  value: item.roles,
                                  "fixed-width": "12.5rem"
                                },
                                on: {
                                  submit: function(roles) {
                                    return _vm.$emit("updateInvite", {
                                      invite: item,
                                      roles: roles
                                    })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : header.id === "reminder"
                        ? _c(
                            "div",
                            { staticClass: "invites-item-actions" },
                            [
                              _c("Button", {
                                attrs: { text: "Send reminder", icon: "mail" },
                                on: {
                                  click: function(roles) {
                                    return _vm.$emit("updateInvite", {
                                      invite: item,
                                      roles: item.roles,
                                      reminder: true
                                    })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : header.id === "actions"
                        ? _c(
                            "div",
                            { staticClass: "invites-item-actions" },
                            [
                              _c("Dropdown", {
                                attrs: {
                                  items: [
                                    {
                                      text: "Revoke invite",
                                      icon: "bin",
                                      filter: "red",
                                      callback: function() {
                                        return _vm.$emit(
                                          "deleteInvite",
                                          item.id
                                        )
                                      }
                                    }
                                  ]
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              6066335
            )
          })
        : _c("div", { staticClass: "invites-empty" }, [
            _c("p", { staticClass: "invites-empty-text" }, [
              _vm._v("No pending invites")
            ]),
            _c("p", { staticClass: "invites-empty-subtext" }, [
              _vm._v(
                " When you invite new members to your workspace, you'll see them here "
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }