var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var invalid = ref.invalid
                return [
                  _c(
                    "div",
                    {
                      staticClass: "u-columns is-vcentered mb-4",
                      class: { "ph-5": !_vm.paddingless }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "u-column is-narrow" },
                        [
                          _c("ImageFilePicker", {
                            ref: "image-picker",
                            staticClass: "mr-4",
                            attrs: {
                              id: "avatar-upload",
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.avatar,
                              callback: function($$v) {
                                _vm.avatar = $$v
                              },
                              expression: "avatar"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "u-column" }, [
                        _c(
                          "p",
                          {
                            staticClass: "link blue bold xxs",
                            on: {
                              click: function() {
                                return _vm.$refs["image-picker"].openUpload()
                              }
                            }
                          },
                          [_vm._v(" Click to upload a logo ")]
                        ),
                        _c("p", { staticClass: "body xxs" }, [
                          _vm._v("This will help others recognize it faster")
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: { "ph-5": !_vm.paddingless },
                      style: { "margin-top": _vm.disabled ? "54px" : undefined }
                    },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "required", name: "name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "b-field",
                                    {
                                      staticClass: "mt-4",
                                      attrs: {
                                        label: "Workspace name",
                                        type: { "is-danger": errors[0] },
                                        message: errors
                                      }
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "workspace-name-input",
                                          "custom-class": "gray-input",
                                          placeholder: "Uman.ai",
                                          disabled: _vm.disabled
                                        },
                                        model: {
                                          value: _vm.name,
                                          callback: function($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("ValidationProvider", {
                        attrs: { name: "description" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "b-field",
                                    {
                                      staticClass: "mt-4",
                                      attrs: {
                                        label: "Describe your workspace",
                                        type: { "is-danger": errors[0] },
                                        message: errors
                                      }
                                    },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "workspace-description-input",
                                          "custom-class": "gray-input",
                                          placeholder:
                                            "Workspace for the organisation uman.ai",
                                          disabled: _vm.disabled,
                                          maxlength: _vm.maxlength,
                                          type: "textarea"
                                        },
                                        model: {
                                          value: _vm.description,
                                          callback: function($$v) {
                                            _vm.description = $$v
                                          },
                                          expression: "description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._t("fields"),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-4",
                          staticStyle: { position: "sticky", bottom: "0" },
                          attrs: {
                            id: "workspace-change-confirm",
                            disabled: _vm.disabled || invalid,
                            type: "is-primary",
                            expanded: "",
                            loading: _vm.creating
                          },
                          on: {
                            click: function() {
                              return _vm.$emit("onEdit", {
                                name: _vm.name,
                                description: _vm.description,
                                is_open: _vm.is_open,
                                avatar: _vm.avatar
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.creating
                                  ? _vm.loadingButtonText
                                  : _vm.buttonText
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    2
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }