<template>
  <div class="updown">
    <slot
      :upload="uploadProvider ? handleUpload : undefined"
      :connect="uploadProvider ? connectIntegration : undefined"
      :download="handleDownload"
      :provider="uploadProvider"
    ></slot>
  </div>
</template>

<script>
import {
  downloadSignedUrl,
  uploadToIntegration
} from '@/services/presentationService'
import { buildOauthUrl, getNonce } from '@/util'
import { userFileStorageIntegrations } from '@c/integrations'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UploadDownload',
  props: {
    propsCall: {
      type: Function,
      default: async () => {}
    },
    callback: {
      type: Function,
      default: () => {}
    },
    downloadCallback: {
      type: Function,
      default: null
    },
    uploadCallback: {
      type: Function,
      default: null
    },
    showResult: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'currentUser', 'userIntegrations']),
    currentUserDomain() {
      return this.$umodel.email(this.currentUser).split('@')[1]
    },
    uploadProvider() {
      const providers = {
        google_drive: 'google_drive',
        sharepoint: 'onedrive'
      }
      return Object.keys(providers).reduce(
        (acc, curr) =>
          (this.currentWorkspace?.integrations || []).includes(curr)
            ? acc || providers[curr]
            : acc,
        ''
      )
    }
  },
  methods: {
    ...mapActions(['getUserIntegrations', 'getUserIntegrationFiles']),
    async checkAvailableIntegrations() {
      await this.getUserIntegrations()
    },
    async handleDownload() {
      let success = true
      let error = undefined
      this.$emit('loading')
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Downloading file...',
        position: 'is-bottom',
        type: 'is-white'
      })
      try {
        const { signed_url, name } = await this.propsCall()
        downloadSignedUrl(signed_url, name, this.downloadCallback)
      } catch (e) {
        success = false
        error = e
        this.$console.debug('Download error', e)
        this.$toast.error(e, 'downloading this file.')
      } finally {
        this.$emit('done')
        this.callback(success, error)
      }
    },
    handleUpload() {
      this.$emit('loading')
      const integration = this.userIntegrations.find(
        ui => ui.type === this.uploadProvider
      )
      if (integration) {
        this.exportToIntegration(integration.type)
        return
      }
      this.connectIntegration()
    },
    connectIntegration(connectOnly = false) {
      const integration = this.userIntegrations.find(
        ui => ui.type === this.uploadProvider
      )
      if (integration) {
        this.$emit('connected')
        if (!connectOnly) this.exportToIntegration(integration)
        return
      }
      const int = userFileStorageIntegrations[this.uploadProvider]
      const nonce = getNonce(25)
      localStorage.setItem('integrationNonce', nonce)
      const url = buildOauthUrl(int.oauthBaseURL, {
        ...int.oauthQueryParams,
        state: `${nonce}%user`,
        hd: this.currentUserDomain
      })
      window.open(`${url}`, '_blank')
      let bc = new BroadcastChannel('uman_integration_auth')
      bc.onmessage = status =>
        this.validateIntegrationAuth(this.uploadProvider, status, connectOnly)
    },
    async exportToIntegration(integration) {
      const idx = this.userIntegrations.findIndex(i => i.type === integration)
      if (idx === -1) {
        this.$toast.danger(
          'An error occurred',
          'Something went wrong while exporting your file, please try again or contact us.'
        )
        this.callback(false, 'No integration found')
        this.$emit('done')
        return
      }
      let toast
      if (this.showResult) {
        toast = this.$buefy.toast.open({
          indefinite: true,
          message: 'Uploading file...',
          position: 'is-bottom',
          type: 'is-white'
        })
      }

      const integration_id = this.userIntegrations[idx].id
      let success = true
      let error = undefined
      let res = undefined
      try {
        const files = await this.getUserIntegrationFiles({ integration_id })
        const file = files.find(f => {
          let path = atob(f.path || '')
          path = path ? JSON.parse(path) : {}
          const pathKey = {
            google_drive: 'file',
            onedrive: 'item'
          }[this.uploadProvider]
          return path[pathKey] === 'root'
        })
        const { signed_url, name } = await this.propsCall()
        res = await uploadToIntegration(
          this.currentUser.uuid,
          integration_id,
          file.uuid,
          signed_url,
          name
        )

        if (this.showResult) {
          toast.close()
          this.showUploadResult(res, integration)
        }
      } catch (e) {
        success = false
        error = e
        this.$console.debug('Upload error', e)
        this.$toast.danger(e, 'exporting your file')
      } finally {
        if (this.showResult) toast.close()
        this.$emit('done')
        if (this.uploadCallback) this.uploadCallback(res, integration)
        this.callback(success, error)
      }
    },
    async validateIntegrationAuth(integration, status, connectOnly = false) {
      const integrationObject = userFileStorageIntegrations[integration]
      if (status && status.data === 'done') {
        this.$toast.success(
          `${integrationObject.title} authenticated`,
          `We successfully connected your account to uman. You can now upload new documents straight to ${integrationObject.title}.`
        )
        await this.checkAvailableIntegrations()
        this.$emit('connected')
        if (!connectOnly) this.exportToIntegration(integration)
      } else if (status) {
        this.$toast.danger(
          'An error occurred',
          status.data === 'error'
            ? 'We could not connect to the external app. Please try again.'
            : status.data
        )
        this.$emit('done')
        this.callback(false, status.data, true)
      }
    },
    showUploadResult(res, integration) {
      const { url, name } = res
      const integrationObject = userFileStorageIntegrations[integration]
      this.$buefy.snackbar.open({
        message: `Successfully uploaded <b>${name}</b> to ${integrationObject.title}`,
        type: 'is-warning',
        position: 'is-bottom',
        actionText: `View on ${integrationObject.title}`,
        cancelText: 'Close',
        indefinite: true,
        onAction: () => {
          window.open(url, '_blank')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
