var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "upload-field upload-icon u-icon rounded is-square",
      class:
        ((_obj = {
          always: !_vm.onlyHover
        }),
        (_obj[_vm.size] = true),
        (_obj.square = !_vm.round),
        (_obj.disabled = _vm.disabled),
        (_obj["has-value"] = _vm.image),
        _obj),
      staticStyle: { border: "5px solid" }
    },
    [
      _c(
        "b-field",
        { staticClass: "full" },
        [
          _c(
            "b-upload",
            {
              ref: "upload",
              staticClass: "full",
              attrs: {
                accept: "image/png, image/jpeg",
                native: "",
                "drag-drop": ""
              },
              on: {
                input: function(val) {
                  return _vm.$emit("input", val)
                }
              }
            },
            [
              !_vm.image
                ? _c("b-icon", {
                    staticClass: "full",
                    attrs: { icon: "image" }
                  })
                : _c("img", { staticClass: "full", attrs: { src: _vm.image } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }