var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UploadDownload", {
    attrs: { "props-call": _vm.propsCall, callback: _vm.callback },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var upload = ref.upload
          var download = ref.download
          var provider = ref.provider
          return [
            upload
              ? _c("Dropdown", {
                  attrs: {
                    items: [
                      {
                        text: "Download",
                        icon: "download",
                        callback: download
                      },
                      {
                        text:
                          "Upload to " +
                          (provider === "google_drive"
                            ? "Google Drive"
                            : "OneDrive"),
                        icon: "upload",
                        callback: upload
                      }
                    ],
                    "align-top": _vm.alignment === "is-top"
                  },
                  nativeOn: {
                    mouseenter: function($event) {
                      return _vm.checkAlignment.apply(null, arguments)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "trigger",
                        fn: function() {
                          return [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: "Export",
                                  position: _vm.alignment,
                                  active:
                                    _vm.trigger !== "button" && !_vm.disabled,
                                  type: "is-dark"
                                }
                              },
                              [
                                _c("Button", {
                                  attrs: {
                                    text:
                                      _vm.trigger === "button" ? "Export" : "",
                                    icon:
                                      _vm.trigger === "button"
                                        ? "chevron-down-medium"
                                        : "download",
                                    type:
                                      _vm.buttonType ||
                                      (_vm.trigger === "button"
                                        ? "primary"
                                        : "white"),
                                    disabled: _vm.disabled,
                                    loading: _vm.loading,
                                    size: _vm.buttonSize
                                  },
                                  nativeOn: {
                                    mouseenter: function($event) {
                                      return _vm.checkAlignment.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              : _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: "Download",
                      position: _vm.alignment,
                      active: _vm.trigger !== "button" && !_vm.disabled,
                      type: "is-dark"
                    }
                  },
                  [
                    _c(
                      "div",
                      { on: { mouseenter: _vm.checkAlignment } },
                      [
                        _c("Button", {
                          attrs: {
                            text: _vm.trigger === "button" ? "Download" : "",
                            icon: _vm.trigger === "button" ? "" : "download",
                            type:
                              _vm.buttonType ||
                              (_vm.trigger === "button" ? "primary" : "white"),
                            "icon-left": true,
                            disabled: _vm.disabled,
                            loading: _vm.loading,
                            size: _vm.buttonSize
                          },
                          on: { click: download }
                        })
                      ],
                      1
                    )
                  ]
                )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }