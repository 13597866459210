var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "IconHeaderModal",
    {
      attrs: {
        icon: _vm.user.avatar,
        "icon-classes": ["u-icon", "large", "round", "is-square"],
        "with-close-icon": !_vm.noCloseIcon,
        title:
          _vm.titlePrefix +
          " " +
          _vm.user.first_name +
          " " +
          _vm.user.last_name,
        subtitle: _vm.subtitle,
        "no-padding": _vm.noPadding
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }