<template>
  <transition name="fade">
    <div
      v-show="editOpen"
      class="edit-labels-container card"
      :class="{ leftaligned: alignLeft, rightaligned: !alignLeft }"
      :style="`top: ${mouseY < 0.75 ? '100%' : 'unset'}; bottom: ${
        mouseY >= 0.75 ? '100%' : 'unset'
      }`"
    >
      <div v-for="type in labelTypes" :key="type" class="label-container">
        <div
          v-for="(label, index) in labelsByType[type]"
          :key="index"
          class="q-label"
          @click="$emit('toggleLabel', label)"
        >
          <div class="label-inner">
            <img :src="require(`@c/assets/icons/${type}QualityLabel.svg`)" />
            <label>{{ label.name }}</label>
          </div>
          <div v-if="activeLabelValue.name === label.name" class="checkmark">
            <img src="@c/assets/icons/check-blue.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'EditLabelsList',
  props: {
    editOpen: {
      type: Boolean,
      default: false
    },
    mouseY: {
      type: Number,
      default: 0
    },
    labelTypes: {
      type: Array,
      default: () => ['positive', 'informative', 'negative', 'warning']
    },
    labelsByType: {
      type: Object,
      default: () => ({
        informative: [],
        positive: [],
        negative: [],
        warning: []
      })
    },
    activeLabelValue: {
      type: Object,
      default: () => {}
    },
    alignLeft: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.edit-labels-container {
  position: absolute;
  z-index: 1;
  min-width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  &.leftaligned {
    left: 0;
  }

  &.rightaligned {
    right: 0;
  }
  .label-container {
    border-bottom: 1px solid #e3e3e3;
    .q-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      background: white;
      &:hover {
        background: lighten($blue, 40%);
      }
      .label-inner {
        padding-left: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        label {
          margin-left: 1rem;
          color: black;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .checkmark {
        padding-right: 1rem;
      }
    }
  }

  .label-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    p {
      color: $primary;
      cursor: pointer;
      font-size: 1rem;
    }
  }
}
</style>
