var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ubutton",
      class:
        ((_obj = {}),
        (_obj[_vm.type] = true),
        (_obj[_vm.size] = true),
        (_obj.disabled = _vm.disabled || _vm.loading),
        (_obj["full-width"] = _vm.fullWidth),
        (_obj["icon-only"] = !_vm.text),
        _obj),
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm._t("left"),
      _vm.icon && _vm.iconLeft
        ? _c("img", {
            staticClass: "ubutton-icon",
            class: { "apply-filter": _vm.filterIcon },
            attrs: {
              src: require("@/assets/icons/" + _vm.icon + ".svg"),
              alt: ""
            }
          })
        : _vm._e(),
      _vm.text
        ? _c("span", { staticClass: "ubutton-text" }, [
            _vm._v(_vm._s(_vm.text))
          ])
        : _vm._e(),
      _vm.icon && !_vm.iconLeft
        ? _c("img", {
            staticClass: "ubutton-icon",
            class: { "apply-filter": _vm.filterIcon },
            attrs: {
              src: require("@/assets/icons/" + _vm.icon + ".svg"),
              alt: ""
            }
          })
        : _vm._e(),
      _vm._t("right"),
      _vm.disabled || _vm.loading
        ? _c("div", { staticClass: "ubutton-overlay" }, [
            _vm.loading
              ? _c("img", {
                  staticClass: "ubutton-overlay-loading",
                  attrs: { src: require("@/assets/icons/spinner.svg"), alt: "" }
                })
              : _vm._e()
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }