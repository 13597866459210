



















































































import IntegrationData from '@c/models/Resource'
import IntegrationLink from '@c/shared/molecules/object-visualisations/resource/subcomponents/IntegrationLink'
import { isEmpty } from 'lodash'
import Vue from 'vue'
import { mapActions } from 'vuex'
import { formatDateWTime } from '../../../../../../../util'
import {
  mimetypeToType,
  SubResourceUnits,
  typeToUnit
} from '../../../../../mimetypes'

export default Vue.extend({
  name: 'ResourceInfo',

  components: {
    IntegrationLink
  },

  props: {
    resourceIntegration: {
      type: Object as () => IntegrationData,
      default: null
    },

    noIntegrationPathTooltip: {
      type: Boolean,
      default: false
    },

    hover: {
      type: Boolean,
      default: false
    },

    condensed: {
      type: Boolean as () => boolean,
      default: false
    },

    hideIntegrationPath: {
      type: Boolean,
      default: false
    },

    hideUser: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    userHovered: false
  }),

  computed: {
    integrationPath() {
      return this.resourceIntegration?.path || []
    },
    mimetype() {
      return this.resourceIntegration?.mimetype
    },
    isPublic() {
      return this.resourceIntegration?.permissions?.is_public
    },
    createdAt() {
      return this.resourceIntegration?.external_created_date
    },
    createdBy() {
      return this.resourceIntegration?.external_created_by
    },
    lastModifiedAt() {
      return this.resourceIntegration?.external_modified_date
    },
    lastModifiedBy() {
      return this.resourceIntegration?.external_modified_by
    },

    lastResources() {
      // TODO:
      return []
    },

    inferredMimetype() {
      return mimetypeToType[this.mimetype]
    },

    subResourceUnit(): SubResourceUnits {
      return typeToUnit[this.inferredMimetype] || 'slide'
    },

    hasLastModifiedUser() {
      return !isEmpty(this.lastModifiedBy)
    },

    hasCreatedUser() {
      return !isEmpty(this.createdBy)
    },

    showLastModified() {
      return (
        (!!this.lastModifiedAt && this.hasLastModifiedUser) ||
        (!!this.lastModifiedAt && !(!!this.createdAt && this.hasCreatedUser))
      )
    },

    showUsername() {
      return this.showLastModified
        ? this.hasLastModifiedUser
        : this.hasCreatedUser
    },

    username() {
      return this.showUsername
        ? this.showLastModified
          ? this.$umodel.full_name(this.lastModifiedBy)
          : this.$umodel.full_name(this.createdBy)
        : ''
    },

    userAvatar() {
      return this.showLastModified
        ? this.$umodel.avatar(this.lastModifiedBy)
        : this.$umodel.avatar(this.createdBy)
    },

    userInitials() {
      return this.showLastModified
        ? this.$umodel.initials(this.lastModifiedBy)
        : this.$umodel.initials(this.createdBy)
    },

    userRole() {
      return (
        (this.showLastModified ? this.lastModifiedBy : this.createdBy)?.member
          ?.role || ''
      )
    },

    date() {
      return this.showLastModified
        ? formatDateWTime(this.lastModifiedAt)
        : formatDateWTime(this.createdAt)
    }
  },
  methods: {
    ...mapActions(['restartBreadCrumbs']),
    gotoUser() {
      const memberId = this.showLastModified
        ? this.lastModifiedBy.member.uuid
        : this.createdBy.member.uuid
      if (
        this.$route.name === 'member-profile' &&
        this.$route.params.member_id === memberId
      )
        return
      this.restartBreadCrumbs({ ...this.$route })
      this.$router.push({
        name: 'member-profile',
        params: {
          workspace_id: this.$route.params.workspace_id,
          member_id: memberId
        }
      })
    }
  }
})
