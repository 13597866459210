var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "loading-dots",
      class: ((_obj = {}), (_obj[_vm.animation] = true), _obj),
      style: _vm.style
    },
    [
      _c("div", { staticClass: "loading-dots-dot" }),
      _c("div", { staticClass: "loading-dots-dot" }),
      _c("div", { staticClass: "loading-dots-dot" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }