var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "recent-ori",
      style: "--column-count: " + _vm.columns + ";"
    },
    [
      _c(
        "div",
        { staticClass: "recent-ori-header" },
        [
          _c("p", { staticClass: "recent-ori-header-title" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c(
            "router-link",
            {
              staticClass: "recent-ori-header-link",
              attrs: { to: _vm.gotoAllRoute }
            },
            [_vm._v(_vm._s(_vm.seeAllTitle))]
          )
        ],
        1
      ),
      !_vm.loading && !_vm.empty
        ? _c(
            "div",
            { staticClass: "recent-ori-list" },
            _vm._l(_vm.ori, function(o) {
              return _c(
                _vm.cardComponent,
                _vm._b(
                  { key: o.uuid, tag: "component", attrs: { clickable: true } },
                  "component",
                  _vm._d({}, [_vm.type, o])
                )
              )
            }),
            1
          )
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "recent-ori-list" },
            _vm._l(_vm.pageSize, function(i) {
              return _c(
                "div",
                {
                  key: "recent-" + _vm.type + "-loading-" + i,
                  staticClass: "recent-ori-list-item"
                },
                [
                  _c("b-skeleton", {
                    attrs: { width: "4rem", height: "4rem" }
                  }),
                  _c("b-skeleton", { attrs: { width: "80%", height: "2rem" } })
                ],
                1
              )
            }),
            0
          )
        : _c("div", { staticClass: "recent-ori-empty" }, [
            _vm._v(" " + _vm._s("No " + _vm.typeName + " found") + " ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }