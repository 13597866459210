var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rdh" },
    [
      _vm.mimetype
        ? _c("DocumentTag", {
            staticClass: "rdh-doctag",
            attrs: { mimetype: _vm.mimetype, large: true }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "rdh-text" },
        [
          _c("div", { staticClass: "rdh-text-overflow" }, [
            _c(
              "span",
              {
                staticClass: "rdh-text-title",
                on: {
                  click: function($event) {
                    return _vm.$emit("gotoResource")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          ]),
          _c("ResourceInfo", {
            attrs: {
              hover: true,
              "resource-integration": _vm.resourceIntegration
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rdh-btns" },
        [
          _vm.downloadFilesEnabled
            ? _c("UploadDownloadDropdown", {
                attrs: { "props-call": _vm.getDownloadProps, trigger: "button" }
              })
            : _vm._e(),
          _c("Button", {
            attrs: { text: _vm.linkText, icon: "external-bold", type: "white" },
            on: {
              click: function($event) {
                return _vm.$emit("gotoResource")
              }
            }
          }),
          _c("Button", {
            attrs: {
              icon: "bookmark" + (_vm.bookmark ? "-filled" : ""),
              type: "white"
            },
            on: {
              click: function($event) {
                return _vm.$emit("toggleBookmark")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }