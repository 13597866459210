import Resource from '@c/models/Resource'
import { deleteDialog } from '@c/shared/logic/dialogs/error/BaseErrorDialogs'

export function deleteResourceDialog(
  parent: any,
  resource: Resource,
  onConfirm: (resource: Resource) => boolean | Promise<boolean>,
  dialogPropsOverwrite?: any
): void {
  deleteDialog(
    parent,
    'Content item',
    'Are you sure you want to delete this item? This item will be deleted <b class="body bold black">for you and every member it\'s visible to.</b> This action cannot be undone.',
    resource.title,
    onConfirm,
    resource,
    {},
    dialogPropsOverwrite || {}
  )
}
