var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    _vm._g(
      _vm._b(
        {
          staticClass: "pa-1 u-btn borderless transparent",
          class: _vm.customTextClass,
          attrs: { size: "is-small", "icon-left": "arrow-left" }
        },
        "b-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_c("p", { class: _vm.customTextClass }, [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }