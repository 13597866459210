// import CODEImage from '@/assets/file_type/filetype-small/file-code-small.svg'
import DOCImageLarge from '@/assets/file_type/filetype-large/file-docx-large.svg'
import IMGImageLarge from '@/assets/file_type/filetype-large/file-img-large.svg'
import PDFImageLarge from '@/assets/file_type/filetype-large/file-pdf-large.svg'
import PPTXImageLarge from '@/assets/file_type/filetype-large/file-pptx-large.svg'
import VIDImageLarge from '@/assets/file_type/filetype-large/file-vid-large.svg'
import XLSXImageLarge from '@/assets/file_type/filetype-large/file-xlsx-large.svg'
import DOCImage from '@/assets/file_type/filetype-small/file-docx-small.svg'
import IMGImage from '@/assets/file_type/filetype-small/file-img-small.svg'
import PDFImage from '@/assets/file_type/filetype-small/file-pdf-small.svg'
import PPTXImage from '@/assets/file_type/filetype-small/file-pptx-small.svg'
import XLSXImage from '@/assets/file_type/filetype-small/file-xlsx-small.svg'
import VIDImage from '@/assets/file_type/filetype-small/video-small.svg'
import GDOCSImage from '@/assets/file_type/googledocs.svg'
import GEXCELImage from '@/assets/file_type/googlesheets.svg'
import GSLIDESImage from '@/assets/file_type/googleslides.svg'

export enum Mimetypes {
  PDF = 'pdf',
  PPTX = 'pptx',
  PPT = 'ppt',
  GOOGLE_SLIDES = 'google slides',
  GOOGLE_DOCS = 'google docs',
  GOOGLE_SHEETS = 'google sheets',
  DOC = 'doc',
  VIDEO = 'video',
  PNG = 'png',
  SVG = 'svg',
  JPEG = 'jpeg',
  XLSX = 'xlsx',
  XLS = 'xls',
  ODS = 'ods'
}

export const typeToMimetype = {
  [Mimetypes.PDF]: 'application/pdf',
  [Mimetypes.PPTX]:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  [Mimetypes.PPT]: 'application/vnd.ms-powerpoint',
  [Mimetypes.GOOGLE_SLIDES]: 'application/vnd.google-apps.presentation',
  [Mimetypes.GOOGLE_SHEETS]: 'application/vnd.google-apps.spreadsheet',
  [Mimetypes.GOOGLE_DOCS]: 'application/vnd.google-apps.document',
  [Mimetypes.DOC]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [Mimetypes.XLS]: 'application/vnd.ms-excel',
  [Mimetypes.XLSX]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [Mimetypes.ODS]: 'application/vnd.oasis.opendocument.spreadsheet',
  [Mimetypes.PNG]: 'image/png',
  [Mimetypes.JPEG]: 'image/jpeg',
  [Mimetypes.SVG]: 'image/svg+xml',
  [Mimetypes.VIDEO]: 'video/mp4'
}

export const mimetypeToType = {
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    Mimetypes.PPTX,
  'application/vnd.ms-powerpoint': Mimetypes.PPT,
  'application/vnd.google-apps.presentation': Mimetypes.GOOGLE_SLIDES,
  'application/vnd.google-apps.document': Mimetypes.GOOGLE_DOCS,
  'application/vnd.google-apps.spreadsheet': Mimetypes.GOOGLE_SHEETS,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    Mimetypes.DOC,
  'application/vnd.ms-excel': Mimetypes.XLS,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    Mimetypes.XLSX,
  'application/vnd.oasis.opendocument.spreadsheet': Mimetypes.ODS,
  'application/pdf': Mimetypes.PDF,
  'application/msword': Mimetypes.DOC,
  'image/png': Mimetypes.PNG,
  'image/svg+xml': Mimetypes.SVG,
  'image/jpeg': Mimetypes.JPEG,
  'video/mp4': Mimetypes.VIDEO,
  'video/quicktime': Mimetypes.VIDEO
}

export const mimetypeToTypeName = {
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint',
  'application/vnd.ms-powerpoint': 'PowerPoint',
  'application/vnd.google-apps.presentation': 'Google Slides',
  'application/vnd.google-apps.document': 'Google Docs',
  'application/vnd.google-apps.spreadsheet': 'Google Sheets',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word',
  'application/vnd.ms-excel': 'Excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'Excel',
  'application/pdf': 'PDF',
  'application/msword': 'Word',
  'image/png': 'Image',
  'image/svg+xml': 'Image',
  'image/jpeg': 'Image',
  'video/mp4': 'Video',
  'video/quicktime': 'Video'
}

export const downloadableMimetypes = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.google-apps.presentation'
]

export enum SubResourceUnits {
  PAGE = 'page',
  SHEET = 'sheet',
  SLIDE = 'slide'
}

export const typeToImage = {
  [Mimetypes.PDF]: PDFImage,
  [Mimetypes.PPTX]: PPTXImage,
  [Mimetypes.PPT]: PPTXImage,
  [Mimetypes.GOOGLE_SLIDES]: GSLIDESImage,
  [Mimetypes.GOOGLE_SHEETS]: GEXCELImage,
  [Mimetypes.GOOGLE_DOCS]: GDOCSImage,
  [Mimetypes.DOC]: DOCImage,
  [Mimetypes.XLS]: XLSXImage,
  [Mimetypes.XLSX]: XLSXImage,
  [Mimetypes.ODS]: XLSXImage,
  [Mimetypes.PNG]: IMGImage,
  [Mimetypes.JPEG]: IMGImage,
  [Mimetypes.SVG]: IMGImage,
  [Mimetypes.VIDEO]: VIDImage
}

export const typeToLargeImage = {
  [Mimetypes.PDF]: PDFImageLarge,
  [Mimetypes.PPTX]: PPTXImageLarge,
  [Mimetypes.PPT]: PPTXImageLarge,
  [Mimetypes.GOOGLE_SLIDES]: GSLIDESImage,
  [Mimetypes.GOOGLE_SHEETS]: GEXCELImage,
  [Mimetypes.GOOGLE_DOCS]: GDOCSImage,
  [Mimetypes.DOC]: DOCImageLarge,
  [Mimetypes.XLS]: XLSXImageLarge,
  [Mimetypes.XLSX]: XLSXImageLarge,
  [Mimetypes.ODS]: XLSXImageLarge,
  [Mimetypes.PNG]: IMGImageLarge,
  [Mimetypes.JPEG]: IMGImageLarge,
  [Mimetypes.SVG]: IMGImageLarge,
  [Mimetypes.VIDEO]: VIDImageLarge
}

export const typeToUnit = {
  [Mimetypes.PDF]: SubResourceUnits.PAGE,
  [Mimetypes.PPTX]: SubResourceUnits.SLIDE,
  [Mimetypes.PPT]: SubResourceUnits.SLIDE,
  [Mimetypes.GOOGLE_SLIDES]: SubResourceUnits.SLIDE,
  [Mimetypes.GOOGLE_SHEETS]: SubResourceUnits.SHEET,
  [Mimetypes.GOOGLE_DOCS]: SubResourceUnits.PAGE,
  [Mimetypes.DOC]: SubResourceUnits.PAGE,
  [Mimetypes.XLS]: SubResourceUnits.SHEET,
  [Mimetypes.XLSX]: SubResourceUnits.SHEET,
  [Mimetypes.ODS]: SubResourceUnits.SHEET
}
