export interface PropsAndEvents {
  props: Record<string, any>
  events?: Record<string, any>
}

export function openModal(
  parent: any,
  modal: any,
  { props, events }: PropsAndEvents,
  modalProps?: object,
  onCancel?: () => void
) {
  parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: modal,
    trapFocus: true,
    props: props || {},
    events: { ...events },
    onCancel: onCancel || (() => parent.$console.debug('Modal closed')),
    ...(modalProps || {})
  })
}
