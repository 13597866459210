var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canShow
    ? _c("div", [
        _c("img", {
          staticClass: "document-tag-image",
          class: {
            "image-large": _vm.large
          },
          attrs: { src: _vm.image }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }