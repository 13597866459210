<template>
  <div class="language-select" :class="{ short }">
    <span v-if="title" class="language-select-title">{{ title }}</span>
    <Dropdown
      :items="options"
      :full-width="true"
      :position-fixed="positionFixed"
      :content-style="contentStyle"
    >
      <template #trigger>
        <div class="language-select-trigger">
          {{ short ? value : valueName }}
          <img
            src="@/assets/icons/chevron-down-medium.svg"
            alt=""
            class="language-select-trigger-icon"
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from './Dropdown.vue'
import { isEmpty } from 'lodash'

export default {
  name: 'LanguageSelect',
  components: { Dropdown },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    default: {
      type: String,
      default: ''
    },
    positionFixed: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    },
    contentStyle: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'supportedLanguages']),
    options() {
      return this.supportedLanguages.map(l => ({
        text: l.name,
        value: l.code,
        callback: () => this.handleLanguageSelect(l)
      }))
    },
    valueName() {
      return this.options.find(o => o.value === this.value)?.text || ''
    }
  },
  created() {
    if (isEmpty(this.value)) {
      this.$emit(
        'input',
        this.default || this.currentWorkspace.language || 'en'
      )
    }
  },
  methods: {
    handleLanguageSelect(language) {
      this.$emit('input', language.code)
    }
  }
}
</script>

<style lang="scss" scoped>
.language-select {
  &:not(.short) {
    width: 100%;
  }

  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    cursor: pointer;
    background: white;

    &:hover {
      border: 1px solid rgba(#000, 0.16);
    }

    &:active,
    &:focus,
    &:focus-within {
      background: rgba(#000, 0.02);
      border: 1px solid $primary;
    }

    &-icon {
      width: 1.2rem;
    }
  }

  &-title {
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
