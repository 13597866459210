var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quality-labels" },
    [
      _vm.smallLabels && !_vm.isEmpty(_vm.label)
        ? _c(
            "div",
            {
              staticClass: "label subresource",
              class: { bordered: _vm.borderedIcons },
              on: { mouseenter: _vm.onHover, mouseleave: _vm.onHoverLeave }
            },
            [
              _c("img", {
                staticClass: "subresource",
                attrs: {
                  src: require("@c/assets/icons/" +
                    _vm.label.type +
                    "QualityLabel.svg")
                }
              }),
              _vm.showHoverInfo
                ? _c("QualityLabelInfo", {
                    attrs: {
                      label: _vm.label,
                      hovered: _vm.hovered,
                      "align-left": _vm.hoverX < _vm.windowWidth / 2
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : !_vm.isEmpty(_vm.label)
        ? _c(
            "div",
            {
              staticClass: "label",
              class: { bordered: _vm.borderedIcons },
              on: {
                mouseenter: function($event) {
                  _vm.hovered = true
                },
                mouseleave: function($event) {
                  _vm.hovered = false
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@c/assets/icons/" +
                    _vm.label.type +
                    "QualityLabel.svg")
                }
              }),
              _c("p", [_vm._v(_vm._s(_vm.label.name))]),
              _vm.showHoverInfo
                ? _c("QualityLabelInfo", {
                    attrs: { label: _vm.label, hovered: _vm.hovered }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.canWriteLabels
        ? _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("EditLabels", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showEditLabels,
                    expression: "showEditLabels"
                  }
                ],
                attrs: {
                  hovering: _vm.isHovering,
                  "active-label": _vm.label,
                  "source-id": _vm.sourceId
                },
                on: { "label-updated": _vm.updateLabel },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "trigger",
                      fn: function(ref) {
                        var onClick = ref.onClick
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "label-edit-trigger-wrapper",
                              class: { visible: _vm.isHovering },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return onClick.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _vm.isEmpty(_vm.label)
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "label-edit-trigger",
                                      class: { bordered: _vm.borderedIcons }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "label-edit-trigger-icon",
                                        attrs: {
                                          src: require("@/assets/icons/label.svg"),
                                          alt: ""
                                        }
                                      }),
                                      !_vm.hideButtonText
                                        ? _c("span", [_vm._v("Curate")])
                                        : _vm._e()
                                    ]
                                  )
                                : _c(
                                    "p",
                                    {
                                      staticClass: "label-edit-trigger",
                                      class: { bordered: _vm.borderedIcons }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "label-edit-trigger-icon",
                                        attrs: {
                                          src: require("@/assets/icons/pencil-grey.svg"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2400548070
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }