var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "curate" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "curate-labels" },
      _vm._l(_vm.qualityLabels, function(qlabel) {
        return _c(
          "p",
          {
            key: qlabel.name,
            staticClass: "curate-labels-label",
            class: { active: _vm.selectedLabel.name === qlabel.name },
            on: {
              click: function($event) {
                return _vm.handleLabelSelect(qlabel)
              }
            }
          },
          [
            _c("img", {
              staticClass: "curate-labels-label-icon",
              attrs: {
                src: require("@/assets/icons/" +
                  qlabel.type +
                  "QualityLabel.svg"),
                alt: ""
              }
            }),
            _vm._v(" " + _vm._s(qlabel.name) + " ")
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "curate-comment" }, [
      _vm.currentUser.avatar && !_vm.avatarError
        ? _c("img", {
            staticClass: "curate-comment-avatar",
            attrs: { src: _vm.currentUser.avatar, alt: "" },
            on: {
              error: function($event) {
                _vm.avatarError = true
              }
            }
          })
        : _c(
            "p",
            {
              staticClass: "curate-comment-avatar",
              style: "background: " + _vm.avatarColor + ";"
            },
            [_vm._v(" " + _vm._s(_vm.initials) + " ")]
          ),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comment,
            expression: "comment"
          }
        ],
        staticClass: "curate-comment-input",
        attrs: {
          id: "comment",
          name: "comment",
          maxlength: "200",
          placeholder: "Write a remark...",
          disabled: _vm.inputsDisabled
        },
        domProps: { value: _vm.comment },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.comment = $event.target.value
          }
        }
      }),
      _c("p", { staticClass: "curate-comment-count" }, [
        _vm._v(_vm._s(_vm.comment.length) + "/200")
      ])
    ]),
    _c("div", { staticClass: "curate-btns" }, [
      _c(
        "p",
        {
          staticClass: "curate-btns-clear",
          class: { disabled: _vm.inputsDisabled },
          on: { click: _vm.handleClear }
        },
        [_vm._v(" Clear ")]
      ),
      _c(
        "p",
        { staticClass: "curate-btns-save", on: { click: _vm.saveLabel } },
        [_vm._v("Save curation")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "curate-header-wrapper" }, [
      _c("p", { staticClass: "curate-header" }, [
        _vm._v("Curate this document")
      ]),
      _c("p", { staticClass: "curate-subheader" }, [
        _vm._v(" Select the appropriate label for this document ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }