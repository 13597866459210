<template>
  <div class="recent-ori" :style="`--column-count: ${columns};`">
    <div class="recent-ori-header">
      <p class="recent-ori-header-title">{{ title }}</p>
      <router-link class="recent-ori-header-link" :to="gotoAllRoute">{{
        seeAllTitle
      }}</router-link>
    </div>
    <div v-if="!loading && !empty" class="recent-ori-list">
      <component
        :is="cardComponent"
        v-for="o in ori"
        :key="o.uuid"
        :[type]="o"
        :clickable="true"
      />
    </div>
    <div v-else-if="loading" class="recent-ori-list">
      <div
        v-for="i in pageSize"
        :key="`recent-${type}-loading-${i}`"
        class="recent-ori-list-item"
      >
        <b-skeleton width="4rem" height="4rem" />
        <b-skeleton width="80%" height="2rem" />
      </div>
    </div>
    <div v-else class="recent-ori-empty">
      {{ `No ${typeName} found` }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OfferingCard from '../offerings/OfferingCard.vue'
import ReferenceCard from '../references/ReferenceCard.vue'
import InspirationCard from '../inspirations/InspirationCard.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'RecentORI',
  components: { OfferingCard, ReferenceCard, InspirationCard, Button },
  props: {
    type: {
      type: String,
      default: 'offering',
      validator: (value) =>
        ['offering', 'inspiration', 'reference'].includes(value)
    },
    pageSize: {
      type: Number,
      default: 6
    },
    columns: {
      type: Number,
      default: 3
    }
  },
  data: () => ({
    ori: [],
    loading: false
  }),
  computed: {
    empty() {
      return !this.ori?.length
    },
    typeName() {
      return {
        offering: 'offerings',
        reference: 'cases',
        inspiration: 'inspirational content'
      }[this.type]
    },
    title() {
      return `Latest ${this.typeName}`
    },
    seeAllTitle() {
      return `See all ${this.typeName}`
    },
    cardComponent() {
      return {
        offering: OfferingCard,
        reference: ReferenceCard,
        inspiration: InspirationCard
      }[this.type]
    },
    gotoAllRoute() {
      return {
        name: 'portfolio',
        params: {
          ori_type: `${this.type}s`,
          workspace_id: this.$route.params.workspace_id
        }
      }
    }
  },
  created() {
    this.loadRecentOri()
  },
  methods: {
    ...mapActions([
      'searchOfferings',
      'searchReferences',
      'searchInspirations'
    ]),
    async loadRecentOri() {
      try {
        this.loading = true
        const searchFunction = {
          offering: this.searchOfferings,
          reference: this.searchReferences,
          inspiration: this.searchInspirations
        }[this.type]
        this.ori = await searchFunction({
          workspace_id: this.$route.params.workspace_id,
          ordering: '-date_created',
          page_size: this.pageSize
        })
        this.$emit('load')
        if (!this.ori?.length) this.$emit('empty')
      } catch (e) {
        this.$console.debug(
          `Error when loading ${this.title.toLowerCase()} on home`,
          e
        )
        this.$toast.error(e, `loading ${this.title.toLowerCase()}`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.recent-ori {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    &-link {
      font-weight: 600;
      color: $primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(var(--column-count), 1fr);
    gap: 1rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      padding: 1rem;
      border-radius: 8px;
      border: 1px solid rgba(#000, 8%);
    }
  }

  &-empty {
    font-weight: 600;
    color: #60666b;
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
    border: 1px solid rgba(#000, 0.08);
  }
}

.b-skeleton {
  margin-top: 0 !important;

  &:first-child {
    width: fit-content;
  }
}
</style>
