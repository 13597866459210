import { Store } from 'vuex'
import TouchpointSensor from './TouchpointSensor'
export { default as LoginSensor } from './authentication/LoginSensor'
export { default as LogoutSensor } from './authentication/LogoutSensor'

export enum SensorType {
  WEBAPP_SEARCH = 'SEARCH',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

/**
 * This function inits a list of sensors for a given store. Make sure you call this after creating a store
 * on either the webapp or extension.
 * @param sensors
 * @param store
 */
export function initSensors(
  sensors: typeof TouchpointSensor[],
  store: Store<any>
) {
  const initialisedSensors = []
  for (const sensor of sensors) {
    // @ts-ignore
    initialisedSensors.push(new sensor(store))
  }
  return initialisedSensors
}
