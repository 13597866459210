var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WorkspaceHeaderModal",
    _vm._g(
      {
        staticStyle: { height: "100%" },
        attrs: {
          workspace: _vm.workspace,
          subtitle:
            "Sync " +
            _vm.integrationType.syncObjectName +
            "s from " +
            _vm.integrationType.title
        }
      },
      _vm.$listeners
    ),
    [
      _c(
        "ContentWithHeader",
        {
          attrs: {
            title: "Add from " + _vm.integrationType.title,
            subtitle:
              "Please make sure you’re not uploading any sensitive information to workspaces that have other members in them."
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "border container paddingless semi-hard thick mt-2"
            },
            [
              _c("div", { staticClass: "mh-4 pv-3" }, [
                _c("p", { staticClass: "body" }, [
                  _c("span", { staticClass: "body bold" }, [
                    _vm._v(
                      _vm._s(
                        _vm.selectedFiles.filter(function(x) {
                          return x.metadata.is_directory
                        }).length
                      ) + " folders"
                    )
                  ]),
                  _vm._v(" selected ")
                ])
              ]),
              _c(
                "div",
                [
                  _vm.lastSync
                    ? _c("div", {
                        staticClass: "divider very-subtle no-margin"
                      })
                    : _vm._e(),
                  _vm.lastSync
                    ? _c(
                        "div",
                        {
                          staticClass: "mh-4 pv-3",
                          staticStyle: { "min-height": "32px" }
                        },
                        [
                          _c("p", { staticClass: "body" }, [
                            _c("span", { staticClass: "body bold" }, [
                              _vm._v("Last update:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.lastSync
                                    ? _vm.lastSync.format("L, H:mm:ss")
                                    : "No last sync time available"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  Object.values(_vm.syncStatus).some(function(status) {
                    return !!status
                  })
                    ? _c("div", {
                        staticClass: "divider very-subtle no-margin"
                      })
                    : _vm._e(),
                  Object.values(_vm.syncStatus).some(function(status) {
                    return !!status
                  })
                    ? _c(
                        "div",
                        { staticClass: "mh-4 pv-3" },
                        [
                          _c("SyncStatusList", {
                            attrs: { "sync-status": _vm.syncStats }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("b-loading", {
                    attrs: { active: _vm.loadingSyncs, "is-full-page": false }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "divider very-subtle no-margin" }),
              _c(
                "div",
                {
                  staticClass: "mh-4 pv-3",
                  staticStyle: {
                    "min-height": "250px",
                    "max-height": "40vh",
                    height: "fit-content",
                    "overflow-y": "scroll"
                  }
                },
                [
                  !_vm.loadingDirs
                    ? _c(
                        "FileSelectTree",
                        _vm._g(
                          {
                            attrs: {
                              "directory-structure": _vm.directoryStructure,
                              "current-workspace-member":
                                _vm.currentWorkspaceMember,
                              "integration-type": _vm.integrationType.title,
                              "no-expands": !_vm.integrationType.multilayered,
                              "icon-overwrite": _vm.integrationType.defaultIcon,
                              "text-overwrite": _vm.integrationType.defaultText
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "sync-status",
                                  fn: function(ref) {
                                    var node = ref.node
                                    return [
                                      _vm.checkSyncStatusRender(node)
                                        ? _c("SyncStatusComponent", {
                                            staticClass: "ml-3",
                                            attrs: {
                                              status: node.metadata.status,
                                              "prefix-message": "",
                                              "suffix-message":
                                                node.metadata.status ===
                                                _vm.syncStatus.UNSUPPORTED
                                                  ? "This filetype is most likely not supported"
                                                  : node.metadata.status_message
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.retrySync(node)
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "loading-children",
                                  fn: function() {
                                    return [
                                      _c("p", [
                                        _vm._v(
                                          "Loading " +
                                            _vm._s(
                                              _vm.integrationType.syncObjectName
                                            ) +
                                            "s"
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2815094684
                            ),
                            model: {
                              value: _vm.selectedFiles,
                              callback: function($$v) {
                                _vm.selectedFiles = $$v
                              },
                              expression: "selectedFiles"
                            }
                          },
                          _vm.$listeners
                        )
                      )
                    : _c(
                        "div",
                        { staticStyle: { "min-height": "300px" } },
                        [
                          _c("b-loading", {
                            attrs: {
                              active: _vm.loadingDirs,
                              "is-full-page": false
                            }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("ModalActionFooter", {
        staticClass: "mt-4",
        attrs: {
          action: _vm.totalSyncs > 0 ? "Update sync" : "Sync items",
          loading: _vm.loadingPost,
          "loading-action": "Creating sync..."
        },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          },
          action: function($event) {
            return _vm.$emit("sync", {
              selectedFiles: _vm.selectedFiles
            })
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }