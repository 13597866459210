import HydratedConnectToIntegration from '@/views-v2/modals/integration/HydratedConnectToIntegration.vue'
import HydratedInviteFromIntegrationModal from '@/views-v2/modals/integration/HydratedInviteFromIntegrationModal.vue'
import HydratedSyncFolderSelectModal from '@/views-v2/modals/integration/HydratedSyncFolderSelectModal.vue'
import HydratedProfileSettings from '@/views-v2/modals/profile/HydratedProfileSettings.vue'
import HydratedCreateWorkspaceModal from '@/views-v2/modals/workspace/HydratedCreateWorkspaceModal.vue'
import HydratedWorkspaceSettingsModal from '@/views-v2/modals/workspace/HydratedWorkspaceSettingsModal.vue'
import { ProfileSettingTab } from '@c/features/profile-settings-modal/interfaces'
import { SettingsModalTab } from '@c/features/workspace-settings-modal/interfaces'
import inject, {
  createDialogs,
  createModals,
  Dialogs,
  Modals
} from '@c/injection'
import allIntegrations, { Integration } from '@c/integrations'
import { BaseSync, default as IntegrationObject } from '@c/models/Integration'
import Resource from '@c/models/Resource'
import User from '@c/models/User'
import Workspace from '@c/models/Workspace'
import WorkspaceMember from '@c/models/WorkspaceMember'
import {
  deleteSync,
  disconnectFileIntegration
} from '@c/shared/logic/dialogs/error/IntegrationDialogs'
import { deleteResourceDialog } from '@c/shared/logic/dialogs/error/ResourceErrorDialogs'
import {
  deleteWorkspaceDialog,
  leaveWorkspaceDialog,
  removeWorkspaceMemberDialog
} from '@c/shared/logic/dialogs/error/WorkspaceErrorDialogs'
import { injectValidations } from '@c/shared/logic/forms/rules/InjectValidations'
import { openModal } from '@c/shared/logic/modals/BaseModals'
import { getToastEngine } from './toast'
// This extends the Set object with some extra handy functions such as intersection, difference and union
import '@c/shared/logic/prototypes/Set'
// This extends the String object with some extra handy functions such as vowel detection and a/an formatting.
import '@c/shared/logic/prototypes/String'
import Console from './console'

const toast = getToastEngine()

function createHydratedModals(): Modals {
  const modals: Modals = createModals()
  const workspace = {
    openSettings: (
      parent: any,
      workspace: Workspace,
      initialTab: SettingsModalTab,
      onCancel?: () => void
    ) =>
      openModal(
        parent,
        HydratedWorkspaceSettingsModal,
        {
          props: { workspace, initialTab }
        },
        { width: 800 },
        onCancel
      ),
    createWorkspace: (parent: any) =>
      openModal(
        parent,
        HydratedCreateWorkspaceModal,
        { props: {} },
        { width: 700 }
      )
  }

  const user = {
    openProfileSettings: (parent: any, user: User, tab: ProfileSettingTab) =>
      openModal(
        parent,
        HydratedProfileSettings,
        { props: { user, initialActiveTab: tab } },
        { width: 700 }
      )
  }

  const integration = {
    inviteFromIntegration: (
      parent: any,
      integrationKey: string,
      integrationId: string,
      workspace: Workspace
    ) =>
      openModal(
        parent,
        HydratedInviteFromIntegrationModal,
        {
          props: {
            workspace,
            integration: allIntegrations[integrationKey],
            integrationId
          }
        },
        { width: 700 }
      ),
    connectToIntegration: (
      parent: any,
      integrationKey: string,
      workspace: Workspace
    ) =>
      openModal(
        parent,
        HydratedConnectToIntegration,
        {
          props: {
            workspace,
            integration: allIntegrations[integrationKey]
          }
        },
        { width: 500 }
      ),
    syncFolderSelectModal: (
      parent: any,
      integration: Integration,
      workspace: Workspace
    ) =>
      openModal(
        parent,
        HydratedSyncFolderSelectModal,
        {
          props: {
            workspaceObject: workspace,
            integration
          }
        },
        { width: 'fit-content' }
      )
  }

  return {
    ...modals,
    workspace,
    user,
    integration: { ...modals.integration, ...integration }
  }
}

function createHydratedDialogs(): Dialogs {
  const dialogs: Dialogs = createDialogs()
  const workspace = {
    deleteWorkspaceDialog: (parent: any, workspace: Workspace) =>
      deleteWorkspaceDialog(
        parent,
        workspace,
        async (workspace1: Workspace) => {
          try {
            await parent.$store.dispatch('deleteWorkspace', {
              id: workspace1.uuid
            })
            if (
              parent.$route.params.id === workspace1.uuid ||
              parent.$route.params.workspace_id === workspace1.uuid
            ) {
              parent.$router.push('/')
            }
          } catch (e) {
            Console.debug(e)
            toast.danger(
              'Cannot delete workspace',
              e ||
                'An error occured while deleting your workspace, please try again.'
            )
            return false
          }
          return true
        }
      ),
    leaveWorkspaceDialog: (parent: any, workspace: Workspace) =>
      leaveWorkspaceDialog(parent, workspace, async (workspace1: Workspace) => {
        try {
          await parent.$store.dispatch('leaveWorkspace', {
            workspaceId: workspace1.uuid,
            userId: parent.$store.getters.currentWorkspaceMember.uuid
          })
          if (
            parent.$route.params.id === workspace1.uuid ||
            parent.$route.params.workspace_id === workspace1.uuid
          ) {
            parent.$router.push('/')
          }
        } catch (e) {
          Console.debug(e)
          toast.danger(
            'Cannot leave workspace',
            e ||
              'An error occured while leaving your workspace, please try again.'
          )
          return false
        }
        return true
      }),
    removeWorkspaceMemberDialog: (
      parent: any,
      workspace: Workspace,
      member: WorkspaceMember,
      onConfirm?: () => void
    ) =>
      removeWorkspaceMemberDialog(
        parent,
        workspace,
        member,
        async (workspace1: Workspace, member1: WorkspaceMember) => {
          try {
            await parent.$store.dispatch('deleteWorkspaceMember', {
              workspace_id: workspace1.uuid,
              member_id: member1.uuid
            })
            if (onConfirm) {
              onConfirm()
            }
          } catch (e) {
            Console.debug(e)
            toast.danger(
              'Cannot remove member from workspace',
              e ||
                'An error occured while deleting the workspace member. Do you have the right permissions to perform this action?'
            )
            return false
          }
          return true
        }
      )
  }

  const resource = {
    deleteResourceDialog: (parent: any, resource: Resource) =>
      deleteResourceDialog(parent, resource, async (resource1: Resource) => {
        try {
          await parent.$store.dispatch('deleteResource', {
            id: resource1.uuid,
            workspaceId: resource1.workspace_id
          })
        } catch (e) {
          toast.danger(
            'Cannot delete resource',
            e ||
              'An error occured while deleting your resource, please try again.'
          )
          return false
        }
        toast.success(
          'Resource deleted',
          'Your resource has been succesfully deleted from this workspace.'
        )
        return true
      })
  }
  const integration = {
    disconnectFileIntegration: (
      parent: any,
      integration: Integration,
      integrationObject: any,
      workspace: Workspace,
      onSuccess: () => void = () => {},
      onFailure: () => void = () => {}
    ) => {
      const component = disconnectFileIntegration(
        parent,
        integration,
        integrationObject,
        workspace,
        async (keep) => {
          try {
            await parent.$store.dispatch('deleteIntegration', {
              integration_id: integrationObject.id,
              workspace_id: workspace.uuid,
              delete_resources: !keep
            })
            toast.success(
              'Successfully Disconnected',
              keep
                ? 'You successfully disconnected the umanai Slack workspace. All previously synced files are still available in uman.ai. You can always reconnect again later.'
                : 'You successfully disconnected the umanai slack workspace and deleted all synced files in uman.ai. You can always reconnect again later.'
            )
            onSuccess()
            component.close()
            return true
          } catch (e) {
            parent.$console.debug(e)
            toast.danger(
              'We could not disconnect your integration',
              e || 'Something went wrong when deleting you integration'
            )
            onFailure()
            return false
          }
        }
      )
    },
    deleteSync: (
      parent: any,
      sync: BaseSync,
      workspace: Workspace,
      integration: IntegrationObject,
      onConfirm?: (
        sync: BaseSync,
        workspace: Workspace,
        integration: IntegrationObject
      ) => boolean | Promise<boolean>
    ) => {
      const component = deleteSync(
        parent,
        sync,
        workspace,
        integration,
        async (keep) => {
          try {
            await parent.$store.dispatch('deleteIntegrationFilesSync', {
              workspace_id: workspace.uuid,
              sync_id: sync.uuid,
              integration_id: integration.id,
              delete_resources: !keep
            })
            toast.success(
              'Successfully Disconnected',
              keep
                ? 'You successfully disconnected the umanai Slack workspace. All previously synced files are still available in uman.ai. You can always reconnect again later.'
                : 'You successfully disconnected the umanai slack workspace and deleted all synced files in uman.ai. You can always reconnect again later.'
            )
            const validOnConfirm = onConfirm || (() => {})
            await validOnConfirm(sync, workspace, integration)
            component.close()
            return true
          } catch (e) {
            parent.$console.debug(e)
            toast.danger(
              'Sync not deleted',
              'We could not delete your sync, please try again later.'
            )
            return false
          }
        }
      )
    }
  }

  return {
    ...dialogs,
    workspace,
    resource,
    integration: { ...dialogs.integration, ...integration }
  }
}

export default function (Vue: any) {
  // Shared inject
  inject(Vue)

  // Overwrite the different maps
  Vue.prototype.$modals = createHydratedModals()
  Vue.prototype.$dialogs = createHydratedDialogs()
  Vue.prototype.$toast = toast
  Vue.prototype.$resource = {
    goToResource: (resourceLink: string) => {
      const win = window.open(resourceLink, '_blank')
      win?.focus()
    }
  }

  injectValidations(Vue)
}
