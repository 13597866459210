





































import EmailInputWithAccessType from '@c/features/invite-modal/molecules/EmailInputWithAccessType.vue'
import { WORKSPACE_ROLES } from '@c/models/Workspace'
import ContentWithHeader from '@c/shared/molecules/structural/blocks/ContentWithHeader.vue'
import Vue, { PropType } from 'vue'
import PendingEmailInvites from './PendingEmailInvites.vue'

export default Vue.extend({
  name: 'EmailInvitesComponent',
  components: {
    EmailInputWithAccessType,
    ContentWithHeader,
    PendingEmailInvites
  },
  props: {
    currentUserRole: {
      type: String as PropType<WORKSPACE_ROLES | undefined>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    invites: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      emailsWithAccessType: [
        {
          email: '',
          accessType: WORKSPACE_ROLES.Member
        }
      ]
    }
  },
  methods: {
    inputAccessType(accessType: string, index: number) {
      Vue.set(this.emailsWithAccessType, index, {
        ...this.emailsWithAccessType[index],
        accessType: accessType || WORKSPACE_ROLES.Member
      })
    },
    inputEmail(email: string, index: number) {
      Vue.set(this.emailsWithAccessType, index, {
        ...this.emailsWithAccessType[index],
        email
      })
      if (!this.emailsWithAccessType.filter((e) => !e.email).length)
        this.emailsWithAccessType.push({
          email: '',
          accessType: WORKSPACE_ROLES.Member
        })
      if (!email && this.emailsWithAccessType.filter((e) => !e.email).length)
        this.emailsWithAccessType.splice(index, 1)
    },
    sendInvites(): void {
      this.$emit(
        'sendInvites',
        this.emailsWithAccessType.filter((x) => x.email)
      )
      this.emailsWithAccessType = [
        {
          email: '',
          accessType: WORKSPACE_ROLES.Member
        }
      ]
    },
    isExpired(invite: any): boolean {
      return new Date(invite.expiration_date) < new Date()
    }
  }
})
