<template>
  <div class="number-input" :style="inputPadding">
    <p v-if="title" class="number-input-title">{{ title }}</p>
    <div class="number-input-wrapper">
      <div class="number-input-left">
        <img
          v-if="leftIcon"
          ref="leftgroupicon"
          :src="require(`@/assets/icons/${leftIcon}.svg`)"
          alt=""
          class="number-input-left-icon"
          @load="handleIconLoad"
        />
      </div>
      <input
        id=""
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        type="number"
        name=""
        class="number-input-input"
        :class="{ rounded, [size]: true }"
        v-bind="{
          ...(min !== undefined && { min }),
          ...(max !== undefined && { max })
        }"
        :style="{ ...(background ? { background } : {}) }"
        @keydown.enter="handleSubmit"
        @input="handleInput"
        @blur="$emit('blur', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's',
      validator: value => ['s', 'm', 'l'].includes(value)
    },
    rounded: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    background: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    inputPaddingLeft: 0
  }),
  computed: {
    rem() {
      return parseFloat(getComputedStyle(document.documentElement).fontSize)
    },
    inputPadding() {
      return {
        '--input-padding-left': `${this.inputPaddingLeft}px`
      }
    }
  },
  mounted() {
    if (!this.leftIcon) this.calculatePadding()
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    },
    handleInput(e) {
      let value = e.target.value
      value = Number(value)
      if (this.min !== undefined && value < this.min) value = this.min
      if (this.max !== undefined && value > this.max) value = this.max
      e.target.value = value
      this.$emit('input', value)
    },
    handleButtonClick() {
      this.$emit(this.submitWithButton ? 'submit' : 'button-click', this.value)
    },
    handleIconLoad() {
      this.calculatePadding()
    },
    calculatePadding() {
      const leftIconWidth = this.leftIcon ? this.$refs.leftgroupicon.width : 0
      const defaultPadding = 0.5 * this.rem
      this.inputPaddingLeft = leftIconWidth ? leftIconWidth + defaultPadding : 0
    }
  }
}
</script>

<style scoped lang="scss">
.number-input {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-input {
    width: 100%;
    background: #f1f2f3;
    border: #e9ebed 1px solid;
    outline: none;
    border-radius: 0.5rem;

    &.rounded {
      border-radius: 999rem;
    }

    &.s {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: calc(0.85rem + var(--input-padding-left));
      padding-right: calc(0.85rem + var(--input-padding-right));
    }

    &.m {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: calc(0.85rem + var(--input-padding-left));
      padding-right: calc(0.85rem + var(--input-padding-right));
    }

    &.l {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: calc(1rem + var(--input-padding-left));
      padding-right: calc(1rem + var(--input-padding-right));
    }

    &:focus {
      border: 1px solid #115efb;
      background: white;
    }

    &::placeholder {
      color: #8f9399;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-title {
    font-weight: 600;
    color: #303032;
  }

  &-left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;

    &-icon {
      height: 1.5rem;
    }
  }
}
</style>
