<template>
  <div
    id="slidecreator-container"
    :style="
      `--sidebar-width: ${sidebarWidth}px; --scrollbar-width: ${scrollbarWidth}px;`
    "
  >
    <div
      class="slidecreator-collapsed"
      :class="{
        active: !slideCollectorOpen && selectedSlides.length
      }"
    >
      <img
        src="@/assets/icons/slides.svg"
        alt=""
        class="slidecreator-collapsed-icon"
      />
      <span class="slidecreator-collapsed-text">{{ collectedText }}</span>
      <Button text="View" @click="openSlideCreator" />
    </div>
    <div
      id="slidecreator"
      class="slidecreator"
      :class="{ open: slideCollectorOpen }"
    >
      <div class="slidecreator-header">
        <img
          src="@/assets/icons/presentation.svg"
          alt=""
          class="slidecreator-header-icon"
        />
        <div class="slidecreator-header-name-wrapper">
          <p
            class="slidecreator-header-name"
            :class="{ hidden: editName }"
            @click="toggleNameEdit"
          >
            {{ name }}
          </p>
          <TextInput
            id="slidecreator-nameinput"
            v-model="name"
            class="slidecreator-header-name-input"
            :class="{ hidden: !editName }"
            button-icon="check-medium"
            @submit="() => toggleNameEdit(false)"
            @blur="() => toggleNameEdit(false)"
          />
        </div>
        <div class="slidecreator-header-btns">
          <div v-for="button in buttons" :key="button.key">
            <UploadDownloadDropdown
              v-if="button.key === 'export'"
              :props-call="getUploadDownloadProps"
              :callback="uploadDownloadCallback"
              :disabled="button.disabled"
              trigger="icon"
              button-type="grey"
              button-size="m"
            />
            <b-tooltip
              v-else
              :label="button.text"
              :active="!button.disabled"
              position="is-top"
              type="is-dark"
            >
              <Button
                :type="button.type"
                :icon="button.icon"
                :disabled="button.disabled"
                size="m"
                @click="button.click"
              />
            </b-tooltip>
          </div>
        </div>
        <div class="slidecreator-header-close">
          <b-tooltip label="Close editor" position="is-top" type="is-dark">
            <Button
              icon="minimize"
              type="grey"
              size="m"
              @click="closeSlideCreator"
            />
          </b-tooltip>
        </div>
      </div>
      <div id="slides" class="slidecreator-content">
        <CustomSlideCreatorCarousel
          v-if="selectedSlides.length > 0"
          :is-downloading="finishingExport"
        />
        <div v-else class="slidecreator-content-empty">
          <img
            class="slidecreator-content-empty-icon"
            src="@/assets/icons/slide-selection.svg"
          />
          <span class="slidecreator-content-empty-text"
            >Click the collect button on a slide to add it to your deck.</span
          >
        </div>
      </div>
      <div class="slidecreator-footer">{{ collectedText }}</div>
      <SlideDownloadProgressBar v-if="isDownloadingSlides" />
      <transition name="fade">
        <div v-if="saveLoading" class="slidecreator-save-overlay">
          <div class="slidecreator-save-overlay-container">
            <img
              src="@/assets/icons/spinner.svg"
              alt=""
              class="slidecreator-save-overlay-icon"
            />
            <span>Saving your presentation</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { sendBatchCollectEvent } from '@/services/feedbackService'
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import CustomSlideCreatorCarousel from '@c/shared/molecules/object-visualisations/resource/subcomponents/CustomSlideCreatorCarousel.vue'
import { mapActions, mapGetters } from 'vuex'
import SlideDownloadProgressBar from './SlideDownloadProgressBar.vue'

export default {
  name: 'CustomSlideCreator',
  components: {
    CustomSlideCreatorCarousel,
    UploadDownloadDropdown,
    Button,
    TextInput,
    SlideDownloadProgressBar
  },
  props: {
    customSlides: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      scrollbarWidth: 0,
      interval: null,
      availableIntegrations: [],
      outsideClickListener: undefined,
      loadingIntegrations: false,
      finishingExport: false,
      unsavedChanges: false,
      saveLoading: false,
      name: 'New presentation',
      prevName: '',
      editName: false
    }
  },
  computed: {
    ...mapGetters([
      'resourceTypeFilter',
      'selectedSlides',
      'windowWidth',
      'isDownloadingSlides',
      'slideCollectorOpen',
      'sidebarWidth',
      'currentUser',
      'currentCollection'
    ]),
    workspaceId() {
      return this.$route.params.workspace_id
    },
    exportDisabled() {
      return (
        !this.selectedSlides?.length || this.finishingExport || this.saveLoading
      )
    },
    collectedText() {
      return `${this.selectedSlides?.length} ${
        this?.selectedSlides?.length === 1 ? 'slide' : 'slides'
      } collected`
    },
    buttons() {
      return [
        {
          text: this.currentCollection
            ? this.hasUnsavedChanges
              ? 'Save unsaved changes'
              : 'No unsaved changes'
            : 'Save to my presentations',
          key: 'save',
          icon: 'save',
          type: `grey${this.hasUnsavedChanges ? '-highlight' : ''}`,
          loading: this.saveLoading,
          disabled: this.exportDisabled,
          click: this.saveCollection
        },
        {
          text: 'Start new presentation',
          key: 'new',
          icon: 'plus-medium',
          type: 'grey',
          disabled: this.exportDisabled || !this.currentCollection,
          click: this.resetSlides
        },
        {
          key: 'export',
          disabled: this.exportDisabled
        },
        {
          text: 'Clear all slides',
          key: 'clear',
          icon: 'bin',
          type: 'grey',
          disabled: this.exportDisabled,
          click: this.onClear
        }
      ]
    },
    hasUnsavedChanges() {
      return this.currentCollection
        ? this.unsavedChanges
        : this.selectedSlides?.length
    }
  },
  watch: {
    workspaceId() {
      this.resetSlides()
    },
    resourceTypeFilter() {
      this.closeSlideCreator()
    },
    selectedSlides(newVal) {
      if (!newVal) this.unsavedChanges = false
      else this.unsavedChanges = true
    },
    editName(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const input = document
            .getElementById('slidecreator-nameinput')
            ?.querySelector('input')
          if (input) input.focus()
        })
      }
    },
    currentCollection: {
      handler(newVal) {
        this.name = newVal.name || 'New presentation'
      },
      deep: true
    }
  },
  mounted() {
    this.scrollbarWidth = this.getScrollbarWidth()
    this.name = this.currentCollection?.name || 'New presentation'
  },
  methods: {
    ...mapActions([
      'resetSlides',
      'startDownloadSlides',
      'increaseDownloadProgress',
      'stopDownloadSlides',
      'setSlideCollectorOpen',
      'getUserIntegrations',
      'createCollection',
      'editCollection',
      'clearCurrentCollection'
    ]),
    openSlideCreator() {
      this.hideOnClickOutside()
      this.setSlideCollectorOpen(true)
      this.$root.$emit('close-slide-detail')
    },
    closeSlideCreator() {
      this.setSlideCollectorOpen(false)
      this.removeClickListener()
    },
    onClear() {
      if (this.finishingExport) return
      sendBatchCollectEvent(this.$route.params.workspace_id, {
        ids: this.selectedSlides.map((slide, idx) => ({
          resource_trace_id:
            slide.references?.[0]?.resource?.trace_id ||
            slide.resource_trace_id,
          subresource_trace_id: slide.trace_id,
          rank: idx + 1
        })),
        collection_id: this.currentCollection?.uuid,
        tab: this.$route.params.tab || 'all',
        comment: 'remove all collected from presentation creator'
      })
      this.resetSlides()
      if (!this.currentCollection) this.closeSlideCreator()
    },
    getScrollbarWidth() {
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.overflow = 'scroll'
      document.body.appendChild(outer)

      const inner = document.createElement('div')
      outer.appendChild(inner)

      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

      outer.parentNode.removeChild(outer)

      return scrollbarWidth
    },
    async getUploadDownloadProps() {
      this.startDownloadSlides(this.selectedSlides.length)
      this.interval = setInterval(() => {
        this.increaseDownloadProgress()
      }, 600)
      try {
        if (this.hasUnsavedChanges) {
          await this.saveCollection()
        }
        return {
          ...this.currentCollection,
          name: `${this.currentCollection.name}.pptx`
        }
      } catch (error) {
        this.$toast.error(error, 'downloading the presentation')
      }
    },
    uploadDownloadCallback(success) {
      clearInterval(this.interval)
      this.stopDownloadSlides({ success })
    },
    async checkAvailableIntegrations() {
      if (
        !Object.keys(this.exportAvailable || {}).every(
          el => this.exportAvailable[el]
        )
      ) {
        this.loadingIntegrations = true
        this.availableIntegrations = await this.getUserIntegrations()
        this.loadingIntegrations = false
      }
    },
    removeClickListener() {
      document.removeEventListener('click', this.outsideClickListener)
    },
    hideOnClickOutside() {
      const el = document.getElementById('slidecreator-container')
      this.outsideClickListener = event => {
        if (
          !el.contains(event.target) &&
          this.slideCollectorOpen &&
          !event.target.closest('.btn-collect')
        ) {
          this.closeSlideCreator()
        }
      }
      document.addEventListener('click', this.outsideClickListener)
    },
    async saveCollection() {
      try {
        this.saveLoading = true
        if (this.hasUnsavedChanges) {
          if (!this.currentCollection) {
            await this.createCollection({
              name: this.name,
              pages: this.selectedSlides.map(slide => slide.uuid),
              workspace_id: this.$route.params.workspace_id,
              act_as: this.$route.query.act_as
            })
          } else {
            await this.editCollection({
              id: this.currentCollection.uuid,
              name: this.name,
              pages: this.currentCollection.pages.map(
                page => page.page || page.uuid || page
              ),
              workspace_id: this.$route.params.workspace_id,
              act_as: this.$route.query.act_as
            })
          }
        }
        this.$toast.success(
          'Presentation saved',
          'Successfully saved your presentation, you can find it in Your Presentations'
        )
      } catch (e) {
        this.$toast.error(e, 'saving the presentation')
      } finally {
        this.saveLoading = false
      }
      this.unsavedChanges = false
    },
    toggleNameEdit(start = true) {
      if (start) {
        this.prevName = this.name
      } else if (this.name !== this.prevName) {
        this.unsavedChanges = true
        this.saveCollection()
      }
      this.editName = !this.editName
    }
  }
}
</script>
<style lang="scss" scoped>
.slidecreator {
  &-collapsed {
    position: fixed;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: fit-content;
    padding: 0.75rem 1.5rem;
    z-index: 999;
    background-color: white;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
    border-radius: 999rem;
    border: 1px solid rgba(#000, 0.08);
    transition: bottom ease 0.3s, opacity ease 0.3s;
    opacity: 0;

    &.active {
      bottom: 20px;
      opacity: 1;
    }

    &-text {
      font-size: 1.1rem;
    }

    &-icon {
      height: 1.75rem;
      width: 1.75rem;
    }
  }

  position: fixed;
  bottom: -100%;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width) - var(--scrollbar-width));
  padding: 0 2rem;
  z-index: 999;
  background: white;
  border-top: 1px solid rgba(#000, 0.08);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: fit-content;
  transition: bottom ease-in-out 0.5s;

  &.open {
    bottom: 0;
  }

  &-header {
    padding: 1rem 1.75rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    &-name {
      font-size: 1.1rem;
      font-weight: 700;
      padding: 0 0.5rem;
      min-width: 15rem;
      border-radius: 2px;
      border: 1px solid transparent;
      transition: border ease 0.2s;
      user-select: none;
      cursor: text;

      &:hover {
        border: 1px solid #303032;
      }

      &-wrapper {
        position: relative;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }

      &-input {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        &.hidden {
          opacity: 0;
          pointer-events: none;
          max-width: 0;
        }
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-close {
      margin-left: auto;
    }
  }

  &-content {
    &-empty {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      height: 100%;
      width: 100%;
      padding: 1rem;

      &-icon {
        height: 2.5rem;
      }

      &-text {
        color: #8f9399;
      }
    }
  }

  &-save-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#000, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    &-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 1.5rem 1rem;
      background: white;
      border-radius: 8px;
      font-weight: 600;
      font-size: 1.2rem;
    }

    &-icon {
      height: 2rem;
      animation: spin 1s linear infinite;
    }
  }

  &-footer {
    padding: 1rem 1.75rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
