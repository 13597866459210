var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-columns is-vcentered integration-row space-between" },
    [
      _c("div", { staticClass: "u-column is-narrow" }, [
        _c(
          "div",
          { staticClass: "u-columns is-vcentered" },
          [
            _c("div", { staticClass: "u-column is-narrow" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "4px",
                    "box-shadow": "0px 0px 1.57895px rgba(0, 0, 0, 0.13)",
                    "border-radius": "3.15789px",
                    height: "24px"
                  }
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "16px",
                      width: "16px",
                      "object-fit": "cover"
                    },
                    attrs: { src: _vm.integration.image }
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "u-column is-narrow" }, [
              _c("p", { staticClass: "mh-3" }, [
                _c("span", { staticClass: "body xs black bold" }, [
                  _vm._v(_vm._s(_vm.integration.title))
                ])
              ])
            ]),
            _vm.status
              ? _c("StatusOrb", {
                  attrs: {
                    type: _vm.statusColor,
                    text: "Integration sync " + _vm.status
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "u-column is-narrow" }, [_vm._t("right")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }