var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "highlight",
      on: {
        mouseenter: function($event) {
          _vm.hovering = true
        },
        mouseleave: function($event) {
          _vm.hovering = false
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "highlight-preview" },
        [
          _c(
            "HighlightSubresources",
            _vm._g(
              {
                attrs: {
                  subresources: _vm.resource.subresources,
                  "resource-type": _vm.resourceType,
                  highlight: _vm.resource.highlight,
                  pinned: _vm.resource.highlight.pinned,
                  mimetype: _vm.resource.integrationfile.mimetype,
                  "resource-trace-id": _vm.resource.trace_id
                },
                on: { inspectResource: _vm.onPreviewClick }
              },
              _vm.$listeners
            )
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "highlight-info" },
        [
          _c(
            "div",
            { staticClass: "highlight-info-row" },
            [
              _c("DocumentTag", {
                staticClass: "highlight-info-icon",
                attrs: { mimetype: _vm.resource.integrationfile.mimetype }
              }),
              _c(
                "span",
                {
                  staticClass: "highlight-info-title",
                  style: _vm.titleWidth,
                  attrs: { title: _vm.resource.title },
                  on: { click: _vm.onResourceClick }
                },
                [_vm._v(_vm._s(_vm.resource.title))]
              ),
              _c("QualityLabels", {
                staticClass: "highlight-info-labels",
                attrs: {
                  "active-label": _vm.resource.label,
                  "source-id": _vm.resource.uuid,
                  "bordered-icons": false,
                  "show-edit-labels": false,
                  "small-labels": ""
                }
              }),
              _vm.downloadFilesEnabled
                ? _c("UploadDownloadDropdown", {
                    attrs: { "props-call": _vm.getDownloadProps }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("ResourceInfo", {
            staticClass: "highlight-info-integration",
            attrs: {
              "resource-integration": _vm.resource.integrationfile,
              hover: _vm.hovering,
              "hide-integration-path": true
            },
            on: { "on-path-click": _vm.onPathClick }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }