<template>
  <div class="fallback-image">
    <b-loading
      v-if="loading"
      class="image-loading-overlay"
      :is-full-page="false"
      :can-cancel="false"
      active
    ></b-loading>
    <img
      v-if="hasValidSource && !isErrored"
      v-show="!loading"
      ref="img"
      class="full"
      :src="src"
      @error="onError"
      @load="onLoad"
    />
    <slot v-else>
      <img class="full" :src="fallback" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: null
    },

    fallback: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isErrored: false,
      loading: true,

      loadTimeout: 1000,
      timout: null
    }
  },

  computed: {
    hasValidSource() {
      return this.src != null && this.src.length > 0
    }
  },

  watch: {
    src() {
      if (this.hasValidSource) {
        this.isErrored = false
      }
    }
  },

  mounted() {
    if (!(this.hasValidSource && !this.isErrored)) {
      this.loading = false
    }

    // try loading image for 1 second, then stop
    setTimeout(() => {
      this.loading = false
    }, this.loadTimeout)
  },

  methods: {
    onLoad() {
      this.loading = false
      clearTimeout(this.timout)
    },

    onError() {
      this.isErrored = true
    }
  }
}
</script>

<style lang="scss" scoped>
.image-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-loading-overlay {
  background: none !important;

  .loading-overlay .loading-background {
    background: none !important;
  }
}

::v-deep .fallback-image,
img {
  max-height: 100%;
  max-width: 100%;
}
</style>
