<template>
  <div class="attributes">
    <p class="attributes-header">
      <span>Labels in your workspace</span>
      <Button
        type="white"
        text="Add label type"
        icon="plus-medium"
        :icon-left="true"
        @click="() => toggleAddAttribute('root')"
      />
    </p>
    <div
      class="attributes-add"
      :class="{ opened: showAddAttribute === 'root' }"
    >
      <TextInput
        v-model="newAttributeInput"
        placeholder="Add label type"
        :max-length="30"
      />
      <Button text="Save" @click="() => handleSaveAttribute()" />
    </div>
    <DraggableAccordion
      v-if="attributes.length"
      ref="attributeaccordion"
      :items="accordionItems"
      :bordered="true"
      gap="1rem"
      @open="(s) => (openedSection = s)"
      @reorder="handlePositionChange"
    >
      <template #header="{ header }">
        <TextInput
          v-if="showEditAttribute === header.uuid"
          v-model="editAttributeName"
          :max-length="30"
          placeholder="Label type name"
          class="attributes-list-item-name-edit"
          @click.native.stop
        />
      </template>
      <template #buttons="{ header }">
        <div class="attributes-list-header-btns">
          <Button
            v-if="showDeleteAttribute === header.uuid"
            text="Delete"
            @click.native.stop="handleDeleteAttribute(header)"
          />
          <Button
            v-if="showDeleteAttribute === header.uuid"
            text="Cancel"
            type="white"
            @click.native.stop="showDeleteAttribute = ''"
          />
          <Button
            v-else-if="showEditAttribute !== header.uuid"
            type="white"
            icon="bin"
            @click.native.stop="handleDeleteAttributeToggle(header)"
          />
          <Button
            v-if="showEditAttribute === header.uuid"
            text="Save"
            @click.native.stop="handleEditAttribute(header)"
          />
          <Button
            v-if="showEditAttribute === header.uuid"
            text="Cancel"
            type="white"
            @click.native.stop="showEditAttribute = ''"
          />
          <Button
            v-else
            type="white"
            icon="pencil-grey"
            @click.native.stop="handleEditAttributeToggle(header)"
          />
          <Button
            type="white"
            text="Add label"
            icon="plus-medium"
            :icon-left="true"
            @click.native.stop="() => toggleAddAttribute(header.uuid)"
          />
        </div>
      </template>
      <template #content="{ item }">
        <div>
          <div class="attributes-list-add">
            <div
              class="attributes-add"
              :class="{ opened: showAddAttribute === item.uuid }"
            >
              <TextInput
                v-model="newAttributeInput"
                placeholder="Add label"
                :max-length="30"
              />
              <Button text="Save" @click="() => handleSaveAttribute(item)" />
            </div>
          </div>
          <DraggableList
            v-if="item.values.length"
            :items="item.values"
            :item-key="`attributes-${item.uuid}`"
            :item-style="{
              padding: '0.5rem 1.5rem'
            }"
            class="attributes-list"
            @reorder="(pos) => handlePositionChange(pos, item)"
          >
            <template #item="{ item: attribute }">
              <div class="attributes-list-item">
                <TextInput
                  v-if="showEditAttribute === attribute.uuid"
                  v-model="editAttributeName"
                  placeholder="Label name"
                  class="attributes-list-item-name-edit"
                  :max-length="30"
                />
                <p v-else class="attributes-list-item-name">
                  {{ attribute.value }}
                </p>
                <div class="attributes-list-item-btns">
                  <Button
                    v-if="showDeleteAttribute === attribute.uuid"
                    text="Delete"
                    @click="handleDeleteAttribute(item, attribute)"
                  />
                  <Button
                    v-if="showDeleteAttribute === attribute.uuid"
                    text="Cancel"
                    type="white"
                    @click="showDeleteAttribute = ''"
                  />
                  <Button
                    v-else-if="showEditAttribute !== attribute.uuid"
                    type="white"
                    icon="bin"
                    @click="handleDeleteAttributeToggle(attribute)"
                  />
                  <Button
                    v-if="showEditAttribute === attribute.uuid"
                    text="Save"
                    @click="handleEditAttribute(item, attribute)"
                  />
                  <Button
                    v-if="showEditAttribute === attribute.uuid"
                    text="Cancel"
                    type="white"
                    @click="showEditAttribute = ''"
                  />
                  <Button
                    v-else
                    type="white"
                    icon="pencil-grey"
                    @click="handleEditAttributeToggle(attribute)"
                  />
                </div>
              </div>
            </template>
          </DraggableList>
          <div v-else class="attributes-empty">
            <img
              src="@/assets/icons/label.svg"
              alt=""
              class="attributes-empty-icon"
            />
            <span class="attributes-empty-text"
              >No values have been added for this label</span
            >
          </div>
        </div>
      </template>
    </DraggableAccordion>
    <div v-else class="attributes-empty">
      <img
        src="@/assets/icons/label.svg"
        alt=""
        class="attributes-empty-icon"
      />
      <span class="attributes-empty-text">No labels could be found</span>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import DraggableAccordion from '@c/library/DraggableAccordion.vue'
import DraggableList from '@c/library/DraggableList.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AttributesModal',
  components: {
    DraggableAccordion,
    DraggableList,
    TextInput,
    Button
  },
  data: () => ({
    openedSection: '',
    showAddAttribute: '',
    newAttributeInput: '',
    showEditAttribute: '',
    editAttributeName: '',
    showDeleteAttribute: ''
  }),
  computed: {
    ...mapGetters(['attributes']),
    accordionItems() {
      return this.attributes.map((attribute) => ({
        ...attribute,
        id: attribute.uuid
      }))
    }
  },
  methods: {
    ...mapActions([
      'createAttribute',
      'createAttributeValue',
      'deleteAttribute',
      'deleteAttributeValue',
      'editAttribute',
      'editAttributeValue'
    ]),
    handleEditAttributeToggle(attribute) {
      this.showDeleteAttribute = ''
      this.editAttributeName = attribute.name || attribute.value
      this.showEditAttribute = attribute.uuid
    },
    toggleAddAttribute(id) {
      if (this.openedSection !== id)
        this.$refs.attributeaccordion?.toggleOpen({ id })
      this.showAddAttribute = id
    },
    handleDeleteAttributeToggle(attribute) {
      this.showDeleteAttribute = attribute.uuid
    },
    async handleEditAttribute(header, value = undefined) {
      try {
        const editFunction = value
          ? this.editAttributeValue
          : this.editAttribute
        await editFunction({
          workspace_id: this.$route.params.workspace_id,
          [value ? 'value' : 'name']: this.editAttributeName,
          attribute_id: header.uuid,
          ...(value ? { value_id: value.uuid } : {})
        })
        this.$toast.success('Label edited')
        this.showEditAttribute = ''
      } catch (e) {
        this.$console.debug('Label edit failed', e)
        this.$toast.error(e, 'editing your label')
      }
    },
    async handleSaveAttribute(header = undefined) {
      if (!this.newAttributeInput) {
        this.showAddAttribute = ''
        return
      }
      try {
        const createFunction = header
          ? this.createAttributeValue
          : this.createAttribute
        await createFunction({
          workspace_id: this.$route.params.workspace_id,
          [header ? 'value' : 'name']: this.newAttributeInput,
          ...(header ? { attribute_id: header.uuid } : {})
        })
        this.newAttributeInput = ''
        this.$toast.success(header ? 'Label value added' : 'Label type added')
        this.showAddAttribute = ''
      } catch (e) {
        this.$console.debug(this, 'Label creation failed', e)
        this.$toast.error(e, 'creating your label')
      }
    },
    async handleDeleteAttribute(header, value = undefined) {
      try {
        const deleteFunction = value
          ? this.deleteAttributeValue
          : this.deleteAttribute
        await deleteFunction({
          workspace_id: this.$route.params.workspace_id,
          attribute_id: header.uuid,
          ...(value ? { value_id: value.uuid } : {})
        })
        this.$toast.success('Label deleted')
        this.showDeleteAttribute = ''
      } catch (e) {
        this.$console.debug('Label deletion failed', e)
        this.$toast.error(e, 'deleting your label')
      }
    },
    async handlePositionChange({ from, to }, header = undefined) {
      try {
        const editFunction = header
          ? this.editAttributeValue
          : this.editAttribute
        const value = header ? header.values[from] : this.attributes[from]
        await editFunction({
          workspace_id: this.$route.params.workspace_id,
          ...(header
            ? { attribute_id: header.uuid, value_id: value.uuid }
            : { attribute_id: value.uuid }),
          position: to + 1
        })
        this.$toast.success('Label position changed')
      } catch (e) {
        this.$console.debug('Label position change failed', e)
        this.$toast.error(e, 'changing your label position')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.attributes {
  background: white;
  padding: 2rem;
  border-radius: 6px;
  max-height: 75vh;
  overflow: auto;

  &-header {
    font-weight: 700;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    & > *:only-child {
      margin-left: auto;
    }
  }

  &-add {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
    align-items: center;

    &.opened {
      height: 3rem;
    }
  }

  &-list {
    max-height: 70vh;
    overflow: auto;

    &-header {
      &-btns {
        display: contents;
      }
    }

    &-add {
      padding: 0 1rem;
    }

    &-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      height: 3rem;

      &-input {
        padding: 0.25rem 0.5rem;
        background: #f1f2f3;
        border-radius: 4px;
        border: 1px solid rgba(#000, 8%);
        width: 100%;

        &:focus,
        &:active,
        &:focus-visible,
        &:focus-within {
          border: 1px solid $primary;
          background: white;
        }
      }

      &-name {
        white-space: nowrap;

        &-edit {
          flex: 1;
        }
      }

      &-btns {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }

  &-empty {
    padding: 0 2rem 2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &-icon {
      height: 2rem;
    }

    &-text {
      color: #60666b;
    }
  }
}
</style>
