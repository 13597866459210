import Workspace from '@c/models/Workspace'
import WorkspaceCreateView from '@c/features/create-workspace-modal/organisms/WorkspaceCreateView.vue'
import WorkspaceInviteView from '@c/features/create-workspace-modal/organisms/WorkspaceInviteView.vue'

export enum CreateStepsEnum {
  CREATE = 'CREATE',
  INVITE = 'INVITE'
}

export interface CREATE_DATA_INPUT {}
export interface INVITE_DATA_INPUT {
  workspace: Workspace
}

export interface CREATE_DATA_OUTPUT {
  title: string
  description: string
}
export interface INVITE_DATA_OUTPUT {}

export const modalComponents = {
  [CreateStepsEnum.CREATE]: WorkspaceCreateView,
  [CreateStepsEnum.INVITE]: WorkspaceInviteView
}

export const allModalComponents = {
  WorkspaceCreateView,
  WorkspaceInviteView
}

export const stepTitles = {
  [CreateStepsEnum.CREATE]: "Let's set up a new workspace",
  [CreateStepsEnum.INVITE]:
    "Workspace created, let's invite others to your workspace"
}
