var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-node" }, [
    _c(
      "div",
      { staticClass: "u-columns is-vcentered space-between tree-node" },
      [
        _c("div", { staticClass: "u-column" }, [
          _c("div", { staticClass: "u-columns is-vcentered pv-1" }, [
            !_vm.noExpands
              ? _c(
                  "div",
                  {
                    staticClass: "u-column is-narrow",
                    on: {
                      click: function($event) {
                        _vm.expanded = !_vm.expanded
                      }
                    }
                  },
                  [
                    (_vm.node.children && _vm.node.children.length > 0) ||
                    _vm.node.expandable
                      ? _c("b-icon", {
                          staticClass: "body body-text",
                          staticStyle: { transition: "all ease 200ms" },
                          style: {
                            transform: _vm.expanded ? "rotate(90deg)" : "",
                            "margin-bottom": _vm.expanded ? "7px" : "",
                            width: _vm.arrowWidth
                          },
                          attrs: { icon: "menu-right" }
                        })
                      : _c("div", { style: { width: _vm.arrowWidth } })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "u-column" },
              [_vm._t("default", null, { node: _vm.node, depth: _vm.depth })],
              2
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "u-column is-narrow" },
          [_vm._t("row-right", null, { node: _vm.node })],
          2
        )
      ]
    ),
    _vm.expanded
      ? _c(
          "div",
          [
            _c("Promised", {
              attrs: { promise: _vm.childrenPromise },
              scopedSlots: _vm._u(
                [
                  {
                    key: "pending",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { style: { "margin-left": _vm.arrowWidth } },
                          [
                            _vm._t(
                              "loading-children",
                              function() {
                                return [
                                  _c("p", [_vm._v("Loading children...")])
                                ]
                              },
                              { node: _vm.node }
                            )
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "default",
                    fn: function(parentData) {
                      return [
                        _c(
                          "div",
                          _vm._l(parentData, function(subNode) {
                            var _obj
                            return _c(
                              "div",
                              {
                                key: subNode.id,
                                class:
                                  ((_obj = {}),
                                  (_obj["ml-" + _vm.indent] = true),
                                  (_obj["tree-node"] = true),
                                  _obj)
                              },
                              [
                                _c(
                                  "DefaultTreeView",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          node: subNode,
                                          depth: _vm.depth + 1
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(slotProps) {
                                                return [
                                                  _vm._t("default", null, {
                                                    node: slotProps.node,
                                                    depth: slotProps.depth
                                                  })
                                                ]
                                              }
                                            },
                                            {
                                              key: "error-children",
                                              fn: function(data) {
                                                return [
                                                  _vm._t(
                                                    "error-children",
                                                    null,
                                                    null,
                                                    data
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "loading-children",
                                              fn: function(data) {
                                                return [
                                                  _vm._t(
                                                    "loading-children",
                                                    null,
                                                    null,
                                                    data
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "row-right",
                                              fn: function(data) {
                                                return [
                                                  _vm._t("row-right", null, {
                                                    node: data.node
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      "DefaultTreeView",
                                      _vm.$props,
                                      false
                                    ),
                                    _vm.$listeners
                                  )
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    }
                  },
                  {
                    key: "rejected",
                    fn: function(error) {
                      return [
                        _c(
                          "div",
                          { style: { "margin-left": _vm.arrowWidth } },
                          [
                            _vm._t(
                              "error-children",
                              function() {
                                return [
                                  _c("p", [
                                    _vm._v(
                                      "An error occurred: " +
                                        _vm._s(error.message)
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v("For node: " + _vm._s(_vm.node))
                                  ])
                                ]
                              },
                              { error: error, node: _vm.node }
                            )
                          ],
                          2
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }