




















import { HighlightedText } from '@c/shared/molecules/structural/blocks/interfaces'
import Vue from 'vue'

export default Vue.extend({
  name: 'HighlightedTextComponent',
  props: {
    splitText: {
      type: [Array as () => HighlightedText[], String],
      default: () => [] as HighlightedText[]
    },
    baseClass: {
      type: Object,
      default: () => ({ body: true })
    },
    customHighlight: {
      type: [String, Object],
      default: undefined
    }
  },
  computed: {
    isHighlighted() {
      return Array.isArray(this.splitText)
    }
  }
})
