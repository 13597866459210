import User from '@c/models/User.ts'

export interface MemberData {
  user: User
  is_owner: boolean
  top_contributor: boolean
}

export enum SettingsModalTab {
  Edit = 'Edit',
  Members = 'Members',
  Invite = 'Invite',
  Integrations = 'Integrations'
}

export default {}
