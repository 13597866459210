const debug = process.env.NODE_ENV != 'production'

const Console = {
  debug(...msg) {
    if (debug) {
      console.debug('[DEBUG]', ...msg) // eslint-disable-line no-console
    }
  },
  log(...msg) {
    if (debug) {
      console.log(...msg) // eslint-disable-line no-console
    }
  },
  info(...msg) {
    console.info(...msg) // eslint-disable-line no-console
  },
  warning(...msg) {
    console.warn(...msg) // eslint-disable-line no-console
  },
  error(...msg) {
    console.error(...msg) // eslint-disable-line no-console
  }
}

export default Console
