var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hovered,
            expression: "hovered"
          }
        ],
        staticClass: "info",
        class: { leftaligned: _vm.alignLeft, rightaligned: !_vm.alignLeft }
      },
      [
        !_vm.hasUser
          ? _c("p", { staticClass: "info-text" }, [
              _vm._v(" Labeled as "),
              _c("img", {
                staticClass: "info-text-icon",
                attrs: {
                  src: require("@/assets/icons/" +
                    _vm.label.type +
                    "QualityLabel.svg"),
                  alt: ""
                }
              }),
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.label.name))
              ])
            ])
          : _c("div", { staticClass: "info-user" }, [
              _vm.$umodel.avatar(_vm.label) && !_vm.avatarError
                ? _c("img", {
                    staticClass: "info-user-avatar",
                    attrs: { src: _vm.$umodel.avatar(_vm.label), alt: "" },
                    on: {
                      error: function($event) {
                        _vm.avatarError = true
                      }
                    }
                  })
                : _c(
                    "p",
                    {
                      staticClass: "info-user-avatar",
                      style: "background: " + _vm.userBackgroundColor + ";"
                    },
                    [_vm._v(" " + _vm._s(_vm.userInitials) + " ")]
                  ),
              _c("div", { staticClass: "info-user-info" }, [
                _c("p", { staticClass: "bold" }, [
                  _vm._v(_vm._s(_vm.userFullName))
                ]),
                _c("p", { staticClass: "info-text" }, [
                  _vm._v(" Labeled this as "),
                  _c("img", {
                    staticClass: "info-text-icon",
                    attrs: {
                      src: require("@/assets/icons/" +
                        _vm.label.type +
                        "QualityLabel.svg"),
                      alt: ""
                    }
                  }),
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(_vm._s(_vm.label.name))
                  ])
                ])
              ])
            ]),
        _vm.label.comment
          ? _c("p", { staticClass: "info-comment" }, [
              _vm._v(" " + _vm._s(_vm.label.comment) + " ")
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }