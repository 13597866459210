var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeCurateEdit,
          expression: "closeCurateEdit"
        }
      ],
      staticClass: "curate"
    },
    [
      _vm._t(
        "trigger",
        function() {
          return [
            _c(
              "div",
              {
                staticClass: "curate-trigger",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.openCurateEdit.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", { staticClass: "curate-trigger-text" }, [
                  _vm._v(
                    _vm._s(_vm.isEmpty(_vm.activeLabel) ? "Curate" : "Edit")
                  )
                ]),
                _c("img", {
                  staticClass: "curate-trigger-chevron",
                  attrs: {
                    src: require("@c/assets/icons/dropdown-chevron.svg"),
                    alt: ""
                  }
                })
              ]
            )
          ]
        },
        { onClick: _vm.openCurateEdit }
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.curateOpen,
                expression: "curateOpen"
              }
            ],
            staticClass: "curate-edit",
            class: { leftaligned: _vm.alignLeft, rightaligned: !_vm.alignLeft },
            style:
              "top: " +
              (_vm.mouseY < 0.6 ? "100%" : "unset") +
              "; bottom: " +
              (_vm.mouseY >= 0.6 ? "100%" : "unset"),
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("div", { staticClass: "curate-edit-label" }, [
              _c("p", { staticClass: "curate-edit-header" }, [
                _vm._v("Curation label")
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: _vm.closeLabelEdit,
                      expression: "closeLabelEdit"
                    }
                  ],
                  staticClass: "label-dropdown",
                  on: { click: _vm.openLabelEdit }
                },
                [
                  _c("div", { staticClass: "label-dropdown-label" }, [
                    !_vm.isEmpty(_vm.activeLabelValue)
                      ? _c("img", {
                          staticClass: "label-dropdown-label-icon",
                          attrs: {
                            src: require("@c/assets/icons/" +
                              _vm.activeLabelValue.type +
                              "QualityLabel.svg")
                          }
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "label-dropdown-label-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isEmpty(_vm.activeLabelValue)
                              ? "Select..."
                              : _vm.activeLabelValue.name
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("img", {
                    staticClass: "curate-trigger-chevron",
                    attrs: {
                      src: require("@c/assets/icons/dropdown-chevron.svg"),
                      alt: ""
                    }
                  }),
                  _c("EditLabelsList", {
                    attrs: {
                      "edit-open": _vm.editOpen,
                      "mouse-y": _vm.mouseYLabel,
                      "label-types": _vm.labelTypes,
                      "labels-by-type": _vm.labelsByType,
                      "active-label-value": _vm.activeLabelValue,
                      "align-left": _vm.alignLeft
                    },
                    on: { toggleLabel: _vm.toggleLabel }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "curate-edit-comment" }, [
              _c("p", { staticClass: "curate-edit-header" }, [
                _vm._v("Remark")
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment,
                    expression: "comment"
                  }
                ],
                staticClass: "curate-edit-comment-text",
                attrs: {
                  id: "comment",
                  name: "comment",
                  maxlength: "200",
                  disabled: _vm.inputsDisabled
                },
                domProps: { value: _vm.comment },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.comment = $event.target.value
                  }
                }
              }),
              _c("p", { staticClass: "curate-edit-comment-counter" }, [
                _vm._v(_vm._s(_vm.comment.length) + "/200")
              ])
            ]),
            _c("div", { staticClass: "curate-edit-btn" }, [
              _c("div", { staticClass: "curate-edit-btn-border" }),
              _c(
                "p",
                {
                  staticClass: "curate-edit-btn-clear",
                  class: { disabled: _vm.inputsDisabled },
                  on: {
                    click: function($event) {
                      return _vm.clear()
                    }
                  }
                },
                [_vm._v(" Clear ")]
              ),
              _c(
                "p",
                {
                  staticClass: "curate-edit-btn-save",
                  on: {
                    click: function($event) {
                      return _vm.saveLabel()
                    }
                  }
                },
                [_vm._v("Save curation")]
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }