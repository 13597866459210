import allIntegrations, { Integration } from '@c/integrations'
import { BaseSync, default as IntegrationObject } from '@c/models/Integration'
import Workspace from '@c/models/Workspace'
import { doubleActionDialog } from '@c/shared/logic/dialogs/error/BaseErrorDialogs'

export function disconnectFileIntegration(
  parent: any,
  integration: Integration,
  integrationObject: any,
  workspace: Workspace,
  onDisconnect?: (keep: boolean) => boolean | Promise<boolean>
): any {
  return doubleActionDialog(
    parent,
    '',
    'You can choose to disconnect your workspace entirely and delete all files that were synced. Or just disconnect your account but still keep all files that were synced in the last update. You can always reconnect again later if you want to.',
    '',
    onDisconnect ? () => onDisconnect(false) : () => true,
    onDisconnect ? () => onDisconnect(true) : () => true,
    'Disconnect',
    'Disconnect, but keep content',
    undefined,
    {
      title: `Disconnect ${integration.title} integration`,
      header: 'Are you sure you want to disconnect this integration?'
    }
  )
}

export function deleteSync(
  parent: any,
  sync: BaseSync,
  workspace: Workspace,
  integration: IntegrationObject,
  onDisconnect?: (keep: boolean) => boolean | Promise<boolean>
): any {
  return doubleActionDialog(
    parent,
    '',
    'You can choose to disconnect your workspace entirely and delete all files that were synced. Or just disconnect your account but still keep all files that were synced in the latest update. You can always reconnect again later if you want to.',
    '',
    onDisconnect ? () => onDisconnect(false) : () => true,
    onDisconnect ? () => onDisconnect(true) : () => true,
    'Disconnect',
    'Disconnect, but keep content',
    undefined,
    {
      title: `Disconnect ${allIntegrations[integration.type]} integration`,
      header: 'Are you sure you want to disconnect this integration?'
    }
  )
}
