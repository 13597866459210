import { Paginator, getClient, getResponseData } from '../../axios'

const client = getClient('/workspaces')

export default {
  state: {
    currentCollection: null
  },
  getters: {
    currentCollection: (state) => state.currentCollection
  },
  mutations: {
    setCurrentCollection(state, collection) {
      state.currentCollection = collection
    },
    addPage(state, slide) {
      if (!state.currentCollection) return
      state.currentCollection.pages.push(slide.uuid)
    },
    deletePage(state, idx) {
      if (!state.currentCollection) return
      state.currentCollection.pages.splice(idx, 1)
    },
    movePage(state, { oldIndex, newIndex }) {
      if (!state.currentCollection) return
      const element = state.currentCollection.pages.splice(oldIndex, 1)[0]
      state.currentCollection.pages.splice(newIndex, 0, element)
    },
    clearPages(state) {
      if (!state.currentCollection) return
      state.currentCollection.pages = []
    }
  },
  actions: {
    setCurrentCollection({ commit }, collection) {
      commit('setCurrentCollection', collection || null)
      commit('setSelectedSlides', [...(collection?.pages || [])])
    },
    async getCollectionsPaginator(
      _,
      { workspace_id, page_size, search, act_as }
    ) {
      return new Paginator(
        client,
        await client.get(`/${workspace_id}/collections/`, {
          params: {
            page_size: page_size || 10,
            search,
            act_as
          }
        })
      )
    },
    async getCollection(
      { dispatch },
      { workspace_id, id, act_as, set_current = true }
    ) {
      const res = await client.get(`/${workspace_id}/collections/${id}/`, {
        params: {
          act_as
        }
      })
      const collection = getResponseData(res)
      if (set_current) dispatch('setCurrentCollection', collection)
      return collection
    },
    clearCurrentCollection({ dispatch }) {
      dispatch('setCurrentCollection', null)
    },
    async createCollection(
      { dispatch },
      { workspace_id, name, pages, act_as, set_current = true }
    ) {
      const res = await client.post(
        `/${workspace_id}/collections/`,
        {
          name,
          pages
        },
        {
          params: {
            act_as
          }
        }
      )
      const collection = getResponseData(res)
      if (set_current) dispatch('setCurrentCollection', collection)
      return collection
    },
    async duplicateCollection(
      { dispatch },
      { workspace_id, collection_id, act_as, set_current = false }
    ) {
      const res = await client.post(
        `/${workspace_id}/collections/`,
        {
          collection_id
        },
        {
          params: {
            act_as
          }
        }
      )
      const collection = getResponseData(res)
      if (set_current) dispatch('setCurrentCollection', collection)
      return collection
    },
    async editCollection(
      { dispatch },
      { workspace_id, id, name, pages, act_as, set_current = true }
    ) {
      const res = await client.patch(
        `/${workspace_id}/collections/${id}/`,
        {
          name,
          pages
        },
        {
          params: {
            act_as
          }
        }
      )
      const collection = getResponseData(res)
      if (set_current) dispatch('setCurrentCollection', collection)
      return collection
    },
    async deleteCollection(
      { dispatch },
      { workspace_id, id, act_as, set_current = false }
    ) {
      const res = await client.delete(`/${workspace_id}/collections/${id}/`, {
        params: {
          act_as
        }
      })
      if (set_current) {
        dispatch('clearCurrentCollection')
        dispatch('resetSlides')
      }
      return getResponseData(res)
    }
  }
}
