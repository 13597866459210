


















import {
  WORKSPACE_ROLES,
  possibleRoleSelection,
  roleDescriptions
} from '@c/models/Workspace'
import DropdownSelectWithDescription from '@c/shared/molecules/inputs/DropdownSelectWithDescription.vue'
import Vue from 'vue'

interface Option {
  key: string
  label: string
  description: string
}

export default Vue.extend({
  name: 'WorkspaceRoleSelect',
  components: { DropdownSelectWithDescription },
  props: {
    value: {
      type: String as () => WORKSPACE_ROLES,
      default: undefined
    },
    currentUserRole: {
      type: String as () => WORKSPACE_ROLES,
      default: WORKSPACE_ROLES.Member
    }
  },
  computed: {
    options(): Option[] {
      return (possibleRoleSelection[
        this.currentUserRole
      ] as WORKSPACE_ROLES[]).map(
        (key: WORKSPACE_ROLES) =>
          ({
            key,
            label: key,
            description: roleDescriptions[key]
          } as Option)
      )
    }
  }
})
