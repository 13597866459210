export default {
  state: {
    selectedSlides: []
  },
  getters: {
    selectedSlides: (state) => state.selectedSlides
  },
  mutations: {
    setSelectedSlides(state, slides) {
      state.selectedSlides = slides
    },
    addSlide(state, slideData) {
      state.selectedSlides.push(slideData)
    },
    deleteSlide(state, idx) {
      state.selectedSlides.splice(idx, 1)
    },
    moveSlide(state, { oldIndex, newIndex }) {
      if (oldIndex !== -1 && newIndex !== oldIndex) {
        const element = state.selectedSlides.splice(oldIndex, 1)[0]
        state.selectedSlides.splice(newIndex, 0, element)
      }
    },
    resetSlides(state) {
      state.selectedSlides = []
    }
  },
  actions: {
    setSelectedSlides({ commit }, slides) {
      commit('setSelectedSlides', slides)
    },
    addSlide({ commit }, slideData) {
      commit('addSlide', slideData)
      commit('addPage', slideData)
    },
    deleteSlide({ state, commit }, uuid) {
      const idx = state.selectedSlides.findIndex((el) => el.uuid === uuid)
      commit('deleteSlide', idx)
      commit('deletePage', idx)
    },
    resetSlides({ commit }) {
      commit('resetSlides')
      commit('clearPages')
    },
    moveSlide({ state, commit }, { uuid, newIndex }) {
      const oldIndex = state.selectedSlides.findIndex((el) => el.uuid === uuid)
      commit('moveSlide', { oldIndex, newIndex })
      commit('movePage', { oldIndex, newIndex })
    }
  }
}
