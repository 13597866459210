<template>
  <div class="intersections">
    <h2 v-if="showHeader" class="modal-subheader">Similar search results</h2>
    <div class="intersections-documents">
      <div
        v-for="(int, index) in intersectionsToShow"
        :key="int.resource.uuid"
        class="intersections-document"
        @mouseenter="hover = int.resource.uuid"
        @mouseleave="hover = ''"
      >
        <div class="intersections-document-header">
          <DocumentTag :mimetype="int.resource.integrationfile.mimetype" />
          <HighlightedTextComponent
            class="intersections-document-header-title"
            :split-text="int.resource.title"
            :base-class="titleClass"
            :custom-highlight="{}"
            @click.native="goToIntersectedResource(int, index + 1)"
          />
          <p
            v-if="getIntersectionTypeText(int) !== ''"
            class="intersections-document-header-count"
          >
            {{ getIntersectionTypeText(int) }}
          </p>
          <UploadDownloadDropdown
            v-if="downloadFilesEnabled"
            :props-call="() => getDownloadProps(int.resource)"
          />
          <div
            v-if="
              highlightsEnabled &&
                (canWriteLabels || highlighted.includes(int.resource.uuid))
            "
            class="intersections-document-header-highlight"
            :class="{ clickable: canWriteLabels }"
            @mouseenter="highlightHovered = int.resource.uuid"
            @mouseleave="highlightHovered = ''"
          >
            <img
              :src="
                require(`@/assets/icons/star${
                  highlighted.includes(int.resource.uuid) ? '-filled' : ''
                }.svg`)
              "
              class="intersections-document-header-highlight-icon"
              alt=""
              @click="toggleHighlight(int.resource.uuid)"
            />
            <HighlightHoverInfo
              v-if="highlighted.includes(int.resource.uuid)"
              :highlight="highlightValues[int.resource.uuid]"
              :hovered="highlightHovered === int.resource.uuid"
            />
          </div>
          <QualityLabels
            :active-label="int.resource.label"
            :source-id="int.resource.uuid"
            :small-labels="true"
            :bordered-icons="false"
          />
        </div>
        <ResourceInfo
          :resource-integration="int.resource.integrationfile"
          :hover="hover === int.resource.uuid"
          @on-path-click="pathClick(int.resource.trace_id, index)"
          v-on="$listeners"
        />
      </div>
      <div
        v-if="showShowMore"
        class="link bold blue intersections-showmore"
        @click="showMore = !showMore"
      >
        {{ showMore ? 'Show less' : 'Show more' }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  eventContext,
  sendFileClickThroughEvent,
  sendFolderClickThroughEvent
} from '@/services/feedbackService'
import {
  highlightResource,
  removeHighlightFromResource
} from '@/services/highlightService'
import { getDocumentDownloadLink } from '@/services/presentationService'
import HighlightHoverInfo from '@/views-v2/home/highlight/HighlightHoverInfo.vue'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import { INTERSECTION_TYPES } from '@c/models/Resource'
import HighlightedTextComponent from '@c/shared/molecules/structural/blocks/HighlightedTextComponent.vue'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { isEmpty } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import QualityLabels from './QualityLabels.vue'
import ResourceInfo from './ResourceInfo.vue'

export default {
  name: 'ResourceIntersections',
  components: {
    DocumentTag,
    HighlightedTextComponent,
    ResourceInfo,
    HighlightHoverInfo,
    QualityLabels,
    UploadDownloadDropdown
  },
  props: {
    similarPages: {
      type: Array,
      default: () => []
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showMore: false,
      hover: '',
      highlighted: [],
      highlightValues: {},
      highlightHovered: ''
    }
  },
  computed: {
    ...mapState({
      workspaces: state => state.workspaces.workspaces
    }),
    ...mapGetters([
      'lastSearchId',
      'downloadFilesEnabled',
      'highlightsEnabled',
      'canWriteLabels'
    ]),
    showShowMore() {
      return this.similarPages.length > 3
    },
    intersectionsToShow() {
      return this.showMore
        ? this.sortedSimilarPages
        : this.sortedSimilarPages.slice(0, 3)
    },
    titleClass() {
      return {
        blue: true,
        header: true,
        bold: true,
        'mb-2': true,
        'cursor-pointer': true,
        'no-select': true
      }
    },
    sortedSimilarPages() {
      return this.similarPages.slice().sort((a, b) => {
        let aUnix = new Date(
          new Date(
            a.resource.integrationfile?.external_created_date || new Date()
          ).toDateString()
        ).getTime()
        let bUnix = new Date(
          new Date(
            b.resource.integrationfile?.external_created_date || new Date()
          ).toDateString()
        ).getTime()
        if (bUnix - aUnix === 0) {
          aUnix = new Date(
            new Date(
              a.resource.integrationfile?.external_modified_date || new Date()
            ).toDateString()
          ).getTime()
          bUnix = new Date(
            new Date(
              b.resource.integrationfile?.external_modified_date || new Date()
            ).toDateString()
          ).getTime()
        }
        return bUnix - aUnix
      })
    }
  },
  mounted() {
    this.highlighted = this.similarPages
      .filter(el => !isEmpty(el.resource.highlight))
      .map(el => el.resource.uuid)
    this.highlightValues = this.similarPages.reduce((acc, el) => {
      if (!isEmpty(el.resource.highlight)) {
        acc[el.resource.uuid] = el.resource.highlight
      }
      return acc
    }, {})
  },
  methods: {
    getIntersectionTypeText(similarPage) {
      if (similarPage.intersectionType === INTERSECTION_TYPES.duplicate)
        return 'Exact copy'
      const resourceType = similarPage.resource.type
      if (!similarPage.duplicatedSubresourcesLength) return ''
      return `${similarPage.duplicatedSubresourcesLength} ${
        resourceType === 'presentation' ? 'slide' : 'page'
      }${similarPage.duplicatedSubresourcesLength === 1 ? '' : 's'} in common`
    },
    async goToIntersectedResource(intersection, rank) {
      sendFileClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: intersection.resource.trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        comment: 'from intersected resources in modal',
        rank
      })
      const win = window.open(intersection.resource.url, '_blank')
      win?.focus()
    },
    async pathClick(resource_trace_id, rank) {
      sendFolderClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.resource,
        rank
      })
    },
    async getDownloadProps(resource) {
      return getDocumentDownloadLink(
        this.$route.params.workspace_id,
        resource.uuid,
        this.$route.query?.act_as
      )
    },
    async toggleHighlight(uuid) {
      if (!this.canWriteLabels) return
      try {
        if (this.highlighted.includes(uuid)) {
          await removeHighlightFromResource(
            this.$route.params.workspace_id,
            uuid,
            this.$route.query.act_as
          )
          this.highlighted = this.highlighted.filter(h => h !== uuid)
          delete this.highlightValues[uuid]
        } else {
          this.highlightValues[uuid] = await highlightResource(
            this.$route.params.workspace_id,
            uuid,
            this.$route.query.act_as
          )
          this.highlighted.push(uuid)
        }
      } catch (e) {
        this.$console.debug(
          'Something went wrong when when changing highlight',
          e
        )
        this.$toast.error(e, 'changing the highlight')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-subheader {
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
}

.intersections {
  &-documents {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }
  &-document {
    &-header {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;

      &-title {
        width: fit-content;
        border-bottom: 2px solid transparent;
        margin-top: 2px;

        &:hover {
          border-bottom: 2px solid $primary;
        }
      }

      &-count {
        height: 1.75rem;
        padding: 0 1rem;
        box-sizing: border-box;
        width: fit-content;
        border-radius: 999rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebf1ff;
        color: $primary;
        font-weight: 700;
        white-space: nowrap;
        margin-left: 0.5rem;
      }

      &-download {
        padding: 0.5rem;
        box-sizing: initial;
        height: 1.2rem;
        width: 1.2rem;
        cursor: pointer;
        border-radius: 999rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(#000, 6%);
        }

        &-icon {
          height: 1.2rem;
        }
      }

      &-highlight {
        position: relative;
        padding: 0.5rem;
        box-sizing: initial;
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 999rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.clickable {
          cursor: pointer;

          &:hover {
            background: rgba(#000, 6%);
          }
        }

        &-icon {
          height: 1.2rem;
        }
      }
    }
  }

  &-showmore {
    align-self: center;
  }
}
</style>
