











import EmailInvitesComponent from '@c/features/invite-modal/organisms/EmailInvitesComponent.vue'
import Workspace, { WORKSPACE_ROLES } from '@c/models/Workspace'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'InviteTab',
  components: {
    EmailInvitesComponent
  },
  props: {
    workspace: {
      type: Object as PropType<Workspace>,
      required: true
    },

    activeIntegrations: {
      type: Array,
      default: function () {
        return []
      }
    },
    invites: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentUserRole: {
      type: String as PropType<WORKSPACE_ROLES | undefined>,
      required: true
    },
    invitesLoading: {
      type: Boolean,
      default: false
    }
  }
})
