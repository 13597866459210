<template>
  <div
    class="
      columns
      is-mobile
      no-margin
      is-vcentered
      combined-input
      pa-3
      border
      element
    "
  >
    <!-- Left is expanded, right is not! -->
    <div class="column no-padding" :class="{ 'is-narrow': !leftExpand }">
      <slot name="left" />
    </div>
    <div class="column no-padding is-narrow">
      <div class="divider semi-hard no-margin" style="height: 28px" />
    </div>
    <div class="column no-padding" :class="{ 'is-narrow': !rightExpand }">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitInput',
  props: {
    leftExpand: {
      type: Boolean,
      default: true
    },
    rightExpand: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.combined-input {
  border: 1px solid #dfdfdf;
  background: white;
}
</style>
