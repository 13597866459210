import { addWorkspaceGuard } from './router-guards/workspace-gaurd'
import { addInviteGuard } from '@/router/router-guards/invite-guard'
import { addInvitationLogoutGuard } from '@/router/router-guards/invite-another-guard'
import { addImpersonationGuard } from './router-guards/impersonation-guard'
import { addLoadingGuard } from '@/router/router-guards/loading-guard'
import { addAuthGuard } from '@/router/router-guards/auth-guard'
import { addSkipGuardsGuard } from '@/router/router-guards/skip-guard'
import { addRedirectGuard } from '@/router/router-guards/redirect-guard'
import { addSearchTransformer } from '@/router/router-guards/search-transformer'

export default function addRouterGuards(router) {
  addWorkspaceGuard(router)
  addInviteGuard(router)
  addInvitationLogoutGuard(router)
  addImpersonationGuard(router)
  addLoadingGuard(router)
  addAuthGuard(router)
  addSkipGuardsGuard(router)
  addRedirectGuard(router)
  addSearchTransformer(router)
}
