var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ContentWithHeader",
        {
          attrs: {
            title: "Invite by email",
            subtitle:
              "Invite people to this workspace by email address. We will send them an email with the invitation."
          }
        },
        [
          _vm._l(_vm.emailsWithAccessType, function(emailObj, index) {
            return _c("EmailInputWithAccessType", {
              key: index,
              staticClass: "pv-2",
              attrs: {
                "no-access-type": "",
                "access-type": (
                  emailObj || { accessType: _vm.defaultAccessType }
                ).accessType,
                email: (emailObj || { email: undefined }).email,
                "current-user-role": _vm.currentUserRole
              },
              on: {
                inputAccessType: function(aType) {
                  return _vm.inputAccessType(aType, index)
                },
                inputEmail: function(email) {
                  return _vm.inputEmail(email, index)
                }
              }
            })
          }),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: {
                type: "is-primary",
                disabled:
                  _vm.emailsWithAccessType.filter(function(x) {
                    return x
                  }).length === 0 || _vm.loading
              },
              on: { click: _vm.sendInvites }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.loading ? "Sending invites..." : "Send invites") +
                  " "
              )
            ]
          )
        ],
        2
      ),
      _vm.invites && _vm.invites.length > 0
        ? _c(
            "div",
            { staticClass: "pending" },
            [
              _c("p", { staticClass: "pending-header" }, [
                _vm._v("Pending invites")
              ]),
              _c(
                "PendingEmailInvites",
                _vm._g({ attrs: { invites: _vm.invites } }, _vm.$listeners)
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }