




import ConnectToIntegration from '@c/features/connect-integration-modal/pages/ConnectToIntegration.vue'
import { Integration } from '@c/integrations'
import Workspace from '@c/models/Workspace'
import Vue from 'vue'
import { mapActions } from 'vuex'
export default Vue.extend({
  name: 'HydratedConnectToIntegration',
  components: { ConnectToIntegration },
  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    },
    integration: {
      type: Object as () => Integration,
      required: true
    }
  },
  methods: {
    ...mapActions(['authenticateIntegration']),
    async saveAPIKeys({ token, ...metadata }) {
      this.$console.debug('Sending api token', token, metadata)
      try {
        await this.authenticateIntegration({
          workspace_id: this.workspace.uuid,
          integration: this.integration.key,
          ...(this.integration.setup.usesAPIKey ||
          this.integration.setup.usesCookie
            ? { api_key: token }
            : { oauth: { code: token } }),
          meta: { ...metadata }
        } as any)
        this.$toast.success(
          'Integration coupled',
          `Your integration has been coupled, you can now set up syncs for ${this.integration.title}.`
        )
        this.$emit('close')
        this.$modals.workspace.openSettings(this, this.workspace)
      } catch (e) {
        this.$toast.error(e, 'coupling integration')
      }
    }
  }
})
