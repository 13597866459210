var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchbar-wrapper" },
    [
      _vm.canWriteOri || _vm.canReadOri || _vm.canUseSearch
        ? _c(
            "div",
            {
              staticClass: "searchbar",
              class: {
                focused: _vm.focused,
                nolocation: _vm.dropdownOptions.length <= 1
              },
              style: { background: _vm.background }
            },
            [
              _c(
                "div",
                { staticClass: "searchbar-trigger", on: { click: _vm.focus } },
                [
                  _c("img", {
                    staticClass: "searchbar-icon",
                    attrs: {
                      src: require("@/assets/icons/search.svg"),
                      alt: ""
                    }
                  }),
                  _c("input", {
                    ref: "searchinput",
                    staticClass: "searchbar-input",
                    attrs: {
                      id: "searchinput",
                      placeholder: _vm.placeholder,
                      type: "text",
                      name: ""
                    },
                    domProps: {
                      value:
                        _vm.currentItem === -1
                          ? _vm.value
                          : _vm.visibleHistory[_vm.currentItem].name
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      },
                      focus: _vm.handleFocus,
                      blur: function() {
                        return (_vm.focused = false)
                      },
                      keyup: [
                        _vm.handleKeyUp,
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return function() {
                            return _vm.search("input")
                          }.apply(null, arguments)
                        }
                      ],
                      input: _vm.handleInput
                    }
                  }),
                  _vm.value
                    ? _c("Button", {
                        attrs: { text: "Clear", type: "light", size: "xs" },
                        on: { click: _vm.handleClear }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.dropdownOptions.length > 1
                ? _c("Dropdown", {
                    ref: "locationdropdown",
                    attrs: {
                      items: _vm.dropdownOptions,
                      "full-width": true,
                      "position-fixed": true,
                      "append-to-body": true
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function() {
                            return [
                              _vm.currentLocation
                                ? _c(
                                    "div",
                                    { staticClass: "searchbar-location" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "searchbar-location-text"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.currentLocation.text) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("img", {
                                        staticClass:
                                          "searchbar-location-chevron",
                                        attrs: {
                                          src: require("@/assets/icons/chevron-down-medium.svg"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _c("div")
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1316504616
                    )
                  })
                : _vm._e(),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showHistory
                  ? _c(
                      "div",
                      { staticClass: "searchbar-history" },
                      _vm._l(_vm.visibleHistory, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "searchbar-history-item",
                            class: { active: index === _vm.currentItem },
                            on: {
                              click: function() {
                                return _vm.selectHistoryItem(item)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "searchbar-history-item-content" },
                              [
                                _c("img", {
                                  staticClass: "searchbar-history-item-icon",
                                  attrs: {
                                    src: require("@/assets/icons/" +
                                      (item.type === "history"
                                        ? "history"
                                        : "search") +
                                      ".svg"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "searchbar-history-item-text"
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]
                            ),
                            item.type === "history"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "searchbar-history-item-remove",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return function() {
                                          return _vm.removeHistoryItem(item)
                                        }.apply(null, arguments)
                                      },
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  [_vm._v(" Remove ")]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.searchLocation === "content" ? _c("SearchTips") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }