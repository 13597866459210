var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "summary" }, [
    _c(
      "div",
      { staticClass: "summary-text" },
      [
        _c("Tag", { attrs: { text: "Summary", type: "white" } }),
        _vm.summaryLoading
          ? _c("LoadingDots")
          : _c("MarkdownEdit", {
              ref: "summarymarkdown",
              attrs: { value: _vm.summary }
            }),
        _vm.summary
          ? _c("Button", {
              staticClass: "summary-text-copy",
              attrs: {
                text: "Copy to clipboard",
                icon: "copy-medium",
                "icon-left": true,
                type: "light"
              },
              on: { click: _vm.copySummary }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }