import { permissionsToRole } from '@c/models/utils'
import WorkspaceMember, {
  WORKSPACE_MEMBER_STATUS
} from '@c/models/WorkspaceMember'
import { flatten } from 'lodash-es'

export enum PERMISSION {
  WORKSPACE_WRITE = 'workspace.write',
  WORKSPACE_WRITE_MEMBERS = 'workspace.write.members',
  WORKSPACE_WRITE_INVITE_MEMBERS = 'workspace.write.members.invite',
  WORKSPACE_LABELS_WRITE = 'workspace.labels.write',
  WORKSPACE_RESOURCE_SEARCH = 'workspace.resources.search',
  WORKSPACE_STORIES_WRITE = 'workspace.stories.write',
  WORKSPACE_CHATS_WRITE = 'workspace.chats.write',
  WORKSPACE_ORI_WRITE = 'workspace.ori.write',
  WORKSPACE_ORI_READ = 'workspace.ori.read'
}

export const FLAT_PERMISSION_MAP: { [key: string]: PERMISSION[] } = {
  [PERMISSION.WORKSPACE_WRITE]: [
    PERMISSION.WORKSPACE_WRITE,
    PERMISSION.WORKSPACE_WRITE_INVITE_MEMBERS,
    PERMISSION.WORKSPACE_WRITE_MEMBERS,
    PERMISSION.WORKSPACE_LABELS_WRITE
  ],
  [PERMISSION.WORKSPACE_WRITE_MEMBERS]: [
    PERMISSION.WORKSPACE_WRITE_MEMBERS,
    PERMISSION.WORKSPACE_WRITE_INVITE_MEMBERS
  ],
  [PERMISSION.WORKSPACE_WRITE_INVITE_MEMBERS]: [
    PERMISSION.WORKSPACE_WRITE_INVITE_MEMBERS
  ],
  [PERMISSION.WORKSPACE_LABELS_WRITE]: [PERMISSION.WORKSPACE_LABELS_WRITE]
}

export function flattenPermissions(permissions: PERMISSION[]): Set<PERMISSION> {
  return new Set(
    flatten(permissions.map((x: PERMISSION) => FLAT_PERMISSION_MAP[x]))
  )
}

export default interface Workspace {
  uuid: string
  name: string
  description: string
  image: string
  owner: WorkspaceMember
  owner_id: string
  member_count: number
  resource_count: number
  is_owner: boolean
  is_admin: boolean
  member_status: WORKSPACE_MEMBER_STATUS
}

export enum MEMBERSHIP_TYPES {
  INVITED = 'INVITED',
  ACCESS_REQUESTED = 'ACCESS_REQUESTED',
  ACTIVE = 'ACTIVE'
}

export enum WORKSPACE_ROLES {
  Admin = 'Admin',
  Curator = 'Curator',
  Member = 'Member',
  Unknown = 'Custom'
}

export const roleDescriptions = {
  [WORKSPACE_ROLES.Admin]:
    'Can manage the workspace, add/remove content, members and apps.',
  [WORKSPACE_ROLES.Curator]:
    'Can label content to promote or demote the use of a certain document.',
  [WORKSPACE_ROLES.Member]:
    'Can access the workspace and contribute content for everyone to see.',
  [WORKSPACE_ROLES.Unknown]: '-'
}

export const rolePermissions = {
  [WORKSPACE_ROLES.Admin]: [
    PERMISSION.WORKSPACE_WRITE,
    PERMISSION.WORKSPACE_LABELS_WRITE,
    PERMISSION.WORKSPACE_RESOURCE_SEARCH
  ],
  [WORKSPACE_ROLES.Curator]: [
    PERMISSION.WORKSPACE_LABELS_WRITE,
    PERMISSION.WORKSPACE_RESOURCE_SEARCH
  ],
  [WORKSPACE_ROLES.Member]: [PERMISSION.WORKSPACE_RESOURCE_SEARCH],
  [WORKSPACE_ROLES.Unknown]: []
}

const roleLadder = [
  WORKSPACE_ROLES.Admin,
  WORKSPACE_ROLES.Curator,
  WORKSPACE_ROLES.Member,
  WORKSPACE_ROLES.Unknown
]

export function workspacePermissionsToRole(
  permissions: string[]
): WORKSPACE_ROLES {
  return permissionsToRole<WORKSPACE_ROLES>(
    permissions,
    roleLadder,
    rolePermissions
  )
}

export const possibleRoleSelection = {
  [WORKSPACE_ROLES.Admin]: [
    WORKSPACE_ROLES.Admin,
    WORKSPACE_ROLES.Curator,
    WORKSPACE_ROLES.Member
  ],
  [WORKSPACE_ROLES.Curator]: [WORKSPACE_ROLES.Curator],
  [WORKSPACE_ROLES.Member]: [],
  [WORKSPACE_ROLES.Unknown]: []
}
