var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.clickable ? "router-link" : "div",
    {
      tag: "component",
      staticClass: "reference-card-wrapper",
      attrs: { to: _vm.route }
    },
    [
      _c(
        "div",
        { staticClass: "reference-card", class: { clickable: _vm.clickable } },
        [
          _c("div", { staticClass: "reference-card-left" }, [
            _vm.image && !_vm.imageErrored
              ? _c("img", {
                  staticClass: "reference-card-image",
                  attrs: { src: _vm.image, alt: "" }
                })
              : _c(
                  "div",
                  {
                    staticClass: "reference-card-image",
                    style: { background: _vm.$umodel.user_color(_vm.reference) }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$umodel.initials(_vm.reference.name)) +
                        " "
                    )
                  ]
                ),
            _c(
              "div",
              { staticClass: "reference-card-content" },
              [
                _c("p", { staticClass: "reference-card-name" }, [
                  _vm._v(_vm._s(_vm.reference.name))
                ]),
                _vm.showAttributes && _vm.attributes.length
                  ? _c("ORITagEdit", {
                      attrs: {
                        selected: _vm.attributes,
                        editable: false,
                        overflowing: true,
                        "tag-size": "xxs"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._t("right")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }