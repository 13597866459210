

















import IconHeaderModal from '../../structural/modals/IconHeaderModal.vue'
import Vue, { PropType } from 'vue'
import Workspace from '@c/models/Workspace'
export default Vue.extend({
  name: 'WorkspaceHeaderModal',
  components: { IconHeaderModal },
  props: {
    workspace: {
      type: Object as PropType<Workspace>,
      required: true
    },
    titlePrefix: {
      type: String,
      default: ''
    },
    noCloseIcon: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: undefined
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
