














import { Integration } from '@c/integrations'
import ModalActionFooter from '@c/shared/molecules/structural/blocks/ModalActionFooter.vue'
import { buildOauthUrl, getNonce } from '@c/util'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ConnectRedirect',
  components: { ModalActionFooter },
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    }
  },
  computed: {
    ...mapGetters(['workspaces']),
    workspace(): any {
      return this.workspaces.find(
        (w) => w.uuid === this.$route.params.workspace_id
      )
    }
  },
  methods: {
    goToIntegration() {
      const nonce = getNonce(25)
      const url = buildOauthUrl(this.integration.oauthBaseURL, {
        ...this.integration.oauthQueryParams,
        state: `${nonce}%workspace`
      })
      localStorage.setItem('integrationNonce', nonce)
      window.open(url, '_blank')
      let bc = new BroadcastChannel('uman_integration_auth')
      bc.onmessage = this.handleIntegrationResponse
    },
    handleIntegrationResponse(res: any) {
      if (res && res.data === 'done') {
        this.$toast.success(
          `${this.integration.title} authenticated`,
          `We successfully connected your ${this.integration.title} account in the ${this.workspace.name} workspace. You can now choose files and folder to sync in this workspace.`
        )
        this.$emit('back')
      } else {
        this.$toast.danger(
          'An error occurred',
          res && res.data === 'error'
            ? 'We could not connect this workspace to your external app. Please try again.'
            : res.data
        )
      }
    }
  }
})
