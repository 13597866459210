var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    columns\n    is-mobile\n    no-margin\n    is-vcentered\n    combined-input\n    pa-3\n    border\n    element\n  "
    },
    [
      _c(
        "div",
        {
          staticClass: "column no-padding",
          class: { "is-narrow": !_vm.leftExpand }
        },
        [_vm._t("left")],
        2
      ),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "column no-padding",
          class: { "is-narrow": !_vm.rightExpand }
        },
        [_vm._t("right")],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column no-padding is-narrow" }, [
      _c("div", {
        staticClass: "divider semi-hard no-margin",
        staticStyle: { height: "28px" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }