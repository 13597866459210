var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tips" }, [
    _c("img", {
      staticClass: "tips-img",
      attrs: { src: require("@/assets/help-bubble.svg"), alt: "tips" },
      on: {
        mouseenter: function($event) {
          _vm.showTips = true
        },
        mouseleave: function($event) {
          _vm.showTips = false
        }
      }
    }),
    _vm.showTips
      ? _c("div", { staticClass: "tips-container" }, [
          _c("p", { staticClass: "tips-container-header" }, [
            _vm._v("Search by")
          ]),
          _c("div", { staticClass: "tips-container-cols" }, [
            _c(
              "div",
              { staticClass: "tips-container-cols-col" },
              _vm._l(_vm.tips, function(tip) {
                return _c(
                  "div",
                  {
                    key: tip.instruction,
                    staticClass: "tips-container-cols-col-entry body"
                  },
                  [_vm._v(" " + _vm._s(tip.instruction) + ": ")]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "tips-container-cols-col" },
              _vm._l(_vm.tips, function(tip) {
                return _c(
                  "div",
                  {
                    key: tip.instruction,
                    staticClass: "tips-container-cols-col-entry"
                  },
                  [_vm._v(" " + _vm._s(tip.example) + " ")]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }