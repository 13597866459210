export function wrapView(component) {
  return () => ({
    component: component,
    timeout: 10000
  })
}

export function wrapViewCodeSplit(component) {
  return () => ({
    component: () => component,
    timeout: 10000
  })
}
