<template>
  <DefaultModal
    :no-padding="noPadding"
    :with-close-icon="withCloseIcon"
    :loading="loading"
    v-on="$listeners"
  >
    <template v-slot:icon>
      <div :class="{ 'mt-5': noPadding, 'ml-5': noPadding }" class="fit">
        <img
          v-if="icon"
          :class="iconClasses"
          class="vertical-centered"
          :src="icon"
        />
      </div>
    </template>
    <template v-slot:title>
      <slot name="title">
        <p class="header sm bold modal-title">{{ title }}</p>
        <p v-if="subtitle" class="body xxs body-text modal-subtitle">
          {{ subtitle }}
        </p>
      </slot>
    </template>
    <template v-slot:title-right>
      <slot name="title-right" />
    </template>
    <slot />
  </DefaultModal>
</template>

<script>
import DefaultModal from './DefaultModal'

export default {
  name: 'IconHeaderModal',
  components: { DefaultModal },
  props: {
    icon: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: undefined
    },
    withCloseIcon: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    iconClasses: {
      type: Array,
      default: () => ['u-icon', 'large', 'square', 'is-square']
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.modal-subtitle {
  line-height: 0.8rem;
}
</style>
