var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SplitInput", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function() {
              return [
                _c("b-input", {
                  attrs: {
                    "custom-class": "borderless",
                    placeholder: "name@company.com",
                    value: _vm.email
                  },
                  on: {
                    input: function(val) {
                      return _vm.$emit("inputEmail", val)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "right",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "mh-3" },
                  [
                    _c("WorkspaceRoleSelect", {
                      attrs: {
                        position: "is-top-left",
                        value: _vm.accessType,
                        "current-user-role": _vm.currentUserRole
                      },
                      on: {
                        input: function(val) {
                          return _vm.$emit("inputAccessType", val)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }