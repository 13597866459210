import { Paginator, getClient, getResponseData } from '../../../store/src/axios'

const workspaceClient = getClient('/workspaces')

export async function getKeyContentTemplates({ workspace_id }) {
  const res = await workspaceClient.get(`/${workspace_id}/templates/`)
  return getResponseData(res)
}

export async function getKeyContentTemplatePaginator({
  workspace_id,
  template_id
}) {
  return new Paginator(
    workspaceClient,
    await workspaceClient.get(`/${workspace_id}/templates/${template_id}/`)
  )
}

export async function createKeyContentTemplate({
  workspace_id,
  output_type,
  name,
  description,
  mimetype,
  content_length,
  status,
  template_id
}) {
  const res = await workspaceClient.post(`/${workspace_id}/templates/`, {
    ...(output_type ? { output_type } : {}),
    ...(name ? { name } : {}),
    ...(description ? { description } : {}),
    ...(mimetype ? { mimetype } : {}),
    ...(content_length ? { content_length } : {}),
    ...(status ? { status } : {}),
    ...(template_id ? { template_id } : {})
  })
  return getResponseData(res)
}

export async function editKeyContentTemplate({
  workspace_id,
  template_id,
  name,
  description,
  status,
  review_period
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/templates/${template_id}/`,
    {
      name,
      description,
      status,
      review_period
    }
  )
  return getResponseData(res)
}

export async function removeKeyContentTemplate({ workspace_id, template_id }) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/templates/${template_id}/`
  )
  return getResponseData(res)
}

export async function parseKeyContentTemplateInput({
  workspace_id,
  template_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/templates/${template_id}/parse/`
  )
  return getResponseData(res)
}

export async function getKeyContentTemplateSlides({
  workspace_id,
  template_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/templates/${template_id}/slides`
  )
  return getResponseData(res)
}

export async function editKeyContentTemplateShape({
  workspace_id,
  template_id,
  slide_id,
  shape_id,
  prompt,
  image
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/templates/${template_id}/slides/${slide_id}/shapes/${shape_id}/`,
    {
      ...((prompt || prompt === '') && { prompt }),
      ...((image || image === '') && { image })
    }
  )
  return getResponseData(res)
}

export async function testKeyContentTemplate({
  workspace_id,
  template_id,
  output_type,
  output_id
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/templates/${template_id}/test/`,
    {
      output_type,
      output_id
    }
  )
  return getResponseData(res)
}

export async function testKeyContentTemplateShape({
  workspace_id,
  template_id,
  slide_id,
  shape_id,
  output_type,
  output_id
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/templates/${template_id}/slides/${slide_id}/shapes/${shape_id}/test/`,
    {
      output_type,
      output_id
    }
  )
  return getResponseData(res)
}

export async function createKeyContentTemplateBlock({
  workspace_id,
  template_id,
  slide_index,
  position,
  output_type,
  selections
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/templates/${template_id}/blocks/`,
    {
      slide_index,
      position,
      selections,
      output_type
    }
  )
  return getResponseData(res)
}

export async function editKeyContentTemplateBlock({
  workspace_id,
  template_id,
  block_id,
  slide_index,
  position,
  // output_type,
  selections
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/templates/${template_id}/blocks/${block_id}/`,
    {
      slide_index,
      position,
      // output_type,
      selections
    }
  )
  return getResponseData(res)
}

export async function removeKeyContentTemplateBlock({
  workspace_id,
  template_id,
  block_id
}) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/templates/${template_id}/blocks/${block_id}/`
  )
  return getResponseData(res)
}

export async function getKeyContentTemplateResources({
  workspace_id,
  template_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/templates/${template_id}/files/`
  )
  return getResponseData(res)
}

export async function editKeyContentTemplateResource({
  workspace_id,
  template_id,
  ori_id,
  ori_type,
  status,
  act_as = undefined
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/${ori_type}/${ori_id}/templates/${template_id}/`,
    {
      status,
      act_as
    }
  )
  return getResponseData(res)
}

export async function getKeyContentTemplatePublishORIs({
  workspace_id,
  template_id,
  ori_type
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/templates/${template_id}/${ori_type}/`
  )
  return res.data
}

export async function setKeyContentTemplatePublishORIs({
  workspace_id,
  template_id,
  ori_type,
  filters,
  page_size
}) {
  const res = await workspaceClient.put(
    `/${workspace_id}/templates/${template_id}/${ori_type}/`,
    filters,
    {
      params: {
        page_size: page_size
      }
    }
  )
  return res.data
}
