












import { SettingsModalTab } from '@c/features/workspace-settings-modal/interfaces'
import SettingsModal from '@c/features/workspace-settings-modal/pages/SettingsModal.vue'
import Workspace from '@c/models/Workspace'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'HydratedWorkspaceSettingsModal',
  components: { SettingsModal },
  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    },
    initialTab: {
      type: [Number, String],
      default: SettingsModalTab.Members
    }
  },
  data() {
    return {
      loading: true,
      activeIntegrations: []
    }
  },
  created() {
    this.hydrate()
  },
  methods: {
    ...mapActions(['getIntegrationOAuthState', 'getIntegrations']),

    async hydrate() {
      await this.initIntegrations()
      this.loading = false
    },

    async initIntegrations() {
      this.activeIntegrations = await this.getIntegrations({
        workspace_id: this.workspace.uuid
      })
    },

    /** Add a state parameter to the OAuth url for CSRF protection */
    async onConnectClick(urlStr) {
      // get new state parameter from API
      const res = await this.getIntegrationOAuthState({
        workspace_id: this.workspace.uuid
      })
      const state = res.state

      // add state parameter
      const url = new URL(urlStr)
      url.searchParams.append('state', state)
      window.open(url.toString(), '_blank')
    },

    deleteIntegration(integration) {
      Vue.set(
        this,
        'activeIntegrations',
        this.activeIntegrations.filter((x) => x.id !== integration.id)
      )
    }
  }
})
