import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './modules/authentication'
import feedback from './modules/feedback'
import filters from './modules/filters'
import integrations from './modules/integrations'

import labels from './modules/labels'
import resources from './modules/resources'
import slideCreator from './modules/slideCreator'
import slideDownload from './modules/slideDownload'
import users from './modules/users'
import workspaces from './modules/workspaces'
import offerings from './modules/offerings'
import references from './modules/references'
import inspiration from './modules/inspiration'
import collections from './modules/collections'
import templates from './modules/templates'
import settings from './modules/settings'
import resourceModals from './modules/resourceModals'
import Mutations from './mutation-types'

Vue.use(Vuex)

export * from '../sensors/sensors'
export { default as Mutations } from './mutation-types'

export function createStore(modules, plugins) {
  return new Vuex.Store({
    modules: {
      authentication,
      resources,
      workspaces,
      users,
      offerings,
      references,
      inspiration,
      collections,
      templates,
      settings,
      feedback,
      integrations,
      filters,
      slideCreator,
      labels,
      slideDownload,
      resourceModals,
      ...(modules || {})
    },

    state: {
      clientName: ''
    },

    mutations: {
      [Mutations.SET_CLIENT_NAME](state, clientName) {
        state.clientName = clientName
      }
    },
    plugins: plugins || []
  })
}

let defaultStore = createStore()
export const defaultStoreMap = { defaultStore }
export default defaultStoreMap.defaultStore
