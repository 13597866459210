var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "carousel-container" },
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _vm._l(_vm.showArrows ? ["left", "right"] : [], function(direction) {
        var _obj, _obj$1, _obj$2
        return _c(
          "div",
          {
            key: direction,
            staticClass: "arrow-position cursor-pointer",
            class:
              ((_obj = {}),
              (_obj["arrow-" + direction] = true),
              (_obj.hidden = direction === "left" ? _vm.isStart : _vm.isEnd),
              (_obj.vertical = _vm.vertical),
              _obj),
            attrs: { id: direction + "-chevron" },
            on: {
              click: function($event) {
                return _vm.scroll(direction === "left" ? -1 : 1)
              },
              mouseover: function($event) {
                _vm.arrowHovered[direction] = true
              },
              mouseleave: function($event) {
                _vm.arrowHovered[direction] = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "arrow-scrim",
                class:
                  ((_obj$1 = { vertical: _vm.vertical }),
                  (_obj$1["arrow-scrim-" + direction] = true),
                  _obj$1)
              },
              [
                _c("div", { staticClass: "arrow-scrim-container" }, [
                  _c("img", {
                    class:
                      ((_obj$2 = {
                        "arrow-scrim-img": true
                      }),
                      (_obj$2["arrow-scrim-dropshadow-" + direction] = !_vm
                        .arrowHovered[direction]),
                      _obj$2),
                    attrs: {
                      src: _vm.arrowHovered[direction]
                        ? _vm.RightArrowHover
                        : _vm.RightArrow,
                      alt: ">"
                    }
                  })
                ])
              ]
            )
          ]
        )
      }),
      _vm._l(
        _vm.dragging
          ? _vm.vertical
            ? ["top", "bottom"]
            : ["left", "right"]
          : [],
        function(side) {
          var _obj
          return _c("div", {
            key: "dragging-" + side,
            staticClass: "carousel-scroll-hover",
            class: ((_obj = {}), (_obj[side] = true), _obj)
          })
        }
      ),
      _c(
        "div",
        {
          ref: "items",
          class: {
            vertical: _vm.vertical,
            scrollable: _vm.showArrows,
            textual: _vm.textItems
          },
          attrs: { id: "items" },
          on: {
            touchdrag: function($event) {
              $event.preventDefault()
            },
            touchend: _vm.handleScrollEnd,
            wheel: _vm.handleScroll,
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                ref: _vm.itemId(item),
                refInFor: true,
                staticClass: "carousel-item",
                class: {
                  vertical: _vm.vertical,
                  draggable: _vm.draggable,
                  textual: _vm.textItems
                },
                on: {
                  mousedown: function(e) {
                    return _vm.startDrag(e, index)
                  },
                  click: function() {
                    return _vm.endDrag(false)
                  },
                  dragstart: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "carousel-item-dropzone-anchor",
                    class: {
                      hovered: _vm.dragging && index === _vm.dragOverIdx,
                      active: _vm.dragging,
                      vertical: _vm.vertical
                    },
                    style: _vm.anchorDimensions
                  },
                  [
                    _c("div", {
                      class: {
                        "carousel-item-dropzone": true,
                        vertical: _vm.vertical
                      },
                      style: _vm.dropzoneDimensions,
                      attrs: {
                        id: "carousel-dropzone-" + index + "-" + _vm.carouselKey
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  { ref: "slotitem", refInFor: true },
                  [_vm._t("default", null, { item: item, idx: index })],
                  2
                ),
                _vm.dragging && _vm.dragIdx === index
                  ? _c(
                      "div",
                      {
                        staticClass: "drag-preview",
                        attrs: { id: "preview-" + index },
                        on: {
                          mouseup: function($event) {
                            return _vm.endDrag()
                          },
                          mousemove: _vm.handleMouseMove
                        }
                      },
                      [
                        _vm.textItems
                          ? _c(
                              "p",
                              {
                                staticClass: "drag-preview-text",
                                style: { width: _vm.itemWidth + "px" }
                              },
                              [_vm._v(" " + _vm._s(_vm.itemText(item)) + " ")]
                            )
                          : _c("img", {
                              staticClass: "drag-preview-img",
                              style: { height: _vm.itemHeight + "px" },
                              attrs: { src: _vm.previewKey(item), alt: "" }
                            })
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm.dragging
            ? _c(
                "div",
                {
                  staticClass: "carousel-item-dropzone-anchor",
                  class: {
                    active: _vm.dragging,
                    hovered:
                      _vm.dragging && _vm.items.length === _vm.dragOverIdx,
                    vertical: _vm.vertical
                  },
                  style: _vm.anchorDimensions
                },
                [
                  _c("div", {
                    class: {
                      "carousel-item-dropzone": true,
                      vertical: _vm.vertical
                    },
                    style: _vm.dropzoneDimensions,
                    attrs: {
                      id:
                        "carousel-dropzone-" +
                        _vm.items.length +
                        "-" +
                        _vm.carouselKey
                    }
                  })
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }