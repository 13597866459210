





































import { Integration } from '@c/integrations'
import StatusOrb from '@c/library/StatusOrb.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'IntegrationRow',
  components: { StatusOrb },
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    },
    integrationObject: {
      type: Object,
      required: false,
      default: () => undefined
    }
  },
  computed: {
    status() {
      return this.integrationObject?.status || ''
    },
    statusColor() {
      if (!this.integrationObject) return ''
      const nonPending = {
        ok: 'green',
        error: 'red'
      } as any
      return nonPending[this.integrationObject.status] || 'orange'
    }
  }
})
