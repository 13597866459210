<template>
  <div class="invites-container">
    <Table
      v-if="invites && invites.length > 0"
      :headers="headers"
      :items="invites"
      :sortable="false"
    >
      <template #item="{ header, item }">
        <p v-if="header.id === 'email'" class="invites-item-email">
          {{ header.key(item) }}
        </p>
        <div v-else-if="header.id === 'invited_by'">
          <div v-if="header.key(item)" class="invites-item-inviter">
            <Avatar :user="item.sender.user" />
            {{ header.key(item) }}
          </div>
          <p v-else class="invites-item-inviter">unknown</p>
        </div>
        <div v-else-if="header.id === 'roles'" class="invites-item-roles">
          <RoleSelect
            :value="item.roles"
            fixed-width="12.5rem"
            @submit="roles => $emit('updateInvite', { invite: item, roles })"
          />
        </div>
        <div v-else-if="header.id === 'reminder'" class="invites-item-actions">
          <Button
            text="Send reminder"
            icon="mail"
            @click="roles => $emit('updateInvite', { invite: item, roles: item.roles, reminder: true })"
          />
        </div>
        <div v-else-if="header.id === 'actions'" class="invites-item-actions">
          <Dropdown
            :items="[
              {
                text: 'Revoke invite',
                icon: 'bin',
                filter: 'red',
                callback: () => $emit('deleteInvite', item.id)
              }
            ]"
          />
        </div>
      </template>
    </Table>
    <div v-else class="invites-empty">
      <p class="invites-empty-text">No pending invites</p>
      <p class="invites-empty-subtext">
        When you invite new members to your workspace, you'll see them here
      </p>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import Dropdown from '@c/library/Dropdown.vue'
import RoleSelect from '@c/library/RoleSelect.vue'
import Table from '@c/library/Table.vue'
import { formatDateWTime } from '@c/util'

export default {
  name: 'PendingEmailInvites',
  components: { Button, Table, Avatar, Dropdown, RoleSelect },
  props: {
    invites: {
      type: Array,
      required: true
    }
  },
  computed: {
    headers() {
      return [
        {
          id: 'email',
          title: 'Email',
          key: invite => invite.email
        },
        {
          id: 'roles',
          title: 'Roles',
          key: () => ''
        },
        {
          id: 'expires',
          title: 'Expires',
          key: invite => formatDateWTime(invite.expiration_date)
        },
        {
          id: 'invited_by',
          title: 'Invited by',
          key: invite =>
            invite.sender?.user
              ? this.$umodel.full_name(invite.sender.user)
              : ''
        },
        {
          id: 'reminder',
          title: '',
          key: () => ''
        },
        {
          id: 'actions',
          title: '',
          key: () => ''
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClickAway)
  },
  unmounted() {
    window.removeEventListener('click', this.handleClickAway)
  }
}
</script>

<style scoped lang="scss">
.invites {
  &-container {
    width: 100%;
  }

  &-item {
    &-email {
      white-space: nowrap;
    }

    &-inviter {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    &-text {
      font-size: 1.25rem;
    }

    &-subtext {
      font-size: 1rem;
      color: #60666b;
    }
  }
}
</style>
