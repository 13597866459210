














import User from '@c/models/User'
import IconHeaderModal from '../../structural/modals/IconHeaderModal.vue'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'UserHeaderModal',
  components: { IconHeaderModal },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    titlePrefix: {
      type: String,
      default: ''
    },
    noCloseIcon: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: undefined
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  }
})
