var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fallback-image" },
    [
      _vm.loading
        ? _c("b-loading", {
            staticClass: "image-loading-overlay",
            attrs: { "is-full-page": false, "can-cancel": false, active: "" }
          })
        : _vm._e(),
      _vm.hasValidSource && !_vm.isErrored
        ? _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            ref: "img",
            staticClass: "full",
            attrs: { src: _vm.src },
            on: { error: _vm.onError, load: _vm.onLoad }
          })
        : _vm._t("default", function() {
            return [
              _c("img", { staticClass: "full", attrs: { src: _vm.fallback } })
            ]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }