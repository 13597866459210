var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "utable-wrapper",
      class: { scrollable: _vm.maxHeight },
      style: Object.assign(
        {},
        _vm.maxHeight ? { maxHeight: _vm.maxHeight } : {},
        _vm.height ? { height: _vm.height } : {},
        _vm.padding ? { "--table-padding": _vm.padding } : {}
      )
    },
    [
      _c(
        "table",
        {
          staticClass: "utable",
          class: { bordered: _vm.bordered },
          style: Object.assign(
            {},
            _vm.background ? { background: _vm.background } : {}
          )
        },
        [
          _vm.showHeader
            ? _c(
                "tr",
                { staticClass: "utable-headers" },
                _vm._l(_vm.headers, function(header) {
                  return _c(
                    "th",
                    {
                      key: _vm.getId(header),
                      staticClass: "utable-headers-title",
                      class: { "has-table-padding": _vm.padding },
                      style: Object.assign(
                        {},
                        _vm.background ? { background: _vm.background } : {}
                      )
                    },
                    [
                      _vm._t(
                        "header",
                        function() {
                          return [
                            header.title
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "utable-headers-title-wrapper",
                                    class: { sortable: _vm.isSortable(header) },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleSortBy(header)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" " + _vm._s(header.title) + " "),
                                    _vm.isSortable(header)
                                      ? _c("img", {
                                          staticClass:
                                            "utable-headers-title-wrapper-sort",
                                          class: {
                                            rotated:
                                              _vm.sortBy === header.id &&
                                              !_vm.sortDesc
                                          },
                                          attrs: {
                                            src: require("@/assets/icons/table_sort" +
                                              (_vm.sortBy === header.id
                                                ? "_desc"
                                                : "") +
                                              ".svg"),
                                            alt: ""
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              : _c("div")
                          ]
                        },
                        { header: header }
                      )
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._l(_vm.itemsSorted, function(item, idx) {
            return _c(
              "tr",
              { key: _vm.getId(item), staticClass: "utable-item" },
              _vm._l(_vm.headers, function(header) {
                return _c(
                  "td",
                  {
                    key: _vm.getId(item) + "-" + _vm.getId(header),
                    staticClass: "utable-item-value",
                    class: {
                      clickable: _vm.isClickable(header, item),
                      "has-table-padding": _vm.padding
                    },
                    on: {
                      click: function() {
                        return _vm.handleClick(item, header)
                      }
                    }
                  },
                  [
                    _vm._t(
                      "item",
                      function() {
                        return [
                          header.id === "user"
                            ? _c(
                                "div",
                                { staticClass: "utable-item-value-avatar" },
                                [
                                  _c("UserAvatarWithText", {
                                    attrs: {
                                      username: _vm.$umodel.full_name(item),
                                      avatar: _vm.$umodel.avatar(item)
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "p",
                                {
                                  staticClass: "utable-item-value-text",
                                  attrs: { title: header.key(item) }
                                },
                                [_vm._v(" " + _vm._s(header.key(item)) + " ")]
                              )
                        ]
                      },
                      { item: item, header: header, index: idx }
                    )
                  ],
                  2
                )
              }),
              0
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }