var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.homekey, staticClass: "home" },
    [
      _c(
        "div",
        { staticClass: "home-hero" },
        [
          _c("h1", { staticClass: "home-hero-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.title ||
                    "Welcome to the " + _vm.currentWorkspace.name + " workspace"
                ) +
                " "
            ),
            _c("img", {
              staticClass: "home-hero-title-icon",
              attrs: { src: require("@/assets/icons/glitter.svg"), alt: "home" }
            })
          ]),
          _c(
            "router-link",
            { attrs: { to: _vm.getStartedRoute } },
            [
              _vm.title
                ? _c("Button", { attrs: { text: "Get started", size: "m" } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "home-content" },
        [
          _vm.canUseSearch
            ? _c("GlobalSearchBar", {
                staticClass: "home-content-search",
                attrs: { background: "#f1f2f3" }
              })
            : _vm._e(),
          _vm.highlightsEnabled ? _c("HighlightOverview") : _vm._e(),
          _vm.canWriteOri || _vm.canReadOri ? _c("HomeRecent") : _vm._e(),
          !(_vm.canWriteStories || _vm.canWriteOri || _vm.canReadOri) &&
          _vm.canUseSearch
            ? _c("HomePageLinks")
            : _vm._e()
        ],
        1
      ),
      _c("ResourceModal"),
      _vm.showCollectSlides ? _c("CustomSlideCreator") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }