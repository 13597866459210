<template>
  <transition name="fade">
    <div
      v-show="hovered"
      class="info"
      :class="{ leftaligned: alignLeft, rightaligned: !alignLeft }"
    >
      <p v-if="!hasUser" class="info-text">
        Labeled as
        <img
          :src="require(`@/assets/icons/${label.type}QualityLabel.svg`)"
          alt=""
          class="info-text-icon"
        />
        <span class="bold">{{ label.name }}</span>
      </p>
      <div v-else class="info-user">
        <img
          v-if="$umodel.avatar(label) && !avatarError"
          :src="$umodel.avatar(label)"
          alt=""
          class="info-user-avatar"
          @error="avatarError = true"
        />
        <p
          v-else
          class="info-user-avatar"
          :style="`background: ${userBackgroundColor};`"
        >
          {{ userInitials }}
        </p>
        <div class="info-user-info">
          <p class="bold">{{ userFullName }}</p>
          <p class="info-text">
            Labeled this as
            <img
              :src="require(`@/assets/icons/${label.type}QualityLabel.svg`)"
              alt=""
              class="info-text-icon"
            />
            <span class="bold">{{ label.name }}</span>
          </p>
        </div>
      </div>
      <p v-if="label.comment" class="info-comment">
        {{ label.comment }}
      </p>
    </div>
  </transition>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'QualityLabelInfo',
  props: {
    label: {
      type: Object,
      required: true
    },
    hovered: {
      type: Boolean,
      required: true
    },
    alignLeft: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    avatarError: false
  }),
  computed: {
    hasUser() {
      return !isEmpty(this.label.member)
    },
    userFullName() {
      return this.hasUser ? this.$umodel.full_name(this.label) : ''
    },
    userInitials() {
      return this.hasUser ? this.$umodel.initials(this.label) : ''
    },
    userBackgroundColor() {
      return this.hasUser ? this.$umodel.user_color(this.label) : ''
    }
  }
}
</script>

<style scoped lang="scss">
.info {
  position: absolute;
  bottom: calc(100% + 0.5rem);
  z-index: 999;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(#000, 8%);
  padding: 0.75rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  min-width: max-content;
  font-weight: 400;

  &.leftaligned {
    left: 0;
  }

  &.rightaligned {
    right: 0;
  }

  &-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1ch;

    &-icon {
      height: 1rem;
    }
  }

  &-user {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;

    &-avatar {
      height: 2rem;
      width: 2rem;
      border-radius: 999rem;
      border: 1px solid rgba(#000, 8%);
      color: white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
    }

    &-info {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      white-space: nowrap;
    }
  }

  &-comment {
    white-space: normal;
  }
}

.bold {
  font-weight: 600;
}
</style>
