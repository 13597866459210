// import dropboxLogo from '@c/assets/logo/dropbox.svg'
import googleDriveLogo from '@c/assets/logo/google_drive.png'
import hubspotLogo from '@c/assets/logo/hubspot.png'
import linkedinLogo from '@c/assets/logo/linkedin.svg'
// import confluenceLogo from '@c/assets/logo/logo-confluence.png'
import sharepointLogo from '@c/assets/logo/sharepoint.svg'
import oneDriveLogo from '@c/assets/logo/onedrive.svg'
// import slackLogo from '@c/assets/logo/slack.png'
// import teamsLogo from '@c/assets/logo/teams.png'

// import confluenceLogoGray from '@c/assets/logo/grayscale/confluence.svg'
import googleDriveLogoGray from '@c/assets/logo/grayscale/drive.svg'
// import dropboxLogoGray from '@c/assets/logo/grayscale/dropbox.svg'
// import slackLogoGray from '@c/assets/logo/grayscale/slack.svg'
// import notionLogoGray from '@c/assets/logo/grayscale/notion.svg'
// import youtubeLogoGray from '@c/assets/logo/grayscale/youtube.svg'
import googleCalendarLogo from '@c/assets/logo/google_calendar.svg'
import outlookCalendarLogo from '@c/assets/logo/outlook_calendar.svg'

export enum FormTypes {
  text = 'text'
}

export interface FormField {
  key: string
  type: FormTypes
  props?: {
    field?: any
    input?: any
  }
}

export interface Integration {
  image: string
  grayImage?: string
  title: string
  description: string
  syncObjectName: string
  folderName: string
  fileName: string
  oauthBaseURL?: string
  oauthQueryParams?: object
  active: boolean
  key: string
  setup: {
    usesCookie?: boolean
    usesAPIKey: boolean
    instructions?: string | string[]
    APILink?: string
    metaFormFields?: FormField[]
  }
  multilayered: boolean
  defaultIcon?: string
  defaultText?: string
  defaultColor?: string
}

export enum IntegrationProvider {
  SLACK = 'slack',
  MSTEAMS = 'ms_teams',
  GDRIVE = 'google_drive',
  SHAREPOINT = 'sharepoint',
  ONEDRIVE = 'onedrive',
  CONFLUENCE = 'confluence',
  DROPBOX = 'dropbox',
  HUBSPOT = 'hubspot',
  LINKEDIN = 'linkedin',
  GCALENDAR = 'google_calendar',
  OUTLOOKCALENDAR = 'microsoft_outlook_calendar',
}

function defaultInstructionNoAPIKey(folderName: string, fileName: string) {
  return `You’ll need to authenticate your account and give us permissions before you can start importing ${fileName}. The only thing we need to be able to access is your ${folderName}s and ${fileName}s.`
}

const FOLDER_NAME = 'folder'
const FILE_NAME = 'file'
// const CHANNEL_NAME = 'channel'
// const MESSAGE_NAME = 'message'

export const messagingIntegrations: { [key: string]: Integration } = {
  // [IntegrationProvider.SLACK]: {
  //   image: slackLogo,
  //   grayImage: slackLogoGray,
  //   title: 'Slack',
  //   description: 'Directly add interesting links from Slack',
  //   syncObjectName: 'weblink',
  //   folderName: CHANNEL_NAME,
  //   fileName: MESSAGE_NAME,
  //   oauthURL: process.env.VUE_APP_SLACK_OAUTH_URL,
  //   active: true,
  //   key: IntegrationProvider.SLACK,
  //   setup: {
  //     usesAPIKey: false,
  //     instructions: defaultInstructionNoAPIKey(CHANNEL_NAME, MESSAGE_NAME)
  //   },
  //   multilayered: false,
  //   defaultText: '#',
  //   defaultColor: '#401445'
  // },
  // [IntegrationProvider.MSTEAMS]: {
  //   image: teamsLogo,
  //   title: 'Microsoft Teams',
  //   folderName: CHANNEL_NAME,
  //   fileName: MESSAGE_NAME,
  //   description: 'Directly add interesting links from MS Teams',
  //   oauthURL: process.env.VUE_APP_TEAMS_OAUTH_URL,
  //   syncObjectName: 'weblink',
  //   active: true,
  //   key: IntegrationProvider.MSTEAMS,
  //   setup: {
  //     usesAPIKey: false,
  //     instructions: defaultInstructionNoAPIKey(CHANNEL_NAME, MESSAGE_NAME)
  //   },
  //   multilayered: false,
  //   defaultIcon: 'microsoft-teams',
  //   defaultColor: '#4B53BC'
  // }
}

export const filestorageIntegrations: { [key: string]: Integration } = {
  [IntegrationProvider.GDRIVE]: {
    image: googleDriveLogo,
    grayImage: googleDriveLogoGray,
    title: 'Google Drive',
    description:
      'You’ll need to authenticate your Google Drive account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL: 'https://accounts.google.com/o/oauth2/v2/auth',
    oauthQueryParams: {
      response_type: 'code',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/google_drive`,
      client_id:
        '387164199820-pblevae6f5u2nr71jks6bp1ffbo7cpn7.apps.googleusercontent.com',
      scope: 'openid https://www.googleapis.com/auth/drive.readonly',
      access_type: 'offline',
      prompt: 'consent'
    },
    active: true,
    key: IntegrationProvider.GDRIVE,
    setup: {
      usesAPIKey: false,
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [IntegrationProvider.SHAREPOINT]: {
    image: sharepointLogo,
    title: 'Sharepoint',
    description:
      'You’ll need to authenticate your Sharepoint account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    oauthQueryParams: {
      client_id: 'c4ea554d-5f70-4a84-9f82-46d76173bd20',
      scope:
        'offline_access openid https://graph.microsoft.com/User.Read.All https://graph.microsoft.com/Sites.Read.All https://graph.microsoft.com/Files.Read.All https://graph.microsoft.com/Group.Read.All',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/sharepoint`,
      response_type: 'code'
    },
    active: true,
    key: IntegrationProvider.SHAREPOINT,
    setup: {
      usesAPIKey: false,
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#1A9BA1'
  }
  // [IntegrationProvider.DROPBOX]: {
  //   image: dropboxLogo,
  //   grayImage: dropboxLogoGray,
  //   title: 'Dropbox',
  //   description:
  //     'You’ll need to authenticate your Dropbox account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
  //   syncObjectName: 'folder',
  //   folderName: FOLDER_NAME,
  //   fileName: FILE_NAME,
  //   oauthURL: process.env.VUE_APP_DROPBOX_OAUTH_URL,
  //   active: true,
  //   key: 'dropbox',
  //   setup: {
  //     usesAPIKey: false,
  //     instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
  //   },
  //   multilayered: true,
  //   defaultColor: '#0061FF'
  // },
  // [IntegrationProvider.CONFLUENCE]: {
  //   image: confluenceLogo,
  //   grayImage: confluenceLogoGray,
  //   title: 'Confluence',
  //   syncObjectName: 'folder',
  //   folderName: FOLDER_NAME,
  //   fileName: FILE_NAME,
  //   description:
  //     'Sync your Confluence files with uman.ai and make them accessible whenever your team truly needs it',
  //   active: true,
  //   key: IntegrationProvider.CONFLUENCE,
  //   setup: {
  //     usesAPIKey: true,
  //     instructions:
  //       'Please click the following link to get a Confluence API access token and the email associated with this account. If you don’t have access make sure to ask the Confluence admin of your team for help.',
  //     APILink: 'https://id.atlassian.com/manage-profile/security/api-tokens',
  //     metaFormFields: [
  //       {
  //         key: 'email',
  //         type: FormTypes.text,
  //         props: {
  //           field: { label: 'User email' },
  //           input: { placeholder: 'confluence@example.com' }
  //         }
  //       },
  //       {
  //         key: 'host',
  //         type: FormTypes.text,
  //         props: {
  //           field: { label: 'Team domain' },
  //           input: { placeholder: 'yourcompany.atlassian.net' }
  //         }
  //       }
  //     ]
  //   },
  //   multilayered: true,
  //   defaultColor: '#0659D3'
  // }
}

export const crmIntegrations: { [key: string]: Integration } = {
  [IntegrationProvider.HUBSPOT]: {
    image: hubspotLogo,
    title: 'Hubspot',
    description:
      'You’ll need to authenticate your Hubspot account give us permissions before you can start synchronizing. The only thing we need to be able to access is your companies.',
    syncObjectName: 'folder',
    folderName: 'industry',
    fileName: 'company',
    oauthBaseURL: 'https://app.hubspot.com/oauth/authorize',
    oauthQueryParams: {
      client_id: '41a8db24-28d4-4cd3-ba90-9876b5457f74',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/hubspot`,
      scope: 'crm.objects.companies.read%20crm.objects.deals.read'
    },
    active: true,
    key: IntegrationProvider.HUBSPOT,
    setup: {
      usesAPIKey: false,
      instructions: defaultInstructionNoAPIKey('industries', 'companies')
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [IntegrationProvider.LINKEDIN]: {
    image: linkedinLogo,
    title: 'LinkedIn',
    description:
      'You’ll need to authenticate your LinkedIn account before you can start getting warm introduction recommendations.',
    syncObjectName: 'people',
    folderName: 'people',
    fileName: 'person',
    active: true,
    key: IntegrationProvider.LINKEDIN,
    setup: {
      usesCookie: true,
      usesAPIKey: false,
      instructions: [
        'Open your browser and go to LinkedIn.',
        "Sign in if you're not already logged in.",
        "Right-click anywhere on the LinkedIn page and choose 'Inspect' or press Ctrl+Shift+I (Windows) or Cmd+Option+I (Mac).",
        "In the developer tools, click on the 'Application' tab.",
        "Under 'Storage' on the left, select 'Cookies' and then the LinkedIn URL.",
        "Look for the 'li_at' cookie in the list.",
        "Double-click the 'Value' field next to 'li_at' and copy it entirely."
      ]
    },
    multilayered: false,
    defaultColor: '#1382BD'
  }
}

export const userCalendarIntegrations: { [key: string]: Integration } = {
  [IntegrationProvider.GCALENDAR]: {
    image: googleCalendarLogo,
    title: 'Google Calendar',
    description:
      'You’ll need to authenticate your Google Calendar account give us permissions before you can start importing calendars.',
    syncObjectName: 'calendar',
    folderName: 'calendar',
    fileName: 'calendar',
    oauthBaseURL: 'https://accounts.google.com/o/oauth2/v2/auth',
    oauthQueryParams: {
      response_type: 'code',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/google_calendar`,
      client_id:
        '387164199820-3f1i5cq5avhvcev844n9cc3s78q6h0ti.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/calendar.readonly openid',
      access_type: 'offline',
      prompt: 'consent'
    },
    active: true,
    key: IntegrationProvider.GCALENDAR,
    setup: {
      usesAPIKey: false,
      instructions: `You'll need to authenticate your account and give us permissions before you can start importing calendars.`
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [IntegrationProvider.OUTLOOKCALENDAR]: {
    image: outlookCalendarLogo,
    title: 'Outlook Calendar',
    description:
      'You’ll need to authenticate your Outlook Calendar account to give us permissions before you can start importing calendars.',
    syncObjectName: 'calendar',
    folderName: 'calendar',
    fileName: 'calendar',
    oauthBaseURL:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    oauthQueryParams: {
      client_id: '8c4643f2-7d60-4732-ae92-3497c6fcbbf6',
      scope:
        'offline_access openid https://graph.microsoft.com/Calendars.Read',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/microsoft_outlook_calendar`,
      response_type: 'code'
    },
    active: true,
    key: IntegrationProvider.OUTLOOKCALENDAR,
    setup: {
      usesAPIKey: false,
      instructions: `You'll need to authenticate your account and give us permissions before you can start importing calendars.`
    },
    multilayered: true,
    defaultColor: '#0459A7'
  }
}

export const userFileStorageIntegrations: { [key: string]: Integration } = {
  [IntegrationProvider.GDRIVE]: {
    image: googleDriveLogo,
    grayImage: googleDriveLogoGray,
    title: 'Google Drive',
    description:
      'You’ll need to authenticate your Google Drive account give us permissions before you can start importing files. The only thing we need to be able to access is your folders and files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL: 'https://accounts.google.com/o/oauth2/v2/auth',
    oauthQueryParams: {
      response_type: 'code',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/google_drive`,
      client_id:
        '387164199820-pblevae6f5u2nr71jks6bp1ffbo7cpn7.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/drive openid',
      access_type: 'offline',
      prompt: 'consent'
    },
    active: true,
    key: IntegrationProvider.GDRIVE,
    setup: {
      usesAPIKey: false,
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#5080E7'
  },
  [IntegrationProvider.ONEDRIVE]: {
    image: oneDriveLogo,
    title: 'OneDrive',
    description:
      'You’ll need to authenticate your Microsoft account to give us permissions before you can start uploading files.',
    syncObjectName: 'folder',
    folderName: FOLDER_NAME,
    fileName: FILE_NAME,
    oauthBaseURL:
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
    oauthQueryParams: {
      client_id: '2098289a-65c9-4696-848b-725b08f16452',
      scope:
        'offline_access openid profile email https://graph.microsoft.com/Files.ReadWrite https://graph.microsoft.com/Files.Read.All',
      redirect_uri: `${process.env.VUE_APP_WEBAPP_HOST}/integrations/authenticate/onedrive`,
      response_type: 'code'
    },
    active: true,
    key: IntegrationProvider.ONEDRIVE,
    setup: {
      usesAPIKey: false,
      instructions: defaultInstructionNoAPIKey(FOLDER_NAME, FILE_NAME)
    },
    multilayered: true,
    defaultColor: '#1A9BA1'
  }
}

const allIntegrations: { [key: string]: Integration } = {
  ...messagingIntegrations,
  ...filestorageIntegrations,
  ...crmIntegrations
}

export default allIntegrations
