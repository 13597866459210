var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: function() {
                return _vm.$emit("loadMore")
              },
              expression: "() => $emit('loadMore')"
            }
          ],
          attrs: {
            total: _vm.amountOfIntegrationUsers,
            "infinite-scroll-disabled": "loading",
            "infinite-scroll-distance": "100",
            checkable: "",
            loading: _vm.loading,
            data: _vm.integrationUsers,
            "checked-rows": _vm.value,
            "sticky-header": "",
            height: "500px"
          },
          on: {
            check: function(val) {
              return _vm.$emit("input", val)
            },
            "check-all": function(val) {
              return _vm.$emit("input", val)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [_c("p", [_vm._v("No users found")])]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-table-column", {
            attrs: { label: "User" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("UserAvatarWithText", {
                      attrs: {
                        avatar: props.row.image_url,
                        username: _vm.$umodel.full_name(props.row),
                        subtext: props.row.username
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }