<template>
  <div
    class="draggable-list"
    :style="{
      ...(gap
        ? { gap, ...(dragging ? { marginTop: `calc(-1 * ${gap})` } : {}) }
        : {})
    }"
  >
    <div
      v-for="(item, idx) in dragItems"
      :key="`draggable-list-${idx}-${itemKey}`"
      class="draggable-list-item-wrapper"
      :class="{
        dragged: dragging && idx === 0,
      }"
      :style="{
        ...(item.dragTarget ? {} : wrapperStyle),
        ...(dragging && (idx === 0 || item.dragTarget)
          ? {
              width: itemDimensions.width + 'px',
              minWidth: itemDimensions.width + 'px',
              height: itemDimensions.height + 'px',
              minHeight: itemDimensions.height + 'px'
            }
          : {}),
        ...(dragging && idx === 0
          ? {
              left: `${mouseCoords.x - draggedItemOffset.x}px`,
              top: `${mouseCoords.y - draggedItemOffset.y}px`
            }
          : {})
      }"
    >
      <div
        class="draggable-list-item"
        :class="{
          'drag-target': item.dragTarget,
          [`draggable-list-item-${itemKey}`]: true,
          [`draggable-list-item-targetable-${itemKey}`]:
            dragging && !item.dragTarget && idx !== 0,
          overlapping: handleOverlaps
        }"
        :style="{
          ...(item.dragTarget ? {} : itemStyle),
        }"
        @click="() => $emit('item-click', item, idx)"
      >
        <slot
          v-if="!item.dragTarget"
          name="left"
          :item="item"
          :index="idx"
        ></slot>
        <div
          v-if="!item.dragTarget && draggable"
          class="draggable-list-item-handle"
          :class="{ bordered: handleBordered, overlapping: handleOverlaps }"
          @mousedown="e => startDrag(e, idx)"
          @mouseup="e => endDrag(e, idx)"
          @click.stop
          @dragstart.prevent
        >
          <img
            src="@/assets/icons/drag.svg"
            alt=""
            class="draggable-list-item-handle-icon"
          />
        </div>
        <slot
          v-if="!item.dragTarget"
          name="item"
          :item="item"
          :index="idx"
        ></slot>
      </div>

      <slot
        v-if="!dragging || !(item.dragTarget || idx === 0)"
        name="after"
        :item="item"
        :index="idx"
      ></slot>
    </div>
  </div>
</template>

<script>
const contains2D = (mouse, el) => {
  return (
    mouse.x > el.x &&
    mouse.x < el.x + el.width &&
    mouse.y > el.y &&
    mouse.y < el.y + el.height
  )
}

export default {
  name: 'DraggableList',
  props: {
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemStyle: {
      type: Object,
      default: () => ({})
    },
    handleBordered: {
      type: Boolean,
      default: false
    },
    handleOverlaps: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    wrapperStyle: {
      type: Object,
      default: () => ({})
    },
    gap: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dragging: false,
    dragIndex: -1, // index of the item being dragged in the original items array
    dragOverIndex: -1, // index of the item being dragged over in the original items array
    mouseCoords: { x: 0, y: 0 },
    draggedItemOffset: { x: 0, y: 0 },
    itemDimensions: { width: 0, height: 0 },
    scrollParent: undefined,
    scrollTimer: undefined
  }),
  computed: {
    dragItems() {
      if (!this.dragging) return this.items
      let rest = this.items.filter((_, i) => i !== this.dragIndex)
      const dragItem = this.items[this.dragIndex]
      const split =
        this.dragOverIndex <= this.dragIndex
          ? this.dragOverIndex
          : this.dragOverIndex - 1
      return [
        dragItem,
        ...rest.slice(0, split),
        { dragTarget: true },
        ...rest.slice(split)
      ]
    }
  },
  watch: {
    items() {
      this.$nextTick(() => (this.scrollParent = this.getScrollParent()))
    }
  },
  mounted() {
    this.scrollParent = this.getScrollParent()
  },
  methods: {
    getScrollParent() {
      let parent = this.$el
      while (parent) {
        if (parent.scrollHeight > parent.clientHeight) {
          return parent
        }
        parent = parent.parentElement
      }
      return window
    },
    getItemDimensions(idx) {
      return Array.from(
        document.getElementsByClassName(`draggable-list-item-${this.itemKey}`)
      )[idx].getBoundingClientRect()
    },
    startDrag(e, idx) {
      this.dragIndex = idx
      this.dragOverIndex = idx
      const dim = this.getItemDimensions(idx)
      this.itemDimensions = { width: dim.width, height: dim.height }
      this.mouseCoords = { x: e.clientX, y: e.clientY }
      this.draggedItemOffset = {
        x: this.mouseCoords.x - dim.x,
        y: this.mouseCoords.y - dim.y
      }
      this.dragging = true
      document.addEventListener('mousemove', this.handleMouseMove)
    },
    handleMouseMove(e) {
      this.mouseCoords = { x: e.clientX, y: e.clientY }

      const targets = Array.from(
        document.getElementsByClassName(
          `draggable-list-item-targetable-${this.itemKey}`
        )
      )
      this.items.forEach((_, i) => {
        if (i === this.dragIndex) return
        const el = targets[i < this.dragIndex ? i : i - 1]
        const rect = el.getBoundingClientRect()
        if (contains2D(this.mouseCoords, rect)) {
          if (i < this.dragOverIndex) this.dragOverIndex = i
          else this.dragOverIndex = i + 1
        }
      })

      if (this.scrollParent) {
        const scroll = delta => {
          if (this.scrollTimer) return
          this.scrollTimer = setInterval(() => {
            this.scrollParent.scrollBy({
              top: delta,
              behavior: 'smooth'
            })
          }, 100)
        }
        const { top, bottom } = this.scrollParent.getBoundingClientRect()
        if (this.mouseCoords.y >= bottom) {
          scroll(50)
        } else if (this.mouseCoords.y <= top) {
          scroll(-50)
        } else {
          clearInterval(this.scrollTimer)
          this.scrollTimer = undefined
        }
      }
    },
    endDrag(e, idx) {
      if (!this.dragging || idx !== 0) return
      if (this.dragOverIndex !== this.dragIndex)
        this.$emit('reorder', {
          from: this.dragIndex,
          to:
            this.dragOverIndex > this.dragIndex
              ? this.dragOverIndex - 1
              : this.dragOverIndex
        })
      this.dragging = false
      this.dragIndex = -1
      this.dragOverIndex = -1
      this.mouseCoords = { x: 0, y: 0 }
      this.draggedItemOffset = { x: 0, y: 0 }
      document.removeEventListener('mousemove', this.handleMouseMove)
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-list {
  display: flex;
  flex-flow: column nowrap;

  & * {
    user-select: none !important;
  }

  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background: white;
    height: 100%;

    &.overlapping {
      position: relative;

      &:hover {
        & .draggable-list-item-handle {
          opacity: 1;
        }
      }
    }

    &.drag-target {
      background: rgba(#000, 0.04);
      border-radius: 4px;
      border: 2px dashed rgba(#000, 0.16);
    }

    &-wrapper {
      display: flex;
      flex-flow: column nowrap;

      &.dragged {
        position: fixed;
        z-index: 1000;
  
        & .draggable-list-item-handle {
          cursor: grabbing;
        }
      }
    }

    &-handle {
      cursor: grab;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 999rem;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      &.bordered {
        height: 2rem;
        width: 2rem;
        border: 1px solid rgba(#000, 0.08);
      }

      &.overlapping {
        position: absolute;
        top: 1rem;
        left: 1rem;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s;
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }
}
</style>
