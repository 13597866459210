






















import ConnectCookie from '@c/features/connect-integration-modal/organisms/ConnectCookie.vue'
import ConnectForm from '@c/features/connect-integration-modal/organisms/ConnectForm.vue'
import ConnectRedirect from '@c/features/connect-integration-modal/organisms/ConnectRedirect.vue'
import { Integration } from '@c/integrations'
import Workspace from '@c/models/Workspace'
import BackButton from '@c/shared/molecules/buttons/BackButton.vue'
import WorkspaceHeaderModal from '@c/shared/molecules/object-visualisations/workspace/WorkspaceHeaderModal.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'ConnectToIntegration',
  components: {
    WorkspaceHeaderModal,
    BackButton,
    ConnectForm,
    ConnectRedirect
  },
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    },
    workspace: {
      type: Object as () => Workspace,
      required: true
    }
  },
  computed: {
    integrationComponent(): any {
      return this.integration.setup.usesAPIKey
        ? ConnectForm
        : this.integration.setup.usesCookie
        ? ConnectCookie
        : ConnectRedirect
    }
  },
  created() {
    window.localStorage.setItem('integrationWorkspace', this.workspace.uuid)
  },
  methods: {
    openWorkspaceSettings(): void {
      this.$emit('close')
      this.$modals.workspace.openSettings(this, this.workspace, 3)
    }
  }
})
