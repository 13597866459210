var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DraggableCarousel", {
    attrs: {
      items: _vm.selectedSlides,
      "scroll-trigger": _vm.slideCollectorOpen,
      "preview-key": _vm.previewKey
    },
    on: { input: _vm.handleOrderChange },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var slide = ref.item
          var idx = ref.idx
          return [
            _c(
              "div",
              {
                staticClass: "selected-slide ma-2",
                on: {
                  mouseenter: function($event) {
                    _vm.hovered = slide.uuid
                  },
                  mouseleave: function($event) {
                    _vm.hovered = ""
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "slide-img",
                  attrs: { src: _vm.previewKey(slide), alt: "" }
                }),
                _c(
                  "div",
                  { staticClass: "selected-slide-btns" },
                  [
                    slide.label || slide.labels
                      ? _c("QualityLabels", {
                          attrs: {
                            "active-label": slide.label || slide.labels[0],
                            "source-id": slide.uuid,
                            "small-labels": true,
                            "show-hover-info": false,
                            "show-edit-labels": false,
                            "bordered-icons": true
                          }
                        })
                      : _vm._e(),
                    _vm.hovered === slide.uuid && !_vm.isDownloading
                      ? _c("Button", {
                          attrs: { icon: "close", type: "white", size: "xs" },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onDelete(slide, idx)
                            },
                            mousedown: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }