var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Dropdown", {
    ref: "roledropdown",
    attrs: {
      items: _vm.roles,
      "full-width": _vm.fullWidth,
      "position-fixed": true,
      disabled: !_vm.roles.length
    },
    on: { hide: _vm.handleHide },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "role-select-trigger",
                class: { empty: !_vm.selection.length },
                style: { width: _vm.widthStyle }
              },
              [
                _c("span", { staticClass: "role-select-trigger-text" }, [
                  _vm._v(_vm._s(_vm.firstSelected))
                ]),
                _vm.selection.length > 1
                  ? _c("span", { staticClass: "role-select-trigger-more" }, [
                      _vm._v("+" + _vm._s(_vm.selection.length - 1))
                    ])
                  : _vm._e(),
                _c("img", {
                  staticClass: "role-select-trigger-chevron",
                  attrs: {
                    src: require("@/assets/icons/chevron-down-medium.svg"),
                    alt: ""
                  }
                })
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("div", { staticClass: "role-select-content" }, [
              _c(
                "div",
                { staticClass: "role-select-content-list" },
                _vm._l(_vm.roles, function(role) {
                  return _c(
                    "div",
                    {
                      key: role.name,
                      staticClass: "role-select-content-list-item",
                      on: {
                        click: function() {
                          return _vm.handleItemClick(role)
                        }
                      }
                    },
                    [
                      _c("Checkbox", {
                        staticClass: "role-select-content-list-item-checkbox",
                        attrs: {
                          value: _vm.selection.some(function(s) {
                            return s.uuid === role.uuid
                          })
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "role-select-content-list-item-info" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "role-select-content-list-item-info-name"
                            },
                            [_vm._v(_vm._s(role.name))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "role-select-content-list-item-info-description"
                            },
                            [_vm._v(_vm._s(role.description))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "role-select-content-save" },
                [
                  _c("Button", {
                    attrs: { text: "Save" },
                    on: { click: _vm.handleSave }
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }