













import Vue from 'vue'
export default Vue.extend({
  name: 'BackButton',
  props: {
    text: {
      type: String,
      default: 'Go back'
    },
    customTextClass: {
      type: [String, Object],
      default: 'body xxs body-text'
    }
  }
})
