var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.highlights.length > 0,
          expression: "highlights.length > 0"
        }
      ],
      staticClass: "overview"
    },
    [
      _c("div", { staticClass: "overview-header" }, [
        _c("span", { staticClass: "overview-header-text" }, [
          _vm._v("Highlighted content")
        ]),
        _vm.highlights && _vm.highlights.length > 3
          ? _c(
              "div",
              { staticClass: "overview-header-btns" },
              [
                _c(
                  "span",
                  {
                    staticClass: "overview-header-btns-showmore",
                    on: { click: _vm.handleShowMore }
                  },
                  [_vm._v(_vm._s(_vm.showMore ? "Show less" : "Show all"))]
                ),
                !_vm.showMore
                  ? _c("Button", {
                      attrs: { icon: "chevron-left-medium", type: "white" },
                      on: {
                        click: function($event) {
                          return _vm.handleArrowClick(-1)
                        }
                      }
                    })
                  : _vm._e(),
                !_vm.showMore
                  ? _c("Button", {
                      attrs: { icon: "chevron-right-medium", type: "white" },
                      on: {
                        click: function($event) {
                          return _vm.handleArrowClick(1)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "overview-grid" },
        _vm._l(_vm.visibleHighlights, function(highlight, idx) {
          return _c(
            "div",
            { key: highlight.uuid, staticClass: "overview-grid-item" },
            [
              _c("HighlightVisualisation", {
                attrs: { resource: highlight, rank: idx + 1 },
                on: {
                  gotoResource: function($event) {
                    return _vm.$emit("gotoResource", highlight)
                  },
                  removeHighlight: function($event) {
                    return _vm.removeHighlight(highlight)
                  },
                  pinHighlight: function($event) {
                    return _vm.pinHighlight(highlight)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }