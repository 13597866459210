import { getClient, getResponseData } from '../../axios'

const client = getClient('/workspaces')

export default {
  state: {
    qualityLabels: [],
    attributes: [],
    attributesLoaded: false,
    attributesLoading: false
  },
  getters: {
    qualityLabels: (state) => state.qualityLabels,
    informativeQualityLabels: (state) =>
      state.qualityLabels.filter((ql) => ql.type === 'informative'),
    negativeQualityLabels: (state) =>
      state.qualityLabels.filter((ql) => ql.type === 'negative'),
    warningQualityLabels: (state) =>
      state.qualityLabels.filter((ql) => ql.type === 'warning'),
    positiveQualityLabels: (state) =>
      state.qualityLabels.filter((ql) => ql.type === 'positive'),
    attributes: (state) => state.attributes,
    attributesLoaded: (state) => state.attributesLoaded
  },
  mutations: {
    setQualityLabels(state, qualityLabels) {
      state.qualityLabels = qualityLabels
    },
    setAttributes(state, attributes) {
      state.attributes = attributes
    },
    addAttribute(state, attribute) {
      state.attributes.push(attribute)
    },
    removeAttribute(state, attribute_id) {
      state.attributes = state.attributes.filter(
        (attr) => attr.uuid !== attribute_id
      )
    },
    setAttributesLoaded(state, value) {
      state.attributesLoaded = value
    },
    updateAttribute(state, attribute) {
      state.attributes = state.attributes.map((attr) =>
        attr.uuid === attribute.uuid ? attribute : attr
      )
    },
    addAttributeValue(state, { attribute_id, value }) {
      const attribute = state.attributes.find(
        (attr) => attr.uuid === attribute_id
      )
      attribute.values.push(value)
    },
    updateAttributeValue(state, { attribute_id, value }) {
      const attribute = state.attributes.find(
        (attr) => attr.uuid === attribute_id
      )
      attribute.values = attribute.values.map((val) =>
        val.uuid === value.uuid ? value : val
      )
    },
    removeAttributeValue(state, { attribute_id, value_id }) {
      const attribute = state.attributes.find(
        (attr) => attr.uuid === attribute_id
      )
      attribute.values = attribute.values.filter((val) => val.uuid !== value_id)
    }
  },
  actions: {
    setQualityLabels({ commit }, qualityLabels) {
      commit('setQualityLabels', qualityLabels)
    },
    async getAttributes(
      { state, commit },
      { workspace_id, linked_to, force = false }
    ) {
      if (!force && state.attributes?.length && !linked_to)
        return state.attributes
      const res = await client.get(`/${workspace_id}/attributes/`, {
        params: {
          linked_to
        }
      })
      const resData = getResponseData(res) || {}
      if (!linked_to) {
        commit('setAttributes', resData)
        commit('setAttributesLoaded', true)
      }
      return resData
    },

    async createAttribute({ commit }, { workspace_id, name }) {
      const res = await client.post(`/${workspace_id}/attributes/`, {
        name
      })
      const attr = getResponseData(res)
      commit('addAttribute', attr)
      return attr
    },

    async editAttribute({ commit, dispatch }, { workspace_id, attribute_id, name, position }) {
      const res = await client.patch(
        `/${workspace_id}/attributes/${attribute_id}/`,
        {
          ...(name ? { name } : {}),
          ...(position ? { position } : {})
        }
      )
      const attribute = getResponseData(res)
      if (position) dispatch('getAttributes', { workspace_id, force: true })
      commit('updateAttribute', attribute)
      return attribute
    },

    async deleteAttribute({ commit }, { workspace_id, attribute_id }) {
      const res = await client.delete(
        `/${workspace_id}/attributes/${attribute_id}/`
      )
      commit('removeAttribute', attribute_id)
      return getResponseData(res)
    },

    async createAttributeValue(
      { commit },
      { workspace_id, attribute_id, value }
    ) {
      const res = await client.post(
        `/${workspace_id}/attributes/${attribute_id}/values/`,
        {
          value
        }
      )
      const resData = getResponseData(res)
      commit('addAttributeValue', { attribute_id, value: resData })
      return res
    },

    async editAttributeValue(
      { commit, dispatch },
      { workspace_id, attribute_id, value_id, value, position }
    ) {
      const res = await client.patch(
        `/${workspace_id}/attributes/${attribute_id}/values/${value_id}/`,
        {
          ...(value ? { value } : {}),
          ...(position ? { position } : {})
        }
      )
      const resData = getResponseData(res)
      if (position) dispatch('getAttributes', { workspace_id, force: true })
      else commit('updateAttributeValue', { attribute_id, value: resData })
      return res
    },

    async deleteAttributeValue({ commit }, { workspace_id, attribute_id, value_id }) {
      const res = await client.delete(
        `/${workspace_id}/attributes/${attribute_id}/values/${value_id}/`
      )
      commit('removeAttributeValue', { attribute_id, value_id })
      return getResponseData(res)
    }
  }
}
