<template>
  <div
    class="highlight"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div class="highlight-preview">
      <HighlightSubresources
        :subresources="resource.subresources"
        :resource-type="resourceType"
        :highlight="resource.highlight"
        :pinned="resource.highlight.pinned"
        :mimetype="resource.integrationfile.mimetype"
        :resource-trace-id="resource.trace_id"
        @inspectResource="onPreviewClick"
        v-on="$listeners"
      />
    </div>
    <div class="highlight-info">
      <div class="highlight-info-row">
        <DocumentTag
          class="highlight-info-icon"
          :mimetype="resource.integrationfile.mimetype"
        />
        <span
          class="highlight-info-title"
          :style="titleWidth"
          :title="resource.title"
          @click="onResourceClick"
          >{{ resource.title }}</span
        >
        <QualityLabels
          class="highlight-info-labels"
          :active-label="resource.label"
          :source-id="resource.uuid"
          :bordered-icons="false"
          :show-edit-labels="false"
          small-labels
        />
        <UploadDownloadDropdown
          v-if="downloadFilesEnabled"
          :props-call="getDownloadProps"
        />
      </div>
      <ResourceInfo
        class="highlight-info-integration"
        :resource-integration="resource.integrationfile"
        :hover="hovering"
        :hide-integration-path="true"
        @on-path-click="onPathClick"
      />
    </div>
  </div>
</template>

<script>
import {
  eventContext,
  eventPage,
  sendFileClickThroughEvent,
  sendFolderClickThroughEvent,
  sendInspectEvent
} from '@/services/feedbackService'
import { getDocumentDownloadLink } from '@/services/presentationService'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import QualityLabels from '@c/shared/molecules/object-visualisations/resource/subcomponents/QualityLabels.vue'
import ResourceInfo from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceInfo.vue'
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import HighlightSubresources from './HighlightSubresources.vue'
import { getLink } from '@c/models/Resource'

export default {
  name: 'HighlightVisualisation',
  components: {
    HighlightSubresources,
    ResourceInfo,
    QualityLabels,
    DocumentTag,
    UploadDownloadDropdown
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    hovering: false
  }),
  computed: {
    ...mapGetters(['downloadFilesEnabled']),
    resourceType() {
      return this.resource.type
    },
    titleWidth() {
      return isEmpty(this.resource.label)
        ? `max-width: 22vw;`
        : `max-width: 20vw;`
    }
  },
  methods: {
    ...mapActions(['setResourceModal']),
    onResourceClick() {
      sendFileClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.resource.trace_id,
        page: eventPage.home,
        context: eventContext.resource,
        comment: 'from home page',
        rank: this.rank
      })
      this.gotoResource()
    },

    onPathClick() {
      sendFolderClickThroughEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.resource.trace_id,
        page: eventPage.home,
        context: eventContext.resource,
        comment: 'from home page',
        rank: this.rank
      })
    },
    onPreviewClick() {
      sendInspectEvent(this.$route.params.workspace_id, {
        resource_trace_id: this.resource.trace_id,
        subresource_trace_id: this.resource.subresources[0].trace_id,
        page: eventPage.home,
        context: eventContext.resource,
        rank: this.rank,
        comment: 'opening resource modal with slide click'
      })
      this.setResourceModal({
        resource_id: this.resource.uuid,
        resource_level: 'resource',
        resource_modal_props: {
          workspaceId: this.$route.params.workspace_id,
          initialPage: 1,
          selectable: false,
          scrollTo: undefined,
          similarPages: [],
          rank: this.rank,
          resourceType: this.resourceType,
          resourceTraceId: this.resource.trace_id
        },
        buefy_modal_props: {}
      })
    },
    getDownloadProps() {
      return getDocumentDownloadLink(
        this.$route.params.workspace_id,
        this.resource.uuid,
        this.$route.query?.act_as
      )
    },

    gotoResource() {
      const link = getLink(this.resource)
      this.$console.debug('Going to', link)
      this.$resource.goToResource(link)
    }
  }
}
</script>

<style scoped lang="scss">
.highlight {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  &-preview {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-top: 1px solid rgba(#000, 8%);
    border-left: 1px solid rgba(#000, 8%);
    border-right: 1px solid rgba(#000, 8%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: #f5f5f5;
    position: relative;
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    flex: 1;
    gap: 0.25rem;
    padding: 1.25rem;
    background: white;
    border: 1px solid rgba(#000, 8%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    &-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-icon {
      display: inline;
      vertical-align: middle;
    }

    &-title {
      color: $primary;
      font-weight: 600;
      cursor: pointer;
      max-width: 22vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: underline;
      }
    }

    &-labels {
      display: inline-flex;
      vertical-align: middle;
    }

    &-integration {
    }
  }
}
</style>
