var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AvatarWithText",
    _vm._g(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "avatar",
              fn: function() {
                return [
                  _vm._t(
                    "avatar",
                    function() {
                      return [
                        _c("UserAvatar", {
                          staticClass: "user-avatar",
                          attrs: {
                            hoverable: _vm.avatarHoverable,
                            avatar: _vm.avatar,
                            height: _vm.size,
                            width: _vm.size
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "dropdown",
                                fn: function() {
                                  return [_vm._t("dropdown")]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    },
                    {
                      hoverable: _vm.avatarHoverable,
                      avatar: _vm.avatar,
                      height: _vm.size,
                      width: _vm.size
                    }
                  )
                ]
              },
              proxy: true
            },
            {
              key: "text",
              fn: function() {
                return [
                  _vm._t(
                    "text",
                    function() {
                      return [
                        _c(
                          "p",
                          {
                            staticClass: "body xs username",
                            staticStyle: { "line-height": "0.75rem" }
                          },
                          [_vm._v(" " + _vm._s(_vm.username) + " ")]
                        )
                      ]
                    },
                    null,
                    { username: _vm.username }
                  )
                ]
              },
              proxy: true
            },
            {
              key: "subtext",
              fn: function() {
                return [
                  _vm._t("subtext", function() {
                    return [
                      _vm.subtext
                        ? _c(
                            "p",
                            {
                              staticClass: "subtext body body-text xxs",
                              staticStyle: { "text-align": "start" }
                            },
                            [_vm._v(" " + _vm._s(_vm.subtext) + " ")]
                          )
                        : _vm._e()
                    ]
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }