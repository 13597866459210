import Resource from '@c/models/Resource'
import WorkspaceMember from '@c/models/WorkspaceMember'

export default interface Integration {
  id: string | number
  type: string
}

export enum SYNC_STATUS {
  SUCCESS = 'success',
  PENDING = 'pending',
  FAILURE_EXTRACTION = 'failure_extraction',
  FAILURE_INDEXING = 'failure_indexing',
  UNSUPPORTED = 'unsupported',
  REQUESTED = 'requested',
  IDENTIFIED = 'identified'
}

export interface BaseSync {
  path: string | any
  name: string
  resource: Resource
  status: SYNC_STATUS
  status_message: string
  last_sync: string
  uuid?: number
}

// export enum FileObjectType {
//   DIRECTORY = 'directory',
//   FILE = 'file',
//   DRIVE = 'drive',
//   PAGE = 'page',
//   WORKSPACE = 'workspace'
// }
//
// export const expandableFileObjectTypes = [
//   FileObjectType.DIRECTORY,
//   FileObjectType.DRIVE,
//   FileObjectType.PAGE,
//   FileObjectType.WORKSPACE
// ]
//
// export const fileObjectTypeIconMap: Record<string, string> = {
//   [FileObjectType.DIRECTORY]: 'folder',
//   [FileObjectType.FILE]: 'file-document',
//   [FileObjectType.DRIVE]: 'harddisk',
//   [FileObjectType.PAGE]: 'text-box',
//   [FileObjectType.WORKSPACE]: 'text-box-multiple-outline'
// }

export interface FileSelectObject {
  path: string
  web_path: string
  name: string
  is_directory: boolean
  is_supported: boolean
  mimetype: string
  sync: boolean
  owner: WorkspaceMember | null
  sync_id: string
  status: string
  status_message: string
}

export interface FileSelectTreeNode extends FileSelectObject {
  checked: boolean | 'indeterminate'
}
