










import InviteTab from '@c/features/workspace-settings-modal/pages/InviteTab.vue'
import Workspace, { WORKSPACE_ROLES } from '@c/models/Workspace'
import Vue from 'vue'

export default Vue.extend({
  name: 'WorkspaceInviteView',
  components: { InviteTab },
  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    }
  },
  computed: {
    workspaceRoles() {
      return WORKSPACE_ROLES
    }
  }
})
