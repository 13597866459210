var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4" },
    [
      _c("p", { staticClass: "header bold black xs mb-3" }, [
        _vm._v(" Instruction to connect " + _vm._s(_vm.integration.title) + " ")
      ]),
      _c("p", { staticClass: "body xxs" }, [
        _vm._v(_vm._s(_vm.integration.setup.instructions))
      ]),
      _c("div", { staticClass: "mt-4 mb-4" }, [
        _vm.integration.setup.APILink
          ? _c(
              "a",
              {
                staticClass: "link underline xs dashed blue",
                attrs: { href: _vm.integration.setup.APILink, target: "_blank" }
              },
              [_vm._v(" Get API link here ")]
            )
          : _vm._e()
      ]),
      _c(
        "b-field",
        { staticClass: "mb-4", attrs: { label: "Token" } },
        [
          _c("b-input", {
            attrs: { placeholder: "qsmdfkjphva37gvk2" },
            model: {
              value: _vm.$data["token"],
              callback: function($$v) {
                _vm.$set(_vm.$data, "token", $$v)
              },
              expression: "$data['token']"
            }
          })
        ],
        1
      ),
      _vm._l(_vm.integration.setup.metaFormFields, function(field) {
        return _c(
          "b-field",
          _vm._b(
            { key: field.key, staticClass: "mb-4" },
            "b-field",
            field.props.field,
            false
          ),
          [
            field.type === "text"
              ? _c(
                  "b-input",
                  _vm._b(
                    {
                      model: {
                        value: _vm.$data[field.key],
                        callback: function($$v) {
                          _vm.$set(_vm.$data, field.key, $$v)
                        },
                        expression: "$data[field.key]"
                      }
                    },
                    "b-input",
                    field.props.input,
                    false
                  )
                )
              : _vm._e()
          ],
          1
        )
      }),
      _c("ModalActionFooter", {
        staticClass: "mt-7",
        attrs: { action: "Connect " + _vm.integration.title },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          },
          action: function($event) {
            return _vm.$emit("save", _vm.$data)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }