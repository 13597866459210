<template>
  <div class="udropdown" :class="{ 'full-width': fullWidth }">
    <div
      ref="dropdowntrigger"
      class="udropdown-trigger"
      :class="{
        'full-width': fullWidth,
        disabled,
        'visualise-disabled': visualiseDisabled
      }"
      @click="toggle"
    >
      <slot name="trigger">
        <div class="udropdown-trigger-content">
          <img
            src="@/assets/icons/kebab.svg"
            alt=""
            class="udropdown-trigger-content-icon"
          />
        </div>
      </slot>
    </div>
    <div
      ref="dropdownwrapper"
      class="udropdown-wrapper"
      :class="{
        visible: toggled,
        'position-fixed': positionFixed,
        leftaligned,
        topaligned
      }"
      :style="{ ...offset, ...contentStyle }"
    >
      <slot name="content">
        <div
          class="udropdown-content"
          :style="{ ...(maxHeight ? { maxHeight, overflowY: 'auto' } : {}) }"
        >
          <div
            v-for="item in items"
            :key="item.text"
            class="udropdown-content-item"
            :class="{
              ...(item.filter ? { [`filter-${item.filter}`]: true } : {})
            }"
            @click="handleItemClick(item)"
          >
            <slot name="item" :item="item">
              <img
                v-if="item.icon"
                :src="require(`@/assets/icons/${item.icon}.svg`)"
                alt=""
                class="udropdown-content-item-icon"
              />
              {{ item.text }}
            </slot>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    alignLeft: {
      type: Boolean,
      default: false
    },
    alignTop: {
      type: Boolean,
      default: false
    },
    calculateAlignment: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    positionFixed: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    visualiseDisabled: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: ''
    },
    selectCloses: {
      type: Boolean,
      default: true
    },
    contentStyle: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    toggled: false,
    offset: {},
    leftaligned: false,
    topaligned: false
  }),
  watch: {
    positionFixed(val) {
      if (val) this.initFixed()
    },
    alignLeft(val) {
      if (!this.calculateAlignment) this.leftaligned = val
    },
    alignTop(val) {
      if (!this.calculateAlignment) this.topaligned = val
    }
  },
  created() {
    if (!this.calculateAlignment) {
      this.leftaligned = this.alignLeft
      this.topaligned = this.alignTop
    }
  },
  mounted() {
    document.addEventListener('click', this.checkClickOutside)
  },
  beforeDestroy() {
    if (this.toggled) this.hideOptions()
    document.removeEventListener('click', this.checkClickOutside)
  },
  methods: {
    toggle(e) {
      this.calculateAlignments(e)
      this.toggled = !this.toggled
      if (this.toggled && this.positionFixed) this.calculateOffset()
      if (!this.toggled) this.hideOptions()
    },
    calculateAlignments(e) {
      if (this.calculateAlignment) {
        this.leftaligned = e.clientX < window.innerWidth / 2
        this.topaligned = e.clientY > window.innerHeight / 2
      }
    },
    calculateOffset() {
      const {
        top,
        left,
        width,
        height
      } = this.$refs.dropdowntrigger.getBoundingClientRect()
      this.offset = {
        ...(this.leftaligned
          ? { left: `${left}px` }
          : { right: `${window.innerWidth - left - width}px` }),
        ...(this.fullWidth ? { minWidth: `${width}px` } : {}),
        ...(this.topaligned
          ? { bottom: `${window.innerHeight - top + 7}px` }
          : { top: `${top + height + 7}px` }),
        ...(this.fullWidth ? { width: `${width}px` } : {})
      }
      if (this.appendToBody) {
        this.$nextTick(() => {
          const wrapper = this.$refs.dropdownwrapper
          document.body.appendChild(wrapper)
        })
      }
    },
    hideOptions() {
      this.toggled = false
      if (this.appendToBody) {
        const wrapper = this.$refs.dropdownwrapper
        try {
          if (document.querySelector('body > .udropdown-wrapper')) {
            document.body.removeChild(wrapper)
          }
        } catch (e) {
          this.$console.debug(e)
        }
      }
      this.$emit('hide')
    },
    handleItemClick(item) {
      if (this.selectCloses) this.hideOptions()
      item.callback()
    },
    checkClickOutside(event) {
      let outside = true
      const bodyWrapper = document.querySelector('body > .udropdown-wrapper')
      if (
        (bodyWrapper && bodyWrapper.contains(event.target)) ||
        this.$refs.dropdownwrapper.contains(event.target) ||
        this.$refs.dropdowntrigger.contains(event.target)
      ) {
        outside = false
      }
      if (outside) this.hideOptions()
    }
  }
}
</script>

<style scoped lang="scss">
.udropdown {
  position: relative;

  &.full-width {
    width: 100%;
  }

  &-trigger {
    cursor: pointer;
    &.full-width {
      width: 100%;
    }

    &.disabled {
      pointer-events: none;

      &.visualise-disabled {
        opacity: 0.35;
      }
    }

    &-content {
      height: 1.5rem;
      width: 1.5rem;
      min-width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999rem;
      cursor: pointer;
      transition: background 0.2s ease;

      &:hover {
        background: rgba(#000, 8%);
      }

      &-icon {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  &-wrapper {
    z-index: 99;
    opacity: 0;
    display: none;
    pointer-events: none;
    transition: opacity 0.2s ease;
    min-width: max-content;
    width: 100%;

    &.visible {
      opacity: 1;
      pointer-events: all;
      display: block;
    }

    &.position-fixed {
      position: fixed;
      width: max-content;
    }

    &:not(.position-fixed) {
      position: absolute;

      &.leftaligned {
        left: 0;
      }

      &.topaligned {
        bottom: calc(100% + 0.5rem);
      }

      &:not(.leftaligned) {
        right: 0;
      }

      &:not(.topaligned) {
        top: calc(100% + 0.5rem);
      }
    }
  }

  &-content {
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);

    &-item {
      min-width: max-content;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: background 0.2s ease;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: rgba(#000, 8%);
      }

      &-icon {
        width: 1rem;
        height: 1rem;
        min-width: 1rem;
      }

      &.filter-green {
        filter: brightness(0) saturate(100%) invert(77%) sepia(18%)
          saturate(3433%) hue-rotate(96deg) brightness(94%) contrast(72%);
      }

      &.filter-red {
        filter: brightness(0) saturate(100%) invert(24%) sepia(98%)
          saturate(5070%) hue-rotate(347deg) brightness(93%) contrast(113%);
      }
    }
  }
}
</style>
