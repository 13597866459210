











import { ProfileSettingTab } from '@c/features/profile-settings-modal/interfaces'
import UserHeaderModal from '@c/shared/molecules/object-visualisations/user/UserHeaderModal.vue'
import ProfileEditTab from '@c/features/profile-settings-modal/organisms/ProfileEditTab.vue'
import User from '@c/models/User'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'ProfileSettingsModal',
  components: {
    UserHeaderModal,
    ProfileEditTab
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    loadingPasswordReset: {
      type: Boolean,
      default: false
    },
    loadingSettings: {
      type: Boolean,
      default: false
    },
    initialActiveTab: {
      type: Number as () => ProfileSettingTab,
      default: ProfileSettingTab.EDIT
    }
  },
  data() {
    return {
      activeTab: this.initialActiveTab
    }
  }
})
