var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attributes" },
    [
      _c(
        "p",
        { staticClass: "attributes-header" },
        [
          _c("span", [_vm._v("Labels in your workspace")]),
          _c("Button", {
            attrs: {
              type: "white",
              text: "Add label type",
              icon: "plus-medium",
              "icon-left": true
            },
            on: {
              click: function() {
                return _vm.toggleAddAttribute("root")
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "attributes-add",
          class: { opened: _vm.showAddAttribute === "root" }
        },
        [
          _c("TextInput", {
            attrs: { placeholder: "Add label type", "max-length": 30 },
            model: {
              value: _vm.newAttributeInput,
              callback: function($$v) {
                _vm.newAttributeInput = $$v
              },
              expression: "newAttributeInput"
            }
          }),
          _c("Button", {
            attrs: { text: "Save" },
            on: {
              click: function() {
                return _vm.handleSaveAttribute()
              }
            }
          })
        ],
        1
      ),
      _vm.attributes.length
        ? _c("DraggableAccordion", {
            ref: "attributeaccordion",
            attrs: { items: _vm.accordionItems, bordered: true, gap: "1rem" },
            on: {
              open: function(s) {
                return (_vm.openedSection = s)
              },
              reorder: _vm.handlePositionChange
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function(ref) {
                    var header = ref.header
                    return [
                      _vm.showEditAttribute === header.uuid
                        ? _c("TextInput", {
                            staticClass: "attributes-list-item-name-edit",
                            attrs: {
                              "max-length": 30,
                              placeholder: "Label type name"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.editAttributeName,
                              callback: function($$v) {
                                _vm.editAttributeName = $$v
                              },
                              expression: "editAttributeName"
                            }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "buttons",
                  fn: function(ref) {
                    var header = ref.header
                    return [
                      _c(
                        "div",
                        { staticClass: "attributes-list-header-btns" },
                        [
                          _vm.showDeleteAttribute === header.uuid
                            ? _c("Button", {
                                attrs: { text: "Delete" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleDeleteAttribute(header)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.showDeleteAttribute === header.uuid
                            ? _c("Button", {
                                attrs: { text: "Cancel", type: "white" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.showDeleteAttribute = ""
                                  }
                                }
                              })
                            : _vm.showEditAttribute !== header.uuid
                            ? _c("Button", {
                                attrs: { type: "white", icon: "bin" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleDeleteAttributeToggle(
                                      header
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.showEditAttribute === header.uuid
                            ? _c("Button", {
                                attrs: { text: "Save" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleEditAttribute(header)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.showEditAttribute === header.uuid
                            ? _c("Button", {
                                attrs: { text: "Cancel", type: "white" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.showEditAttribute = ""
                                  }
                                }
                              })
                            : _c("Button", {
                                attrs: { type: "white", icon: "pencil-grey" },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleEditAttributeToggle(header)
                                  }
                                }
                              }),
                          _c("Button", {
                            attrs: {
                              type: "white",
                              text: "Add label",
                              icon: "plus-medium",
                              "icon-left": true
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return function() {
                                  return _vm.toggleAddAttribute(header.uuid)
                                }.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "content",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "attributes-list-add" }, [
                            _c(
                              "div",
                              {
                                staticClass: "attributes-add",
                                class: {
                                  opened: _vm.showAddAttribute === item.uuid
                                }
                              },
                              [
                                _c("TextInput", {
                                  attrs: {
                                    placeholder: "Add label",
                                    "max-length": 30
                                  },
                                  model: {
                                    value: _vm.newAttributeInput,
                                    callback: function($$v) {
                                      _vm.newAttributeInput = $$v
                                    },
                                    expression: "newAttributeInput"
                                  }
                                }),
                                _c("Button", {
                                  attrs: { text: "Save" },
                                  on: {
                                    click: function() {
                                      return _vm.handleSaveAttribute(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          item.values.length
                            ? _c("DraggableList", {
                                staticClass: "attributes-list",
                                attrs: {
                                  items: item.values,
                                  "item-key": "attributes-" + item.uuid,
                                  "item-style": {
                                    padding: "0.5rem 1.5rem"
                                  }
                                },
                                on: {
                                  reorder: function(pos) {
                                    return _vm.handlePositionChange(pos, item)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var attribute = ref.item
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "attributes-list-item"
                                            },
                                            [
                                              _vm.showEditAttribute ===
                                              attribute.uuid
                                                ? _c("TextInput", {
                                                    staticClass:
                                                      "attributes-list-item-name-edit",
                                                    attrs: {
                                                      placeholder: "Label name",
                                                      "max-length": 30
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editAttributeName,
                                                      callback: function($$v) {
                                                        _vm.editAttributeName = $$v
                                                      },
                                                      expression:
                                                        "editAttributeName"
                                                    }
                                                  })
                                                : _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "attributes-list-item-name"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            attribute.value
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "attributes-list-item-btns"
                                                },
                                                [
                                                  _vm.showDeleteAttribute ===
                                                  attribute.uuid
                                                    ? _c("Button", {
                                                        attrs: {
                                                          text: "Delete"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleDeleteAttribute(
                                                              item,
                                                              attribute
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.showDeleteAttribute ===
                                                  attribute.uuid
                                                    ? _c("Button", {
                                                        attrs: {
                                                          text: "Cancel",
                                                          type: "white"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.showDeleteAttribute =
                                                              ""
                                                          }
                                                        }
                                                      })
                                                    : _vm.showEditAttribute !==
                                                      attribute.uuid
                                                    ? _c("Button", {
                                                        attrs: {
                                                          type: "white",
                                                          icon: "bin"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleDeleteAttributeToggle(
                                                              attribute
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.showEditAttribute ===
                                                  attribute.uuid
                                                    ? _c("Button", {
                                                        attrs: { text: "Save" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleEditAttribute(
                                                              item,
                                                              attribute
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm.showEditAttribute ===
                                                  attribute.uuid
                                                    ? _c("Button", {
                                                        attrs: {
                                                          text: "Cancel",
                                                          type: "white"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.showEditAttribute =
                                                              ""
                                                          }
                                                        }
                                                      })
                                                    : _c("Button", {
                                                        attrs: {
                                                          type: "white",
                                                          icon: "pencil-grey"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleEditAttributeToggle(
                                                              attribute
                                                            )
                                                          }
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _c("div", { staticClass: "attributes-empty" }, [
                                _c("img", {
                                  staticClass: "attributes-empty-icon",
                                  attrs: {
                                    src: require("@/assets/icons/label.svg"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "span",
                                  { staticClass: "attributes-empty-text" },
                                  [
                                    _vm._v(
                                      "No values have been added for this label"
                                    )
                                  ]
                                )
                              ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3913878932
            )
          })
        : _c("div", { staticClass: "attributes-empty" }, [
            _c("img", {
              staticClass: "attributes-empty-icon",
              attrs: { src: require("@/assets/icons/label.svg"), alt: "" }
            }),
            _c("span", { staticClass: "attributes-empty-text" }, [
              _vm._v("No labels could be found")
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }