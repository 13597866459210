var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ph-2" },
    [
      _c("p", { staticClass: "header bold black xs mb-3" }, [
        _vm._v(
          " Connect with your " + _vm._s(_vm.integration.title) + " cookie "
        )
      ]),
      Array.isArray(_vm.integration.setup.instructions)
        ? _c(
            "ol",
            { staticClass: "ph-4 xs" },
            _vm._l(_vm.integration.setup.instructions, function(
              instruction,
              idx
            ) {
              return _c("li", { key: "instruction-" + idx }, [
                _vm._v(" " + _vm._s(instruction) + " ")
              ])
            }),
            0
          )
        : _c("p", { staticClass: "ph-4 xs" }, [
            _vm._v(_vm._s(_vm.integration.setup.instructions))
          ]),
      _c(
        "b-field",
        { staticClass: "pt-4 mb-4", attrs: { label: "Cookie" } },
        [
          _c("b-input", {
            attrs: { placeholder: _vm.integration.title + " access token" },
            model: {
              value: _vm.$data["token"],
              callback: function($$v) {
                _vm.$set(_vm.$data, "token", $$v)
              },
              expression: "$data['token']"
            }
          })
        ],
        1
      ),
      _c("ModalActionFooter", {
        staticClass: "mt-7",
        attrs: { action: "Connect " + _vm.integration.title },
        on: {
          close: function($event) {
            return _vm.$emit("close")
          },
          action: function($event) {
            return _vm.$emit("save", _vm.$data)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }