<template>
  <component
    :is="clickable ? 'router-link' : 'div'"
    :to="route"
    class="offering-card-wrapper"
  >
    <div class="offering-card" :class="{ clickable }">
      <div class="offering-card-left">
        <img
          v-if="image && !imageErrored"
          class="offering-card-image"
          :src="image"
          alt=""
        />
        <div
          v-else
          class="offering-card-image"
          :style="{ background: $umodel.user_color(offering) }"
        >
          {{ $umodel.initials(offering.name) }}
        </div>
        <div class="offering-card-content">
          <div class="offering-card-name-wrapper">
            <p class="offering-card-name">
              {{ offering.name }}
            </p>
            <StatusOrb
              v-if="showStatus"
              :type="
                offering.status
                  ? offering.status === 'published'
                    ? 'green'
                    : 'orange'
                  : 'grey'
              "
              size="xs"
              :text="offering.status.capitalize() || 'No content'"
              class="offering-card-name-status"
            />
          </div>
          <ORITagEdit
            v-if="showAttributes && attributes.length"
            :selected="attributes"
            :editable="false"
            :overflowing="true"
            tag-size="xxs"
          />
        </div>
      </div>
      <slot name="right"></slot>
    </div>
  </component>
</template>

<script>
import ORITagEdit from '../ori/ORITagEdit.vue'
import StatusOrb from '@c/library/StatusOrb.vue'

export default {
  name: 'OfferingCard',
  components: { ORITagEdit, StatusOrb },
  props: {
    offering: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    showAttributes: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    imageErrored: false
  }),
  computed: {
    image() {
      return this.offering.image || ''
    },
    attributes() {
      return this.offering?.attributes || []
    },
    route() {
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: 'offerings',
          ori_id: this.offering.uuid
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.offering-card {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(#000, 8%);
  background: white;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  height: 100%;

  &-wrapper {
    height: 100%;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(#000, 16%);
      box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
    }
  }

  &-left {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    overflow: hidden;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    overflow: hidden;
  }

  &-image {
    width: 4rem;
    min-width: 4rem;
    max-width: 8rem;
    height: 4rem;
    min-height: 4rem;
    border-radius: 4px;
    object-fit: contain;
    overflow: hidden;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  &-name {
    font-size: 1.14rem;
    font-weight: 600;
    color: #303032;
    display: inline;

    &-status {
      display: inline-flex;
      transform: translateY(2px);
    }
  }
}
</style>
