var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DarkDialogContainer",
    {
      staticStyle: { "margin-top": "-8px" },
      attrs: {
        type: "light",
        "vertical-align": "top",
        "horizontal-align": "right"
      }
    },
    [
      _c("div", { staticClass: "ph-4 pb-2" }, [
        _c("p", { staticClass: "body body-text caps xxs" }, [
          _vm._v("Connected To:")
        ]),
        _c("p", { staticClass: "body xxs" }, [
          _vm._v(_vm._s(_vm.integration.title))
        ])
      ]),
      _c("div", { staticClass: "mv-2" }, [
        _c("div", { staticClass: "divider very-subtle full-width no-margin" })
      ]),
      _c("div", { staticClass: "ph-4 pb-2" }, [
        _c("ul", { staticClass: "menu-list dark" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "body no-padding-horizontal",
                on: { click: _vm.disconnect }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "trash-can", size: "is-small" }
                }),
                _vm._v(" Disconnect app ")
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }