import { getClient } from '../../../store/src/axios'

const client = getClient('/workspaces')
const userClient = getClient('/users')

export async function mergeSubresources(workspaceId, subresourceIds, act_as) {
  const { data } = await client.post(
    `/${workspaceId}/subresources/merge/`,
    {
      subresource_ids: subresourceIds
    },
    {
      ...(act_as && { params: { act_as } })
    }
  )
  return data
}

export async function getDocumentDownloadLink(workspaceId, resourceId, act_as) {
  const { data } = await client.get(
    `/${workspaceId}/resources/${resourceId}/content/`,
    {
      params: {
        act_as
      }
    }
  )
  return data
}

export function downloadSignedUrl(url, title, callback = null) {
  let xhr = new XMLHttpRequest()
  const a = document.createElement('a')

  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const file = new Blob([xhr.response], { type: 'application/octet-stream' })
    a.href = window.URL.createObjectURL(file)
    a.download = title
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    if (callback) callback()
  }
  xhr.send()
}

export async function uploadToIntegration(
  user_id,
  integration_id,
  integrationfile_id,
  signed_url,
  name
) {
  const { data } = await userClient.post(
    `/${user_id}/integrations/${integration_id}/files/`,
    {
      parent_integrationfile_id: integrationfile_id,
      signed_url,
      name
    }
  )
  return data
}
