var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recent" },
    _vm._l(_vm.types, function(type, idx) {
      return _c("RecentORI", {
        key: "recent-" + type,
        attrs: {
          type: type,
          "page-size": [6, 3, 3][idx],
          columns: [3, 1, 1][idx]
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }