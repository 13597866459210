import Console from '@/console'
import store from '@/store'

export async function addImpersonationGuard(router) {
  router.beforeEach(async (to, from, next) => {
    if (store.getters.debugGuards)
      Console.debug('Impersonation guard (to, from)', to, from)
    if (!to.query.act_as && from.query.act_as) {
      if (
        'act_as' in to.query ||
        to.params.workspace_id !== from.params.workspace_id
      ) {
        next()
      } else if (from.query.act_as && to.name !== 'login') {
        next({
          ...to,
          query: {
            ...to.query,
            act_as: from.query.act_as
          }
        })
      }
    }
    next()
  })
}
