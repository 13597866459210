import Vue from 'vue'
import {
  getClient,
  getPaginatedData,
  getResponseData,
  Paginator
} from '../../axios'
import Mutations from '../mutation-types'

const client = getClient('/integrations', false)
const createClient = (workspace_id) =>
  getClient(`/workspaces/${workspace_id}/integrations`, false)

const getDefaultState = () => {
  return {
    domains: undefined,
    domainSpecificActions: undefined,
    integrationsByWorkspace: {},
    userIntegrations: [],
    workspaceMemberIntegrations: [],
    workspaceMemberIntegrationsLoaded: false
  }
}

export default {
  state: {
    ...getDefaultState()
  },
  getters: {
    integrationsByWorkspace: (state) => state.integrationsByWorkspace,
    userIntegrations: (state) => state.userIntegrations,
    workspaceMemberIntegrations: (state) => state.workspaceMemberIntegrations,
    workspaceMemberIntegrationsLoaded: (state) =>
      state.workspaceMemberIntegrationsLoaded
  },
  mutations: {
    [Mutations.SET_CURRENT_WIDGET_DOMAINS](state, { domains }) {
      state.domains = domains
    },
    [Mutations.SET_CURRENT_DOMAIN_SPECIFIC_ACTIONS](state, { domains }) {
      state.domainSpecificActions = domains
    },
    setIntegrationsByWorkspace(state, { integrations, workspace_id }) {
      Vue.set(state, 'integrationsByWorkspace', {
        ...state.integrationsByWorkspace,
        [workspace_id]: integrations
      })
    },
    removeIntegrationFromWorkspace(state, { integration_id, workspace_id }) {
      Vue.set(state, 'integrationsByWorkspace', {
        ...state.integrationsByWorkspace,
        [workspace_id]: state.integrationsByWorkspace[workspace_id].filter(
          (x) => x.id !== integration_id
        )
      })
    },
    setUserIntegrations(state, integrations) {
      state.userIntegrations = integrations
    },
    setWorkspaceMemberIntegrations(state, integrations) {
      state.workspaceMemberIntegrations = integrations
    },
    setWorkspaceMemberIntegrationsLoaded(state, loaded) {
      state.workspaceMemberIntegrationsLoaded = loaded
    }
  },
  actions: {
    // Workspace-level integrations

    async getIntegrations({ commit }, { workspace_id }) {
      const res = await createClient(workspace_id).get('/')
      const data = getResponseData(res)
      commit('setIntegrationsByWorkspace', { workspace_id, integrations: data })
      return data
    },

    async deleteIntegration(
      { commit },
      { workspace_id, integration_id, delete_resources }
    ) {
      const res = await createClient(workspace_id).delete(
        `/${integration_id}/`,
        { params: { delete_resources } }
      )
      commit('removeIntegrationFromWorkspace', { workspace_id, integration_id })
      return getResponseData(res)
    },

    async getIntegrationUsers(_, { workspace_id, integration_id }) {
      return await getPaginatedData(
        createClient(workspace_id),
        `/${integration_id}/users/`
      )
    },

    async getIntegrationUsersPaginator(_, { workspace_id, integration_id }) {
      const client = createClient(workspace_id)
      return new Paginator(
        client,
        client.get(`/${integration_id}/users/`),
        () => {}
      )
    },

    async getWidgetDomains({ commit }, data) {
      const res = await client.get('/widgets/domains/', data)
      const domains = getResponseData(res)
      commit(Mutations.SET_CURRENT_WIDGET_DOMAINS, { domains: domains })
      return domains
    },

    async getDomainSpecificActions({ commit }, data) {
      const res = await client.get('/actions/domains/', data)
      const domains = getResponseData(res)
      commit(Mutations.SET_CURRENT_DOMAIN_SPECIFIC_ACTIONS, {
        domains: domains
      })
      return domains
    },

    async authenticateIntegration(
      { state, commit },
      { workspace_id, integration, oauth, api_key }
    ) {
      const res = await createClient(workspace_id).post('/authenticate/', {
        integration,
        ...(oauth ? { oauth } : {}),
        ...(api_key ? { api_key } : {})
      })
      const int = getResponseData(res)
      window.localStorage.setItem('integrationWorkspace', workspace_id)
      commit('setIntegrationsByWorkspace', {
        workspace_id,
        integrations: [
          ...(state.integrationsByWorkspace[workspace_id] || []),
          int
        ]
      })
      return IntersectionObserver
    },

    async getIntegrationOAuthState(_, { workspace_id }) {
      const res = await createClient(workspace_id).get('/oauth_state/')
      return getResponseData(res)
    },

    async getIntegrationFiles(_, { workspace_id, integration_id, path }) {
      const client = createClient(workspace_id)
      return getPaginatedData(client, `/${integration_id}/files/`, {
        params: { path }
      })
    },

    async postIntegrationFiles(_, { workspace_id, integration_id, payload }) {
      const res = await createClient(workspace_id).post(
        `/${integration_id}/sync/`,
        payload
      )
      return getResponseData(res)
    },

    async getIntegrationSyncStats(_, { workspace_id, integration_id, params }) {
      return await getPaginatedData(
        createClient(workspace_id),
        `/${integration_id}/stats/`,
        { params: { ...params } },
        20
      )
    },

    // User-level integrations

    async getUserIntegrations({ commit }) {
      const client = getClient('/users')
      const res = await client.get('/me/integrations/')
      const data = getResponseData(res)
      commit('setUserIntegrations', data)
      return data
    },

    async authenticateUserIntegration(_, { integration, code }) {
      const res = await getClient('/users').post(
        '/me/integrations/authenticate/',
        {
          integration,
          oauth: { code }
        }
      )
      return getResponseData(res)
    },

    async getUserIntegrationFiles(_, { integration_id, query, path }) {
      const client = getClient('/users')
      return getPaginatedData(
        client,
        `/me/integrations/${integration_id}/files/`,
        {
          params: {
            ...(query ? { query } : {}),
            ...(path ? { path } : {})
          }
        }
      )
    },

    async getUserIntegrationFilesPaginator(
      _,
      { integration_id, search, path, page_size = 20 }
    ) {
      const client = getClient('/users')
      const body = {
        search,
        ...(path ? { path } : {}),
        ...(page_size ? { page_size } : {})
      }

      const res = await client.get(
        `/me/integrations/${integration_id}/files/`,
        { params: { ...body } }
      )

      return new Paginator(
        client,
        res,
        () => {},
        undefined,
        (url) => client.get(url, body)
      )
    },

    // Workspace member level integrations

    async getWorkspaceMemberIntegrations(
      { commit },
      { workspace_id, member_id }
    ) {
      const client = getClient(
        `/workspaces/${workspace_id}/members/${member_id}`
      )
      const res = await client.get('/integrations/')
      const data = getResponseData(res)
      commit('setWorkspaceMemberIntegrations', data)
      commit('setWorkspaceMemberIntegrationsLoaded', true)
      return data
    },

    async authenticateWorkspaceMemberIntegration(
      _,
      { workspace_id, member_id, integration, code }
    ) {
      const res = await getClient(
        `/workspaces/${workspace_id}/members/${member_id}`
      ).post('/integrations/authenticate/', {
        integration,
        oauth: { code }
      })
      return getResponseData(res)
    },

    async getWorkspaceMemberIntegrationFiles(
      _,
      {
        workspace_id,
        member_id,
        integration_id,
        query,
        path,
        object_name = 'files'
      }
    ) {
      const client = getClient(
        `/workspaces/${workspace_id}/members/${member_id}`
      )
      return getPaginatedData(
        client,
        `/integrations/${integration_id}/${object_name}/`,
        {
          params: {
            ...(query ? { query } : {}),
            ...(path ? { path } : {})
          }
        }
      )
    },

    async getWorkspaceMemberIntegrationFilesPaginator(
      _,
      {
        workspace_id,
        member_id,
        integration_id,
        search,
        path,
        object_name = 'files',
        page_size = 20
      }
    ) {
      const client = getClient(
        `/workspaces/${workspace_id}/members/${member_id}`
      )
      const body = {
        search,
        ...(path ? { path } : {}),
        ...(page_size ? { page_size } : {})
      }

      const res = await client.get(
        `/integrations/${integration_id}/${object_name}/`,
        { params: { ...body } }
      )

      return new Paginator(
        client,
        res,
        () => {},
        undefined,
        (url) => client.get(url, body)
      )
    },

    async setCalendarSync(
      _,
      { workspace_id, member_id, integration_id, calendar_id, sync, tool_ids }
    ) {
      const client = getClient(
        `/workspaces/${workspace_id}/members/${member_id}`
      )
      const res = await client.patch(
        `/integrations/${integration_id}/calendars/${calendar_id}/`,
        {
          sync,
          tool_ids
        }
      )
      return getResponseData(res)
    }
  }
}
