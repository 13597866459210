export default interface User {
  uuid: string
  username: string
  email: string
  first_name: string
  last_name: string
  avatar: string
  date_joined: string
}

export interface UserMinimal {
  uuid: string
  username: string
  first_name: string
  last_name: string
  avatar?: string
}

export interface Expert {
  uuid: string
  username: string
  first_name: string
  last_name: string
  avatar?: string
  doc_count: number
  hits: Record<string, any>
  topic_taglines: string[]
}

export enum ACCESS_LEVEL_ENUM {
  FULL_ACCESS = 'FULL_ACCESS',
  ADMIN = 'ADMIN'
}

export const ACCESS_LEVELS: { [key: string]: string } = {
  FULL_ACCESS: 'Full Access'
  // ADMIN: 'Admin'
}

export function first_name(user: any) {
  return (
    user.member?.user?.first_name || user.user?.first_name || user.first_name
  )
}

export function last_name(user: any) {
  return user.member?.user?.last_name || user.user?.last_name || user.last_name
}

export function email(user: any) {
  return (
    user.member?.user?.username ||
    user.user?.username ||
    user.username ||
    user.email
  )
}

export function avatar(user: any) {
  return (
    user.member?.user?.avatar || user.user?.avatar || user.avatar || user.image
  )
}

export function full_name(user: any) {
  return first_name(user)
    ? `${first_name(user)} ${last_name(user)}`
    : email(user)
}

export function initials(user: any) {
  const name = typeof user === 'string' ? user : full_name(user)
  return (
    name
      .split(' ')
      .map((str: string) => str.charAt(0))
      .join('')
      .toUpperCase()
      .replace(/[^A-Z]/g, '')
      .slice(0, 2) || name[0]
  )
}

export function user_color(user: any) {
  const colors = [
    '#A6BD94',
    '#CCB68C',
    '#91B4FD',
    '#B49BFD',
    '#D89BFD',
    '#FD9BE8',
    '#FE9AA0',
    '#FDAC9B',
    '#FCAD4F',
    '#FCCF36',
    '#7CDFB4'
  ]
  const i = (hashCode(user) % (colors.length >> 1)) + (colors.length >> 1)
  return colors[i]

  // // generate from user hashcode
  // const deg = 359
  // const precision = 999997
  // const hue = (hashCode(user) % (deg * precision)) / (1.0 * precision) + deg
  // return `hsl(${hue}, 65%, 70%)`
}

/*
Adapted from StackOverflow:
https://stackoverflow.com/a/7616484
*/
function hashCode(x: any) {
  var hash = 0,
    i,
    h
  const addHash = (hash: number, h: number) => ((hash << 5) - hash + h) | 0

  // hash object / array
  if ((typeof x === 'object' || x instanceof Object) && x !== null) {
    for (let k in x) {
      h = hashCode(x[k])
      hash = addHash(hash, h)
    }
  }
  // hash string
  else if (typeof x === 'string' || x instanceof String) {
    if (x.length === 0) return hash
    for (i = 0; i < x.length; i++) {
      h = x.charCodeAt(i)
      hash = addHash(hash, h)
    }
  } else return x as number

  return hash
}
