<template>
  <div class="u-columns space-between is-vcentered">
    <div class="u-column is-narrow">
      <div class="u-columns is-vcentered" style="flex-wrap: nowrap">
        <div v-if="!noIcon" class="column no-padding is-narrow">
          <div class="pr-3">
            <slot name="icon" />
          </div>
        </div>
        <div v-if="!noTitle" class="column no-padding is-narrow">
          <slot name="title" />
        </div>
      </div>
    </div>
    <div class="u-column is-narrow">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalHeader',
  props: {
    noIcon: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
