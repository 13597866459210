import * as Sentry from '@sentry/browser'

const debug = process.env.NODE_ENV != 'production'

const Console = {
  debug(...msg) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: msg.join(' '),
      level: Sentry.Severity.Debug
    })
    if (debug) {
      console.debug('[DEBUG]', ...msg) // eslint-disable-line no-console
    }
  },
  log(...msg) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: msg.join(' '),
      level: Sentry.Severity.Log
    })
    if (debug) {
      console.log(...msg) // eslint-disable-line no-console
    }
  },
  info(...msg) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: msg.join(' '),
      level: Sentry.Severity.Info
    })
    console.info(...msg) // eslint-disable-line no-console
  },
  warning(...msg) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: msg.join(' '),
      level: Sentry.Severity.Warning
    })
    console.warn(...msg) // eslint-disable-line no-console
  },
  error(...msg) {
    Sentry.captureMessage(msg.join(' '), Sentry.Severity.Error)
    console.error(...msg) // eslint-disable-line no-console
  }
}

export default Console
