var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "SettingsModal",
        _vm._g(
          {
            attrs: {
              workspace: _vm.workspace,
              "active-integrations": _vm.activeIntegrations
            },
            on: {
              authenticateIntegration: _vm.onConnectClick,
              deleteIntegration: _vm.deleteIntegration
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }