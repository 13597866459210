





















































































import ImageFilePicker from '@c/shared/molecules/inputs/ImageFilePicker.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'

export default Vue.extend({
  name: 'EditTab',
  components: {
    ImageFilePicker,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    icon: {
      type: String,
      default: undefined
    },
    initialName: {
      type: String,
      default: undefined
    },
    initialDescription: {
      type: String,
      default: undefined
    },
    maxlength: {
      type: Number,
      default: 140
    },
    iconSize: {
      type: String,
      default: 'xxl'
    },
    creating: {
      type: Boolean,
      default: false
    },
    loadingButtonText: {
      type: String,
      default: 'Saving...'
    },
    buttonText: {
      type: String,
      default: 'Save changes'
    },
    allowPublicWorkspaces: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    paddingless: {
      type: Boolean,
      default: false
    }
  },
  data: function (): {
    name: string
    description: string
  } {
    return {
      name: this.initialName,
      description: this.initialDescription,
      avatar: this.icon
    }
  },
  watch: {
    initialName(newVal) {
      this.name = newVal
    },
    initialDescription(newVal) {
      this.description = newVal
    },
    name(newVal) {
      this.$emit('nameChanged', newVal)
    },
    description(newVal) {
      this.$emit('descriptionChanged', newVal)
    },
    avatar(newVal) {
      if (newVal?.size > 2 * 1000 * 1000) {
        this.$toast.danger(
          'Workspace image too large',
          'We support images up to 2MB, your image exceeded this amount. Please upload a smaller workspace avatar.'
        )
      } else {
        this.$emit('newAvatar', newVal)
      }
    }
  }
})
