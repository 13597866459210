<template>
  <DraggableList
    :items="items"
    :item-key="itemKey"
    :item-style="{
      padding: '0 0 0 0.5rem',
      borderRadius: '4px',
      border: bordered ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'
    }"
    :gap="gap"
    class="accordion"
    @reorder="(order) => $emit('reorder', order)"
    @item-click="(i) => toggleOpen(i)"
  >
    <template #item="{ item }">
      <div
        class="accordion-item-header"
        :class="{ opened: opened === item.id }"
      >
        <img
          src="@/assets/icons/chevron-down-medium.svg"
          alt=""
          class="accordion-item-header-icon"
        />
        <slot name="header" :header="item">
          {{ item.name }}
        </slot>
        <div class="accordion-item-header-buttons">
          <slot name="buttons" :header="item"></slot>
        </div>
      </div>
    </template>
    <template #after="{ item }">
      <div
        class="accordion-item-content"
        :class="{ opened: opened === item.id }"
      >
        <slot name="content" :item="item"></slot>
      </div>
    </template>
  </DraggableList>
</template>

<script>
import DraggableList from './DraggableList.vue'

export default {
  name: 'DraggableAccordion',
  components: {
    DraggableList
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    bordered: {
      type: Boolean,
      default: false
    },
    gap: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    opened: ''
  }),
  methods: {
    toggleOpen(item) {
      this.opened = this.opened === item.id ? '' : item.id
      this.$emit('open', this.opened)
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  &-item {
    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      padding: 0.75rem 1rem;
      cursor: pointer;

      &.opened {
        & > .accordion-item-header-icon {
          transform: rotate(180deg);
        }
      }

      &-icon {
        height: 1.2rem;
        transition: transform 0.3s;
      }

      &-buttons {
        margin-left: auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
        align-items: center;
      }
    }

    &-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s;

      &.opened {
        max-height: 100vh;
      }
    }
  }
}
</style>
