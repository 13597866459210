












import Vue from 'vue'
import {
  mimetypeToType,
  typeToImage,
  typeToLargeImage
} from '../../../mimetypes'

export default Vue.extend({
  name: 'DocumentTag',
  props: {
    mimetype: {
      type: String,
      default: ''
    },
    large: {
      type: Boolean as () => boolean,
      default: false
    }
  },
  computed: {
    inferredMimetype() {
      return mimetypeToType[this.mimetype]
    },
    canShow() {
      return Boolean(this.inferredMimetype)
    },
    image() {
      // this.$console.debug(typeToImage[this.inferredMimetype])
      return this.large
        ? typeToLargeImage[this.inferredMimetype]
        : typeToImage[this.inferredMimetype]
    }
  }
})
