var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "preview-image-container": true,
        "preview-border-normal": true,
        "zoom-cursor": _vm.zoomCursor
      },
      on: {
        mouseenter: function($event) {
          _vm.isHovering = true
        },
        mouseleave: function($event) {
          _vm.isHovering = false
        }
      }
    },
    [
      _vm.canLoad && _vm.subresource.preview
        ? _c("img", {
            ref: "image",
            staticClass: "preview-image",
            attrs: { src: _vm.subresource.preview },
            on: {
              load: _vm.handleImageLoaded,
              error: function($event) {
                return _vm.$emit("cantLoad")
              }
            }
          })
        : _vm._e(),
      _vm.imageLoading || !_vm.canLoad
        ? _c(
            "div",
            { staticClass: "subresource-loading-overlay" },
            [
              _c("b-loading", {
                attrs: { "is-full-page": false, active: _vm.imageLoading }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "subresource-hover-scrim" }),
      _vm.showPageButton
        ? _c(
            "div",
            { staticClass: "body xxs pv-2 ph-3 white subresource-scrim-tag" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.unit.capitalize()) +
                  " " +
                  _vm._s(_vm.subresource.page) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.slideCollected || _vm.isHovering,
                expression: "slideCollected || isHovering"
              }
            ],
            staticClass: "subresource-hover-buttons"
          },
          [
            _c("QualityLabels", {
              attrs: {
                "active-label": _vm.subresource.label,
                "source-id": _vm.subresource.uuid,
                "small-labels": false,
                "show-hover-info": false,
                "show-edit-labels": false,
                "bordered-icons": true
              }
            }),
            _c("CollectSlideButton", {
              attrs: {
                subresource: _vm.subresource,
                rank: _vm.rank,
                mimetype: _vm.mimetype,
                "resource-trace-id": _vm.resourceTraceId,
                "always-show": _vm.forceShowCollect
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }