<template>
  <div>
    <div class="mb-4 text-container">
      <div
        class="u-columns is-vcentered space-between"
        :style="{ 'justify-content': center ? 'center' : undefined }"
      >
        <div class="u-column is-narrow text-container" style="max-width: 100%">
          <b-tooltip
            :label="tooltip"
            :dashed="tooltip !== undefined"
            type="is-dark"
            class="tooltip-body-text"
            position="is-right"
            multilined
            :active="tooltip !== undefined"
          >
            <p
              class="body bold header title"
              :class="{
                'has-text-centered': center,
                'has-text-white': dark,
                caps: type === 'capped-title',
                'mb-3': type === 'black-spaced',
                [titleSize]: true
              }"
              style="line-height: 1rem"
            >
              {{ title }}
              <b-icon
                v-if="tooltip !== undefined"
                size="is-small"
                icon="help-circle-outline"
                class="tooltip-icon-body-text"
              />
            </p>
          </b-tooltip>
          <p
            v-if="subtitle"
            class="body"
            :class="{
              'has-text-centered': center,
              [subtitleSize]: true,
              'body-text': type !== 'black-spaced'
            }"
          >
            {{ subtitle }}
          </p>
        </div>
        <div class="column is-narrow no-padding action-container">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
const types = ['default', 'capped-title', 'black-spaced']

export default {
  name: 'ContentWithHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: undefined
    },
    center: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'default',
      validator: (val) => types.includes(val)
    },
    titleSize: {
      type: String,
      default: 'xs'
    },
    subtitleSize: {
      type: String,
      default: 'xs'
    }
  }
}
</script>

<style scoped lang="scss">
.tooltip-body-text {
  border-color: $body-text;
}

.tooltip-icon-body-text {
  color: $body-text;
}
</style>
