import { Store } from 'vuex'
import AbstractSensor from './AbstractSensor'

export default class StoreSensor<T> extends AbstractSensor<T> {
  protected store: Store<any>

  constructor(type: string, store: Store<any>) {
    super(type)
    this.store = store
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  protected transformState(payload: any, state: any): T {
    throw Error('Not implemented')
  }
}
