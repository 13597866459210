import Console from '@/console'
import store from '@/store'
import { getAppHostname, getAppURL } from '../../../../util'

export function addRedirectGuard(router) {
  router.beforeEach((to, from, next) => {
    // Handle redirect
    if (store.getters.debugGuards)
      Console.debug('Redirect guard (to, from)', to, from)
    const authenticated = store.getters.authenticated
    const redirect = sessionStorage.getItem('redirect')
    if (authenticated && redirect && redirect.length > 0) {
      const redirectURL = new URL(redirect, getAppURL(''))
      if (redirectURL.host !== getAppHostname()) {
        Console.warning(`Redirect requested to ${redirect}, ignoring`)
      }
      sessionStorage.removeItem('redirect')
      next(redirectURL.pathname + redirectURL.search)
    }
    next()
  })
}
