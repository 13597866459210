import { getClient } from '../../../store/src/axios'

const workspaceClient = getClient('/workspaces')

export async function getChats({ workspace_id, act_as }) {
  const { data } = await workspaceClient.get(`/${workspace_id}/chats/`, {
    params: { act_as }
  })
  return data
}

export async function getChatHistory({ workspace_id, chat_id, act_as }) {
  const { data } = await workspaceClient.get(
    `/${workspace_id}/chats/${chat_id}/`,
    {
      params: { act_as }
    }
  )
  return data
}

export async function askQuestion({
  workspace_id,
  question,
  chat_id,
  act_as,
  sources
}) {
  const { data } = await workspaceClient.post(
    `/${workspace_id}/chats/`,
    {
      question,
      ...(chat_id && { uuid: chat_id }),
      ...(sources && { sources })
    },
    {
      ...(act_as && { params: { act_as } })
    }
  )
  return data
}

export async function deleteChat({ workspace_id, chat_id, act_as }) {
  const { data } = await workspaceClient.delete(
    `/${workspace_id}/chats/${chat_id}/`,
    {
      params: { act_as }
    }
  )
  return data
}
