import { capitalize, every } from 'lodash-es'
import {
  avatar,
  email,
  first_name,
  full_name,
  initials,
  last_name,
  user_color
} from './User'
import { mapDateString, relativeTime } from './shared'

export const utils = {
  full_name,
  initials,
  user_color,
  first_name,
  last_name,
  email,
  avatar,
  mapDateString,
  relativeTime,
  capitalize
}

export function permissionsToRole<T extends string>(
  permissions: string[],
  roleLadder: T[],
  rolePermissionMap: Record<string, string[]>
): T {
  const permissionSet = new Set(permissions)
  for (const role of roleLadder) {
    if (every(rolePermissionMap[role as string], (x) => permissionSet.has(x))) {
      return role
    }
  }
  throw Error('Could not map permissions to a role')
}
