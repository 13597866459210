
































































import DefaultModal from '@c/shared/molecules/structural/modals/DefaultModal.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'DialogModal',
  components: { DefaultModal },
  props: {
    title: {
      type: String,
      default: undefined
    },
    header: {
      type: String,
      default: undefined
    },
    action: {
      type: String,
      default: undefined
    },
    modificationType: {
      type: String,
      default: undefined
    },
    objectType: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: ''
    },
    object: {
      type: Object as () => any,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    },
    onConfirm: {
      type: Function as any,
      default: function (): (objectType: any) => boolean {
        return () => true
      }
    },
    type: {
      type: String,
      default: 'danger'
    }
  },
  data() {
    return { loading: false }
  },
  methods: {
    async confirm() {
      this.loading = true
      const success = await Promise.resolve(this.onConfirm(this.object))
      if (success) {
        this.$emit('close')
      }
      this.loading = false
    }
  }
})
