var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "integration-link u-columns is-vcentered" }, [
    _c("div", { staticClass: "u-column is-narrow align-center" }, [
      _c("div", { staticStyle: { height: "16px" } }, [
        _vm.integrationData.image &&
        (_vm.hover || !_vm.integrationData.grayImage)
          ? _c("img", {
              staticClass: "integration-btn",
              attrs: { src: _vm.integrationData.image }
            })
          : _c("img", {
              staticClass: "integration-btn",
              attrs: { src: _vm.integrationData.grayImage }
            })
      ])
    ]),
    !_vm.condensed
      ? _c(
          "div",
          { staticClass: "u-column ml-3 u-columns" },
          [
            !_vm.noIntegrationPathTooltip && _vm.integrationPath.length > 1
              ? _c(
                  "b-tooltip",
                  {
                    staticClass: "u-column",
                    attrs: {
                      active:
                        _vm.integrationPath !== undefined &&
                        !_vm.noIntegrationPathTooltip,
                      type: "is-dark",
                      animated: "",
                      position: "is-top"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "u-columns is-vcentered" },
                                [
                                  _vm.integrationPath.length > _vm.maxPathLength
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-column u-columns no-padding is-vcentered"
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "u-column integration-white"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.abbreviate(_vm.pathRoot)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "u-column integration-white pr-3 pl-3 chevron-right"
                                            },
                                            [_vm._v(" ❯ ... ❯ ")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(_vm.pathBody, function(
                                    pathEntry,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "u-column u-columns no-padding is-vcentered"
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "u-column integration-white"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.abbreviate(pathEntry)
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "u-column integration-white pr-3 pl-3 chevron-right"
                                          },
                                          [_vm._v(" ❯ ")]
                                        )
                                      ]
                                    )
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "u-column semi-bold integration-white"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.abbreviate(_vm.pathHeader)
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                2
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1339492223
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "u-columns is-vcentered",
                        on: {
                          mouseenter: function($event) {
                            return _vm.$emit("path-inspection")
                          }
                        }
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "body u-column semi-bold integration-path-header"
                          },
                          [_vm._v(" " + _vm._s(_vm.pathHeader) + " ")]
                        )
                      ]
                    )
                  ]
                )
              : _c("div", [
                  _c("div", { staticClass: "u-columns is-vcentered" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "body u-column semi-bold integration-path-header"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.pathHeader || _vm.pathRoot) + " "
                        )
                      ]
                    )
                  ])
                ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }