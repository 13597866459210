var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    _vm._g(
      _vm._b(
        {
          staticClass: "u-btn border element none",
          class: { "light-gray": _vm.lightGray },
          attrs: { size: "is-small", "icon-left": "dots-vertical" }
        },
        "b-button",
        Object.assign({}, _vm.$props, _vm.$attrs),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }