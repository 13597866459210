import { render, staticRenderFns } from "./FileAppIntegrations.vue?vue&type=template&id=47b6dcc4&scoped=true&"
import script from "./FileAppIntegrations.vue?vue&type=script&lang=ts&"
export * from "./FileAppIntegrations.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b6dcc4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47b6dcc4')) {
      api.createRecord('47b6dcc4', component.options)
    } else {
      api.reload('47b6dcc4', component.options)
    }
    module.hot.accept("./FileAppIntegrations.vue?vue&type=template&id=47b6dcc4&scoped=true&", function () {
      api.rerender('47b6dcc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/features/workspace-settings-modal/organisms/FileAppIntegrations.vue"
export default component.exports