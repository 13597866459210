<template>
  <div class="activity-grid-container" :style="sizeStyle">
    <div
      v-for="(week, weekIndex) in weeks"
      :key="weekIndex"
      class="activity-grid-col"
    >
      <div
        v-if="monthStarts[weekIndex]"
        class="activity-grid-label"
        :class="{ big }"
      >
        {{ monthStarts[weekIndex] }}
      </div>
      <div
        v-for="(day, dayIndex) in week"
        :key="`${weekIndex}-${dayIndex}`"
        class="activity-grid-cell"
        :class="{ active: day.count > 0 }"
        @mouseenter="hovered = `${weekIndex}-${dayIndex}`"
        @mouseleave="hovered = ''"
      >
        <div
          v-if="hovered === `${weekIndex}-${dayIndex}`"
          class="activity-grid-cell-hover"
          :class="{ leftaligned: weekIndex < 26 }"
        >
          <p class="activity-grid-cell-hover-day">
            {{ getFormattedDate(weekIndex, dayIndex) }}
          </p>
          <p class="activity-grid-cell-hover-count">
            {{ day.count }}
            change{{ day.count === 1 ? '' : 's'
            }}{{ Object.keys(day.users).length > 0 ? ' by' : '' }}
          </p>
          <ul
            v-if="Object.keys(day.users).length > 0"
            class="activity-grid-cell-hover-user-list"
          >
            <li
              v-for="user in Object.values(day.users)"
              :key="user.member.uuid"
              class="activity-grid-cell-hover-user"
            >
              {{ $umodel.full_name(user) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      type: Array,
      required: true
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hovered: '',
    weekMs: 1000 * 60 * 60 * 24 * 7,
    dayMs: 1000 * 60 * 60 * 24
  }),
  computed: {
    yearAgo() {
      return new Date(Date.now() - 52 * this.weekMs)
    },
    weeks() {
      const weeks = Array.from({ length: 52 }, () =>
        Array.from({ length: 7 }, () => ({ count: 0, users: {} }))
      )
      this.activity.forEach((activity) => {
        const date = new Date(activity.date)
        if (date.getTime() - this.yearAgo.getTime() < 0) return
        const weekIndex = this.getWeekIndex(date)
        const dayIndex = (6 - new Date().getDay() + date.getDay()) % 7
        weeks[weekIndex][dayIndex].count += 1
        weeks[weekIndex][dayIndex].users = {
          ...weeks[weekIndex][dayIndex].users,
          ...(activity.integrationuser
            ? {
                [activity.integrationuser.member.uuid]: activity.integrationuser
              }
            : {})
        }
      })
      return weeks
    },
    monthStarts() {
      const months = Array.from(
        { length: 12 },
        (_, idx) => ((new Date().getMonth() + idx + 1) % 12) + 1
      )
      const janIdx = months.indexOf(1)
      const curYear = new Date().getFullYear()
      const monthStarts = months.map(
        (el, idx) =>
          new Date(`${el}-1-${idx >= janIdx ? curYear : curYear - 1}`)
      )
      return monthStarts.reduce(
        (prev, curr) => ({
          ...prev,
          [this.getWeekIndex(curr)]: this.$moment(curr).format('MMM')
        }),
        {}
      )
    },
    sizeStyle() {
      return this.big
        ? '--activity-grid-cell-gap: 3px; font-size: 0.9rem; padding-top: 1.5rem;'
        : '--activity-grid-cell-gap: 1px; font-size: 0.7rem; padding-top: 1rem;'
    }
  },
  methods: {
    getWeekIndex(date) {
      const diff = date.getTime() - this.yearAgo.getTime()
      return Math.floor(diff / (1000 * 60 * 60 * 24 * 7))
    },

    getFormattedDate(weekIndex, dayIndex) {
      const day = new Date(
        this.yearAgo.getTime() +
          weekIndex * this.weekMs +
          (dayIndex + 1) * this.dayMs
      )
      return this.$moment(day).format('dddd MMM D, YYYY')
    }
  }
}
</script>
<style scoped lang="scss">
.activity-grid {
  &-container {
    display: grid;
    grid-template-columns: repeat(52, 1fr);
    gap: var(--activity-grid-cell-gap);
  }

  &-col {
    position: relative;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    gap: var(--activity-grid-cell-gap);
  }

  &-cell {
    position: relative;
    aspect-ratio: 1 / 1;
    background: #e9ebed;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 2px;

    &.active {
      background: #24ce85;
    }

    &:hover {
      border: 2px solid $primary;
    }

    &-hover {
      position: absolute;
      bottom: calc(100% + 0.5rem);
      z-index: 999;
      background: white;
      border-radius: 4px;
      border: 1px solid rgba(#000, 8%);
      padding: 0.5rem;
      white-space: nowrap;
      font-size: 0.9rem;

      &.leftaligned {
        left: 0;
      }

      &:not(.leftaligned) {
        right: 0;
      }

      &-day {
        font-weight: 700;
      }

      &-count {
        font-weight: 400;
        color: rgba(#000, 50%);
      }

      &-user {
      }
    }
  }

  &-label {
    color: #9098ae;
    position: absolute;
    top: -1rem;
    left: 0;

    &.big {
      top: -1.5rem;
    }
  }
}
</style>
