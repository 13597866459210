var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-input", style: _vm.inputPadding }, [
    _vm.title
      ? _c("p", { staticClass: "text-input-title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e(),
    _c("div", { staticClass: "text-input-wrapper" }, [
      _c("div", { staticClass: "text-input-left" }, [
        _vm.leftIcon
          ? _c("img", {
              ref: "leftgroupicon",
              staticClass: "text-input-left-icon",
              class: { invalid: _vm.invalid, valid: _vm.valid },
              attrs: {
                src: require("@/assets/icons/" + _vm.leftIcon + ".svg"),
                alt: ""
              },
              on: { load: _vm.handleIconLoad }
            })
          : _vm._e()
      ]),
      _c(
        "input",
        _vm._b(
          {
            ref: "textinput",
            staticClass: "text-input-input",
            class:
              ((_obj = {
                rounded: _vm.rounded,
                disabled: _vm.disabled || _vm.loading,
                focused: _vm.focused,
                invalid: _vm.invalid,
                valid: _vm.valid
              }),
              (_obj[_vm.size] = true),
              _obj),
            style: Object.assign(
              {},
              _vm.background ? { background: _vm.background } : {}
            ),
            attrs: {
              id: "",
              placeholder: _vm.placeholder,
              disabled: _vm.disabled || _vm.loading,
              type: "text",
              name: ""
            },
            domProps: { value: _vm.value },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSubmit.apply(null, arguments)
              },
              input: function($event) {
                return _vm.$emit("input", $event.target.value)
              },
              focus: function($event) {
                return _vm.$emit("focus", $event)
              },
              blur: function($event) {
                return _vm.$emit("blur", $event)
              }
            }
          },
          "input",
          Object.assign(
            {},
            _vm.maxLength && { maxlength: _vm.maxLength },
            _vm.minLength && { minlength: _vm.minLength }
          ),
          false
        )
      ),
      _c("div", { staticClass: "text-input-right" }, [
        _vm.maxLength
          ? _c("div", { staticClass: "text-input-right-count" }, [
              _c("span", [_vm._v(_vm._s(_vm.value.length))]),
              _c("span", { ref: "rightgroupcount" }, [
                _vm._v(" / " + _vm._s(_vm.maxLength))
              ])
            ])
          : _vm._e(),
        _vm.buttonText || _vm.buttonIcon
          ? _c(
              "div",
              { ref: "rightgroupbutton" },
              [
                _c("Button", {
                  staticClass: "text-input-right-btn",
                  class: { visible: _vm.value || _vm.maxLength },
                  attrs: {
                    icon: _vm.buttonIcon,
                    text: _vm.buttonText,
                    size: "xs",
                    type: "light-solid",
                    disabled: !_vm.value || _vm.invalid || _vm.disabled,
                    loading: _vm.loading
                  },
                  on: { click: _vm.handleButtonClick }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }