var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "u-columns space-between" }, [
      _c(
        "div",
        { staticClass: "u-column is-narrow" },
        [
          _c(
            "b-button",
            { attrs: { loading: _vm.loading }, on: { click: _vm.close } },
            [_vm._v(_vm._s(_vm.closeText))]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "u-column is-narrow" },
        [
          _vm._t("action", function() {
            return [
              _c(
                "b-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    type: "is-primary",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("action")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.action))]
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }