var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogModal",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  _c("div", { staticClass: "pt-6 u-columns space-between" }, [
                    _c("div", { staticClass: "u-column is-narrow" }, [
                      _c("div", { staticClass: "u-columns" }, [
                        _c(
                          "div",
                          { staticClass: "u-column is-narrow" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mh-3",
                                attrs: {
                                  loading: _vm.loading,
                                  type: "is-danger"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onClick(_vm.onConfirmAction1)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.action1) + " ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "u-column is-narrow" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "u-btn mh-3 light-gray borderless",
                                attrs: { loading: _vm.loading },
                                on: {
                                  click: function($event) {
                                    return _vm.onClick(_vm.onConfirmAction2)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.action2) + " ")]
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "u-column is-narrow" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { loading: _vm.loading },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [_vm._v(" Cancel ")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        "DialogModal",
        Object.assign({}, _vm.$attrs, _vm.$props),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }