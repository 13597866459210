














import ThirdPartyTab from '@c/features/workspace-settings-modal/pages/ThirdPartyTab.vue'
import Workspace from '@c/models/Workspace'
import WorkspaceHeaderModal from '@c/shared/molecules/object-visualisations/workspace/WorkspaceHeaderModal.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsModal',
  components: {
    WorkspaceHeaderModal,
    ThirdPartyTab
  },
  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    },
    activeIntegrations: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
})
