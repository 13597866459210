var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subresources" },
    [
      _c("img", {
        staticClass: "subresources-preview",
        class: { hidden: !_vm.loaded },
        attrs: { src: _vm.subresources[0].preview, alt: "" },
        on: {
          load: function($event) {
            _vm.loaded = true
          }
        }
      }),
      !_vm.loaded ? _c("b-skeleton", { attrs: { square: true } }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }