export enum ProfileSettingTab {
  EDIT = 0,
  PASSWORD = 1
}

export interface UserFormData {
  email?: string
  first_name?: string
  last_name?: string
  username?: string
  image?: File
}

export interface Topic {
  name: string
  public: boolean
}
