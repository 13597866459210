import Vue from 'vue'
import { getClient, getResponseData, Paginator } from '../../axios'
import Mutations from '../mutation-types'

const client = getClient('/users')

const getDefaultState = () => {
  return {
    currentUser: null,
    currentWorkspaceMembers: {},
    users: {}
  }
}

export default {
  state: {
    ...getDefaultState()
  },
  getters: {
    currentUser: (state) => state.currentUser,
    currentWorkspaceMembers: (state) => state.currentWorkspaceMembers,
    currentWorkspaceMember: (state, getters, rootState, rootGetters) =>
      state.currentWorkspaceMembers?.[rootGetters.currentWorkspace.uuid],
    isAdmin: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes('workspace.write'),
    isUman: (state, getters) => getters.currentUser?.is_internal,
    canWriteIntegrations: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.integrations.write'
      ),
    canWriteMembers: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.members.write'
      ),
    canWriteOri: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.ori.write'
      ),
    canReadOri: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.ori.read'
      ),
    canWriteOriTemplates: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.templates.ori.write'
      ),
    canWriteTools: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.tools.write'
      ),
    canWriteLabels: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.labels.write'
      ),
    canWriteStories: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.stories.write'
      ),
    canWriteChats: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.chats.write'
      ),
    canWriteCalendars: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.calendars.write'
      ),
    canUseSearch: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.resources.search'
      ),
    canUseProspecting: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.prospects.read'
      ),
    canWriteProspectingLists: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.prospect_lists.write'
      ),
    canReadProspectingLists: (state, getters) =>
      getters.currentWorkspaceMember?.permissions?.includes(
        'workspace.prospect_lists.read'
      )
  },

  mutations: {
    UPSERT_MEMBERS(state, users) {
      for (const user of users) {
        Vue.set(state.users, user.uuid, {
          ...state.users[user.uuid],
          ...user
        })
      }
    },

    [Mutations.SET_CURRENT_USER](state, user) {
      state.currentUser = user
    },

    [Mutations.RESET_USERS_STATE](state) {
      Object.assign(state, {
        ...getDefaultState(),
        currentUser: state.currentUser
      })
    },

    [Mutations.SET_CURRENT_WORKSPACEMEMBER](state, { member, workspace_id }) {
      Vue.set(state.currentWorkspaceMembers, workspace_id, member)
    }
  },

  actions: {
    async verifyUserEmail(_, { code }) {
      return await client.get(`/verify/${code}/`)
    },

    async getUsers({ commit }, data) {
      const res = await client.get('/', data)
      const users = getResponseData(res)
      commit('UPSERT_MEMBERS', users)
      return users
    },

    async getUsersPaginator({ commit }, data) {
      return new Paginator(client, await client.get('/', data), (data) =>
        commit('UPSERT_MEMBERS', data)
      )
    },

    async getCurrentUser({ commit }) {
      const res = await client.get('/me/')
      const user = getResponseData(res)
      commit(Mutations.SET_CURRENT_USER, user)
      return user
    },

    async updateAvatar({ commit }, avatar) {
      let formData = null
      if (avatar instanceof File) {
        formData = new FormData()
        formData.append('avatar', avatar)
      }
      const res = await client.put('/me/avatar/', formData)
      const data = getResponseData(res)
      // commit(Mutations.SET_CURRENT_WORKSPACEMEMBER, data.user)
      commit(Mutations.SET_CURRENT_USER, data)
      return data
    },

    async getUser({ state, commit }, { id }) {
      if (id in state.users) {
        return state.users[id]
      }

      const res = await client.get(`/${id}/`)
      const user = getResponseData(res)
      commit('UPSERT_MEMBERS', [user])
      return user
    },

    async createUser({ commit }, data) {
      const res = await client.post('/', data)
      const user = getResponseData(res)
      commit('UPSERT_MEMBERS', [user])
      return user
    },

    async patchUser({ state, commit }, { id, data }) {
      const res = await client.patch(`/${id}/`, data)
      const user = getResponseData(res)
      if (user.uuid == state.currentUser.uuid) {
        // commit(Mutations.SET_CURRENT_WORKSPACEMEMBER, user)
        commit(Mutations.SET_CURRENT_USER, user)
      }
      commit('UPSERT_MEMBERS', [user])
      return user
    },

    setCurrentWorkspaceMember({ commit }, { workspace_id, member }) {
      commit(Mutations.SET_CURRENT_WORKSPACEMEMBER, { workspace_id, member })
    },

    async setPassword({ dispatch, state }, { password }) {
      try {
        return await dispatch('patchUser', {
          id: state.currentUser.uuid,
          data: { password }
        })
      } catch (e) {
        if (e.password) {
          throw Error(`${e.password[0]}`)
        } else {
          throw Error('An error occurred')
        }
      }
    },

    async requestPasswordReset(_, { email }) {
      const res = await client.post('/password_reset/', {
        email
      })
      return getResponseData(res)
    },

    async resetUserPassword(_, { password, code }) {
      const res = await client.post(`/password_reset/${code}/`, {
        password
      })
      return getResponseData(res)
    }
  }
}
