var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { class: Object.assign({}, _vm.baseClass) }, [
    _vm.isHighlighted
      ? _c(
          "span",
          _vm._l(_vm.splitText, function(obj, index) {
            return _c(
              "span",
              {
                key: index,
                class: Object.assign({}, _vm.baseClass, {
                  bold: _vm.customHighlight ? _vm.baseClass.bold : obj.bold
                }),
                style:
                  _vm.customHighlight && obj.bold ? _vm.customHighlight : {}
              },
              [_vm._v(_vm._s(obj.text))]
            )
          }),
          0
        )
      : _c("span", { class: Object.assign({}, _vm.baseClass) }, [
          _vm._v(" " + _vm._s(_vm.splitText) + " ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }