import { getClient } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function highlightResource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  const { data } = await client.post(
    `/${workspaceId}/resources/${resourceId}/highlight/`,
    {},
    {
      ...(impersonatedMember && { params: { act_as: impersonatedMember } })
    }
  )
  return data
}

export async function removeHighlightFromResource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  await client.delete(`/${workspaceId}/resources/${resourceId}/highlight/`, {
    ...(impersonatedMember ? { params: { act_as: impersonatedMember } } : {})
  })
}

export async function pinHighlightedResource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  const { data } = await client.post(
    `/${workspaceId}/resources/${resourceId}/highlight/pin/`,
    {},
    {
      ...(impersonatedMember && { params: { act_as: impersonatedMember } })
    }
  )
  return data
}

export async function removePinFromHighlight(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  await client.delete(
    `/${workspaceId}/resources/${resourceId}/highlight/pin/`,
    {
      ...(impersonatedMember ? { params: { act_as: impersonatedMember } } : {})
    }
  )
}
