var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide-deck" }, [
    _c(
      "div",
      { staticClass: "slide-deck-preview" },
      [
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.firstImageLoaded,
              expression: "firstImageLoaded"
            }
          ],
          staticClass: "slide-deck-preview-image",
          attrs: { src: _vm.image, alt: "" },
          on: {
            load: function($event) {
              _vm.firstImageLoaded = true
            }
          }
        }),
        !_vm.firstImageLoaded
          ? _c("b-skeleton", { attrs: { height: "100%" } })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "slide-deck-buttons" },
          [_vm._t("buttons", null, { index: _vm.index })],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "slide-deck-controls" },
      [
        _c("Button", {
          staticClass: "slide-deck-controls-arrow",
          attrs: { icon: "arrow-left", type: "grey", disabled: _vm.isStart },
          on: {
            click: function() {
              return _vm.changeIndex(-_vm.items.length)
            }
          }
        }),
        _c("Button", {
          staticClass: "slide-deck-controls-arrow",
          attrs: { icon: "chevron-left", type: "grey", disabled: _vm.isStart },
          on: {
            click: function() {
              return _vm.changeIndex(-1)
            }
          }
        }),
        _c(
          "div",
          { staticClass: "slide-deck-controls-counter" },
          [
            _c("NumberInput", {
              staticClass: "slide-deck-controls-counter-input",
              attrs: { min: 1, max: _vm.items ? _vm.items.length : 0 },
              on: { submit: _vm.changePage, blur: _vm.changePage },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            }),
            _c("span", [_vm._v("/")]),
            _c("span", [_vm._v(_vm._s(_vm.itemCount))])
          ],
          1
        ),
        _c("Button", {
          staticClass: "slide-deck-controls-arrow right-arrow",
          attrs: { icon: "chevron-left", type: "grey", disabled: _vm.isEnd },
          on: {
            click: function() {
              return _vm.changeIndex(1)
            }
          }
        }),
        _c("Button", {
          staticClass: "slide-deck-controls-arrow right-arrow",
          attrs: { icon: "arrow-left", type: "grey", disabled: _vm.isEnd },
          on: {
            click: function() {
              return _vm.changeIndex(_vm.items.length)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }