import Vue from 'vue'
export default Vue.extend({
  methods: {
    imageToURL(img: File, callback: (imgUrl?: string) => void) {
      if (!img) {
        callback(undefined)
        return
      }
      const reader = new FileReader()
      // it's onload event and you forgot (parameters)
      reader.onload = function (e) {
        callback(e?.target?.result as string | undefined)
      }
      // you have to declare the file loading
      reader.readAsDataURL(img)
    },
    async asyncImageToURL(img: File): Promise<string> {
      return new Promise((resolve, reject) => {
        this.imageToURL(img, (imgUrl: string | undefined) => {
          if (imgUrl) {
            resolve(imgUrl)
          } else {
            reject()
          }
        })
      })
    }
  }
})
