import Console from '@/console'
import store from '@/store'

export function addInvitationLogoutGuard(router) {
  router.beforeEach((to, from, next) => {
    if (store.getters.debugGuards)
      Console.debug('Invitation logout guard (to, from)', to, from)
    // Check if 'to' is /signup and email is not same email as user
    if (
      to.name &&
      to.name === 'register' &&
      to.query.email &&
      to.query.email !== store.getters?.currentUser?.username &&
      from?.meta?.authentication
    ) {
      store.dispatch('logout')
    }
    next()
  })
}
