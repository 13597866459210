





































import DefaultModal from '@c/shared/molecules/structural/modals/DefaultModal.vue'
import Vue, { PropType } from 'vue'
import { mapMutations, mapState } from 'vuex'
import Mutations from '@/store/mutation-types'
export default Vue.extend({
  name: 'NoShowAgainDialogModal',
  components: { DefaultModal },
  props: {
    storeKey: {
      type: String as () => string,
      default: undefined
    },
    message: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    title: {
      type: String,
      required: true
    },
    onConfirm: {
      type: Function as PropType<() => boolean | Promise<boolean>>,
      required: true
    }
  },
  data() {
    return {
      dontShowAgain: false
    }
  },
  computed: {
    ...mapState({
      storeKeySet: function (state: any) {
        return (state.webapp as any).rememberDialogKeys[this.storeKey]
      }
    })
  },
  mounted() {
    if (this.storeKeySet) {
      this.onConfirm()
      this.$emit('close')
    }
  },
  methods: {
    ...mapMutations([Mutations.SET_REMEMBER_DIALOG_KEY]),
    async confirm() {
      const success = await this.onConfirm()
      if (success) {
        if (this.dontShowAgain) {
          ;(this as any)[Mutations.SET_REMEMBER_DIALOG_KEY as string](
            this.storeKey
          )
        }
        this.$emit('close')
      }
    }
  }
})
