<template>
  <div :key="homekey" class="home">
    <div class="home-hero">
      <h1 class="home-hero-title">
        {{ title || `Welcome to the ${currentWorkspace.name} workspace` }}
        <img
          src="@/assets/icons/glitter.svg"
          alt="home"
          class="home-hero-title-icon"
        />
      </h1>
      <router-link :to="getStartedRoute">
        <Button v-if="title" text="Get started" size="m" />
      </router-link>
    </div>
    <div class="home-content">
      <GlobalSearchBar
        v-if="canUseSearch"
        background="#f1f2f3"
        class="home-content-search"
      />
      <HighlightOverview v-if="highlightsEnabled" />
      <HomeRecent v-if="canWriteOri || canReadOri" />
      <HomePageLinks
        v-if="!(canWriteStories || canWriteOri || canReadOri) && canUseSearch"
      />
    </div>
    <ResourceModal />
    <CustomSlideCreator v-if="showCollectSlides" />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import HomeRecent from './home/HomeRecent.vue'
import { mapGetters, mapActions } from 'vuex'
import HomePageLinks from './home/HomePageLinks.vue'
import HighlightOverview from './home/HighlightOverview.vue'
import ResourceModal from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceModal.vue'
import CustomSlideCreator from '@c/shared/molecules/object-visualisations/resource/subcomponents/CustomSlideCreator.vue'
import GlobalSearchBar from '@c/shared/molecules/inputs/GlobalSearchBar.vue'

export default {
  name: 'HomePage',
  components: {
    Button,
    HomeRecent,
    HomePageLinks,
    HighlightOverview,
    ResourceModal,
    CustomSlideCreator,
    GlobalSearchBar
  },
  computed: {
    ...mapGetters([
      'canWriteStories',
      'canWriteOri',
      'canReadOri',
      'canUseSearch',
      'highlightsEnabled',
      'showCollectSlides',
      'currentWorkspace'
    ]),
    title() {
      if (this.canWriteStories) return 'Prepare your next client meeting'
      if (this.canWriteOri || this.canReadOri)
        return `Discover ${this.currentWorkspace.name}${
          ['s', 'z'].includes(this.currentWorkspace.name.slice(-1)[0])
            ? "'"
            : "'s"
        } portfolio`
      if (this.canUseSearch) return "Find the content you're looking for"
      return ''
    },
    homekey() {
      return this.$route.params.workspace_id
    },
    getStartedRoute() {
      if (this.canWriteStories)
        return { name: 'meetings', query: { create: true } }
      if (this.canWriteOri || this.canReadOri)
        return { name: 'portfolio', params: { ori_type: 'offerings' } }
      if (this.canUseSearch) return { name: 'search' }
      return undefined
    }
  },
  created() {
    this.checkCurrentWorkspace()
    this.checkUpdateBanner()
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    checkCurrentWorkspace() {
      if (
        !this.currentWorkspace ||
        this.currentWorkspace.uuid !== this.$route.params.workspace_id
      ) {
        this.setCurrentWorkspace(this.$route.params.workspace_id)
      }
    },
    checkUpdateBanner() {
      if (localStorage.getItem('uman_product_update_hide_banner'))
        localStorage.removeItem('uman_product_update_hide_banner')
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;

  &-hero {
    background: linear-gradient(
      94deg,
      rgba(#115efb, 0.06) -0.54%,
      rgba(#da42d4, 0.15) 89.06%
    );
    height: 40vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 3.5vh;

    &-title {
      background: linear-gradient(
        270deg,
        #303032 0.24%,
        rgba(#115efb, 0.75) 112.49%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: fit-content;
      font-size: 3.5rem;
      line-height: unset;
      text-align: center;
      padding: 0 0.5rem;

      &-icon {
        display: inline-block;
        min-width: 2.85rem;
        min-height: 2.85rem;
        margin-left: 0.75rem;
        vertical-align: middle;
      }
    }
  }

  &-content {
    padding: 5vh 5vw;
    display: flex;
    flex-flow: column nowrap;
    gap: 5vh;

    &-search {
      align-self: center;
      width: min(65rem, 100%);
    }
  }
}
</style>
