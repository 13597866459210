var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-edit-wrapper" },
    [
      _vm.editable
        ? _c(
            "div",
            { ref: "tageditlist", staticClass: "tag-edit" },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: "Edit labels",
                    position: "is-" + (_vm.bottom ? "bottom" : "top"),
                    type: "is-dark"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tag-edit-btn-wrapper",
                      on: { mouseenter: _vm.checkAlignment }
                    },
                    [
                      _c("Button", {
                        attrs: {
                          icon: "label-add",
                          type: "white",
                          size: _vm.buttonSize,
                          text: _vm.trigger === "button" ? "Edit labels" : "",
                          "icon-left": _vm.trigger === "button",
                          loading: _vm.loading
                        },
                        on: { click: _vm.handleEditToggle }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showEdit,
                        expression: "showEdit"
                      }
                    ],
                    staticClass: "tag-edit-list",
                    class: {
                      leftalign: _vm.alignLeft,
                      rightalign: !_vm.alignLeft,
                      bottom: _vm.bottom,
                      top: !_vm.bottom
                    }
                  },
                  [
                    _c("div", { staticClass: "tag-edit-list-search-wrapper" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.query,
                            expression: "query"
                          }
                        ],
                        staticClass: "tag-edit-list-search",
                        attrs: {
                          id: "tag-search",
                          type: "text",
                          name: "tag-search",
                          placeholder: "Search for labels or label types"
                        },
                        domProps: { value: _vm.query },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.query = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "tag-edit-list-items" },
                      [
                        !_vm.filteredOptions.length
                          ? _c(
                              "div",
                              { staticClass: "tag-edit-list-items-empty" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.query
                                        ? 'No results found for "' +
                                            _vm.query +
                                            '"'
                                        : "No labels available in your workspace"
                                    ) +
                                    " "
                                ),
                                _c("Button", {
                                  attrs: { text: "Create new labels" },
                                  on: {
                                    click: function() {
                                      return (_vm.modalOpen = true)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(_vm.filteredOptions, function(option) {
                          return _c(
                            "div",
                            {
                              key: option.uuid,
                              staticClass: "tag-edit-list-item"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "tag-edit-list-item-title" },
                                [_vm._v(_vm._s(option.name))]
                              ),
                              _vm._l(option.values, function(value) {
                                return _c(
                                  "div",
                                  {
                                    key: value.uuid,
                                    staticClass: "tag-edit-list-item-value",
                                    class: { selected: value.selected },
                                    on: {
                                      click: function($event) {
                                        return _vm.queueToggleTag(option, value)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "tag-edit-list-item-value-icon",
                                      attrs: {
                                        src: require("@/assets/icons/" +
                                          (value.selected ? "minus" : "plus") +
                                          "-medium.svg"),
                                        alt: ""
                                      }
                                    }),
                                    value.selected
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "tag-edit-list-item-value-remove"
                                          },
                                          [_vm._v("Remove label:")]
                                        )
                                      : _vm._e(),
                                    _c("span", [_vm._v(_vm._s(value.value))])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        }),
                        _vm.filteredOptions.length
                          ? _c("Button", {
                              staticClass: "tag-edit-list-add",
                              attrs: {
                                text: "Create new labels",
                                size: "xs",
                                type: "light"
                              },
                              on: {
                                click: function() {
                                  return (_vm.modalOpen = true)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "tag-edit-list-footer" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.selectionChanges) + " changes "
                        ),
                        _c("Button", {
                          attrs: {
                            text: "Save",
                            disabled: !_vm.selectionChanges
                          },
                          on: { click: _vm.submit }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.showSelected && _vm.selected && _vm.selected.length
        ? _c(
            "div",
            { staticClass: "tag-edit-tags" },
            _vm._l(_vm.selected, function(section) {
              return _c(
                "div",
                { key: section.uuid, staticClass: "tag-edit-tags-section" },
                [
                  _c("span", { staticClass: "tag-edit-tags-section-title" }, [
                    _vm._v(_vm._s(section.name))
                  ]),
                  _vm._l(section.values, function(tag) {
                    return _c("Tag", {
                      key: tag.uuid,
                      attrs: {
                        text: tag.value,
                        type: "white",
                        size: _vm.tagSize,
                        title: tag.value,
                        overflowing: _vm.overflowing
                      }
                    })
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.modalOpen,
            callback: function($$v) {
              _vm.modalOpen = $$v
            },
            expression: "modalOpen"
          }
        },
        [_c("AttributesModal")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }