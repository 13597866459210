var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-columns space-between is-vcentered" }, [
    _c("div", { staticClass: "u-column is-narrow" }, [
      _c(
        "div",
        {
          staticClass: "u-columns is-vcentered",
          staticStyle: { "flex-wrap": "nowrap" }
        },
        [
          !_vm.noIcon
            ? _c("div", { staticClass: "column no-padding is-narrow" }, [
                _c("div", { staticClass: "pr-3" }, [_vm._t("icon")], 2)
              ])
            : _vm._e(),
          !_vm.noTitle
            ? _c(
                "div",
                { staticClass: "column no-padding is-narrow" },
                [_vm._t("title")],
                2
              )
            : _vm._e()
        ]
      )
    ]),
    _c("div", { staticClass: "u-column is-narrow" }, [_vm._t("right")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }