//@ts-ignore
import { Paginator } from 'umanai-vuex/src/axios'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Console from '../console'

interface Data {
  consumptionLoading: boolean
  settingUpPaginator: boolean
  paginatorData?: any[]
  pages?: Generator
  paginator?: Paginator
  pagesLoaded: number
  paginatorDataCount: number
  skipSetup: boolean
  loadingError: boolean
  isDone: boolean
}

export const PaginatorConsumer = Vue.extend({
  data(): Data {
    return {
      consumptionLoading: false,
      settingUpPaginator: false,
      pagesLoaded: 0,
      paginatorData: [],
      paginator: undefined,
      pages: undefined,
      paginatorDataCount: 0,
      skipSetup: false,
      loadingError: false,
      isDone: false
    }
  },
  computed: {
    ...mapGetters(['resourceTypeFilter'])
  },
  async created() {
    if (!this.skipSetup && !this.$store.state?.webapp?.forceAppRefresh) {
      this.settingUpPaginator = true
      await this.setupPaginatorConsumer()
      this.settingUpPaginator = false
    } else {
      Console.debug('Skipping paginator setup for now')
    }
  },
  methods: {
    async getPaginator(): Promise<Paginator | undefined> {
      Console.debug('getPaginator not implemented')
      return new Promise((resolve) => {
        resolve(undefined)
      })
    },

    async getPages(): Promise<Generator | undefined> {
      if (this.paginator) {
        return this.paginator.pages()
      }
      Console.debug('Cannot get pages from paginator: ', this.paginator)
    },

    async getCount(): Promise<number> {
      return await this.paginator?.count()
    },

    async setupPaginatorConsumer(reset = false): Promise<void> {
      try {
        this.loadingError = false
        if (this.consumptionLoading && !reset) return
        this.consumptionLoading = true
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
      } catch (e) {
        Console.warning('Failed to setup paginator consumer', e)
        this.loadingError = true
      } finally {
        this.consumptionLoading = false
      }
    },

    async loadPage(force: boolean = false) {
      Console.debug('Loading pages from paginator')
      if (this.isDone) {
        Console.debug(
          'We already got all data from the paginator, skipping event.'
        )
        return
      }
      if (!this.pages) {
        Console.debug('Pages was undefined, retrying to get pages')
        await this.getPages()
      }

      if (this.consumptionLoading && !force) {
        Console.debug('Already loading a new page')
        return
      }

      await this.beforePageLoad(this.pagesLoaded)
      this.consumptionLoading = true
      if (!this.pages) return
      const data = await this!!.pages!!.next()
      if (!(data && data.value && data.value.data)) {
        this.$emit('noData')
        Console.debug('Received no data')
        this.consumptionLoading = false
        this.isDone = true
        return
      }
      this.pagesLoaded += 1
      const isDone = data.done || !(<any>data).value.next
      if (isDone) {
        this.paginatorData = [...(this.paginatorData || []), ...data.value.data]
        this.$emit('done')
        Console.debug("Received all data, we're done")
        await this.onDataReceive(data, this.pagesLoaded)
        this.consumptionLoading = false
        this.isDone = true
        return
      }

      Console.debug('Received data from pages: ', data)
      await this.onDataReceive(data, this.pagesLoaded)
      this.paginatorData = [...(this.paginatorData || []), ...data.value.data]
      this.consumptionLoading = false
    },

    async beforePageLoad(page: number) {
      this.$console.debug('onPageLoad', page)
    },

    async onDataReceive(data: any, pagesLoaded: number) {
      this.$console.debug('onDataReceive', data, pagesLoaded)
    },

    clearPaginatorConsumer() {
      this.isDone = false
      this.paginatorData = undefined
      this.pagesLoaded = 0
    },

    async resetPaginatorConsumer() {
      this.clearPaginatorConsumer()
      await this.setupPaginatorConsumer(true)
    }
  }
})
