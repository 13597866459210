var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "similar" }, [
    _c(
      "div",
      { staticClass: "similar-toggle mb-3" },
      _vm._l(_vm.similarityOptions, function(option) {
        return _c(
          "p",
          {
            key: option.label,
            class: {
              "similar-toggle-btn": true,
              active: _vm.similarity === option.similarity
            },
            on: {
              click: function($event) {
                return _vm.getSimilarSlides(option.similarity)
              }
            }
          },
          [_vm._v(" " + _vm._s(option.label) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "similar-slides" },
      [
        _vm._l(_vm.similarSlides, function(ref, idx) {
          var slide = ref.slide
          return _c(
            "div",
            { key: slide.uuid, staticClass: "similar-slides-slide" },
            [
              _c("SlideVisualisation", {
                attrs: {
                  subresource: slide,
                  index: 0,
                  "loaded-resources": 1,
                  "total-resources": 1,
                  rank: idx + 1,
                  location: "similar slides in modal"
                },
                on: {
                  load: function($event) {
                    _vm.imagesLoaded += 1
                  },
                  cantLoad: function($event) {
                    _vm.imagesErrored += 1
                  }
                }
              })
            ],
            1
          )
        }),
        _vm._l(_vm.allImagesLoaded ? 0 : 3, function(idx) {
          return _c("b-skeleton", {
            key: idx,
            attrs: { height: "100%", size: "is-large" }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }