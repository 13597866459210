<template>
  <div class="tag-edit-wrapper">
    <div v-if="editable" ref="tageditlist" class="tag-edit">
      <b-tooltip
        label="Edit labels"
        :position="`is-${bottom ? 'bottom' : 'top'}`"
        type="is-dark"
      >
        <div class="tag-edit-btn-wrapper" @mouseenter="checkAlignment">
          <Button
            icon="label-add"
            type="white"
            :size="buttonSize"
            :text="trigger === 'button' ? 'Edit labels' : ''"
            :icon-left="trigger === 'button'"
            :loading="loading"
            @click="handleEditToggle"
          />
        </div>
      </b-tooltip>
      <transition name="fade">
        <div
          v-show="showEdit"
          class="tag-edit-list"
          :class="{
            leftalign: alignLeft,
            rightalign: !alignLeft,
            bottom,
            top: !bottom
          }"
        >
          <div class="tag-edit-list-search-wrapper">
            <input
              id="tag-search"
              v-model="query"
              type="text"
              name="tag-search"
              placeholder="Search for labels or label types"
              class="tag-edit-list-search"
            />
          </div>
          <div class="tag-edit-list-items">
            <div
              v-if="!filteredOptions.length"
              class="tag-edit-list-items-empty"
            >
              {{
                query
                  ? `No results found for "${query}"`
                  : 'No labels available in your workspace'
              }}
              <Button
                text="Create new labels"
                @click="() => (modalOpen = true)"
              />
            </div>
            <div
              v-for="option in filteredOptions"
              :key="option.uuid"
              class="tag-edit-list-item"
            >
              <span class="tag-edit-list-item-title">{{ option.name }}</span>
              <div
                v-for="value in option.values"
                :key="value.uuid"
                class="tag-edit-list-item-value"
                :class="{ selected: value.selected }"
                @click="queueToggleTag(option, value)"
              >
                <img
                  :src="
                    require(`@/assets/icons/${
                      value.selected ? 'minus' : 'plus'
                    }-medium.svg`)
                  "
                  alt=""
                  class="tag-edit-list-item-value-icon"
                />
                <span
                  v-if="value.selected"
                  class="tag-edit-list-item-value-remove"
                  >Remove label:</span
                >
                <span>{{ value.value }}</span>
              </div>
            </div>
            <Button
              v-if="filteredOptions.length"
              text="Create new labels"
              size="xs"
              type="light"
              class="tag-edit-list-add"
              @click="() => (modalOpen = true)"
            />
          </div>
          <div class="tag-edit-list-footer">
            {{ selectionChanges }} changes
            <Button text="Save" :disabled="!selectionChanges" @click="submit" />
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="showSelected && selected && selected.length"
      class="tag-edit-tags"
    >
      <div
        v-for="section in selected"
        :key="section.uuid"
        class="tag-edit-tags-section"
      >
        <span class="tag-edit-tags-section-title">{{ section.name }}</span>
        <Tag
          v-for="tag in section.values"
          :key="tag.uuid"
          :text="tag.value"
          type="white"
          :size="tagSize"
          :title="tag.value"
          :overflowing="overflowing"
        />
      </div>
    </div>
    <b-modal v-model="modalOpen">
      <AttributesModal />
    </b-modal>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapGetters } from 'vuex'
import Tag from '@c/library/Tag.vue'
import AttributesModal from '../offerings/AttributesModal.vue'

export default {
  name: 'ORITagEdit',
  components: {
    AttributesModal,
    Button,
    Tag
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    showSelected: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    trigger: {
      type: String,
      default: 'icon',
      validator: (value) => ['icon', 'button'].includes(value)
    },
    buttonSize: {
      type: String,
      default: 'xs',
      validator: (value) => ['xxs', 'xs', 's', 'm', 'l'].includes(value)
    },
    tagSize: {
      type: String,
      default: 'xs',
      validator: (value) => ['xxs', 'xs', 's', 'm', 'l'].includes(value)
    },
    loading: {
      type: Boolean,
      default: false
    },
    overflowing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showEdit: false,
    query: '',
    alignLeft: false,
    bottom: false,
    modalOpen: false,
    localSelected: []
  }),
  computed: {
    ...mapGetters(['attributes']),
    options() {
      return this.attributes || []
    },
    filteredOptions() {
      return [
        ...(this.options || [])
          .filter(
            (opt) =>
              opt.values.length &&
              (opt.name.toLowerCase().includes(this.query.toLowerCase()) ||
                (opt.values || []).some((val) =>
                  val.value.toLowerCase().includes(this.query.toLowerCase())
                ))
          )
          .map((opt) => {
            const selected =
              this.localSelected.find((el) => el.uuid === opt.uuid)?.values ||
              []
            let values = opt.values
              .filter((value) =>
                opt.name.toLowerCase().includes(this.query.toLowerCase())
                  ? value
                  : value.value.toLowerCase().includes(this.query.toLowerCase())
              )
              .map((val) => ({
                ...val,
                selected: selected.some((el) => el.uuid === val.uuid)
              }))
            return { ...opt, values }
          })
      ]
    },
    flattenedSelection() {
      return Object.keys(this.localSelected || {})
        .reduce((acc, curr) => {
          acc.push(...(this.localSelected[curr]?.values || []))
          return acc
        }, [])
        .map((el) => el.uuid)
    },
    selectionChanges() {
      let flattenedInputSelection = Object.keys(this.selected || {})
        .reduce((acc, curr) => {
          acc.push(...(this.selected[curr]?.values || []))
          return acc
        }, [])
        .map((el) => el.uuid)
      const added = this.flattenedSelection.filter(
        (el) => !flattenedInputSelection.includes(el)
      )
      const removed = flattenedInputSelection.filter(
        (el) => !this.flattenedSelection.includes(el)
      )
      return added.length + removed.length
    }
  },
  watch: {
    showEdit(newVal) {
      if (newVal) {
        document.addEventListener('mousedown', this.handleOutsideClick)
      } else {
        document.removeEventListener('mousedown', this.handleOutsideClick)
      }
    }
  },
  methods: {
    checkAlignment(e) {
      this.alignLeft = e.clientX < window.innerWidth / 2
      this.bottom = e.clientY < window.innerHeight / 2 + window.innerHeight / 5
    },
    handleEditToggle() {
      this.showEdit = !this.showEdit
      if (this.showEdit) this.localSelected = [...this.selected]
    },
    handleOutsideClick(e) {
      if (!this.$refs.tageditlist.contains(e.target)) {
        this.showEdit = false
      }
    },
    queueToggleTag(attribute, value) {
      this.$nextTick(() => this.toggleTag(attribute, value))
    },
    toggleTag(attribute, value) {
      this.query = ''
      let newSelected = [...this.localSelected]
      newSelected = newSelected.reduce((acc, el) => {
        if (el.uuid === attribute.uuid) {
          let newValues = [...el.values]
          if (value.selected) {
            newValues = newValues.filter((val) => val.uuid !== value.uuid)
          } else {
            newValues.push(value)
          }
          acc.push({ ...el, values: newValues })
        } else {
          acc.push(el)
        }
        return acc
      }, [])
      const existingAttribute = newSelected.find(
        (el) => el.uuid === attribute.uuid
      )
      if (!existingAttribute) {
        newSelected.push({ ...attribute, values: [value] })
      } else if (existingAttribute.values.length === 0) {
        newSelected = newSelected.filter((el) => el.uuid !== attribute.uuid)
      }
      this.localSelected = newSelected
    },
    submit() {
      this.$emit('input', this.localSelected)
      this.showEdit = false
    }
  }
}
</script>

<style scoped lang="scss">
.tag-edit {
  position: relative;

  &-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
  }

  &-tags {
    display: contents;

    &-section {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
      border-radius: 6px;
      background: #f1f2f3;
      padding: 0.25rem 0.5rem;
      overflow: hidden;

      &-title {
        font-weight: 600;
        font-size: 0.85rem;
      }
    }
  }

  &-btn-wrapper {
    border-radius: 999rem;
    height: fit-content;
    width: fit-content;
    background: white;
  }

  &-list {
    position: absolute;
    z-index: 99;
    padding: 1rem 0 0;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);
    min-width: 20rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &.leftalign {
      left: 0;
    }

    &.rightalign {
      right: 0;
    }

    &.bottom {
      top: 100%;
    }

    &.top {
      bottom: 100%;
    }

    &-search {
      width: 100%;
      padding: 0.25rem 0.5rem;
      background: #f1f2f3;
      border-radius: 4px;
      border: 1px solid rgba(#000, 8%);
      width: 100%;

      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        border: 1px solid $primary;
        background: white;
      }

      &-wrapper {
        padding: 0 0.5rem;
        width: 100%;
      }
    }

    &-items {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      max-height: 20rem;
      overflow-y: auto;

      &-empty {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;
        color: #60666b;
      }
    }

    &-item {
      &-title {
        font-weight: 600;
        padding: 0.5rem;
        user-select: none;
      }

      &-value {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        user-select: none;

        &:hover {
          background: rgba(#000, 8%);
        }

        &.selected {
          background: rgba(#000, 8%);
        }

        &-icon {
          height: 1rem;
        }

        &-remove {
          pointer-events: none;
        }
      }
    }

    &-add {
      align-self: center;
    }

    &-footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      border-top: 1px solid rgba(#000, 8%);
      color: #60666b;
    }
  }
}
</style>
