var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCollectButton
    ? _c(
        "b-tooltip",
        {
          attrs: {
            label: _vm.slideCollected ? "Remove from collection" : "Collect",
            type: "is-dark",
            position: "is-top",
            "append-to-body": "",
            active: _vm.hasTooltip
          }
        },
        [
          _c("b-button", {
            staticClass: "btn-collect",
            class: { collected: _vm.slideCollected },
            attrs: {
              type: "is-primary",
              size: _vm.big ? "is-big" : "is-small",
              rounded: "",
              "icon-left": _vm.slideCollected
                ? _vm.hover
                  ? "minus"
                  : "check"
                : "plus",
              disabled: _vm.isDownloadingSlides
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onCollect.apply(null, arguments)
              }
            },
            nativeOn: {
              mouseenter: function($event) {
                _vm.hover = true
              },
              mouseleave: function($event) {
                _vm.hover = false
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }