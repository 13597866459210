var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dark" },
    [
      _c(
        "DropdownSelectWithDescription",
        _vm._g(
          _vm._b(
            {
              attrs: {
                width: 300,
                position: _vm.$attrs.position || "is-bottom-left",
                "custom-class": "dark-dropdown",
                value: _vm.value,
                options: _vm.options
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "trigger",
                    fn: function(ref) {
                      var value = ref.value
                      return [_vm._t("trigger", null, { value: value })]
                    }
                  }
                ],
                null,
                true
              )
            },
            "DropdownSelectWithDescription",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }