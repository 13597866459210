



























import { Integration } from '@c/integrations'
import Workspace from '@c/models/Workspace'
import DarkDialogContainer from '@c/shared/molecules/structural/containers/DarkDialogContainer.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'FileIntegrationDropdown',
  components: { DarkDialogContainer },
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    },
    integrationObject: {
      type: Object as () => any | undefined,
      default: undefined
    },
    workspace: {
      type: Object as () => Workspace,
      default: undefined
    }
  },
  methods: {
    disconnect() {
      this.$dialogs.integration.disconnectFileIntegration(
        this,
        this.integration,
        this.integrationObject,
        this.workspace,
        () => {
          this.$emit('deleteIntegration', this.integrationObject)
        }
      )
    }
  }
})
