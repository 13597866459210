var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultModal",
    {
      staticStyle: { "max-width": "700px !important" },
      attrs: { "with-close-icon": "" },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("p", { staticClass: "header bold sm" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        _vm.currentComponent,
        _vm._g(
          _vm._b(
            { tag: "component", attrs: { step: _vm.step } },
            "component",
            _vm.stepData,
            false
          ),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }