var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CreateWorkspaceModal",
    _vm._g(
      {
        attrs: {
          step: _vm.currentStep,
          "step-data": _vm.stepData,
          creating: _vm.creating
        },
        on: { next: _vm.next }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }