import Console from '@c/console'
import { utils } from '@c/models/utils'
import WorkspaceMember from '@c/models/WorkspaceMember'
import {
  deleteDialog,
  leaveDialog
} from '@c/shared/logic/dialogs/error/BaseErrorDialogs'
import {
  deleteSync,
  disconnectFileIntegration
} from '@c/shared/logic/dialogs/error/IntegrationDialogs'
import { logoutDialog } from '@c/shared/logic/dialogs/error/UserErrorDialogs'
import {
  connectToIntegration,
  inviteFromIntegration,
  syncFolderSelectModal
} from '@c/shared/logic/modals/IntegrationModals'
// This extends the Set object with some extra handy functions such as intersection, difference and union
import '@c/shared/logic/prototypes/Set'
// This extends the String object with some extra handy functions such as vowel detection and a/an formatting.
import '@c/shared/logic/prototypes/String'
import moment from 'moment'
import VueLazyload from 'vue-lazyload'
import { DirectiveBinding } from 'vue/types/options'
import { deleteResourceDialog } from './shared/logic/dialogs/error/ResourceErrorDialogs'
import {
  deleteWorkspaceDialog,
  leaveWorkspaceDialog,
  removeWorkspaceMemberDialog
} from './shared/logic/dialogs/error/WorkspaceErrorDialogs'
import { openModal, PropsAndEvents } from './shared/logic/modals/BaseModals'
import { openProfileSettings } from './shared/logic/modals/ProfileModals'
import {
  createWorkspace,
  openSettings
} from './shared/logic/modals/WorkspaceModals'

export interface Dialogs {
  shared: {
    deleteDialog: (
      parent: any,
      objectType: string,
      message: string,
      name: string,
      onConfirm: () => boolean | Promise<boolean>
    ) => void
    leaveDialog: (
      parent: any,
      objectType: string,
      message: string,
      name: string,
      onConfirm: () => boolean | Promise<boolean>
    ) => void
  }
  workspace: {
    deleteWorkspaceDialog: any
    leaveWorkspaceDialog: any
    removeWorkspaceMemberDialog: any
  }
  resource: {
    deleteResourceDialog: any
  }
  user: {
    logoutDialog: any
  }
  integration: {
    disconnectFileIntegration: any
    deleteSync: any
  }
}

export interface Modals {
  shared: {
    openModal: (
      parent: any,
      modal: any,
      { props, events }: PropsAndEvents
    ) => void
  }
  workspace: { openSettings: any; createWorkspace: any }
  user: { openProfileSettings: any }
  integration: {
    inviteFromIntegration: any
    connectToIntegration: any
    syncFolderSelectModal: any
  }
}

export function createDialogs(): Dialogs {
  return {
    shared: {
      deleteDialog,
      leaveDialog
    },
    workspace: {
      deleteWorkspaceDialog,
      leaveWorkspaceDialog,
      removeWorkspaceMemberDialog
    },
    resource: {
      deleteResourceDialog
    },
    user: {
      logoutDialog
    },
    integration: {
      disconnectFileIntegration,
      deleteSync
    }
  }
}

export function createModals(): Modals {
  return {
    shared: {
      openModal
    },
    workspace: {
      openSettings,
      createWorkspace
    },
    user: {
      openProfileSettings
    },
    integration: {
      inviteFromIntegration,
      connectToIntegration,
      syncFolderSelectModal
    }
  }
}

export default function (Vue: any) {
  Vue.prototype.$dialogs = createDialogs()
  Vue.prototype.$modals = createModals()
  Vue.prototype.$resource = {
    goToResource: (resourceLink: string) => {
      const win = window.open(resourceLink, '_blank')
      win?.focus()
    }
  }
  Vue.prototype.$member = {
    goToMember: (member: WorkspaceMember, router: any) => {
      router.push(`/workspace/${member.workspace}/member/${member.uuid}`)
    }
  }
  Vue.prototype.$umodel = utils
  Vue.prototype.$console = Console
  Vue.prototype.$moment = moment
  Vue.use(VueLazyload, {})

  Vue.directive('click-outside', {
    bind: function (el: any, binding: DirectiveBinding, vnode: any) {
      el.clickOutsideEvent = function (event: MouseEvent) {
        // here I check that click was outside the el and his childrens
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[(binding as any).expression](event)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el: any) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  })
}
