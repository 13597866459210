var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full", staticStyle: { "min-height": "400px" } },
    [
      _c(
        "div",
        {
          staticClass: "u-columns space-between",
          staticStyle: { "flex-direction": "column" }
        },
        [
          _c(
            "div",
            { staticClass: "u-column is-narrow" },
            [
              _c(
                "ContentWithHeader",
                {
                  attrs: {
                    title: "Knowledge base apps",
                    subtitle:
                      "Sync folders and content from tools you're already using"
                  }
                },
                [
                  _c(
                    "FileAppIntegrations",
                    _vm._g(
                      {
                        staticClass: "mt-2",
                        attrs: {
                          workspace: _vm.workspace,
                          "all-integrations": _vm.chatIntegrations,
                          "active-integrations": _vm.activeIntegrations,
                          "sync-object-name": "channels"
                        }
                      },
                      _vm.$listeners
                    )
                  ),
                  _c("div", {
                    staticClass: "divider semi-hard no-margin full-width"
                  }),
                  _c(
                    "FileAppIntegrations",
                    _vm._g(
                      {
                        attrs: {
                          workspace: _vm.workspace,
                          "all-integrations": _vm.fileIntegrations,
                          "active-integrations": _vm.activeIntegrations,
                          "sync-object-name": "folders"
                        }
                      },
                      _vm.$listeners
                    )
                  ),
                  _c("div", {
                    staticClass: "divider semi-hard no-margin full-width"
                  }),
                  _c(
                    "FileAppIntegrations",
                    _vm._g(
                      {
                        attrs: {
                          workspace: _vm.workspace,
                          "all-integrations": _vm.crmIntegrations,
                          "active-integrations": _vm.activeIntegrations
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }