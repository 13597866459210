import { wrapView } from '@/router/router-utils'
import { v2DefaultComponents } from '@/router/shared'

const SearchPageWrapper = wrapView(import('@/views-v2/SearchPageWrapper'))
const Portfolio = wrapView(import('@/views-v2/Portfolio'))
const Prospecting = wrapView(import('@/views-v2/Prospecting'))
const Accounts = wrapView(import('@/views-v2/Accounts'))
const Collections = wrapView(import('@/views-v2/Collections'))
const Chat = wrapView(import('@/views-v2/Chat'))
const Meetings = wrapView(import('@/views-v2/Meetings'))
const MeetingOutputs = wrapView(import('@/views-v2/MeetingOutputs'))
const Analytics = wrapView(import('@/views-v2/Analytics'))
const CurationPage = wrapView(import('@/views-v2/CurationPage'))
const MemberProfile = wrapView(import('@/views-v2/MemberProfile'))
const HomePage = wrapView(import('@/views-v2/HomePage'))
const WorkspaceSettings = wrapView(import('@/views-v2/WorkspaceSettings'))

const WorkspaceListWrapper = wrapView(import('@/views-v2/WorkspaceListWrapper'))
const WorkspaceCreateWrapper = wrapView(
  import('@/views-v2/WorkspaceCreateWrapper')
)

const v2Views = [
  {
    path: '/workspace/add',
    name: 'workspace-new',
    components: {
      shell: WorkspaceCreateWrapper
    }
  },
  {
    path: '/workspaces',
    name: 'workspace-list',
    components: {
      shell: WorkspaceListWrapper
    },
    props: {
      shell: (route) => ({
        canReturn: Boolean(route.query.return)
      })
    }
  },
  {
    path: '/workspace/:workspace_id/search/:tab?',
    name: 'search',
    components: {
      ...v2DefaultComponents,
      default: SearchPageWrapper
    },
    meta: {
      load: true,
      keepSearch: true
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    }
  },
  {
    path: '/workspace/:workspace_id/meetings',
    name: 'meetings',
    components: {
      ...v2DefaultComponents,
      default: Meetings
    },
    meta: {
      load: true,
      noscroll: true
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    }
  },
  {
    path: '/workspace/:workspace_id/meetings/:meeting_id/output',
    name: 'meeting-outputs',
    components: {
      ...v2DefaultComponents,
      default: MeetingOutputs
    },
    meta: {
      load: true,
      noscroll: true
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    }
  },
  {
    path: '/workspace/:workspace_id/portfolio/:ori_type/:ori_id?',
    name: 'portfolio',
    components: {
      ...v2DefaultComponents,
      default: Portfolio
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      keepSearch: true,
      noscroll: true
    },
    children: [
      {
        path: 'create',
        name: 'portfolio-create'
      },
      {
        path: 'edit',
        name: 'portfolio-edit'
      }
    ]
  },
  {
    path: '/workspace/:workspace_id/prospecting',
    name: 'prospecting',
    components: {
      ...v2DefaultComponents,
      default: Prospecting
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      noscroll: true
    }
  },
  {
    path: '/workspace/:workspace_id/accounts',
    name: 'accounts',
    components: {
      ...v2DefaultComponents,
      default: Accounts
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      noscroll: true
    }
  },
  {
    path: '/workspace/:workspace_id/presentations',
    name: 'presentations',
    components: {
      ...v2DefaultComponents,
      default: Collections
    },
    meta: {
      load: true,
      keepSearch: true,
      noscroll: true
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    }
  },
  {
    path: '/workspace/:workspace_id/chat/:chat_id?',
    name: 'chat',
    components: {
      ...v2DefaultComponents,
      default: Chat
    },
    meta: {
      noscroll: true
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    }
  },
  {
    path: '/workspace/:workspace_id/analytics',
    name: 'analytics',
    components: {
      ...v2DefaultComponents,
      default: Analytics
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      noscroll: true
    }
  },
  {
    path: '/workspace/:workspace_id/curate',
    name: 'curation',
    components: {
      ...v2DefaultComponents,
      default: CurationPage
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      noscroll: true
    }
  },
  {
    path: '/workspace/:workspace_id/settings/:tab+',
    name: 'ws-settings',
    components: {
      ...v2DefaultComponents,
      default: WorkspaceSettings
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      noscroll: true
    }
  },
  {
    path: '/workspace/:workspace_id/member/:member_id',
    name: 'member-profile',
    components: {
      ...v2DefaultComponents,
      default: MemberProfile
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    },
    meta: {
      noscroll: true
    }
  },
  {
    path: '/workspace/:workspace_id',
    name: 'home',
    components: {
      ...v2DefaultComponents,
      default: HomePage
    },
    props: {
      shell: (route) => ({
        currentWorkspaceId: route.params.workspace_id,
        hasContentBlock: true,
        noPadding: true,
        currentWorkspaceMemberId: route?.query?.member
      })
    }
  },
  {
    path: '/(.*)',
    name: 'catchall',
    redirect: {
      name: 'home'
    },
    component: {
      template: '<div/>'
    }
  }
]

export default [...v2Views]
