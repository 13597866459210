var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.allIntegrations, function(integration, index) {
      return _c(
        "div",
        { key: integration.key },
        [
          index !== 0
            ? _c("div", {
                staticClass: "divider semi-hard no-margin full-width"
              })
            : _vm._e(),
          _c("IntegrationRow", {
            attrs: {
              integration: integration,
              "integration-object": _vm.getIntegrationObject(integration)
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          !_vm.getIntegrationObject(integration)
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    size: "is-small",
                                    type: "is-primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToSyncPage(integration)
                                    }
                                  }
                                },
                                [_vm._v(" Connect ")]
                              )
                            : _c(
                                "div",
                                { staticClass: "u-columns is-vcentered" },
                                [
                                  !_vm.isCrmIntegration(integration)
                                    ? _c(
                                        "div",
                                        { staticClass: "u-column is-narrow" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "u-btn is-primary-subtle",
                                              attrs: { size: "is-small" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.goToSyncPage(
                                                    integration
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Sync " +
                                                  _vm._s(_vm.syncObjectName) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "u-column is-narrow" },
                                    [
                                      _c(
                                        "b-dropdown",
                                        {
                                          attrs: {
                                            custom: "",
                                            "mobile-modal": false,
                                            position: "is-bottom-left"
                                          }
                                        },
                                        [
                                          _c("DropdownButton", {
                                            attrs: {
                                              slot: "trigger",
                                              size: "is-medium"
                                            },
                                            slot: "trigger"
                                          }),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                custom: "",
                                                paddingless: ""
                                              }
                                            },
                                            [
                                              _c("FileIntegrationDropdown", {
                                                attrs: {
                                                  integration: integration,
                                                  workspace: _vm.workspace,
                                                  "integration-object": _vm.getIntegrationObject(
                                                    integration
                                                  )
                                                },
                                                on: {
                                                  deleteIntegration: function(
                                                    x
                                                  ) {
                                                    return _vm.$emit(
                                                      "deleteIntegration",
                                                      x
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }