var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "EmailInvitesComponent",
        _vm._g(
          {
            attrs: {
              "current-user-role": _vm.currentUserRole,
              loading: _vm.invitesLoading,
              invites: _vm.invites
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }