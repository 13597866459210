import { TreeNode } from '@c/shared/molecules/structural/trees/interfaces'
import { flatMap } from 'lodash-es'

export function isPromise(x: any) {
  return Promise.resolve(x) == x
}

export function filterNodes(
  filterFunc: (node: TreeNode) => boolean,
  tree: TreeNode
) {
  let result: TreeNode[] = []
  if (filterFunc(tree)) {
    result = [...result, tree]
  }
  if (tree.children && (tree.children as TreeNode[]).length > 0) {
    result = [
      ...result,
      ...flatMap(tree.children as TreeNode[], (child: TreeNode) =>
        filterNodes(filterFunc, child)
      )
    ]
  }
  return result
}

export function filterTree(
  filterFunc: (node: TreeNode) => boolean,
  tree: TreeNode,
  filterParents: boolean = true
): TreeNode[] {
  // Parent functions
  if (
    !filterParents &&
    !isPromise(tree.children) &&
    (tree.children as TreeNode[]).length !== 0
  ) {
    return (tree.children as TreeNode[]).flatMap((child: TreeNode) =>
      filterTree(filterFunc, child, filterParents)
    )
  } else if (
    filterParents &&
    !isPromise(tree.children) &&
    (tree.children as TreeNode[]).length !== 0
  ) {
    if (filterFunc(tree)) {
      const filteredChildren = (tree.children as TreeNode[]).flatMap(
        (child: TreeNode) => filterTree(filterFunc, child, filterParents)
      )
      filteredChildren.push(tree)
      return filteredChildren
    }
  }

  // Child functions
  else if (
    !isPromise(tree.children) &&
    (tree.children as TreeNode[]).length === 0
  ) {
    if (filterFunc(tree)) {
      return [tree]
    }
  }
  return []
}

export function findBranches(
  filterFunc: (node: TreeNode) => boolean,
  tree: TreeNode
): TreeNode[] {
  // Parent functions
  let result: TreeNode[] = []
  if (isPromise(tree.children) || tree.children === undefined) {
    return result
  }

  if (filterFunc(tree)) {
    return [tree]
  }

  for (const node of tree.children as TreeNode[]) {
    if (filterFunc(node)) {
      result.push(node)
      continue
    }

    result = [...result, ...findBranches(filterFunc, node)]
  }

  return result
}

export async function traverseUpward(
  func: (node: TreeNode) => void,
  node: TreeNode,
  withBase = true
) {
  let currentNode = withBase ? node : node.parent
  while (currentNode) {
    await func(currentNode)
    currentNode = currentNode.parent
  }
}

export async function traverseDownward(
  func: (node: TreeNode) => void,
  node: TreeNode,
  withBase = true
) {
  let currentNodes: TreeNode[] = (withBase
    ? [node]
    : node.children || []) as unknown as TreeNode[]
  while (currentNodes.length > 0) {
    const currentNode: TreeNode = currentNodes[0]
    func(currentNode)
    if (!isPromise(currentNode.children)) {
      currentNodes?.push(...((currentNode.children as TreeNode[]) || []))
    }
    currentNodes = currentNodes.slice(1)
  }
}

export async function findNode(
  tree: TreeNode,
  nodeId: any
): Promise<TreeNode | undefined> {
  if (tree.id === nodeId) {
    return tree
  }
  if (tree.children) {
    const resolvedTreeChildren = (await Promise.resolve(
      tree.children
    )) as TreeNode[]
    const foundChildrenForTree = (
      await Promise.all(
        resolvedTreeChildren.map((x: TreeNode) => findNode(x, nodeId))
      )
    ).find((x) => x)
    return foundChildrenForTree
  }
  return undefined
}

export function findSkipAsyncNode(
  tree: TreeNode,
  nodeId: any
): TreeNode | undefined {
  if (tree.id === nodeId) {
    return tree
  }
  if (tree.children) {
    if (!isPromise(tree.children)) {
      const resolvedTreeChildren = tree.children as TreeNode[]
      const foundChildrenForTree = resolvedTreeChildren
        .map((x: TreeNode) => findSkipAsyncNode(x, nodeId))
        .find((x) => x)
      return foundChildrenForTree
    }
  }
  return undefined
}

export async function resolveAllChildren(tree: TreeNode) {
  tree.children = await Promise.resolve(tree.children)
  if (tree.children) {
    await Promise.all(tree.children.map((child) => resolveAllChildren(child)))
  }
  return tree
}

export async function traverseNodes(
  tree: TreeNode,
  filterFunc: (node: TreeNode) => boolean,
  mapFunc: (node: TreeNode) => void
) {
  /**
   * Traverse all nodes where the filterFunc is called on each node. If false, the children are still traversed but the
   * mapFunc is not called on it.
   */

  // Check current node
  if (filterFunc(tree)) {
    mapFunc(tree)
  }

  // First resolve all children
  await resolveAllChildren(tree)

  // Then traverse down
  for (const child of (tree.children || []) as TreeNode[]) {
    await traverseNodes(child, filterFunc, mapFunc)
  }
}
