import {
  searchDetail,
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import ISO6391 from 'iso-639-1'
import { uniqBy } from 'lodash-es'
import Vue from 'vue'
import { Paginator, getClient, getResponseData } from '../../axios'
import Console from '../../console'
import Mutations from '../mutation-types'

const client = getClient('/workspaces')

const getDefaultState = () => {
  return {
    workspaces: {},
    joinableWorkspaces: {},
    activeWorkspace: undefined,
    currentWorkspace: undefined,
    roles: [],
    rolesWorkspace: ''
  }
}

export default {
  state: getDefaultState(),

  mutations: {
    [Mutations.UPSERT_WORKSPACES](state, workspaces) {
      for (const workspace of workspaces) {
        Vue.set(state.workspaces, workspace.uuid, {
          ...state.workspaces[workspace.uuid],
          ...workspace
        })
      }
    },

    [Mutations.UPSERT_JOINABLE_WORKSPACES](state, workspaces) {
      for (const workspace of workspaces) {
        Vue.set(state.joinableWorkspaces, workspace.uuid, {
          ...state.joinableWorkspaces[workspace.uuid],
          ...workspace
        })
      }
    },

    [Mutations.UPSERT_WORKSPACE_MEMBERS](state, { data, workspace_id }) {
      Vue.set(state.workspaces, workspace_id, {
        ...state.workspaces[workspace_id],
        members: uniqBy(
          [...(state.workspaces[workspace_id].members || []), ...data],
          (x) => x.uuid
        )
      })
    },

    DELETE_WORKSPACE(state, workspace) {
      Vue.delete(state.workspaces, workspace.uuid)
    },

    RESET_ACTIVE_WORKSPACE(state) {
      // reset active workspace to another workspace than current one
      const otherWorkspaces = Object.values(state.workspaces).filter(
        (x) => x.uuid != state.activeWorkspace?.uuid
      )
      if (otherWorkspaces.length > 0) {
        state.activeWorkspace = otherWorkspaces[0]
      } else {
        state.activeWorkspace = null
      }
    },

    DELETE_JOINABLE_WORKSPACE(state, workspace) {
      Vue.delete(state.joinableWorkspaces, workspace.uuid)
    },

    [Mutations.SET_ACTIVE_WORKSPACE](state, workspace) {
      state.activeWorkspace = workspace
    },

    [Mutations.RESET_WORKSPACES](state) {
      Object.assign(state, getDefaultState())
    },

    MODIFY_NUMBER_OF_MEMBERS(state, { change, workspace_id }) {
      Vue.set(state.workspaces, workspace_id, {
        ...state.workspaces[workspace_id],
        member_count: state.workspaces[workspace_id].member_count + change
      })
    },
    setCurrentWorkspace(state, workspaceId) {
      const workspace = state.workspaces[workspaceId]
      if (workspace) state.currentWorkspace = workspace
    },
    setRoles(state, { roles, workspace_id }) {
      state.roles = roles
      state.rolesWorkspace = workspace_id
    }
  },

  actions: {
    setCurrentWorkspace({ commit }, workspaceId) {
      commit('setCurrentWorkspace', workspaceId)
    },

    async getWorkspace(_, { id }) {
      const res = await client.get(`/${id}/`)
      return getResponseData(res)
    },

    async getSearchResourcesPaginator(
      { commit, getters },
      { data, params, noCommit, extraData, extraParams, resourceType }
    ) {
      if (!resourceType) return
      Console.log({ data, params, noCommit, extraData, extraParams })
      const searchData = {
        relevancy: 'medium',
        ...data
      }
      const emptyExtraParams = { ...params }
      Object.keys(extraParams).forEach((x) => (emptyExtraParams[x] = undefined))
      const extraParamsCopy = Object.assign({}, extraParams)
      delete extraParamsCopy.workspace

      if (searchData.languages) {
        for (let i = 0; i < searchData.languages.length; i++) {
          let isoCode = ISO6391.getCode(searchData.languages[i])
          if (searchData.languages[i] === 'Chinese') {
            isoCode = 'zh-cn'
          }
          searchData.languages[i] = isoCode
        }
      }
      const resourceLevelMap = {
        slides: 'subresources',
        snippets: 'snippets',
        people: 'members'
      }
      let resourceLevel =
        resourceType in resourceLevelMap
          ? resourceLevelMap[resourceType]
          : 'resources'

      const searchEnrichmentContext = getters.searchEnrichmentContext
      const searchEnrichment =
        searchEnrichmentContext && searchEnrichmentContext.trigger
          ? searchEnrichmentContext
          : {
              page: searchSourcePage.search_results,
              trigger: searchTrigger.search,
              detail: searchDetail.search.url
            }

      const response = await client.post(
        `/${extraParams.workspace}/${resourceLevel}/search/`,
        {
          ...searchData,
          ...extraData,
          tab: searchData.tab || resourceType,
          ...searchEnrichment
        },
        { params: { ...params, ...extraParamsCopy }, timeout: 10000 }
      )
      if (
        !(
          (searchData.tab || resourceType) === 'all' &&
          resourceLevel === 'resources'
        )
      )
        commit('setSearchEnrichmentContext', undefined)
      if (!(searchEnrichmentContext && searchEnrichmentContext.trigger)) {
        commit('setSearchEnrichmentContext', {
          trigger: 'search',
          detail: 'url'
        })
      }
      return new Paginator(
        client,
        response,
        (data) => {
          if (!noCommit && resourceLevel === 'subresources')
            commit('upsertSubresources', data)
        },
        resourceLevel,
        async (url) => {
          const searchEnrichment = getters.searchEnrichmentContext
          return client.post(
            url,
            {
              ...searchData,
              tab: searchData.tab || resourceType,
              ...searchEnrichment
            },
            {
              params: { ...emptyExtraParams },
              timeout: 10000
            }
          )
        }
      )
    },

    async getSearchResourcesPage(
      { commit },
      { data, params, noCommit, extraData, extraParams, resourceType }
    ) {
      if (!resourceType) return
      const searchData = {
        relevancy: 'medium',
        ...data
      }
      const emptyExtraParams = { ...params }
      Object.keys(extraParams).forEach((x) => (emptyExtraParams[x] = undefined))
      const extraParamsCopy = Object.assign({}, extraParams)
      delete extraParamsCopy.workspace

      if (searchData.languages) {
        for (let i = 0; i < searchData.languages.length; i++) {
          let isoCode = ISO6391.getCode(searchData.languages[i])
          if (searchData.languages[i] === 'Chinese') {
            isoCode = 'zh-cn'
          }
          searchData.languages[i] = isoCode
        }
      }
      const resourceLevelMap = {
        slides: 'subresources',
        snippets: 'snippets',
        people: 'members'
      }
      let resourceLevel =
        resourceType in resourceLevelMap
          ? resourceLevelMap[resourceType]
          : 'resources'

      const response = await client.post(
        `/${extraParams.workspace}/${resourceLevel}/search/`,
        { ...searchData, ...extraData, tab: searchData.tab || resourceType },
        { params: { ...params, ...extraParamsCopy }, timeout: 10000 }
      )

      const response_data = getResponseData(response, false)
      if (!noCommit) {
        const mutation =
          resourceLevel === 'resources'
            ? Mutations.UPSERT_RESOURCES
            : 'upsertSubresources'
        commit(mutation, response_data)
      }
      return { response_data, response }
    },

    async getWorkspaces({ commit }, config) {
      const res = await client.get('/', config)
      const data = getResponseData(res)
      commit(Mutations.UPSERT_WORKSPACES, data)
      return data
    },

    async getJoinableWorkspaces({ commit }) {
      const res = await client.get('/', {
        query: { invited: true },
        params: { invited: true }
      })
      const data = getResponseData(res)
      commit(Mutations.UPSERT_JOINABLE_WORKSPACES, data)
      return data
    },

    async getWorkspacesPaginator({ commit }, config) {
      return new Paginator(client, await client.get('/', config), (data) =>
        commit(Mutations.UPSERT_WORKSPACES, data)
      )
    },

    async createWorkspace({ commit }, data) {
      const res = await client.post('/', data)
      const resData = getResponseData(res)
      commit(Mutations.UPSERT_WORKSPACES, [resData])
      commit(Mutations.SET_ACTIVE_WORKSPACE, resData)
      return resData
    },

    async patchWorkspace({ commit, state }, { id, data }) {
      const res = await client.patch(`/${id}/`, data)
      const workspace = getResponseData(res)
      commit(Mutations.UPSERT_WORKSPACES, [workspace])
      if (id === state.currentWorkspace?.uuid)
        commit('setCurrentWorkspace', workspace.uuid)
      return workspace
    },

    async updateImage({ commit }, { id, image }) {
      let formData = null
      if (image instanceof File) {
        formData = new FormData()
        formData.append('image', image)
      }
      const res = await client.put(`/${id}/image/`, formData)
      const resData = getResponseData(res)
      commit(Mutations.UPSERT_WORKSPACES, [resData])
      commit(Mutations.SET_ACTIVE_WORKSPACE, resData)
      return resData
    },

    async joinWorkspace({ commit }, { workspace }) {
      await client.post(`/${workspace.uuid}/members/`)

      commit(Mutations.SET_ACTIVE_WORKSPACE, workspace)
      commit('DELETE_JOINABLE_WORKSPACE', workspace)
      return workspace
    },

    async leaveWorkspace({ commit }, { workspaceId, userId }) {
      await client.delete(`/${workspaceId}/members/${userId}`)
      commit('RESET_ACTIVE_WORKSPACE')
      commit('DELETE_WORKSPACE', { uuid: workspaceId })
    },

    async deleteWorkspace({ commit }, { id }) {
      await client.delete(`/${id}/`)
      commit('RESET_ACTIVE_WORKSPACE')
      commit('DELETE_WORKSPACE', { uuid: id })
    },

    // Workspace members

    async deleteWorkspaceMember({ commit }, { workspace_id, member_id }) {
      const res = await client.delete(`/${workspace_id}/members/${member_id}/`)
      commit('MODIFY_NUMBER_OF_MEMBERS', { workspace_id, change: -1 })
      return getResponseData(res)
    },

    async patchWorkspaceMember(_, { workspace_id, member_id, data }) {
      const res = await client.patch(
        `/${workspace_id}/members/${member_id}/`,
        data
      )
      return getResponseData(res)
    },

    async getWorkspaceMember(_, { workspace_id, member_id, params }) {
      const res = await client.get(`/${workspace_id}/members/${member_id}/`, {
        params
      })
      const data = getResponseData(res)
      return data
    },

    async getCurrentWorkspaceMember({ commit }, { workspace_id }) {
      const client = getClient(`/workspaces/${workspace_id}/members`)
      return client.get('/me/').then((res) => {
        const resData = getResponseData(res)
        const member = Array.isArray(resData) ? resData[0] : resData
        commit(Mutations.SET_CURRENT_WORKSPACEMEMBER, { member, workspace_id })
        return member
      })
    },

    async getWorkspaceMemberInsights(_, { workspace_id, member_id, params }) {
      const res = await client.get(
        `/${workspace_id}/members/${member_id}/insights/`,
        {
          params
        }
      )
      const data = getResponseData(res)
      return data
    },

    async getWorkspaceMembersPaginator(_, { workspace_id, params, extended }) {
      return new Paginator(
        client,
        client.get(`/${workspace_id}/members/`, {
          params: {
            page_size: 15,
            extended,
            ...params
          }
        }),
        () => {}
      )
    },

    async getWorkspaceMembersPage(
      _,
      { workspace_id, params, page_size, extended }
    ) {
      const res = await client.get(`/${workspace_id}/members/`, {
        params: {
          page_size: page_size || 15,
          extended,
          ...params
        }
      })
      const data = getResponseData(res)
      return data
    },

    // Permissions

    async getRoles({ state, commit }, { workspace_id }) {
      if (state.rolesWorkspace === workspace_id) return state.roles
      const res = await client.get(`/${workspace_id}/roles/`)
      const data = getResponseData(res)
      commit('setRoles', { roles: data, workspace_id })
      return data
    },

    async setRoles(_, { workspace_id, member_id, role_ids }) {
      const res = await client.patch(`/${workspace_id}/members/${member_id}/`, {
        role_ids
      })
      return getResponseData(res)
    },

    // Resources

    async getWorkspaceResourceDetail(_, { workspace_id, resource_id }) {
      const res = await client.get(`/${workspace_id}/resources/${resource_id}/`)
      return getResponseData(res)
    },

    async getWorkspaceSubresourceDetail(_, { workspace_id, subresource_id }) {
      const res = await client.get(
        `/${workspace_id}/subresources/${subresource_id}/`
      )
      return getResponseData(res)
    },

    async getWorkspaceResourceDetailBulk(
      _,
      { workspace_id, resource_ids, act_as }
    ) {
      const res = await client.post(
        `/${workspace_id}/resources/get-bulk/`,
        {
          resource_ids
        },
        {
          ...(act_as && { params: { act_as } })
        }
      )
      return getResponseData(res)
    },

    async getWorkspaceInsights(_, { workspace_id, impersonatedMember }) {
      const res = await client.get(`/${workspace_id}/insights/`, {
        ...(impersonatedMember
          ? { params: { act_as: impersonatedMember } }
          : {})
      })
      return getResponseData(res)
    },

    async getResourceSummarization(_, { workspace_id, resource_id, act_as }) {
      const res = await client.get(
        `/${workspace_id}/resources/${resource_id}/summarize/`,
        { ...(act_as ? { params: { act_as } } : {}) }
      )
      return getResponseData(res)
    },

    async getHighlightedResources(_, { workspace_id, act_as }) {
      const res = await client.get(`/${workspace_id}/resources/`, {
        params: { highlighted: true, ...(act_as ? { act_as } : {}) }
      })
      return getResponseData(res)
    },

    // Analytics

    async getWorkspaceAnalytics(_, { workspace_id, export_filetype }) {
      const res = await client.get(`/${workspace_id}/analytics/`, {
        ...(export_filetype ? { params: { export: export_filetype } } : {})
      })
      return getResponseData(res)
    }
  },

  getters: {
    activeWorkspace(state) {
      return state.activeWorkspace
    },

    workspaces(state) {
      return Object.values(state.workspaces).sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },

    joinableWorkspaces(state) {
      return Object.values(state.joinableWorkspaces).sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    currentWorkspace: (state) => state.currentWorkspace,
    roles: (state) => state.roles,
    rolesWorkspace: (state) => state.rolesWorkspace,
    showPeopleTab: (state) =>
      !!state.currentWorkspace?.settings.find((s) => s.name === 'people_tab')
        ?.enabled || false,
    showCollectSlides: (state) =>
      !!state.currentWorkspace?.settings.find(
        (s) => s.name === 'collect_slides'
      )?.enabled || false,
    downloadFilesEnabled: (state) =>
      !!state.currentWorkspace?.settings.find(
        (s) => s.name === 'download_files'
      )?.enabled || false,
    highlightsEnabled: (state) =>
      !!state.currentWorkspace?.settings.find(
        (s) => s.name === 'highlight_resource'
      )?.enabled || false,
    deduplicationEnabled: (state) =>
      !!state.currentWorkspace?.settings.find((s) => s.name === 'deduplication')
        ?.enabled || false,
    oriManagementEnabled: (state) =>
      !!state.currentWorkspace?.settings.find(
        (s) => s.name === 'ori_asset_management'
      )?.enabled || false,
    draftOriContentEnabled: (state) =>
      !!state.currentWorkspace?.settings.find(
        (s) => s.name === 'draft_ori_content'
      )?.enabled || false
  }
}
