var render = function() {
  var _obj, _obj$1, _obj$2, _obj$3
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-focus" }, [
    _c(
      "div",
      { staticClass: "no-focus", staticStyle: { position: "relative" } },
      [
        _c(
          "div",
          {
            staticClass: "dark-dialog-container no-focus",
            class:
              ((_obj = {}),
              (_obj[_vm.horizontalAlign] = !_vm.noArrow),
              (_obj[_vm.verticalAlign] = !_vm.noArrow),
              (_obj[_vm.type] = true),
              _obj)
          },
          [_c("div", { staticClass: "no-focus pv-3" }, [_vm._t("default")], 2)]
        ),
        !_vm.noArrow
          ? _c(
              "div",
              {
                staticClass: "dark-dialog-arrow-container",
                class:
                  ((_obj$1 = {}),
                  (_obj$1[_vm.verticalAlign] = !_vm.noArrow),
                  (_obj$1[_vm.horizontalAlign] = !_vm.noArrow),
                  (_obj$1[_vm.type] = true),
                  _obj$1)
              },
              [
                _c("div", {
                  staticClass: "shadow-remover",
                  class:
                    ((_obj$2 = {}),
                    (_obj$2[_vm.verticalAlign] = !_vm.noArrow),
                    _obj$2)
                }),
                _c(
                  "svg",
                  {
                    staticClass: "dark-dialog-arrow",
                    class: ((_obj$3 = {}), (_obj$3[_vm.type] = true), _obj$3),
                    attrs: {
                      width: "28",
                      height: "14",
                      viewBox: "0 0 57 28",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M26.0607 26.0607L0 0L56.0355 0L31.891 25.9554C30.3281 27.6354 27.6832 27.6832 26.0607 26.0607Z"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }