import { getClient } from '../../../store/src/axios'

const client = getClient('/workspaces')

export async function addBookmarkToResource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  const { data } = await client.post(
    `/${workspaceId}/resources/${resourceId}/bookmark/`,
    {},
    {
      ...(impersonatedMember && { params: { act_as: impersonatedMember } })
    }
  )
  return data[0]
}

export async function deleteBookmarkFromResource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  await client.delete(`/${workspaceId}/resources/${resourceId}/bookmark/`, {
    ...(impersonatedMember ? { params: { act_as: impersonatedMember } } : {})
  })
}

export async function addBookmarkToSubresource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  const { data } = await client.post(
    `/${workspaceId}/subresources/${resourceId}/bookmark/`,
    {},
    {
      ...(impersonatedMember && { params: { act_as: impersonatedMember } })
    }
  )
  return data[0]
}

export async function deleteBookmarkFromSubresource(
  workspaceId,
  resourceId,
  impersonatedMember
) {
  await client.delete(`/${workspaceId}/subresources/${resourceId}/bookmark/`, {
    ...(impersonatedMember ? { params: { act_as: impersonatedMember } } : {})
  })
}
