var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-dropdown", _vm._b({}, "b-dropdown", _vm.$attrs, false), [
    _c(
      "div",
      {
        staticClass: "cursor-pointer",
        attrs: { slot: "trigger" },
        slot: "trigger"
      },
      [
        _vm._t(
          "trigger",
          function() {
            return [
              _c("div", { staticClass: "u-columns is-vcentered" }, [
                _c(
                  "div",
                  { staticClass: "u-column is-narrow" },
                  [
                    _vm._t(
                      "default",
                      function() {
                        return [
                          _c("p", { staticClass: "body body-text" }, [
                            _vm._v(
                              " " + _vm._s(_vm.value || "Select a value") + " "
                            )
                          ])
                        ]
                      },
                      { value: _vm.value }
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "u-column is-narrow" },
                  [
                    _c("b-icon", {
                      attrs: {
                        "custom-class": "body body-text",
                        icon: "chevron-down"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          },
          { value: _vm.value }
        )
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "no-margin border element none", class: _vm.customClass },
      _vm._l(_vm.options, function(option) {
        return _c(
          "b-dropdown-item",
          { key: option.key, style: { width: _vm.width + "px" } },
          [
            _c(
              "div",
              [
                _c(
                  "b-radio",
                  {
                    attrs: { "native-value": option.key, name: option.key },
                    model: {
                      value: _vm.currentValue,
                      callback: function($$v) {
                        _vm.currentValue = $$v
                      },
                      expression: "currentValue"
                    }
                  },
                  [
                    _c("p", { staticClass: "body mv-1" }, [
                      _vm._v(_vm._s(option.label))
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "body body-text important xxs mv-1",
                        staticStyle: { "margin-top": "-8px" }
                      },
                      [_vm._v(" " + _vm._s(option.description) + " ")]
                    )
                  ]
                )
              ],
              1
            )
          ]
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }