import Console from './webapp/src/console'

/** Global utility functions */

import moment from 'moment'

export function formatDate(date) {
  const m = moment(date)
  return m.format('MMM D, YYYY')
}

export function formatDateWTime(date) {
  const m = moment(date)
  return m.format('D MMM YYYY, H:mm')
}

export function relativeTime(date) {
  const m = moment(date)
  return m.fromNow()
}

export function toPossesive(subject) {
  let tail = "'s"
  if (subject.endsWith('s')) {
    tail = "'"
  }

  return `${subject}${tail}`
}

export function errorToast(context, err) {
  let msg = err
  if (err instanceof Error) {
    msg = err.message
  }
  context.$buefy.toast.open({
    message: msg,
    type: 'is-danger'
  })
}

export function successToast(context, msg) {
  context.$buefy.toast.open({
    message: msg,
    type: 'is-success'
  })
}

export function defaultToast(context, msg) {
  context.$buefy.toast.open({
    message: msg
  })
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function capitalize(str) {
  const head = str.charAt(0).toUpperCase()
  const tail = str.slice(1)

  return head.concat(tail)
}

export function truncateWords(str, word_count, max_length) {
  if (str == null) {
    return str
  }
  let words = str.split(' ').slice(0, word_count)

  // Remove last word if longer than max_length
  let length = words.reduce((total, word) => (total += word.length), 0)
  let truncated = false
  while (length >= max_length) {
    truncated = true
    words.pop()
    length = words.reduce((total, word) => (total += word.length), 0)
  }

  if (truncated) {
    words.push('...')
  }

  return words.join(' ')
}

export function truncateLink(str, maxLength, numberOfKeptSuffixChars = 10) {
  return (
    str.slice(0, maxLength - numberOfKeptSuffixChars) +
    (str.length > maxLength - 3 ? '...' : '') +
    str.slice(
      str.length - Math.min(numberOfKeptSuffixChars, str.length - maxLength),
      str.length
    )
  )
}

export function durationToString(ms) {
  if (ms > 3600000) {
    return Math.round(ms / 3600000) + ' hours'
  }
  if (ms > 60000) {
    return Math.round(ms / 60000) + ' minutes'
  }
  if (ms > 1000) {
    return Math.round(ms / 1000) + ' seconds'
  }
  return Math.round(ms) + ' milliseconds'
}

export function isValidEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export function getEnvironment() {
  return process.env.NODE_ENV || 'production'
}

export function getAppHostname() {
  if (process.env.VUE_APP_WEBAPP_HOST) {
    return process.env.VUE_APP_WEBAPP_HOST
  }
  switch (getEnvironment()) {
    case 'development':
      return 'app.development.uman.ai'
    case 'testing':
      return 'app.testing.uman.ai'
    case 'local':
      return 'localhost:8080'
    case 'acceptance':
      return 'app.acceptance.uman.ai'
    case 'production-pwc':
      return 'app.pwc.uman.ai'
    case 'production':
      return 'app.uman.ai'
    default:
      return `app.${getEnvironment()}.uman.ai`
  }
}

export function getAppURL(path = '') {
  const host = getAppHostname()
  return host + path
}

export function getVersion() {
  return process.env.VUE_APP_VERSION
}

const firefox = 'https://addons.mozilla.org/nl/firefox/addon/uman-ai/'
const chrome =
  'https://chrome.google.com/webstore/detail/umanai/hcoagckgaojoepkjmgofefooplfbbohf'

const vendor = ((navigator && navigator.vendor) || '').toLowerCase()
const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase()

export function isChrome() {
  return Boolean(
    /google inc/.test(vendor)
      ? userAgent.match(/(?:chrome|crios)\/(\d+)/)
      : null
  )
}

export function isFirefox() {
  return Boolean(userAgent.match(/(?:firefox|fxios)\/(\d+)/))
}

export function getExtensionUrl() {
  if (isFirefox()) {
    return firefox
  } else if (isChrome()) {
    return chrome
  }
}

export class BoundedNumber {
  constructor(min, max, value) {
    this.min = min
    this.max = max
    this.value = value
  }

  get isMin() {
    return this.value == this.min
  }

  get isMax() {
    return this.value == this.max
  }

  add(amount) {
    if (this.value + amount < this.max) {
      this.value += amount
    } else {
      this.value = this.max
    }
  }

  sub(amount) {
    if (this.value - amount > this.min) {
      this.value -= amount
    } else {
      this.value = this.min
    }
  }

  valueOf() {
    return this.value
  }
}

export function extensionInstalled() {
  return document.body.classList.contains('umanai-installed')
}

/**
 * Calculates the factorial of n using memoization.
 *
 * @param {number} n Integer for which to calculate the factorial
 * @returns {number} Factorial of `n`
 */
function factorial(n) {
  if (typeof factorial.mem === 'undefined') factorial.mem = { 0: 1 }

  let y = factorial.mem[n]

  if (y !== undefined) return y

  y = n * factorial(n - 1)
  factorial.mem[n] = y
  return y
}

/**
 * Smoothens a list of points by using Bézier curves.
 *
 * @param {{x: number, y: number}[]} P
 * The list of points to smoothen using the Bézier curve
 * @param {{x: number, y: number}[]} bends
 * A list of points, of which the axes represent percentages.
 * Each bend point generates a new point between 2 existing points in the data.
 * Their coordinates being the percentage value of the bend for the 2 given points.
 * @param {number} granularity
 * The granularity or step size of the resulting set of numbers.
 * This defines the resolution of the curve, lower values result in higher resolutions.
 * @returns {{x: number, y: number}[]} The Bézier curve
 */
export function bezierCurve(
  P,
  bends = [
    { x: 0.5, y: 0 },
    { x: 0.5, y: 1 }
  ],
  granularity = 0.1
) {
  if (typeof bezierCurve.c === 'undefined') bezierCurve.c = {}

  const xy = []
  const n = bends.length

  // Polynomial Coeffecient
  const C = (j, P) => {
    let coef = 1
    for (let m = 0; m < j; ++m) coef *= n - m + 1

    let s = { x: 0, y: 0 },
      c = 0,
      p
    for (let i = 0; i <= j; ++i) {
      c = (-1) ** (i + j) / (factorial(i) * factorial(j - i))

      p = P[i]
      s.x += c * p.x
      s.y += c * p.y
    }

    return { x: coef * s.x, y: coef * s.y }
  }

  // Bézier Polynomial
  const B = (t, P) => {
    let xy = { x: 0, y: 0 }
    for (let j = 0; j < P.length; ++j) {
      const c = C(j, P)
      xy.x += t ** j * c.x
      xy.y += t ** j * c.y
    }
    return xy
  }

  // Add bending points
  const Q = [...P]
  const step = bends.length + 1
  const m = (P.length - 1) * step
  for (let i = 1; i < m; i += step) {
    Q.splice(
      i,
      0,
      ...bends
        .map((b) => ({
          x: Q[i].x + b.x * (Q[i - 1].x - Q[i].x),
          y: Q[i].y + b.y * (Q[i - 1].y - Q[i].y)
        }))
        .reverse()
    )
  }

  // Apply Bézier Curve
  for (let i = 0; i <= Q.length - n - 2; i += n + 1)
    for (let t = 0; t < 1; t += granularity) {
      const b = B(t, Q.slice(i, i + n + 2))
      xy.push({ x: b.x, y: b.y })
    }

  return xy
}

export function goHome(route, router) {
  const homeRouteParams = {
    name: 'home',
    params: {
      workspace_id: route.params.workspace_id
    },
    query: {}
  }
  router.push(homeRouteParams)
}

export function setImpersonatedMember(member, route, router) {
  try {
    router.push({
      query: {
        ...route.query,
        act_as: member.uuid || member
      }
    })
  } catch (e) {
    Console.debug('Something went wrong when setting impersonation', e)
  }
}

export function removeImpersonatedMember(route, router) {
  try {
    router.push({
      query: {
        ...route.query,
        act_as: undefined
      }
    })
  } catch (e) {
    Console.debug('Something went wrong when setting impersonation', e)
  }
}

export function filterFromRoute(route, key) {
  const x = route.query[key]
    ? route.query[key].split(',').map((el) => el.replaceAll('¬', ','))
    : []
  return x
}

export function routeSafe(value) {
  return value.replaceAll(',', '¬')
}

export function buildOauthUrl(baseURL, query) {
  return `${baseURL}?${Object.keys(query)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
    })
    .join('&')}`
}

export function refreshRoute(router, route) {
  router.replace({ query: { ...route.query, temp: Date.now() } })
  router.replace({ query: { ...route.query, temp: undefined } })
}

export function setRoutePadding(router, route, padding = true) {
  route.meta.noscroll = !padding
  refreshRoute(router, route)
}

export function getNonce(length = 20) {
  const x = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let y = ''
  for (let idx = 0; idx < length; idx++) {
    y += x[Math.floor(Math.random() * x.length)]
  }
  return y
}
