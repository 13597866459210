












































import Vue from 'vue'

interface Option {
  key: string
  label: string
  description: string
}

export default Vue.extend({
  name: 'DropdownSelectWithDescription',
  props: {
    options: {
      type: Array as () => Option[],
      required: true
    },
    value: {
      type: String as () => string,
      default: undefined
    },
    width: {
      type: Number as () => Number,
      default: undefined
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  watch: {
    currentValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal)
      }
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentValue = newVal
      }
    }
  }
})
