import Mutations from '@/store/mutation-types'
import Workspace from '@c/models/Workspace'
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

export const ResourceGridMixin = Vue.extend({
  computed: {
    ...mapState({
      workspaces: (state: any) =>
        Object.values(state.workspaces.workspaces) as Workspace[],
      currentUser: (state: any) =>
        state.users.currentUser || state.users.currentWorkspaceMember,
      resourceObjects: function (state: any) {
        return state.resources.orderedResources
      }
    }),

    populatedWorkspaces() {
      return this.workspaces
    }
  },
  methods: {
    ...mapMutations([Mutations.UPSERT_RESOURCES])
  }
})

export default Vue.extend({
  mixins: [ResourceGridMixin]
})
