var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "carousel-container" },
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isStart,
              expression: "!isStart"
            }
          ],
          staticClass: "arrow-position arrow-left cursor-pointer",
          attrs: { id: "left-chevron" },
          on: {
            click: function($event) {
              return _vm.scroll(-1)
            },
            mouseover: function($event) {
              _vm.arrowHovered["left"] = true
            },
            mouseleave: function($event) {
              _vm.arrowHovered["left"] = false
            }
          }
        },
        [
          _c("div", { staticClass: "arrow-scrim arrow-scrim-left" }, [
            _c("div", { staticClass: "arrow-scrim-container" }, [
              _c("img", {
                class: {
                  "arrow-scrim-img": true,
                  "arrow-scrim-dropshadow-left": !_vm.arrowHovered["left"]
                },
                attrs: {
                  src: _vm.arrowHovered["left"]
                    ? _vm.RightArrowHover
                    : _vm.RightArrow,
                  alt: ">"
                }
              })
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isEnd,
              expression: "!isEnd"
            }
          ],
          staticClass: "arrow-position arrow-right cursor-pointer",
          attrs: { id: "right-chevron" },
          on: {
            click: function($event) {
              return _vm.scroll(1)
            },
            mouseover: function($event) {
              _vm.arrowHovered["right"] = true
            },
            mouseleave: function($event) {
              _vm.arrowHovered["right"] = false
            }
          }
        },
        [
          _c("div", { staticClass: "arrow-scrim" }, [
            _c("div", { staticClass: "arrow-scrim-container" }, [
              _c("img", {
                class: {
                  "arrow-scrim-img": true,
                  "arrow-scrim-dropshadow-right": !_vm.arrowHovered["right"]
                },
                attrs: {
                  src: _vm.arrowHovered["right"]
                    ? _vm.RightArrowHover
                    : _vm.RightArrow,
                  alt: ">"
                }
              })
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          ref: "subresources",
          attrs: { id: "subresources" },
          on: {
            touchdrag: function($event) {
              $event.preventDefault()
            },
            touchstart: _vm.checkLoadMore,
            touchend: _vm.handleScrollEnd,
            wheel: _vm.handleScroll,
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        _vm._l(_vm.subresources, function(subresource, index) {
          return _c("SubResourceVisualisation", {
            key: index,
            class: "mr-" + _vm.marginBetween,
            attrs: {
              subresource: subresource,
              unit: _vm.subResourceUnit,
              "can-load": _vm.loadAll ? true : index < _vm.initialLoad,
              "show-page-button": _vm.showPageButton,
              clickable: _vm.clickable,
              "zoom-cursor": _vm.zoomCursor,
              rank: index,
              "resource-trace-id": _vm.resourceTraceId,
              "resource-type": _vm.resourceType,
              mimetype: _vm.mimetype,
              "force-show-collect": _vm.forceShowCollect
            },
            on: {
              imageLoaded: function($event) {
                return _vm.handleImageLoaded(index)
              },
              cantLoad: function($event) {
                return _vm.handleImageNotLoading()
              }
            },
            nativeOn: {
              click: function($event) {
                $event.preventDefault()
                return _vm.emitSlideClicked(subresource, index)
              }
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }