import Mutations from '../store/mutation-types'
import Vue from 'vue'
import Console from '@/console'

export const HydrationMixinNoWatch = Vue.extend({
  async created() {
    Console.log('Hydrating component on create due to mixin')
    await this._hydrate()
  },
  methods: {
    async _hydrate(): Promise<void> {
      await this.hydrate()
      this.$store.commit(Mutations.STOP_LOADING)
      Console.log('Hydrated')
    },
    async hydrate(): Promise<void> {
      Console.warning(
        'The hydrate function was not implemented, while the Hydration mixin was used. This is most likely an oversight.'
      )
    }
  }
})

export default Vue.extend({
  mixins: [HydrationMixinNoWatch],
  watch: {
    async id() {
      Console.debug('ID changed, resetting store and rehydrating')
      this.$store.commit(Mutations.START_LOADING)
      //@ts-ignore
      await this.resetStore()
      //@ts-ignore
      await this._hydrate()
    }
  },
  methods: {
    async resetStore(): Promise<void> {}
  }
})
