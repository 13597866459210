<template>
  <div>
    <b-modal v-model="showModal" trap-focus @close="closeModal">
      <SlideVisualisationModal
        v-if="resourceLevel === 'subresource'"
        :key="modalKey"
        v-bind="props"
        @close="$emit('close')"
      />
      <ResourceDetailModal
        v-if="resourceLevel === 'resource'"
        :key="modalKey"
        v-bind="props"
        @gotoResource="onGoToResourceFromDetail"
        @subresource-page-change="onSubresourcePageChange"
        @bookmark="handleBookmarkToggle"
        @close="$emit('close')"
      >
        <template #subHeader>
          <slot name="subHeader" :resource="resource"></slot>
        </template>
      </ResourceDetailModal>
    </b-modal>
  </div>
</template>

<script>
import {
  eventContext,
  eventPage,
  sendFileClickThroughEvent,
  sendInspectEvent
} from '@/services/feedbackService'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResourceDetailModal from './ResourceDetailModal.vue'
import SlideVisualisationModal from './SlideVisualisationModal.vue'

export default {
  name: 'ResourceModal',
  components: { SlideVisualisationModal, ResourceDetailModal },
  data: () => ({
    showModal: false,
    props: {},
    resource: undefined,
    subresource: undefined,
    activityViewed: [],
    modalKey: 0
  }),
  computed: {
    ...mapGetters(['lastSearchId']),
    ...mapState({
      resourceModalResourceId: state =>
        state.resourceModals.resourceModalResourceId,
      resourceModal: state => state.resourceModals.resourceModal,
      subresources: state => state.resources.subresources
    }),

    resourceLevel() {
      return this.resourceModal.resourceLevel
    },
    resourceModalProps() {
      return this.resourceModal.modalProps
    },
    buefyModalProps() {
      return this.resourceModal.buefyModalProps
    },
    eventHandlers() {
      return this.resourceModal.eventHandlers
    },
    impersonatedMember() {
      return this.$route.query.act_as
    },
    isCurationModal() {
      return this.$route.name === 'curation'
    }
  },
  watch: {
    async resourceModalResourceId(newVal) {
      if (!newVal) {
        this.showModal = false
        return
      }
      try {
        if (this.resourceLevel === 'subresource') {
          const subresource =
            newVal in this.subresources
              ? this.subresources[newVal]
              : await this.getSubresourceInfo({
                  workspaceId: this.$route.params.workspace_id,
                  subresourceId: newVal
                })
          this.subresource = subresource
          this.props = {
            ...this.resourceModalProps,
            subresource
          }
        } else {
          const resource = await this.getResourceInfo({
            workspaceId: this.$route.params.workspace_id,
            resourceId: newVal,
            impersonatedMember: this.impersonatedMember
          })
          this.resource = resource
          this.props = {
            ...this.resourceModalProps,
            ...this.getResourceModalProps(resource)
          }
        }
        this.modalKey += 1
        this.showModal = true
      } catch (e) {
        this.$console.error('Something went wrong when opening a modal', e)
        this.$toast.error(e, 'opening content modal')
      }
    }
  },
  methods: {
    ...mapActions([
      'getSubresourceInfo',
      'getResourceInfo',
      'closeResourceModal'
    ]),
    closeModal() {
      this.closeResourceModal()
    },
    getResourceModalProps(resource) {
      return {
        mimetype: resource.integrationfile?.mimetype,
        title:
          typeof resource.title === 'string'
            ? resource.title
            : resource.title.map(el => el.text).join(''),
        resourceIntegration: resource.integrationfile,
        relevantSubresources: resource.subresources,
        resourceId: resource.uuid,
        resourceTraceId: resource.trace_id,
        activity: resource.integrationfile?.activity,
        label: resource.label,
        bookmark: resource.bookmark,
        resourceType: resource.type
      }
    },
    async onSubresourcePageChange(page) {
      if (!this.isCurationModal) {
        sendInspectEvent(this.$route.params.workspace_id, {
          resource_trace_id: this.resource.trace_id,
          subresource_trace_id: this.resource.subresources.find(
            sr => sr.page === page
          ).trace_id,
          context: eventContext.resource,
          ...(this.resourceModalProps.rank
            ? { rank: this.resourceModalProps.rank }
            : {}),
          comment: 'navigation in resource modal with slide click',
          ...(this.$route.name === 'home'
            ? {
                page: eventPage.home
              }
            : {
                page: eventPage.search_results,
                tab: this.$route.params.tab || 'all'
              })
        })
      }
    },
    async onGoToResourceFromDetail(url) {
      if (!this.isCurationModal) {
        sendFileClickThroughEvent(this.$route.params.workspace_id, {
          resource_trace_id: this.resource.trace_id,
          tab: this.$route.params.tab || 'all',
          context: eventContext.resource,
          ...(this.resourceModalProps.rank
            ? { rank: this.resourceModalProps.rank }
            : {})
        })
        const win = window.open(url || this.resource.url, '_blank')
        win?.focus()
      }
    },
    handleBookmarkToggle(bookmark) {
      this.resource.bookmark = bookmark ? 'bookmark' : null
      this.props.bookmark = this.resource.bookmark
      this.UPSERT_RESOURCES([this.resource])
    }
  }
}
</script>

<style lang="scss" scoped></style>
