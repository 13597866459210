import { render, staticRenderFns } from "./HighlightVisualisation.vue?vue&type=template&id=62c37872&scoped=true&"
import script from "./HighlightVisualisation.vue?vue&type=script&lang=js&"
export * from "./HighlightVisualisation.vue?vue&type=script&lang=js&"
import style0 from "./HighlightVisualisation.vue?vue&type=style&index=0&id=62c37872&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c37872",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62c37872')) {
      api.createRecord('62c37872', component.options)
    } else {
      api.reload('62c37872', component.options)
    }
    module.hot.accept("./HighlightVisualisation.vue?vue&type=template&id=62c37872&scoped=true&", function () {
      api.rerender('62c37872', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views-v2/home/highlight/HighlightVisualisation.vue"
export default component.exports