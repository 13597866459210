export default {
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_ONBOARDING: 'SET_ONBOARDING',

  STOP_AUTH_CYCLE: 'STOP_AUTH_CYCLE',
  SET_REFRESH_MARGIN: 'SET_REFRESH_MARGIN',
  LOGOUT: 'LOGOUT',

  SET_ACTIVE_WORKSPACE: 'SET_ACTIVE_WORKSPACE',
  UPSERT_WORKSPACES: 'UPSERT_WORKSPACES',
  UPSERT_JOINABLE_WORKSPACES: 'UPSERT_JOINABLE_WORKSPACES',
  UPSERT_WORKSPACE_MEMBERS: 'UPSERT_WORKSPACE_MEMBERS',

  SET_CURRENT_WORKSPACEMEMBER: 'SET_CURRENT_WORKSPACEMEMBER',
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  UPSERT_RESOURCES: 'UPSERT_RESOURCES',

  UPSERT_SECTIONS: 'UPSERT_SECTIONS',
  UPDATE_SECTION: 'UPDATE_SECTION',
  DELETE_SECTION: 'DELETE_SECTION',

  // RESET STORE
  RESET_WORKSPACES: 'RESET_WORKSPACES',
  RESET_USERS_STATE: 'RESET_USERS_STATE',
  RESET_RESOURCES_STATE: 'RESET_RESOURCES_STATE'
}
