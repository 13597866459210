var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "ucheckbox-wrapper",
      class: ((_obj = {}), (_obj[_vm.size] = true), _obj)
    },
    [
      _c("input", {
        ref: "checkboxinput",
        staticClass: "ucheckbox",
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.value || _vm.indeterminate },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event.target.checked)
          }
        }
      }),
      _c(
        "span",
        {
          staticClass: "ucheckbox-overlay",
          class: { "is-radio": _vm.type === "radio" }
        },
        [
          _c(
            "span",
            {
              staticClass: "ucheckbox-overlay-check",
              class: { indeterminate: _vm.indeterminate }
            },
            [
              _c("span", {
                staticClass: "ucheckbox-overlay-check-mark small",
                class: { indeterminate: _vm.indeterminate }
              }),
              !_vm.indeterminate
                ? _c("span", {
                    staticClass: "ucheckbox-overlay-check-mark large"
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }