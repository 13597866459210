export default {
  state: {
    resourceModalResourceId: '',
    resourceModal: {
      resourceLevel: '',
      resourceId: '',
      modalProps: {},
      buefyModalProps: {},
      rootProps: {}
    }
  },
  getters: {},
  mutations: {
    setResourceModalResourceId(state, id) {
      state.resourceModalResourceId = id
    },
    setResourceModalInfo(state, info) {
      state.resourceModal = info
    }
  },
  actions: {
    setResourceModal(
      { commit },
      { resource_id, resource_level, resource_modal_props, buefy_modal_props, root_props }
    ) {
      commit('setResourceModalResourceId', resource_id)
      commit('setResourceModalInfo', {
        resourceLevel: resource_level,
        modalProps: resource_modal_props,
        buefyModalProps: buefy_modal_props,
        rootProps: root_props
      })
    },
    closeResourceModal({ commit }) {
      commit('setResourceModalResourceId', '')
      commit('setResourceModalInfo', {
        resourceLevel: '',
        modalProps: {},
        buefyModalProps: {},
        rootProps: {}
      })
    }
  }
}
