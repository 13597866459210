import { wrapView } from '@/router/router-utils'

const OldBareApplicationShell = wrapView(
  import('@/views-v2/structural/BareApplicationShell')
)

// import HydratedApplicationShell from '@/views-v2/structural/HydratedApplicationShell'
const HydratedApplicationShell = wrapView(
  import('@/views-v2/structural/HydratedApplicationShell')
)

export const v2DefaultComponents = {
  shell: HydratedApplicationShell
}

export const v2BareComponents = {
  shell: OldBareApplicationShell
}

export const defaultShellProps = {
  shell: {
    currentWorkspaceId: undefined
  }
}
