










import { CreateStepsEnum } from '@c/features/create-workspace-modal/interfaces'
import CreateWorkspaceModal from '@c/features/create-workspace-modal/pages/CreateWorkspaceModal.vue'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'HydratedCreateWorkspaceModal',
  components: { CreateWorkspaceModal },
  data() {
    return {
      creating: false,
      currentStep: CreateStepsEnum.CREATE as CreateStepsEnum,
      stepData: {}
    }
  },
  watch: {
    invitingMembersByEmail(newVal, oldVal) {
      if (newVal !== oldVal) {
        Vue.set(this.stepData, 'invitesLoading', newVal)
      }
    }
  },
  methods: {
    ...mapActions(['createWorkspace', 'updateImage']),

    async saveWorkspace({ data }) {
      if (this.creating) {
        this.$toast.danger(
          'Already creating',
          'We are already creating your workspace, this should be ready in a few instants.'
        )
        return
      }

      const { name, description, is_open, permissions, avatar } = data

      this.creating = true
      const workspace = await this.createWorkspace({
        name,
        description,
        is_open,
        permissions
      })

      if (avatar) {
        await this.updateImage({ id: workspace.uuid, image: avatar })
      }
      this.creating = false
      this.$router.push(`/workspace/${workspace.uuid}`)
      return workspace
    },
    async next({ data, next }) {
      if (this.currentStep === CreateStepsEnum.CREATE) {
        const workspace = await this.saveWorkspace({ data })
        this.stepData = { workspace }
      }
      this.currentStep = next
    }
  }
})
