
























































import { SubResourceUnits } from '@c/mimetypes'
import { SubResource } from '@c/models/Resource'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CollectSlideButton from './CollectSlideButton.vue'
import QualityLabels from './QualityLabels.vue'

export default Vue.extend({
  name: 'SubResourceVisualisation',
  components: {
    CollectSlideButton,
    QualityLabels
  },
  props: {
    subresource: {
      type: Object as () => SubResource,
      default: undefined
    },
    unit: {
      type: String as () => SubResourceUnits,
      default: SubResourceUnits.PAGE
    },
    canLoad: {
      type: Boolean,
      required: true
    },
    showPageButton: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean as () => boolean,
      default: true
    },
    zoomCursor: {
      type: Boolean as () => boolean,
      default: true
    },
    rank: {
      type: Number,
      required: true
    },
    resourceTraceId: {
      type: String,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    },
    mimetype: {
      type: String,
      required: true
    },
    forceShowCollect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageLoading: true,
      isHovering: false
    }
  },

  computed: {
    ...mapGetters(['selectedSlides']),
    slideCollected() {
      return this.selectedSlides
        .map((ss) => ss.trace_id || ss.subresource_trace_id)
        .includes(this.subresource.trace_id)
    }
  },

  methods: {
    handleImageLoaded() {
      this.imageLoading = false
      this.$emit('imageLoaded')
    }
  }
})
