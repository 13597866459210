<template>
  <div class="similar">
    <div class="similar-toggle mb-3">
      <p
        v-for="option in similarityOptions"
        :key="option.label"
        :class="{
          'similar-toggle-btn': true,
          active: similarity === option.similarity
        }"
        @click="getSimilarSlides(option.similarity)"
      >
        {{ option.label }}
      </p>
    </div>
    <div class="similar-slides">
      <div
        v-for="({ slide }, idx) in similarSlides"
        :key="slide.uuid"
        class="similar-slides-slide"
      >
        <SlideVisualisation
          :subresource="slide"
          :index="0"
          :loaded-resources="1"
          :total-resources="1"
          :rank="idx + 1"
          location="similar slides in modal"
          @load="imagesLoaded += 1"
          @cantLoad="imagesErrored += 1"
        />
        <!-- class="slide-img" -->
      </div>
      <b-skeleton
        v-for="idx in allImagesLoaded ? 0 : 3"
        :key="idx"
        height="100%"
        size="is-large"
      />
    </div>
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SimilarSlides',
  components: { SlideVisualisation: () => import('./SlideVisualisation.vue') },
  mixins: [PaginatorConsumer],
  props: {
    slideId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    similarity: 'default',
    countAboveThreshold: -1,
    threshold: -1,
    imagesLoaded: 0,
    imagesErrored: 0,
    similarityOptions: [
      {
        label: 'All',
        similarity: 'default'
      },
      {
        label: 'Layout',
        similarity: 'visual'
      },
      {
        label: 'Content',
        similarity: 'semantic'
      }
    ]
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser
    }),
    isUman() {
      return this.currentUser?.is_internal
    },
    infiniteScroll() {
      return this.isUman && process.env.NODE_ENV !== 'production'
    },
    similarSlides() {
      return this.infiniteScroll
        ? this.paginatorData || []
        : (this.paginatorData || []).slice(0, this.countAboveThreshold)
    },
    allImagesLoaded() {
      return (
        !this.consumptionLoading &&
        this.imagesLoaded + this.imagesErrored === this.similarSlides.length
      )
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.infiniteScroll) {
        this.$parent.$refs['slide-modal'].addEventListener('scroll', () => {
          const modal = this.$parent.$refs['slide-modal']
          const isEnd =
            modal.scrollTop >= modal.scrollHeight - modal.clientHeight - 3
          if (isEnd) {
            this.loadPage()
          }
        })
      }
    })
  },
  methods: {
    ...mapActions(['getSimilarSlidesPaginator']),
    getPaginator() {
      const paginator = this.getSimilarSlidesPaginator({
        workspace_id: this.$route.params.workspace_id,
        slide_id: this.slideId,
        params: {
          page_size: 15,
          type: this.similarity
        },
        act_as: this.$route.query.act_as,
        callback: (_, rawData) => {
          this.countAboveThreshold = rawData.count_above_threshold
          this.threshold = rawData.threshold
        }
      })
      return paginator
    },
    async setupPaginatorConsumer() {
      try {
        this.loadingError = false
        if (this.consumptionLoading) return
        this.consumptionLoading = true
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
        this.consumptionLoading = false
        if (this.similarSlides.length === 0) this.$emit('noSimilarSlides')
        else this.$emit('ready')
      } catch (e) {
        this.$emit('noSimilarSlides')
      }
    },
    getSimilarSlides(type) {
      this.imagesLoaded = 0
      this.imagesErrored = 0
      this.similarity = type
      this.resetPaginatorConsumer()
    }
  }
}
</script>

<style lang="scss">
.similar {
  &-toggle {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;

    &-btn {
      background: transparent;
      color: #60666b;
      font-weight: 500;
      padding: 0.5rem 1rem;
      border-radius: 999rem;
      transition: all ease 200ms;
      cursor: pointer;

      &.active {
        background: #303032;
        color: white;
      }
    }
  }

  &-slides {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 0.5rem;

    &-slide {
      min-width: 100%;
      width: 100%;
      border-radius: 4px;
      border-radius: 4px;
      border: 1px solid rgba(#000, 4%);
    }

    .slide-img {
      width: 100%;
      display: block;
    }

    .b-skeleton {
      aspect-ratio: 16 / 9;
      margin-top: 0;
      min-width: 100%;
      width: 100%;
    }
  }
}
</style>
