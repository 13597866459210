<template>
  <div id="homeinsightsblock" class="links">
    <p v-if="currentWorkspace" class="links-header">
      What's happening at {{ currentWorkspace.name }}
    </p>
    <b-tabs
      v-if="!showLoadingSkeleton"
      v-model="carouselPage"
      position="is-centered"
      :has-drag="true"
      :autoplay="false"
    >
      <b-tab-item
        v-for="(page, i) in carouselPages"
        :key="i"
        :label="pageHeaders[page]"
      >
        <template #header>
          <p :class="{ 'links-item-header': true, active: carouselPage === i }">
            <img
              :src="require(`@/assets/icons/${pageHeaderIcons[page]}.svg`)"
              alt=""
              class="links-item-header-icon"
            />
            {{ pageHeaders[page] }}
          </p>
        </template>
        <div class="links-item">
          <div v-if="!contentEmpty[page]" class="links-item-columns">
            <div class="links-item-cols-col-container">
              <div class="links-item-columns-col-header">
                <p class="links-item-columns-col-header-text">Topics</p>
              </div>
              <div
                :class="{
                  'links-item-columns-col': true,
                  'col-overflow': page === 'top'
                }"
              >
                <router-link
                  v-for="item in contentMapping[page].topics"
                  :key="item.name"
                  :to="insightRoute(item)"
                >
                  <div
                    :title="item.name"
                    class="links-item-columns-col-item"
                    @click="searchInsight(page)"
                  >
                    <p class="links-item-columns-col-item-text">
                      {{ item.name }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="links-item-cols-col-container">
              <div class="links-item-columns-col-header">
                <p class="links-item-columns-col-header-text">Industries</p>
              </div>
              <div
                :class="{
                  'links-item-columns-col': true,
                  'col-overflow': page === 'top'
                }"
              >
                <router-link
                  v-for="item in contentMapping[page].industries"
                  :key="item.name"
                  :to="insightRoute(item)"
                >
                  <div
                    :title="item.name"
                    class="links-item-columns-col-item"
                    @click="searchInsight(page)"
                  >
                    <p class="links-item-columns-col-item-text">
                      {{ item.name }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="links-item-cols-col-container">
              <div class="links-item-columns-col-header">
                <p class="links-item-columns-col-header-text">Customers</p>
              </div>
              <div
                :class="{
                  'links-item-columns-col': true,
                  'col-overflow': page === 'top'
                }"
              >
                <router-link
                  v-for="item in contentMapping[page].organisations"
                  :key="item.name"
                  :to="insightRoute(item)"
                >
                  <div
                    :title="item.name"
                    class="links-item-columns-col-item"
                    @click="searchInsight(page)"
                  >
                    <p class="links-item-columns-col-item-text">
                      {{ item.name }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else class="links-item-empty">
            <p class="links-item-empty-message">
              {{ emptyMessage[page] }}
            </p>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <div v-else class="links-loading">
      <div class="links-loading-header">
        <b-skeleton width="20rem" height="100%"></b-skeleton>
      </div>
      <div class="links-loading-cols">
        <b-skeleton width="16rem" height="100%"></b-skeleton>
        <b-skeleton width="16rem" height="100%"></b-skeleton>
        <b-skeleton width="16rem" height="100%"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  searchDetail,
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomePageLinks',
  data: () => ({
    loading: false,
    insights: {},
    error: false,
    carouselPage: 0,
    pageHeaders: {
      top: 'All time',
      hot: 'Trending',
      personal: 'For you'
    },
    pageHeaderIcons: {
      top: 'users',
      hot: 'trending-up',
      personal: 'user'
    },
    maxInsights: 7,
    carouselPageLoading: false
  }),
  computed: {
    ...mapGetters(['currentWorkspace']),
    workspaceId() {
      return this.$route.params.workspace_id
    },
    topContent() {
      const content = this.insights?.top
        ? {
            topics: this.insights.top.topics,
            organisations: this.insights.top.organisations,
            industries: this.insights.top.industries
          }
        : {
            topics: [],
            authors: [],
            industries: []
          }
      return Object.keys(content).reduce(
        (prev, key) => ({
          ...prev,
          [key]: content[key]
        }),
        {}
      )
    },
    hotContent() {
      const content = this.insights?.hot
        ? {
            topics: this.insights.hot.topics,
            organisations: this.insights.hot.organisations,
            industries: this.insights.hot.industries
          }
        : {
            topics: [],
            authors: [],
            industries: []
          }
      return Object.keys(content).reduce(
        (prev, key) => ({
          ...prev,
          [key]: content[key].slice(0, this.maxInsights)
        }),
        {}
      )
    },
    myContent() {
      const content = this.insights?.me
        ? {
            topics: this.insights.me.topics,
            organisations: this.insights.me.organisations,
            industries: this.insights.me.industries
          }
        : {
            topics: [],
            organisations: [],
            industries: []
          }
      return Object.keys(content).reduce(
        (prev, key) => ({
          ...prev,
          [key]: content[key].slice(0, this.maxInsights)
        }),
        {}
      )
    },
    contentEmpty() {
      return {
        personal: ['topics', 'organisations', 'industries'].every(
          (block) => (this.myContent?.[block] || []).length === 0
        ),
        top: ['topics', 'organisations', 'industries'].every(
          (block) => (this.topContent?.[block] || []).length === 0
        ),
        hot: ['topics', 'organisations', 'industries'].every(
          (block) => (this.hotContent?.[block] || []).length === 0
        )
      }
    },
    contentMapping() {
      return {
        hot: this.hotContent,
        top: this.topContent,
        personal: this.myContent
      }
    },
    emptyMessage() {
      return {
        hot: "Looks like there is no recent content in your company workspace, add some content to your company's document storage to see it here.",
        top: "Looks like there is no content in your company workspace, add some content to your company's document storage to see it here.",
        personal:
          "Looks like you haven't created any documents yet, add some content to your company's document storage to see it here."
      }
    },
    showLoadingSkeleton() {
      return this.loading || this.carouselPageLoading
    },
    carouselPages() {
      return this.contentEmpty.personal
        ? ['hot', 'top']
        : ['personal', 'hot', 'top']
    }
  },
  watch: {
    loading(newVal) {
      if (newVal) {
        this.carouselPageLoading = true
      } else {
        this.checkCarouselPage()
        this.carouselPageLoading = false
      }
    }
  },
  created() {
    this.loadInsights()
  },
  methods: {
    ...mapActions(['getWorkspaceInsights', 'setSearchEnrichmentContext']),
    async loadInsights() {
      try {
        this.loading = true
        this.insights = await this.getWorkspaceInsights({
          workspace_id: this.$route.params.workspace_id,
          impersonatedMember: this.$route.query.act_as
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },
    insightRoute(entity) {
      return {
        name: 'search',
        query: {
          query: entity.name
        }
      }
    },
    searchInsight(type) {
      const typeMapping = {
        top: searchDetail.insight.all_time,
        hot: searchDetail.insight.trending,
        personal: searchDetail.insight.for_you
      }
      this.setSearchEnrichmentContext({
        page: searchSourcePage.home,
        trigger: searchTrigger.insight,
        detail: typeMapping[type]
      })
    },
    checkCarouselPage() {
      this.carouselPage = this.contentEmpty.personal
        ? this.contentEmpty.hot
          ? this.carouselPages.findIndex((p) => p === 'top')
          : this.carouselPages.findIndex((p) => p === 'hot')
        : this.carouselPages.findIndex((p) => p === 'personal')
    }
  }
}
</script>

<style scoped lang="scss">
.links {
  padding: 1rem 2rem 0 2rem;
  border-radius: 16px;
  border: 1px solid rgba(#000, 8%);
  background: white;

  &-header {
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding-bottom: 1rem;
  }
  &-item {
    min-width: 35vw;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    &-header {
      color: #303032;
      font-weight: 700;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 1rem;

      &-icon {
        height: 1.25rem;
      }

      &.active {
        color: $primary;
        filter: invert(28%) sepia(78%) saturate(4777%) hue-rotate(218deg)
          brightness(99%) contrast(98%);
      }
    }

    &-columns {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      padding: 0 5rem 0 5rem;

      &-col {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        gap: 0.5rem;
        overflow: hidden;
        margin-bottom: 1rem;
        max-height: 35vh;

        &.col-overflow {
          flex-flow: column nowrap;
          overflow-y: auto;
          position: relative;
          padding-right: 2px;

          &::-webkit-scrollbar {
            width: 6px;
          }
          /* Track */
          &::-webkit-scrollbar-track {
            display: none;
          }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #dedfe2;
            border-radius: 999rem;
          }
          scrollbar-width: none;
        }

        &-header {
          background: white;
          padding: 0.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &-text {
            font-weight: 600;
            white-space: nowrap;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid rgba(#000, 16%);
            width: fit-content;
          }
        }

        &-item {
          width: 18rem;
          max-width: 30vw;
          cursor: pointer;
          border-radius: 6px;
          background: #f1f2f3;
          min-height: 2rem;
          height: 2rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          &:hover {
            background: #ebebeb;
          }

          &-text {
            width: 100%;
            color: #8f9399;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
          }
        }
      }
    }

    &-empty {
      width: 960px;
      height: 35vh;
      padding: 0 7rem 2rem 7rem;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      &-message {
        padding: 1rem;
        border-radius: 4px;
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #8f9399;
        font-weight: 600;
        background: #f1f2f3;
      }
    }
  }

  &-loading {
    display: grid;
    grid-template-rows: 2rem 400px;
    justify-content: center;
    gap: 0.5rem;

    &-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-cols {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      padding: 0 5rem 0 5rem;
      height: 350px;
    }
  }
}

::v-deep .icon:hover {
  border: 2px solid rgba(#000, 16%) !important;
}

::v-deep .icon {
  padding: 1.25rem;
  color: #60666b !important;
  border: 2px solid transparent !important;
}

::v-deep .carousel-indicator {
  filter: grayscale(1);
}

.b-skeleton {
  margin-top: 0;
  width: unset !important;
}
</style>
