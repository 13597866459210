<template>
  <Dropdown
    ref="roledropdown"
    :items="roles"
    :full-width="fullWidth"
    :position-fixed="true"
    :disabled="!roles.length"
    @hide="handleHide"
  >
    <template #trigger>
      <div
        class="role-select-trigger"
        :class="{ empty: !selection.length }"
        :style="{ width: widthStyle }"
      >
        <span class="role-select-trigger-text">{{ firstSelected }}</span>
        <span v-if="selection.length > 1" class="role-select-trigger-more"
          >+{{ selection.length - 1 }}</span
        >
        <img
          src="@/assets/icons/chevron-down-medium.svg"
          alt=""
          class="role-select-trigger-chevron"
        />
      </div>
    </template>
    <template #content>
      <div class="role-select-content">
        <div class="role-select-content-list">
          <div
            v-for="role in roles"
            :key="role.name"
            class="role-select-content-list-item"
            @click="() => handleItemClick(role)"
          >
            <Checkbox
              :value="selection.some((s) => s.uuid === role.uuid)"
              class="role-select-content-list-item-checkbox"
            />
            <div class="role-select-content-list-item-info">
              <span class="role-select-content-list-item-info-name">{{
                role.name
              }}</span>
              <span class="role-select-content-list-item-info-description">{{
                role.description
              }}</span>
            </div>
          </div>
        </div>
        <div class="role-select-content-save">
          <Button text="Save" @click="handleSave" />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from './Dropdown.vue'
import Checkbox from './Checkbox.vue'
import Button from './Button.vue'

export default {
  name: 'RoleSelect',
  components: { Dropdown, Checkbox, Button },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    fixedWidth: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    selection: [],
    saved: true
  }),
  computed: {
    ...mapGetters(['roles']),
    widthStyle() {
      return this.fullWidth ? '100%' : this.fixedWidth || 'auto'
    },
    firstSelected() {
      return this.selection.length
        ? this.roles.find((r) => this.selection.some((s) => s.uuid === r.uuid))
            .name
        : 'No roles selected'
    }
  },
  watch: {
    value() {
      this.initSelection()
    },
    roles() {
      this.initSelection()
    }
  },
  created() {
    this.initSelection()
  },
  methods: {
    initSelection() {
      this.selection = this.value
        .map((v) => this.roles.find((r) => r.uuid === v.uuid))
        .filter((r) => r)
    },
    handleItemClick(item) {
      this.saved = false
      const index = this.selection.findIndex((s) => s.uuid === item.uuid)
      if (index === -1) {
        this.selection.push(item)
      } else {
        this.selection.splice(index, 1)
      }
    },
    handleHide() {
      if (!this.saved) {
        this.initSelection()
      }
    },
    handleSave() {
      this.$emit('submit', this.selection)
      this.saved = true
      this.$refs.roledropdown.hideOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
.role-select {
  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
    padding: 0.5rem 0;

    &.empty {
      color: #60666b;
    }

    &-text {
      max-width: calc(100% - 2.5rem);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-more {
      color: #60666b;
    }

    &-chevron {
      width: 1.2rem;
      margin-left: auto;
    }
  }

  &-content {
    width: min(40rem, 80vw);
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);

    &-list {
      display: flex;
      flex-flow: column nowrap;
      padding: 0.5rem 0;
      max-height: 40vh;
      overflow-y: auto;

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem 1rem;
        cursor: pointer;

        &:hover {
          background: rgba(#000, 0.04);
        }

        &-checkbox {
          pointer-events: none;
        }

        &-info {
          display: flex;
          flex-flow: column nowrap;
          user-select: none;

          &-name {
            font-weight: 600;
          }

          &-description {
            color: #60666b;
          }
        }
      }
    }

    &-save {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem 1rem;
      border-top: 1px solid rgba(#000, 0.08);
    }
  }
}
</style>
