<template>
  <div class="subresources">
    <img
      :src="subresources[0].preview"
      alt=""
      class="subresources-preview"
      :class="{ hidden: !loaded }"
      @load="loaded = true"
    />
    <b-skeleton v-if="!loaded" :square="true"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: 'HighlightSubresourcesPreview',
  props: {
    subresources: {
      type: Array,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    }
  },
  data: () => ({
    currentPage: 0,
    hovered: false,
    loaded: false
  }),
  computed: {
    pageIndicator() {
      const pageMap = {
        document: 'page',
        presentation: 'slide',
        spreadsheet: 'sheet'
      }
      return `${pageMap[this.resourceType]} ${
        this.subresources[this.currentPage].page
      }`.capitalize()
    },
    lastPage() {
      return this.subresources.length - 1
    }
  }
}
</script>

<style scoped lang="scss">
.subresources {
  height: 100%;
  position: relative;
  overflow: hidden;

  &-preview {
    height: 100%;
    width: 100%;
    aspect-ratio: 16 / 9;
    vertical-align: middle;
    object-fit: contain;

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &.hidden {
      display: none;
    }
  }
}

.b-skeleton {
  height: 100%;
  aspect-ratio: 16 / 9;

  &-item.b-skeleton-item {
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
