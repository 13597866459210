














import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import InviteFromIntegrationModal from '@c/features/invite-from-integration/pages/InviteFromIntegrationModal.vue'
import { Integration } from '@c/integrations'
import Workspace from '@c/models/Workspace'
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'HydratedInviteFromIntegrationModal',
  components: { InviteFromIntegrationModal },
  mixins: [PaginatorConsumer],
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    },
    integrationId: {
      type: String,
      required: true
    },
    workspace: {
      type: Object as () => Workspace,
      required: true
    }
  },
  data() {
    return {
      amountOfMembers: 0
    }
  },
  methods: {
    ...mapActions(['getIntegrationUsersPaginator']),
    async getPaginator() {
      const paginator = await this.getIntegrationUsersPaginator({
        workspace_id: this.workspace.uuid,
        integration_id: this.integrationId
      })
      this.amountOfMembers = await paginator.count()
      return paginator
    }
  }
})
