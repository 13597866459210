import ConnectToIntegration from '@c/features/connect-integration-modal/pages/ConnectToIntegration.vue'
import InviteFromIntegrationModal from '@c/features/invite-from-integration/pages/InviteFromIntegrationModal.vue'
import SyncFolderSelectModal from '@c/features/sync-folder-select/pages/SyncFolderSelectModal.vue'
import allIntegrations from '@c/integrations'
import Integration from '@c/models/Integration'
import Workspace from '@c/models/Workspace'
import { openModal } from '@c/shared/logic/modals/BaseModals'

export function inviteFromIntegration(
  parent: any,
  integrationKey: string,
  integrationId: string,
  workspace: Workspace
) {
  openModal(parent, InviteFromIntegrationModal, {
    props: {
      workspace,
      integration: allIntegrations[integrationKey],
      integrationId
    }
  })
}

export function connectToIntegration(
  parent: any,
  integrationKey: string,
  workspace: Workspace
) {
  openModal(
    parent,
    ConnectToIntegration,
    {
      props: {
        workspace,
        integration: allIntegrations[integrationKey]
      }
    },
    { width: 500 }
  )
}

export function syncFolderSelectModal(
  parent: any,
  integration: Integration,
  workspace: Workspace
) {
  openModal(
    parent,
    SyncFolderSelectModal,
    {
      props: {
        workspace,
        integration
      }
    },
    { width: 500 }
  )
}
