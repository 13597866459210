var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WorkspaceHeaderModal",
    _vm._g(
      {
        attrs: {
          "title-prefix": "Invite to",
          subtitle: "From " + _vm.integration.title,
          workspace: _vm.workspace
        }
      },
      _vm.$listeners
    ),
    [
      _c(
        "IntegrationUserTable",
        _vm._g(
          {
            staticClass: "integration-user-table",
            attrs: {
              "amount-of-integration-users": _vm.amountOfIntegrationUsers,
              "integration-users": _vm.integrationUsers,
              loading: _vm.loadingMore
            },
            model: {
              value: _vm.checkedUsers,
              callback: function($$v) {
                _vm.checkedUsers = $$v
              },
              expression: "checkedUsers"
            }
          },
          _vm.$listeners
        )
      ),
      _c("ModalActionFooter", {
        staticClass: "mt-5",
        attrs: { loading: _vm.loadingSave, action: "Send invites" },
        on: {
          action: function($event) {
            return _vm.$emit("sendInvites", { checkedUsers: _vm.checkedUsers })
          },
          close: function($event) {
            return _vm.$emit("close")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }