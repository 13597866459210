<template>
  <div class="searchbar-wrapper">
    <div
      v-if="canWriteOri || canReadOri || canUseSearch"
      class="searchbar"
      :class="{ focused, nolocation: dropdownOptions.length <= 1 }"
      :style="{ background }"
    >
      <div class="searchbar-trigger" @click="focus">
        <img src="@/assets/icons/search.svg" alt="" class="searchbar-icon" />
        <input
          id="searchinput"
          ref="searchinput"
          :value="currentItem === -1 ? value : visibleHistory[currentItem].name"
          :placeholder="placeholder"
          type="text"
          name=""
          class="searchbar-input"
          @click.stop
          @focus="handleFocus"
          @blur="() => (focused = false)"
          @keyup="handleKeyUp"
          @keyup.enter="() => search('input')"
          @input="handleInput"
        />
        <Button
          v-if="value"
          text="Clear"
          type="light"
          size="xs"
          @click="handleClear"
        />
      </div>
      <Dropdown
        v-if="dropdownOptions.length > 1"
        ref="locationdropdown"
        :items="dropdownOptions"
        :full-width="true"
        :position-fixed="true"
        :append-to-body="true"
      >
        <template #trigger>
          <div v-if="currentLocation" class="searchbar-location">
            <p class="searchbar-location-text">
              {{ currentLocation.text }}
            </p>
            <img
              src="@/assets/icons/chevron-down-medium.svg"
              alt=""
              class="searchbar-location-chevron"
            />
          </div>
          <div v-else></div>
        </template>
      </Dropdown>
      <transition name="fade">
        <div v-if="showHistory" class="searchbar-history">
          <div
            v-for="(item, index) in visibleHistory"
            :key="index"
            class="searchbar-history-item"
            :class="{ active: index === currentItem }"
            @click="() => selectHistoryItem(item)"
          >
            <div class="searchbar-history-item-content">
              <img
                :src="
                  require(`@/assets/icons/${
                    item.type === 'history' ? 'history' : 'search'
                  }.svg`)
                "
                alt=""
                class="searchbar-history-item-icon"
              />
              <div class="searchbar-history-item-text">{{ item.name }}</div>
            </div>
            <div
              v-if="item.type === 'history'"
              class="searchbar-history-item-remove"
              @click.stop="() => removeHistoryItem(item)"
              @mousedown.prevent
            >
              Remove
            </div>
          </div>
        </div>
      </transition>
    </div>
    <SearchTips v-if="searchLocation === 'content'" />
  </div>
</template>

<script>
import {
  searchDetail,
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import Button from '@c/library/Button.vue'
import Dropdown from '@c/library/Dropdown.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  deleteSearchHistoryItem,
  getSearchSearchSuggestion
} from '@/services/searchAutocompleteService'
import { debounce } from 'lodash'
import SearchTips from '../object-visualisations/resource/subcomponents/SearchTips.vue'

export default {
  name: 'GlobalSearchBar',
  components: { Button, Dropdown, SearchTips },
  props: {
    background: {
      type: String,
      default: 'white'
    }
  },
  data: () => ({
    value: '',
    currentItem: -1,
    historyItems: [],
    focused: false,
    searchLocation: ''
  }),
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'resourceTypeFilter',
      'currentWorkspaceMember',
      'canReadOri',
      'canWriteOri',
      'canUseSearch'
    ]),
    placeholder() {
      return `Find ${this.currentLocation.text.toLowerCase()} in ${
        this.currentWorkspace.name
      }`
    },
    displayedValue() {
      return this.value === undefined ? this.$route?.query?.query : this.value
    },
    definedLocations() {
      return [this.currentLocation].filter(x => x)
    },
    dropdownOptions() {
      return [
        ...(this.canUseSearch
          ? [
              {
                text: 'Content',
                key: 'content',
                callback: () => this.setSearchLocation('content')
              }
            ]
          : []),
        ...(this.canReadOri || this.canWriteOri
          ? [
              {
                text: 'Offerings',
                key: 'offerings',
                callback: () => this.setSearchLocation('offerings')
              },
              {
                text: 'Cases',
                key: 'references',
                callback: () => this.setSearchLocation('references')
              },
              {
                text: 'Inspirational content',
                key: 'inspirations',
                callback: () => this.setSearchLocation('inspirations')
              }
            ]
          : [])
      ]
    },
    currentLocation() {
      return this.searchLocation
        ? this.dropdownOptions.find(x => x.key === this.searchLocation)
        : undefined
    },
    visibleHistory() {
      return [
        ...(this.value
          ? [
              {
                name: this.value,
                type: 'input',
                uuid: 'input'
              }
            ]
          : []),
        ...this.historyItems.filter(h => h.name !== this.value)
      ]
    },
    showHistory() {
      return (
        this.focused &&
        (this.visibleHistory.length > 1 ||
          (this.visibleHistory.length === 1 &&
            this.visibleHistory[0].type !== 'input'))
      )
    }
  },
  watch: {
    focused(newVal) {
      if (!newVal) this.currentItem = -1
      else this.$refs.locationdropdown?.hideOptions()
    },
    $route: {
      handler(to, from) {
        if (
          !to.matched[0].meta.keepSearch ||
          to.name !== from.name ||
          to.params.ori_type !== from.params.ori_type
        ) {
          this.value = ''
          this.checkCurrentLocation()
        }
        this.checkQuery()
      },
      deep: true
    }
  },
  created() {
    this.checkCurrentLocation()
    this.checkQuery()
  },
  methods: {
    ...mapActions(['setSearchEnrichmentContext']),
    checkQuery() {
      this.value = this.$route.query.query || ''
    },
    checkCurrentLocation() {
      this.searchLocation =
        this.$route.params.ori_type || this.dropdownOptions[0].key
    },
    setSearchLocation(location) {
      this.searchLocation = location
      this.historyItems = []
    },
    focus() {
      this.$refs.searchinput.focus()
    },
    blur() {
      this.$refs.searchinput.blur()
    },
    handleFocus() {
      this.focused = true
      this.debouncedHistory()
    },
    handleClear() {
      this.value = ''
      this.historyItems = []
    },
    handleInput(e) {
      this.currentItem = -1
      this.value = e.target.value
      this.debouncedHistory()
    },
    handleKeyUp(e) {
      // if arrow keys, rotate currentItem through visibleHistory
      if (e.key === 'ArrowDown') {
        this.currentItem = Math.min(
          this.currentItem + 1,
          this.visibleHistory.length - 1
        )
      } else if (e.key === 'ArrowUp') {
        this.currentItem = Math.max(this.currentItem - 1, -1)
      }
    },
    debouncedHistory: debounce(async function() {
      if (this.searchLocation === 'content') {
        await this.getAutoCompleteHistory()
      }
    }, 150),
    async getAutoCompleteHistory() {
      this.historyItems = await getSearchSearchSuggestion(
        this.currentWorkspace.uuid,
        this.currentWorkspaceMember.uuid,
        this.value,
        this.resourceTypeFilter || 'all'
      )
    },
    selectHistoryItem(item) {
      this.search(item.type, item.name)
    },
    async removeHistoryItem(item) {
      try {
        await deleteSearchHistoryItem(
          this.currentWorkspace.uuid,
          this.currentWorkspaceMember.uuid,
          item.uuid
        )
        this.historyItems.splice(
          this.historyItems.findIndex(i => i.uuid === item.uuid),
          1
        )
      } catch (e) {
        this.$console.debug('Error when removing search history item', e)
        this.$toast.error(e, 'removing search history item')
      }
    },
    search(type, value = '') {
      this.blur()
      if (['input', 'history', 'autocomplete'].includes(type)) {
        const typeMap = {
          input: searchDetail.search.input,
          history: searchDetail.search.suggestion_history,
          autocomplete: searchDetail.search.suggestion_autocomplete
        }
        this.setSearchEnrichmentContext({
          page: searchSourcePage.search_results,
          trigger: searchTrigger.search,
          detail: typeMap[type]
        })
      }
      const route = ['offerings', 'references', 'inspirations'].includes(
        this.searchLocation
      )
        ? 'portfolio'
        : 'search'
      const query = value
        ? value
        : this.currentItem === -1
        ? this.value
        : this.visibleHistory[this.currentItem].name
      this.$router.push({
        name: route,
        params: {
          workspace_id: this.$route.params.workspace_id,
          ...(route !== 'search' ? { ori_type: this.searchLocation } : {}),
          ...(this.$route.params.tab ? { tab: this.$route.params.tab } : {})
        },
        query: {
          ...(query ? { query } : {}),
          ...(this.$route.query?.view ? { view: this.$route.query.view } : {})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.searchbar {
  background: white;
  border: 1px solid #f1f2f3;
  border-radius: 999rem;
  display: grid;
  grid-template-columns: 1fr min(15vw, 20rem);
  align-items: center;
  position: relative;

  &.nolocation {
    grid-template-columns: 1fr;
  }

  &:hover {
    border-color: rgba(#000, 0.08);
  }

  &.focused {
    border-color: $primary;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 2rem;
    gap: 0.5rem;
    align-items: center;
  }

  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 0.75rem;
    gap: 0.5rem;
    min-height: 2.5rem; // Makes sure the button appearing doesn't change the height of the input
  }

  &-icon {
    height: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    min-width: 1.5rem;
  }

  &-input {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    flex: 1;
  }

  &-location {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.75rem;
    border-left: 1px solid rgba(#000, 0.08);

    &-icon {
      width: 1.2rem;
      min-width: 1.2rem;
    }

    &-text {
      flex: 1;
      font-weight: 600;
    }

    &-chevron {
      width: 1rem;
      min-width: 1rem;
    }
  }

  &-history {
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: white;
    position: absolute;
    top: 2.7rem;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 999;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 0.5rem 1rem;
      cursor: pointer;

      &.active {
        background-color: #f1f2f3;
      }

      &:hover {
        background-color: #f1f2f3;
      }

      &-content {
        display: flex;
        align-items: center;
      }

      &-text {
        margin-left: 0.5rem;
      }

      &-remove {
        color: #8f9399;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
</style>
