<template>
  <div class="recent">
    <RecentORI
      v-for="(type, idx) in types"
      :key="`recent-${type}`"
      :type="type"
      :page-size="[6, 3, 3][idx]"
      :columns="[3, 1, 1][idx]"
    />
  </div>
</template>

<script>
import RecentORI from '../ori/RecentORI.vue'

export default {
  name: 'HomeRecent',
  components: {
    RecentORI
  },
  data: () => ({
    types: ['offering', 'reference', 'inspiration'],
    empty: []
  })
}
</script>

<style lang="scss" scoped>
.recent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'top top'
    'left right';
  gap: 5vh 5vw;

  & > * {
    &:nth-child(1) {
      grid-area: top;
    }

    &:nth-child(2) {
      grid-area: left;
    }

    &:nth-child(3) {
      grid-area: right;
    }
  }
}
</style>
