var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.title
    ? _c(
        "div",
        {
          staticClass: "toast",
          style:
            "--bar-color: " +
            _vm.color +
            "; --bar-timeout: " +
            _vm.timeout +
            "ms;"
        },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "toast-content" },
            [
              _c("span", { staticClass: "toast-content-title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm.description
                ? _c("HTMLRenderer", {
                    staticClass: "toast-content-description",
                    attrs: { value: _vm.description }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toast-bar" }, [
      _c("div", { staticClass: "toast-bar-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }