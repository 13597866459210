import ProfileSettingsModal from '@c/features/profile-settings-modal/pages/ProfileSettingsModal.vue'
import User from '@c/models/User'
import { openModal } from '@c/shared/logic/modals/BaseModals'
import { ProfileSettingTab } from '@c/features/profile-settings-modal/interfaces'

export function openProfileSettings(
  parent: any,
  user: User,
  tab: ProfileSettingTab
) {
  openModal(parent, ProfileSettingsModal, {
    props: { user, initialActiveTab: tab }
  })
}
