





























































































































































import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import {
  addBookmarkToResource,
  deleteBookmarkFromResource
} from '@/services/bookmarkService'
import {
  eventContext,
  eventPage,
  sendInspectEvent
} from '@/services/feedbackService'
import { IntegrationData, SubResource } from '@c/models/Resource'
import Vue from 'vue'
import { mapActions } from 'vuex'
import {
  SubResourceUnits,
  mimetypeToType,
  typeToUnit
} from '../../../../../mimetypes'
import QualityLabels from './QualityLabels.vue'
import ResourceDetailHeader from './ResourceDetailHeader.vue'
import ResourceIntersections from './ResourceIntersections.vue'
import QuestionAnswerResource from './QuestionAnswerResource.vue'
import ResourceModalCuration from './ResourceModalCuration.vue'
import SubResourceList from './SubResourceList.vue'
import SubResourceSlideDeck from './SubResourceSlideDeck.vue'
import SummarizeResource from './SummarizeResource.vue'
import ActivityGrid from './activity/ActivityGrid.vue'

export default Vue.extend({
  name: 'ResourceDetailModal',

  components: {
    ResourceDetailHeader,
    SubResourceSlideDeck,
    SubResourceList,
    ActivityGrid,
    ResourceIntersections,
    QualityLabels,
    ResourceModalCuration,
    SummarizeResource,
    QuestionAnswerResource
  },

  mixins: [PaginatorConsumer],

  props: {
    mimetype: {
      type: String as () => string,
      default: undefined
    },

    title: {
      type: String as () => string,
      required: true
    },

    resourceIntegration: {
      type: Object as () => IntegrationData,
      default: undefined
    },

    relevantSubresources: {
      type: Array as () => SubResource[],
      default: undefined
    },

    workspaceId: {
      type: String as () => string,
      default: undefined
    },

    resourceId: {
      type: String,
      default: undefined
    },

    initialPage: {
      type: Number as () => number,
      default: -1
    },

    subresourcesRelevant: {
      type: Boolean as () => boolean,
      default: true
    },

    activity: {
      type: Array as () => object[],
      default: []
    },

    selectable: {
      type: Boolean as () => boolean,
      default: false
    },

    label: {
      type: Object,
      default: () => {}
    },
    bookmark: {
      type: [String, null],
      default: null
    },
    similarPages: {
      type: Array as () => object[],
      default: () => []
    },
    scrollTo: {
      type: String,
      default: ''
    },
    resourceType: {
      type: String,
      required: true
    },
    resourceTraceId: {
      type: String,
      required: true
    },
    curationModal: {
      type: Boolean,
      default: false
    },
    forceShowCollect: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      onlyRelevant: true,
      maxPageSize: 999,
      selectedPage: -1,
      scrolling: false,
      scrollingTimer: undefined,
      activityViewed: false,
      AITab: 0,
      AITabs: ['similar', 'summarize', 'question-answer']
    }
  },

  computed: {
    inferredMimetype(): any {
      return mimetypeToType[this.mimetype]
    },

    subResourceUnit(): SubResourceUnits {
      return typeToUnit[this.inferredMimetype] || 'slide'
    }
  },
  watch: {
    consumptionLoading(newVal) {
      if (!newVal) {
        if (this.scrollTo !== '') {
          setTimeout(() => {
            if (this.AITabs.includes(this.scrollTo)) {
              const AIidx = this.AITabs.indexOf(this.scrollTo)
              this.AITab = AIidx
              const el = this.$refs.aitabs
              el.scrollIntoView({ behavior: 'smooth' })
            }
          }, 500)
        }
      }
    },
    AITab() {
      this.$nextTick(() => {
        const el = this.$refs.aitabs
        el.scrollIntoView({ behavior: 'smooth' })
      })
    }
  },
  mounted() {
    this.selectedPage = this.initialPage
  },
  destroyed() {
    clearTimeout(this.scrollingTimer)
  },
  methods: {
    ...mapActions(['getSubResourcePaginator']),
    async getPaginator() {
      const paginator = await this.getSubResourcePaginator({
        workspace_id: this.workspaceId,
        resource_id: this.resourceId,
        params: {
          page_size: this.maxPageSize,
          ...(this.$route.query.act_as
            ? { act_as: this.$route.query.act_as }
            : {})
        }
      })
      return paginator
    },
    subresourcePageChanged(page) {
      if (!page) return
      this.selectedPage = page
      if (!this.curationModal) {
        sendInspectEvent(this.$route.params.workspace_id, {
          resource_trace_id: this.resourceTraceId,
          subresource_trace_id: this.paginatorData[page - 1].trace_id,
          context: eventContext.resource,
          rank: page,
          comment: 'navigation in resource modal with arrows',
          ...(this.$route.name === 'home'
            ? {
                page: eventPage.home
              }
            : {
                page: eventPage.search_results,
                tab: this.$route.params.tab || 'all'
              })
        })
      }
    },
    relevantSlideClicked(page: number) {
      this.selectedPage = page
      this.$emit('subresource-page-change', page)
    },

    handleScrolling() {
      if (
        Math.round(this.$refs.content.scrollTop) +
          this.$refs.content.clientHeight ===
          this.$refs.content.scrollHeight &&
        !this.activityViewed
      ) {
        this.activityViewed = true
        this.$emit('activity-viewed')
      }
      this.scrolling = true
      if (this.scrollingTimer) clearTimeout(this.scrollingTimer)
      this.scrollingTimer = setTimeout(() => {
        this.scrolling = false
      }, 1000)
    },
    async toggleBookmark() {
      try {
        if (this.bookmark) {
          await deleteBookmarkFromResource(
            this.$route.params.workspace_id,
            this.resourceId,
            this.$route.query.act_as
          )
        } else {
          await addBookmarkToResource(
            this.$route.params.workspace_id,
            this.resourceId,
            this.$route.query.act_as
          )
        }
      } catch (e) {
        this.$toast.error(
          e,
          `${this.bookmark ? 'removing' : 'adding'} your bookmark`
        )
      }
    },
    gotoResource() {
      const slide = this.paginatorData?.find(
        sr => sr.page === this.selectedPage
      )

      this.$emit('gotoResource', slide?.url)
    }
  }
})
