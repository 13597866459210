import Console from '@/console'

export default abstract class AbstractSensor<T> {
  protected readonly type: string

  protected constructor(type: string) {
    this.type = type
  }

  protected checkData(data: T) {
    if (!data) {
      Console.debug(
        `Did not send data for type "${this.type}" as there was no data`
      )
      return
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  public async sendData(data: T) {
    throw Error('Not Implemented')
  }
}
