











import Vue from 'vue'
export default Vue.extend({
  props: {
    lightGray: {
      type: Boolean,
      default: false
    }
  }
})
