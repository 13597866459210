interface String {
  startsWithVowel: () => boolean
  startsWithConsonant: () => boolean
  formatAOrAn: () => string
  indicesOf: (substr: string) => number[]
  capitalize: () => string
}

String.prototype.startsWithVowel = function () {
  return ['a', 'e', 'i', 'o', 'u'].includes(this[0].toLowerCase())
}

String.prototype.startsWithConsonant = function () {
  return !this.startsWithVowel()
}

String.prototype.formatAOrAn = function () {
  if (this.startsWithVowel()) {
    return 'an'
  }
  return 'a'
}

String.prototype.indicesOf = function (substr) {
  let a = [],
    i = -1
  while ((i = this.indexOf(substr, i + 1)) >= 0) a.push(i)
  return a
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
