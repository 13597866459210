import { Store } from 'vuex'
import { SensorType } from '../sensors'
import StoreActionSensor from '../StoreActionSensor'

export default class LoginSensor extends StoreActionSensor<{
  type: 'password'
}> {
  constructor(store: Store<any>) {
    super(SensorType.LOGIN, 'authenticate', store)
  }

  protected transformState(): any {
    return {
      type: 'password'
    }
  }
}
