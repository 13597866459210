






























import { IntegrationUser } from '@c/models/IntegrationUser'
import UserAvatarWithText from '@c/shared/molecules/object-visualisations/user/UserAvatarWithText.vue'
import Vue from 'vue'
//@ts-ignore
import infiniteScroll from 'vue-infinite-scroll'

export default Vue.extend({
  name: 'IntegrationUserTable',
  components: { UserAvatarWithText },
  directives: { infiniteScroll },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    integrationUsers: {
      type: Array as () => IntegrationUser[],
      default: function (): IntegrationUser[] {
        return []
      }
    },
    amountOfIntegrationUsers: {
      type: Number,
      default: undefined
    },
    value: {
      type: Array as () => boolean[],
      default: function (): boolean[] {
        return []
      }
    }
  }
})
