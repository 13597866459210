
























































































import { TreeNode } from '@c/shared/molecules/structural/trees/interfaces'
import Vue, { PropType } from 'vue'

const ARROW_WIDTH = '14px'

export default Vue.extend({
  name: 'DefaultTreeView',
  props: {
    node: {
      type: Object as PropType<TreeNode>,
      required: true
    },
    indent: {
      type: Number,
      default: 3
    },
    depth: {
      type: Number,
      default: 0
    },
    noExpands: {
      type: Boolean,
      default: false
    }
  },
  data(): { expanded: boolean } {
    return {
      expanded: this.node.expanded || false
    }
  },
  computed: {
    childrenPromise(): Promise<TreeNode[]> {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      return Promise.resolve((this.node || []).children || [])
    },
    arrowWidth(): string {
      return ARROW_WIDTH
    }
  },
  watch: {
    async expanded(newVal: boolean): Promise<void> {
      this.$emit('expanded', { expanded: newVal, node: this.node })
      const resolvedChildren = await Promise.resolve(this.node.children)
      const hasChildren =
        resolvedChildren &&
        resolvedChildren.length &&
        resolvedChildren.length > 0

      if (newVal && !hasChildren) {
        this.$emit('getChildren', { node: this.node })
      }
    }
  }
})
