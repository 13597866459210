import { render, staticRenderFns } from "./DoubleActionDialogModal.vue?vue&type=template&id=2bb3eefe&scoped=true&"
import script from "./DoubleActionDialogModal.vue?vue&type=script&lang=ts&"
export * from "./DoubleActionDialogModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb3eefe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2bb3eefe')) {
      api.createRecord('2bb3eefe', component.options)
    } else {
      api.reload('2bb3eefe', component.options)
    }
    module.hot.accept("./DoubleActionDialogModal.vue?vue&type=template&id=2bb3eefe&scoped=true&", function () {
      api.rerender('2bb3eefe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/shared/molecules/structural/modals/DoubleActionDialogModal.vue"
export default component.exports