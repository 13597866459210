export default {
  state: {
    downloadSlideProgress: 0,
    isDownloadingSlides: false,
    downloadSlideCount: 0,
    slideCollectorOpen: false
  },
  getters: {
    downloadSlideProgress: (state) => state.downloadSlideProgress,
    isDownloadingSlides: (state) => state.isDownloadingSlides,
    downloadSlideCount: (state) => state.downloadSlideCount,
    slideCollectorOpen: (state) => state.slideCollectorOpen
  },
  mutations: {
    setDownloadSlideProgress(state, progress) {
      state.downloadSlideProgress = progress
    },
    setDownloadingSlides(state, downloading) {
      state.isDownloadingSlides = downloading
    },
    setDownloadSlideCount(state, slideCount) {
      state.downloadSlideCount = slideCount
    },
    increaseDownloadProgress(state) {
      const inc = state.downloadSlideProgress + 20 / state.downloadSlideCount
      state.downloadSlideProgress = inc > 95 ? 95 : inc
    },
    setSlideCollectorOpen(state, open) {
      state.slideCollectorOpen = open
    }
  },
  actions: {
    startDownloadSlides({ commit }, slideCount) {
      commit('setDownloadSlideCount', slideCount)
      commit('setDownloadingSlides', true)
    },
    increaseDownloadProgress({ commit }) {
      commit('increaseDownloadProgress')
    },
    stopDownloadSlides({ commit }, { success }) {
      commit('setDownloadSlideProgress', 100)
      commit('setDownloadSlideCount', 0)
      setTimeout(() => {
        commit('setDownloadingSlides', false)
        commit('setDownloadSlideProgress', 0)
        if (success) {
          commit('setSlideCollectorOpen', false)
          commit('resetSlides')
        }
      }, 1000)
    },
    setSlideCollectorOpen({ commit }, open) {
      commit('setSlideCollectorOpen', open)
    }
  }
}
