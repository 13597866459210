var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dirStructure
    ? _c(
        "div",
        _vm._l(_vm.dirStructure, function(node) {
          return _c(
            "DefaultTreeView",
            _vm._g(
              {
                key: node.id,
                attrs: { node: node, "no-expands": _vm.noExpands },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "row-right",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "u-columns justify-end" }, [
                            _c(
                              "div",
                              { staticClass: "u-column is-narrow" },
                              [_vm._t("sync-status", null, { node: node })],
                              2
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "u-columns is-vcentered mv-3" },
                            [
                              _c("div", { staticClass: "u-column is-narrow" }, [
                                _c(
                                  "div",
                                  { staticClass: "ml-2 file-checkbox" },
                                  [
                                    data.node.metadata.is_directory
                                      ? _c("b-checkbox", {
                                          attrs: {
                                            size: "is-small",
                                            indeterminate:
                                              data.node.metadata.checked ===
                                              "indeterminate",
                                            value: data.node.metadata.checked
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return function() {
                                                var checked = !data.node
                                                  .metadata.checked
                                                var is_public =
                                                  data.node.metadata.is_public
                                                _vm.emitInput({
                                                  checked: checked,
                                                  is_public: is_public,
                                                  node: data.node
                                                })
                                              }.apply(null, arguments)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "u-column is-narrow",
                                  staticStyle: { margin: "0 2px 0 0" }
                                },
                                [
                                  !_vm.textOverwrite
                                    ? _c("b-icon", {
                                        staticClass: "node-icon",
                                        attrs: {
                                          icon: _vm.getNodeIcon(data.node)
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("div", { staticClass: "u-column is-narrow" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "body xs",
                                    staticStyle: {
                                      "max-width": "350px",
                                      "text-overflow": "ellipsis",
                                      "overflow-x": "hidden",
                                      "white-space": "nowrap"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.textOverwrite
                                            ? _vm.textOverwrite
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(data.node.metadata.name) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-column is-narrow permission-button"
                                },
                                [
                                  data.node.metadata.is_directory &&
                                  data.node.metadata.checked === true
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "permission-button",
                                          attrs: {
                                            "aria-role": "list",
                                            position: "is-bottom-left"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              data.node.metadata.is_public
                                                ? {
                                                    key: "trigger",
                                                    fn: function() {
                                                      return [
                                                        _c("b-button", {
                                                          attrs: {
                                                            label: "Public",
                                                            "icon-left":
                                                              "earth",
                                                            "icon-right":
                                                              "menu-down"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                : {
                                                    key: "trigger",
                                                    fn: function() {
                                                      return [
                                                        _c("b-button", {
                                                          attrs: {
                                                            label:
                                                              "Permissioned",
                                                            "icon-left":
                                                              "account-multiple",
                                                            "icon-right":
                                                              "menu-down"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: data.node.metadata.is_public,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.node.metadata,
                                                "is_public",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.node.metadata.is_public"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              staticClass:
                                                "has-background-white",
                                              attrs: {
                                                value: true,
                                                "aria-role": "listitem"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "media-left",
                                                    attrs: { icon: "earth" }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media-content"
                                                    },
                                                    [
                                                      _c("h3", [
                                                        _vm._v("Public")
                                                      ]),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "media-small"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Every workspace member can search for this document or folder contents. "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              staticClass:
                                                "has-background-white",
                                              attrs: {
                                                value: false,
                                                "aria-role": "listitem"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "media-left",
                                                    attrs: {
                                                      icon: "account-multiple"
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media-content"
                                                    },
                                                    [
                                                      _c("h3", [
                                                        _vm._v("Permissioned")
                                                      ]),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "media-small"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Inherit rights from " +
                                                              _vm._s(
                                                                _vm.integrationType
                                                              ) +
                                                              ", only people with access to the document can view it. "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "loading-children",
                      fn: function() {
                        return [_vm._t("loading-children")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              _vm.$listeners
            )
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }