var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InviteFromIntegrationModal", {
    attrs: {
      workspace: _vm.workspace,
      integration: _vm.integration,
      "amount-of-integration-users": 0,
      "integration-users": _vm.paginatorData || [],
      "loading-more": _vm.consumptionLoading,
      "loading-save": false
    },
    on: {
      loadMore: _vm.loadPage,
      sendInvites: function() {},
      close: function($event) {
        return _vm.$emit("close")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }