export default {
  getters: {
    settingsTabs: (state, getters, rootState, rootGetters) => {
      return [
        ...(rootGetters.isUman ? ['general'] : []),
        ...(rootGetters.canWriteMembers ? ['members'] : []),
        ...(rootGetters.canWriteIntegrations ? ['apps'] : []),
        ...(rootGetters.canWriteIntegrations && rootGetters.canUseProspecting
          ? ['prospecting']
          : []),
        ...(rootGetters.canWriteOriTemplates ? ['portfolio_templates'] : []),
        ...(rootGetters.canWriteTools ? ['meeting_templates', 'deal_stages'] : [])
      ]
    }
  }
}
