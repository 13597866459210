<template>
  <div @mouseenter="mouseOver()" @mouseleave="mouseLeave()">
    <b-dropdown
      ref="dropdown"
      :triggers="['hover']"
      :mobile-modal="false"
      position="is-top-right"
    >
      <div
        slot="trigger"
        style="width: fit-content; height: fit-content; position: relative"
        @click="$emit('click')"
      >
        <slot v-if="orb" name="orb">
          <div
            :style="{ width: width / 3 + 'px', height: height / 3 + 'px' }"
            class="orb"
          />
        </slot>
        <DefaultImage
          class="avatar-container"
          :src="avatar"
          :fallback="fallback"
          :style="{ width: width + 'px', height: height + 'px' }"
        >
          <slot></slot>
        </DefaultImage>
      </div>
      <b-dropdown-item custom paddingless>
        <div class="fit-width" style="margin-bottom: 16px">
          <slot name="dropdown"></slot>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import DefaultImage from '../../../../components/base/DefaultImage'
import DefaultProfileImage from '@c/assets/profile-account.png'
export default {
  name: 'UserAvatar',
  components: { DefaultImage },
  props: {
    avatar: {
      type: String,
      default: undefined
    },

    width: {
      type: [Number, String],
      default: 40
    },

    height: {
      type: [Number, String],
      default: 40
    },

    orb: {
      type: Boolean,
      default: false
    },

    hoverable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentTimer: undefined,
      currentCloseTimer: undefined,
      isOpen: false
    }
  },

  computed: {
    fallback() {
      return DefaultProfileImage
    }
  },

  methods: {
    mouseOver() {
      if (this.hoverable) {
        if (this.currentCloseTimer) {
          clearTimeout(this.currentCloseTimer)
          this.currentCloseTimer = undefined
        }
        if (this.currentTimer) {
          return
        }
        this.currentTimer = setTimeout(() => {
          if (this.isOpen) {
            return
          }
          this.isOpen = true
          if (this.$refs.dropdown) {
            this.$refs.dropdown.isActive = true
          }
          this.currentTimer = undefined
        }, 500)
      }
    },

    mouseLeave() {
      if (this.currentTimer) {
        clearTimeout(this.currentTimer)
        this.currentTimer = undefined
      }
      if (!this.currentCloseTimer) {
        setTimeout(() => {
          if (this.isOpen) {
            this.isOpen = false
            if (this.$refs.dropdown) {
              this.$refs.dropdown.isActive = false
            }
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.orb {
  border-radius: 100%;
  background-color: $blue-orb-accent;
  position: absolute;
  top: 0;
  right: 0;
}

::v-deep .avatar-container img {
  border-radius: 100%;
}
</style>
