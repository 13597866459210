<template>
  <div
    class="subresource"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <HighlightSubresourceLink
      v-if="showLink"
      class="top-rounded"
      :subresource="subresources[0]"
    />
    <HighlightSubresourcesPreview
      v-else
      class="clickable"
      :subresources="subresources"
      :resource-type="resourceType"
      @click.native="$emit('inspectResource')"
    />
    <div class="subresource-buttons">
      <QualityLabels
        :active-label="subresources[0].label"
        :source-id="subresources[0].uuid"
        :small-labels="false"
        :show-hover-info="false"
        :show-edit-labels="false"
        :bordered-icons="true"
      />
      <div
        class="subresource-buttons-highlight"
        :class="{
          clickable: canWriteLabels
        }"
        @click="onHighlightClick"
        @mouseenter="onHighlightHover"
        @mouseleave="highlightHovered = false"
      >
        <img
          src="@/assets/icons/star-filled.svg"
          alt=""
          class="subresource-buttons-highlight-icon"
        />
        <HighlightHoverInfo
          :highlight="highlight"
          :hovered="highlightHovered"
          :align-left="highlightHoveredX < windowWidth / 2"
        />
      </div>
      <CollectSlideButton
        :subresource="subresources[0]"
        :rank="1"
        :mimetype="mimetype"
        :resource-trace-id="resourceTraceId"
      />
      <b-tooltip
        v-if="canWriteLabels"
        :label="pinned ? 'Unpin content' : 'Pin at start of highlights'"
        position="is-top"
        type="is-dark"
      >
        <div class="subresource-buttons-pin" @click="pinHighlight">
          <img
            :src="require(`@/assets/icons/pin${pinned ? '-filled' : ''}.svg`)"
            alt=""
            class="subresource-buttons-pin-icon"
          />
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import CollectSlideButton from '@c/shared/molecules/object-visualisations/resource/subcomponents/CollectSlideButton.vue'
import { mapGetters } from 'vuex'
import HighlightHoverInfo from './HighlightHoverInfo.vue'
import HighlightSubresourceLink from './HighlightSubresourceLink.vue'
import HighlightSubresourcesPreview from './HighlightSubresourcesPreview.vue'
import QualityLabels from '@c/shared/molecules/object-visualisations/resource/subcomponents/QualityLabels.vue'

export default {
  name: 'HighlightSubresources',
  components: {
    HighlightSubresourcesPreview,
    HighlightSubresourceLink,
    HighlightHoverInfo,
    CollectSlideButton,
    QualityLabels
  },
  props: {
    subresources: {
      type: Array,
      required: true
    },
    resourceType: {
      type: String,
      required: true
    },
    highlight: {
      type: Object,
      default: undefined
    },
    pinned: {
      type: Boolean,
      default: false
    },
    mimetype: {
      type: String,
      default: undefined
    },
    resourceTraceId: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    hovered: false,
    highlightHovered: false,
    highlightHoveredX: -1
  }),
  computed: {
    ...mapGetters(['canWriteLabels']),
    showLink() {
      return this.resourceType === 'video'
    },
    windowWidth() {
      return window.innerWidth
    }
  },
  methods: {
    onHighlightClick() {
      if (this.canWriteLabels) {
        this.$emit('removeHighlight')
      }
    },
    pinHighlight() {
      if (this.canWriteLabels) {
        this.$emit('pinHighlight')
      }
    },
    onHighlightHover(e) {
      this.highlightHoveredX = e.clientX
      this.highlightHovered = true
    }
  }
}
</script>

<style scoped lang="scss">
.subresource {
  position: relative;

  &:hover > .subresource-buttons {
    opacity: 1;
  }

  &-buttons {
    position: absolute;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    top: 1rem;
    right: 1rem;
    opacity: 0;
    transition: opacity ease 400ms;
    user-select: none;

    &-highlight {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999rem;
      background: white;
      border: 1px solid rgba(#000, 8%);

      &-icon {
        height: 1rem;
      }
    }

    &-pin {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999rem;
      background: white;
      border: 1px solid rgba(#000, 8%);
      cursor: pointer;

      &-icon {
        height: 1rem;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}
</style>
