import Console from './console'

function getAPIUrl() {
  Console.debug(process.env)

  if (!process.env.VUE_APP_BACKEND_HOST) {
    throw new Error('VUE_APP_BACKEND_HOST environment variable is not set')
  }

  return {
    webapp: process.env.VUE_APP_BACKEND_HOST,
    extension: process.env.VUE_APP_BACKEND_HOST
  }
}

const apiUrl = getAPIUrl()

export default {
  apiUrl
}
