import { createKeyContentTemplate } from '@/services/keyContentService'
import { getClient, getResponseData } from '../../axios'

const client = getClient('/workspaces')

export default {
  state: {
    templates: [],
    salesKits: []
  },
  getters: {
    templates: (state) => state.templates,
    salesKits: (state) => state.salesKits
  },
  mutations: {
    setTemplates(state, templates) {
      state.templates = templates
    },
    addTemplate(state, template) {
      state.templates.push(template)
    },
    editTemplate(state, { idx, template }) {
      state.templates.splice(idx, 1, template)
    },
    deleteTemplate(state, idx) {
      state.templates.splice(idx, 1)
    },
    resetTemplates(state) {
      state.templates = []
    },
    setSalesKits(state, salesKits) {
      state.salesKits = salesKits
    },
    addSalesKit(state, salesKit) {
      state.salesKits.push(salesKit)
    },
    editSalesKit(state, { idx, saleskit }) {
      state.salesKits.splice(idx, 1, saleskit)
    },
    deleteSalesKit(state, idx) {
      state.salesKits.splice(idx, 1)
    },
    resetSalesKits(state) {
      state.salesKits = []
    }
  },
  actions: {
    async getTemplates({ state, commit }, { workspace_id }) {
      if (state.templates?.length) return state.templates
      const response = await client.get(`/${workspace_id}/tools/`)
      const templates = getResponseData(response)
      commit('setTemplates', templates)
      return templates
    },

    async getTemplate(_, { workspace_id, template_id }) {
      const response = await client.get(
        `/${workspace_id}/tools/${template_id}/`
      )
      return getResponseData(response)
    },

    async createTemplate(
      { commit },
      { workspace_id, name, description, prompt, status, template_id }
    ) {
      const response = await client.post(`/${workspace_id}/tools/`, {
        name,
        description,
        content: prompt,
        status,
        template_id
      })
      const res = getResponseData(response)
      commit('addTemplate', res)
      return res
    },

    async editTemplate(
      { state, commit },
      { workspace_id, template_id, name, description, prompt, status }
    ) {
      const response = await client.patch(
        `/${workspace_id}/tools/${template_id}/`,
        {
          name,
          description,
          content: prompt,
          status
        }
      )
      const template = getResponseData(response)
      const idx = state.templates.findIndex((x) => x.uuid === template.uuid)
      commit('editTemplate', { idx, template })
      return template
    },

    async deleteTemplate({ state, commit }, { workspace_id, template_id }) {
      const response = await client.delete(
        `/${workspace_id}/tools/${template_id}/`
      )
      const data = getResponseData(response)
      const idx = state.templates.findIndex((x) => x.uuid === template_id)
      commit('deleteTemplate', idx)
      return data
    },

    async duplicateTemplate({ dispatch }, { workspace_id, template }) {
      const master = template.template_id ? template : (await dispatch('getTemplate', {
        workspace_id,
        template_id: template.uuid
      }))
      let baseTemplate = undefined
      if (template.template_id) {
        baseTemplate = await createKeyContentTemplate({
          workspace_id,
          template_id: template.template_id
        })
      }
      return dispatch('createTemplate', {
        workspace_id,
        ...(
          template.template_id
            ? {
              name: baseTemplate.name,
              description: baseTemplate.description,
              template_id: baseTemplate.uuid
            }
            : {
              name: `${master.name} (copy)`,
              description: master.description,
              prompt: master.content
            }
          )
      })
    },

    resetTemplates({ commit }) {
      commit('resetTemplates')
    },

    async getSalesKits({ state, commit }, { workspace_id }) {
      if (state.salesKits?.length) return state.salesKits
      const response = await client.get(`/${workspace_id}/toolkits/`)
      const saleskits = getResponseData(response)
      commit('setSalesKits', saleskits)
      return saleskits
    },

    async getSalesKit(_, { workspace_id, sales_kit_id }) {
      const response = await client.get(
        `/${workspace_id}/toolkits/${sales_kit_id}/`
      )
      return getResponseData(response)
    },

    async createSalesKit(
      { commit },
      { workspace_id, name, description, status, template_ids }
    ) {
      const response = await client.post(`/${workspace_id}/toolkits/`, {
        name,
        description,
        tool_ids: template_ids,
        status
      })
      const salesKit = getResponseData(response)
      commit('addSalesKit', salesKit)
      return salesKit
    },

    async editSalesKit(
      { state, commit },
      { workspace_id, sales_kit_id, name, description, status, template_ids }
    ) {
      const response = await client.patch(
        `/${workspace_id}/toolkits/${sales_kit_id}/`,
        {
          name,
          description,
          tool_ids: template_ids,
          status
        }
      )
      const saleskit = getResponseData(response)
      const idx = state.salesKits.findIndex((x) => x.uuid === saleskit.uuid)
      commit('editSalesKit', { idx, saleskit })
      return saleskit
    },

    async deleteSalesKit({ state, commit }, { workspace_id, sales_kit_id }) {
      const response = await client.delete(
        `/${workspace_id}/toolkits/${sales_kit_id}/`
      )
      const data = getResponseData(response)
      const idx = state.salesKits.findIndex((x) => x.uuid === sales_kit_id)
      commit('deleteSalesKit', idx)
      return data
    },

    async duplicateSalesKit({ dispatch }, { workspace_id, sales_kit_id }) {
      const master = await dispatch('getSalesKit', {
        workspace_id,
        sales_kit_id
      })
      return dispatch('createSalesKit', {
        workspace_id,
        name: `${master.name} (copy)`,
        description: master.description,
        template_ids: master.tools.map((x) => x.uuid)
      })
    },

    resetSalesKits({ commit }) {
      commit('resetSalesKits')
    }
  }
}
