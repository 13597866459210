<template>
  <div ref="slide-modal" class="modal-container pa-5 scrollbar-visible">
    <div class="modal-header">
      <QualityLabelGroup
        :labels="subresource.labels"
        :big="true"
        :show-hover-info="false"
      />
      <CollectSlideButton
        :big="true"
        :subresource="subresource"
        :rank="rank"
        :has-tooltip="false"
        :always-show="forceShowCollect"
        location="slide modal"
      />
    </div>
    <img :src="subresource.preview" class="modal-image" />
    <div ref="references" class="title-container">
      <div class="modal-subheader">
        Appears in {{ subresource.total_references }}
        {{ subresource.total_references === 1 ? 'document' : 'documents' }}
      </div>
      <b-tooltip
        :label="isBookmarked ? 'Remove bookmark' : 'Add bookmark'"
        position="is-top"
        type="is-dark"
        append-to-body
      >
        <b-button
          class="btn-bookmark btn-bookmark-modal"
          rounded
          size="is-small"
          @click="toggleBookmark()"
        >
          <img
            :src="
              require(`@c/assets/icons/bookmark${
                isBookmarked ? '-filled' : ''
              }.svg`)
            "
            alt=""
          />
        </b-button>
      </b-tooltip>
    </div>
    <div class="references">
      <div v-for="(reference, idx) in visibleReferences" :key="idx">
        <div class="reference mt-3">
          <DocumentTag
            class="u-column is-narrow"
            :mimetype="reference.resource.integrationfile.mimetype"
          />
          <span class="link" @click.stop="onFileNameClick(reference)">
            {{ reference.resource.title }}
          </span>
          <QualityLabels
            :small-labels="true"
            :active-label="reference.resource.label"
            :source-id="reference.resource.uuid"
            :bordered-icons="false"
          />
          <UploadDownloadDropdown
            v-if="downloadFilesEnabled"
            :props-call="() => getDownloadProps(reference.resource)"
          />
        </div>
        <div class="integration-container">
          <ResourceInfo
            :resource-integration="reference.resource.integrationfile"
            :hover="true"
            @on-path-click="onPathClick(reference)"
          />
        </div>
      </div>
      <div
        v-if="showShowMoreReferences"
        class="blue link bold references-showmore mt-3"
        @click="showMoreReferences = !showMoreReferences"
      >
        {{ showMoreReferences ? 'Show less' : 'Show more' }}
      </div>
    </div>
    <div
      v-if="showSimilarSlides"
      ref="similar"
      class="modal-subheader mt-5 mb-3"
    >
      Similar slides
    </div>
    <SimilarSlides
      v-if="showSimilarSlides"
      :slide-id="subresource.uuid"
      @noSimilarSlides="noSimilarSlides = true"
      @ready="similarSlidesReady = true"
    />
  </div>
</template>

<script>
import {
  addBookmarkToSubresource,
  deleteBookmarkFromSubresource
} from '@/services/bookmarkService'
import {
  eventContext,
  sendFileClickThroughEvent,
  sendFolderClickThroughEvent
} from '@/services/feedbackService'
import { getDocumentDownloadLink } from '@/services/presentationService'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import { mapGetters } from 'vuex'
import { formatDateWTime } from '../../../../../../../util'
import DocumentTag from '../../../tags/DocumentTag.vue'
import SimilarSlides from '../SimilarSlides.vue'
import CollectSlideButton from './CollectSlideButton.vue'
import QualityLabelGroup from './QualityLabelGroup.vue'
import QualityLabels from './QualityLabels.vue'
import ResourceInfo from './ResourceInfo.vue'

export default {
  name: 'SlideVisualisationModal',
  components: {
    QualityLabelGroup,
    QualityLabels,
    DocumentTag,
    SimilarSlides,
    ResourceInfo,
    CollectSlideButton,
    UploadDownloadDropdown
  },
  props: {
    subresource: {
      type: Object,
      required: true
    },
    bookmark: {
      type: String,
      default: ''
    },
    scroll: {
      type: String,
      default: ''
    },
    rank: {
      type: Number,
      default: -1
    },
    forceShowCollect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hideSimilarSlides: false,
      showMoreReferences: false,
      noSimilarSlides: false,
      similarSlidesReady: false,
      isBookmarked: false
    }
  },
  computed: {
    ...mapGetters(['lastSearchId', 'downloadFilesEnabled']),
    showSimilarSlides() {
      return !this.noSimilarSlides
    },
    visibleReferences() {
      return this.showMoreReferences
        ? this.subresource.references
        : this.subresource.references.slice(0, 3)
    },
    showShowMoreReferences() {
      return this.subresource.total_references > 3
    }
  },
  watch: {
    similarSlidesReady(newVal) {
      if (newVal && this.scroll !== '') {
        const el = this.$refs[this.scroll]
        setTimeout(() => {
          el.scrollIntoView({ behavior: 'smooth' })
        }, 500)
      }
    }
  },
  mounted() {
    this.isBookmarked = !!this.bookmark
  },
  methods: {
    async toggleBookmark() {
      try {
        if (this.isBookmarked) {
          await deleteBookmarkFromSubresource(
            this.$route.params.workspace_id,
            this.subresource.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = false
        } else {
          await addBookmarkToSubresource(
            this.$route.params.workspace_id,
            this.subresource.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = true
        }
      } catch (e) {
        this.$toast.error(
          e,
          `${this.isBookmarked ? 'removing' : 'adding'} bookmark`
        )
      }
    },
    getUrl(reference) {
      if (!reference.url) return reference.resource.url
      return reference.url
    },
    onFileNameClick(reference) {
      sendFileClickThroughEvent(this.$route.params.workspace_id, {
        subresource_trace_id: this.subresource.trace_id,
        resource_trace_id: reference.resource.trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.subresource,
        comment: 'from slide modal references',
        ...(this.rank !== -1 ? { rank: this.rank } : {})
      })
      const win = window.open(reference.url, '_blank')
      win?.focus()
    },
    onPathClick(reference) {
      sendFolderClickThroughEvent(this.$route.params.workspace_id, {
        subresource_trace_id: this.subresource.trace_id,
        resource_trace_id: reference.resource.trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.subresource,
        comment: 'from slide modal references',
        ...(this.rank !== -1 ? { rank: this.rank } : {})
      })
    },
    showDate(resource) {
      return formatDateWTime(
        resource.integrationfile.external_modified_date ||
          resource.integrationfile.external_created_date ||
          this.lastModifiedAt ||
          this.createdAt
      )
    },
    async getDownloadProps(resource) {
      return getDocumentDownloadLink(
        this.$route.params.workspace_id,
        resource.uuid,
        this.$route.query?.act_as
      )
    }
  }
}
</script>

<style scoped lang="scss">
.modal-container {
  background: white;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  max-height: 90vh;
  scrollbar-width: thin;
  scrollbar-color: rgba(#000, 0) transparent;
  transition: all 0.2s ease;
  .modal-image {
    border: 2px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .modal-subheader {
    font-size: 1.2em;
    font-weight: 700;
  }
}

.integration-container {
  display: flex;
  flex-flow: row wrap;

  .integration-owner {
    display: flex;
    flex-flow: row nowrap;

    &-name {
      max-width: 30ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.reference {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  background-color: rgba(#000, 0.4) !important;
}
.modal-container::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  transition: all 0.2s ease;
}

.modal-container::-webkit-scrollbar-thumb {
  transition: all 0.2s ease;
  background-color: rgba(#000, 0);
  border-radius: 100px;
}
.scrollbar-visible {
  scrollbar-color: rgba(#000, 0.4) transparent !important;
}
.btn-bookmark {
  width: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &::v-deep span {
    line-height: 1;
  }
}
.references {
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  box-sizing: border-box;
  padding: 0 0.5rem;

  &-showmore {
    align-self: center;
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.link {
  color: $blue;
  font-weight: bolder;
  word-break: break-word;
  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

.download {
  padding: 0.5rem;
  box-sizing: initial;
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(#000, 6%);
  }

  &-icon {
    height: 1.2rem;
  }
}
</style>
