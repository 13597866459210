import { full_name } from '@c/models/User'
import Workspace from '@c/models/Workspace'
import WorkspaceMember from '@c/models/WorkspaceMember'
import {
  deleteDialog,
  leaveDialog
} from '@c/shared/logic/dialogs/error/BaseErrorDialogs'

export function deleteWorkspaceDialog(
  parent: any,
  workspace: Workspace,
  onConfirm: (workspace: Workspace) => boolean | Promise<boolean>
): void {
  deleteDialog(
    parent,
    'workspace',
    'Are you sure you want to delete this workspace? All content inside will be deleted <b class="body bold black">for you and every member inside.</b> This action cannot be undone.',
    workspace.name,
    () => onConfirm(workspace),
    workspace
  )
}

export function leaveWorkspaceDialog(
  parent: any,
  workspace: Workspace,
  onConfirm: (workspace: Workspace) => boolean | Promise<boolean>
): void {
  leaveDialog(
    parent,
    'workspace',
    "Are you sure you want to leave this workspace? You'll need to be invited again if you want to join again.<br/><br/>Do you want to give another member admin rights for this workspace?",
    workspace.name,
    () => onConfirm(workspace),
    workspace
  )
}

export function removeWorkspaceMemberDialog(
  parent: any,
  workspace: Workspace,
  member: WorkspaceMember,
  onConfirm: (
    workspace: Workspace,
    member: WorkspaceMember
  ) => boolean | Promise<boolean>
): void {
  leaveDialog(
    parent,
    'workspace member',
    `Are you sure you want to remove ${full_name(
      member.user
    )} from this workspace?`,
    full_name(member.user),
    () => onConfirm(workspace, member),
    workspace,
    { modificationType: 'remove', width: 500 }
  )
}
