export default {
  state: {
    lastSearchId: undefined,
    searchEnrichmentContext: {}
  },
  getters: {
    lastSearchId: (state) => state.lastSearchId,
    searchEnrichmentContext: (state) => state.searchEnrichmentContext
  },
  mutations: {
    setLastSearchId(state, searchId) {
      state.lastSearchId = searchId
    },
    setSearchEnrichmentContext(state, context) {
      state.searchEnrichmentContext = context
    }
  },
  actions: {
    setLastSearchId({ commit }, searchId) {
      commit('setLastSearchId', searchId)
    },
    /**
     *
     * @param {*} context
     * Should contain:
     * - page
     * - tab
     * - trigger
     * - detail _(optional)_
     */
    setSearchEnrichmentContext({ commit }, context) {
      commit('setSearchEnrichmentContext', context)
    }
  }
}
