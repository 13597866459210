import { render, staticRenderFns } from "./ResourceInfo.vue?vue&type=template&id=9dbb5012&scoped=true&"
import script from "./ResourceInfo.vue?vue&type=script&lang=ts&"
export * from "./ResourceInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ResourceInfo.vue?vue&type=style&index=0&id=9dbb5012&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dbb5012",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9dbb5012')) {
      api.createRecord('9dbb5012', component.options)
    } else {
      api.reload('9dbb5012', component.options)
    }
    module.hot.accept("./ResourceInfo.vue?vue&type=template&id=9dbb5012&scoped=true&", function () {
      api.rerender('9dbb5012', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/shared/molecules/object-visualisations/resource/subcomponents/ResourceInfo.vue"
export default component.exports