import { Store } from 'vuex'
import StoreSensor from './StoreSensor'
import { isArray } from 'lodash-es'

export default class StoreActionSensor<T> extends StoreSensor<T> {
  protected readonly action: string[]

  constructor(type: string, action: string | string[], store: Store<any>) {
    super(type, store)
    if (!isArray(action)) {
      this.action = [action]
    } else {
      this.action = action
    }
    for (const storeAction of this.action) {
      store.subscribeAction((action, state) => {
        if (action.type === storeAction) {
          this.sendData(this.transformState(action.payload, state))
        }
      })
    }
  }

  // @ts-ignore
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  protected transformState(payload: any, state: any): T {
    throw Error('Not implemented')
  }
}
