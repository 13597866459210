
















































































































import SyncStatusList from '@c/features/content-sync-modal/molecules/SyncStatusList.vue'
import allIntegrations from '@c/integrations'
import Integration, { SYNC_STATUS } from '@c/models/Integration'
import Workspace from '@c/models/Workspace'
import WorkspaceMember from '@c/models/WorkspaceMember'
import WorkspaceHeaderModal from '@c/shared/molecules/object-visualisations/workspace/WorkspaceHeaderModal.vue'
import ContentWithHeader from '@c/shared/molecules/structural/blocks/ContentWithHeader.vue'
import ModalActionFooter from '@c/shared/molecules/structural/blocks/ModalActionFooter.vue'
import FileSelectTree from '@c/shared/molecules/structural/trees/FileSelectTree.vue'
import SyncStatusComponent from '@c/features/content-sync-modal/molecules/SyncStatusComponent.vue'
import {
  FileMetadata,
  TreeNode
} from '@c/shared/molecules/structural/trees/interfaces'
import Vue from 'vue'

export default Vue.extend({
  name: 'SyncFolderSelectModal',

  components: {
    WorkspaceHeaderModal,
    ModalActionFooter,
    FileSelectTree,
    ContentWithHeader,
    SyncStatusList,
    SyncStatusComponent
  },

  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    },

    integration: {
      type: Object as () => Integration,
      required: true
    },

    populatedWorkspaces: {
      type: Array as () => Workspace[],
      default: function (): Workspace[] {
        return []
      }
    },

    loadingPost: {
      type: Boolean,
      default: false
    },

    directoryStructure: {
      type: Array,
      default: function () {
        return []
      }
    },

    currentWorkspaceMember: {
      type: Object as () => WorkspaceMember | undefined,
      default: undefined
    },

    loadingDirs: {
      type: Boolean,
      default: false
    },

    loadingSyncs: {
      type: Boolean,
      default: false
    },

    syncStats: {
      type: Object as {},
      default: () => {}
    }
  },

  data() {
    return {
      selectedFiles: []
    }
  },

  computed: {
    integrationType() {
      return allIntegrations[this.integration.type]
    },

    syncStatus() {
      return SYNC_STATUS
    },

    lastSync() {
      const date = this.syncStats.last_sync
      return date ? this.$moment(date) : null
    },

    totalSyncs() {
      return Object.values(SYNC_STATUS)
        .map((val) => this.syncStats[val] || 0)
        .reduce((a, b) => a + b, 0)
    }
  },

  methods: {
    retrySync(node: TreeNode<FileMetadata>) {
      if (node.metadata.status === SYNC_STATUS.FAILURE) {
        // TODO: this
        this.$toast.danger(
          'Currently not possible',
          'We are working hard to bring this feature to your flow of work.'
        )
      }
    },

    checkSyncStatusRender(node) {
      return node && node.metadata.status
    }
  }
})
