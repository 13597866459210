var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "UserHeaderModal",
    {
      attrs: { user: _vm.user, subtitle: "Settings" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "ProfileEditTab",
        _vm._g(
          {
            attrs: {
              user: _vm.user,
              "initial-data": _vm.user,
              loading: _vm.loadingSettings
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }