import DialogModal from '@c/shared/molecules/structural/modals/DialogModal.vue'

export function logoutDialog(
  parent: any,
  onConfirm: () => boolean | Promise<boolean>
): void {
  parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: DialogModal,
    trapFocus: true,
    props: {
      objectType: '',
      message: 'Are you certain you want to log out?',
      onConfirm,
      modificationType: 'Log Out'
    },
    events: {},
    width: 400
  })
}
