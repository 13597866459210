var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "updown" },
    [
      _vm._t("default", null, {
        upload: _vm.uploadProvider ? _vm.handleUpload : undefined,
        connect: _vm.uploadProvider ? _vm.connectIntegration : undefined,
        download: _vm.handleDownload,
        provider: _vm.uploadProvider
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }