import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

export function injectValidations(Vue: any) {
  // Validation library
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)

  // Override the default message.
  extend('required', {
    ...required,
    message: 'This field is required'
  })
}
