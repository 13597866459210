var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editOpen,
            expression: "editOpen"
          }
        ],
        staticClass: "edit-labels-container card",
        class: { leftaligned: _vm.alignLeft, rightaligned: !_vm.alignLeft },
        style:
          "top: " +
          (_vm.mouseY < 0.75 ? "100%" : "unset") +
          "; bottom: " +
          (_vm.mouseY >= 0.75 ? "100%" : "unset")
      },
      _vm._l(_vm.labelTypes, function(type) {
        return _c(
          "div",
          { key: type, staticClass: "label-container" },
          _vm._l(_vm.labelsByType[type], function(label, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "q-label",
                on: {
                  click: function($event) {
                    return _vm.$emit("toggleLabel", label)
                  }
                }
              },
              [
                _c("div", { staticClass: "label-inner" }, [
                  _c("img", {
                    attrs: {
                      src: require("@c/assets/icons/" +
                        type +
                        "QualityLabel.svg")
                    }
                  }),
                  _c("label", [_vm._v(_vm._s(label.name))])
                ]),
                _vm.activeLabelValue.name === label.name
                  ? _c("div", { staticClass: "checkmark" }, [
                      _c("img", {
                        attrs: {
                          src: require("@c/assets/icons/check-blue.svg"),
                          alt: ""
                        }
                      })
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }