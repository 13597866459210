<template>
  <button
    class="ubutton"
    :class="{
      [type]: true,
      [size]: true,
      disabled: disabled || loading,
      'full-width': fullWidth,
      'icon-only': !text
    }"
    @click="$emit('click')"
    >
    <slot name="left"></slot>
    <img
      v-if="icon && iconLeft"
      :src="require(`@/assets/icons/${icon}.svg`)"
      alt=""
      class="ubutton-icon"
      :class="{ 'apply-filter': filterIcon }"
    />
    <span v-if="text" class="ubutton-text">{{ text }}</span>
    <img
    v-if="icon && !iconLeft"
    :src="require(`@/assets/icons/${icon}.svg`)"
    alt=""
    class="ubutton-icon"
    :class="{ 'apply-filter': filterIcon }"
    />
    <slot name="right"></slot>
    <div v-if="disabled || loading" class="ubutton-overlay">
      <img
        v-if="loading"
        src="@/assets/icons/spinner.svg"
        alt=""
        class="ubutton-overlay-loading"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: (s) =>
        ['primary', 'light', 'light-solid', 'white', 'grey', 'black'].includes(
          s
        )
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l'].includes(s)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    filterIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    click() {
      if (!this.disabled && !this.loading) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ubutton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  border-radius: 999rem;
  cursor: pointer;
  outline: inherit;
  transition: background 0.2s ease;
  position: relative;
  height: fit-content;
  width: fit-content;

  &-icon {
    transition: all 0.2s ease;
  }

  &-text {
    transition: all 0.2s ease;
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
  }

  &.primary {
    background: $primary;
    color: white;
    border: 1px solid transparent;
    & > .ubutton-text {
      color: white;
    }

    & > .ubutton-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(100%);
    }

    &:hover {
      background: darken($primary, 15%);
    }
  }

  &.light {
    background: rgba(#ebf1ff, 0%);
    border: 1px solid transparent;

    & > .ubutton-text {
      color: $primary;
    }

    & > .ubutton-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }

    &:hover {
      background: #ebf1ff;
    }
  }
  &.light-solid {
    background: #ebf1ff;
    border: 1px solid $primary;

    & > .ubutton-text {
      color: $primary;
    }

    & > .ubutton-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }

    &:hover {
      background: darken(#ebf1ff, 5%);
    }
  }

  &.white {
    background: white;
    border: 1px solid #dddfe2;

    & > .ubutton-text {
      color: #60666b;
    }

    & > .ubutton-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }

    &:hover {
      background: darken(white, 6%);
      border: 1px solid rgba(#000, 0%);
      & > .ubutton-text {
        color: #303032;
      }

      & > .ubutton-icon.apply-filter {
        filter: brightness(0) saturate(100%) invert(11%) sepia(4%)
          saturate(615%) hue-rotate(202deg) brightness(101%) contrast(81%);
      }
    }
  }

  &.grey {
    background: transparent;
    border: 1px solid transparent;

    & > .ubutton-text {
      color: #60666b;
    }

    & > .ubutton-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }

    &:hover {
      background: rgba(#000, 6%);
      & > .ubutton-text {
        color: #303032;
      }

      & > .ubutton-icon.apply-filter {
        filter: brightness(0) saturate(100%) invert(11%) sepia(4%)
          saturate(615%) hue-rotate(202deg) brightness(101%) contrast(81%);
      }
    }
  }

  &.black {
    background: rgba(#000, 64%);
    border: 1px solid transparent;

    & > .ubutton-text {
      color: white;
    }

    & > .ubutton-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(100%);
    }

    &:hover {
      background: black;
    }
  }

  &.icon-only {
    justify-content: center;
    padding: unset !important;

    &.xxs {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
    }

    &.xs {
      width: 1.85rem;
      height: 1.85rem;
      min-width: 1.85rem;
      min-height: 1.85rem;
    }

    &.s {
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
    }

    &.m {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
    }

    &.l {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &-overlay {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 999rem;
    background: rgba(#fff, 0.5);

    &-loading {
      height: min(1.2rem, 85%);
      width: min(1.2rem, 85%);
      min-width: min(1.2rem, 85%);
      min-height: min(1.2rem, 85%);
      animation: spin 1s linear infinite;
    }
  }

  &.xxs {
    padding: 0.15rem 0.5rem;

    & > .ubutton-text {
      font-size: 0.85rem;
    }

    & > .ubutton-icon {
      height: 0.9rem;
      width: 0.9rem;
      min-width: 0.9rem;
      min-height: 0.9rem;
      object-fit: contain;
    }
  }

  &.xs {
    padding: 0.35rem 0.85rem;

    & > .ubutton-text {
      font-size: 0.85rem;
    }

    & > .ubutton-icon {
      height: 1.1rem;
      width: 1.1rem;
      min-width: 1.1rem;
      min-height: 1.1rem;
      object-fit: contain;
    }
  }

  &.s {
    padding: 0.35rem 1.15rem;

    & > .ubutton-text {
      font-size: 1rem;
    }

    & > .ubutton-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.m {
    padding: 0.8rem 1.45rem;

    & > .ubutton-text {
      font-size: 1rem;
    }

    & > .ubutton-icon {
      height: 1.5rem;
      width: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      object-fit: contain;
    }
  }

  &.l {
    padding: 0.95rem 1.7rem;

    & > .ubutton-text {
      font-size: 1.15rem;
    }

    & > .ubutton-icon {
      height: 1.75rem;
      width: 1.75rem;
      min-width: 1.75rem;
      min-height: 1.75rem;
      object-fit: contain;
    }
  }

  &.full-width {
    width: 100%;
    justify-content: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
