var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "draggable-list",
      style: Object.assign(
        {},
        _vm.gap
          ? Object.assign(
              {},
              { gap: _vm.gap },
              _vm.dragging ? { marginTop: "calc(-1 * " + _vm.gap + ")" } : {}
            )
          : {}
      )
    },
    _vm._l(_vm.dragItems, function(item, idx) {
      var _obj
      return _c(
        "div",
        {
          key: "draggable-list-" + idx + "-" + _vm.itemKey,
          staticClass: "draggable-list-item-wrapper",
          class: {
            dragged: _vm.dragging && idx === 0
          },
          style: Object.assign(
            {},
            item.dragTarget ? {} : _vm.wrapperStyle,
            _vm.dragging && (idx === 0 || item.dragTarget)
              ? {
                  width: _vm.itemDimensions.width + "px",
                  minWidth: _vm.itemDimensions.width + "px",
                  height: _vm.itemDimensions.height + "px",
                  minHeight: _vm.itemDimensions.height + "px"
                }
              : {},
            _vm.dragging && idx === 0
              ? {
                  left: _vm.mouseCoords.x - _vm.draggedItemOffset.x + "px",
                  top: _vm.mouseCoords.y - _vm.draggedItemOffset.y + "px"
                }
              : {}
          )
        },
        [
          _c(
            "div",
            {
              staticClass: "draggable-list-item",
              class:
                ((_obj = {
                  "drag-target": item.dragTarget
                }),
                (_obj["draggable-list-item-" + _vm.itemKey] = true),
                (_obj["draggable-list-item-targetable-" + _vm.itemKey] =
                  _vm.dragging && !item.dragTarget && idx !== 0),
                (_obj.overlapping = _vm.handleOverlaps),
                _obj),
              style: Object.assign({}, item.dragTarget ? {} : _vm.itemStyle),
              on: {
                click: function() {
                  return _vm.$emit("item-click", item, idx)
                }
              }
            },
            [
              !item.dragTarget
                ? _vm._t("left", null, { item: item, index: idx })
                : _vm._e(),
              !item.dragTarget && _vm.draggable
                ? _c(
                    "div",
                    {
                      staticClass: "draggable-list-item-handle",
                      class: {
                        bordered: _vm.handleBordered,
                        overlapping: _vm.handleOverlaps
                      },
                      on: {
                        mousedown: function(e) {
                          return _vm.startDrag(e, idx)
                        },
                        mouseup: function(e) {
                          return _vm.endDrag(e, idx)
                        },
                        click: function($event) {
                          $event.stopPropagation()
                        },
                        dragstart: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "draggable-list-item-handle-icon",
                        attrs: {
                          src: require("@/assets/icons/drag.svg"),
                          alt: ""
                        }
                      })
                    ]
                  )
                : _vm._e(),
              !item.dragTarget
                ? _vm._t("item", null, { item: item, index: idx })
                : _vm._e()
            ],
            2
          ),
          !_vm.dragging || !(item.dragTarget || idx === 0)
            ? _vm._t("after", null, { item: item, index: idx })
            : _vm._e()
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }