import Console from '@/console'
import store from '@/store'
import { getClient } from '../../../store/src/axios'

const client = getClient('/users')

export const eventPage = {
  home: 'home',
  search_results: 'search_results'
}

export const eventTab = {
  all: 'all',
  document: 'document',
  presentation: 'presentation',
  people: 'people',
  slides: 'slides',
  video: 'video',
  sheet: 'sheet'
}

const tabMapping = {
  all: eventTab.all,
  documents: eventTab.document,
  presentations: eventTab.presentation,
  slides: eventTab.slides,
  people: eventTab.people,
  videos: eventTab.video,
  sheets: eventTab.sheet
}

export const eventType = {
  file_click_through: 'file_click_through',
  folder_click_through: 'folder_click_through',
  insert: 'insert',
  collect: 'collect',
  inspect: 'inspect',
  view: 'view',
  list: 'list'
}

export const eventContext = {
  subresource: 'subresource',
  resource: 'resource'
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - (sub)resource_trace_id
 * - tab - *[ all, document, presentation, people, slides ]*
 * - context - *[ resource, subresource ]*
 */
export async function sendFileClickThroughEvent(workspaceId, event) {
  try {
    const userId = store.getters.currentUser.uuid
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        event: event.event || eventType.file_click_through,
        page: event.page || eventPage.search_results,
        date_created: new Date().toISOString(),
        ...(event.page !== eventPage.home && {
          tab: tabMapping[event.tab],
          search_id: event.search_id || store.getters.lastSearchId
        })
      }
    ])
  } catch (error) {
    Console.debug('Something went wrong when sending a clickthrough event')
  }
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - (sub)resource_trace_id
 * - tab - *[ all, document, presentation, people, slides ]*
 * - context - *[ resource, subresource ]*
 */
export async function sendFolderClickThroughEvent(workspaceId, event) {
  try {
    const userId = store.getters.currentUser.uuid
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        event: eventType.folder_click_through,
        page: eventPage.search_results,
        date_created: new Date().toISOString()
      }
    ])
  } catch (error) {
    Console.debug('Something went wrong when sending a clickthrough event')
  }
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - (sub)resource_trace_id
 * - tab - *[ all, document, presentation, people, slides ]*
 */
export async function sendCollectEvent(workspaceId, event) {
  try {
    const userId = store.getters.currentUser.uuid
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        tab: tabMapping[event.tab],
        search_id: event.search_id || store.getters.lastSearchId,
        context: eventContext.subresource,
        event: eventType.collect,
        page: eventPage.search_results,
        date_created: new Date().toISOString()
      }
    ])
  } catch (error) {
    Console.debug('Something went wrong when sending a clickthrough event')
  }
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - ids: array with {subresource_trace_id, resource_trace_id, rank}
 * - tab: *[ all, document, presentation, people, slides ]*
 * - comment: more detailed description of where the event is triggered from
 */
export async function sendBatchCollectEvent(workspaceId, event) {
  try {
    const userId = store.getters.currentUser.uuid
    const { ids, tab, comment } = event
    await client.post(
      `/${userId}/events/`,
      ids.map((entry) => ({
        workspace_id: workspaceId,
        tab: tabMapping[tab],
        rank: entry.rank,
        ...(entry.resource_trace_id
          ? { resource_trace_id: entry.resource_trace_id }
          : {}),
        ...(entry.subresource_trace_id
          ? { subresource_trace_id: entry.subresource_trace_id }
          : {}),
        search_id: store.getters.lastSearchId,
        event: eventType.collect,
        page: eventPage.search_results,
        context: eventContext.subresource,
        date_created: new Date().toISOString(),
        comment
      }))
    )
  } catch (error) {
    Console.debug('Something went wrong when sending a collection event')
  }
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - (sub)resource_trace_id
 * - tab - *[ all, document, presentation, people, slides ]*
 * - context - *[ resource, subresource ]*
 */
export async function sendInspectEvent(workspaceId, event) {
  try {
    const userId = store.getters.currentUser.uuid
    await client.post(`/${userId}/events/`, [
      {
        ...event,
        workspace_id: workspaceId,
        event: eventType.inspect,
        page: event.page || eventPage.search_results,
        date_created: new Date().toISOString(),
        ...(event.page !== eventPage.home && {
          tab: tabMapping[event.tab],
          search_id: event.search_id || store.getters.lastSearchId
        })
      }
    ])
  } catch (error) {
    Console.debug('Something went wrong when sending a clickthrough event')
  }
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - ids: array with {subresource_trace_id, resource_trace_id, rank}
 * - tab - *[ all, document, presentation, people, slides ]*
 * - context - *[ resource, subresource ]*
 * @param {function} callback optional argument, this is called immediately after the view event is sent
 */
export async function sendBatchViewEvent(
  workspaceId,
  event,
  callback = () => {}
) {
  try {
    const { tab, context, ids } = event
    const userId = store.getters.currentUser.uuid
    client.post(
      `/${userId}/events/`,
      ids.map((entry) => ({
        workspace_id: workspaceId,
        tab: tabMapping[tab],
        context,
        rank: entry.rank,
        ...(entry.resource_trace_id
          ? { resource_trace_id: entry.resource_trace_id }
          : {}),
        ...(entry.subresource_trace_id
          ? { subresource_trace_id: entry.subresource_trace_id }
          : {}),
        search_id: store.getters.lastSearchId,
        event: eventType.view,
        page: eventPage.search_results,
        date_created: new Date().toISOString()
      }))
    )
    callback()
  } catch (error) {
    Console.debug('Something went wrong when sending a clickthrough event')
  }
}

/**
 * @param {string} workspaceId workspace ID
 * @param {object} event has to contain:
 * - ids: array with {subresource_trace_id, resource_trace_id}
 * - tab - *[ all, document, presentation, people, slides ]*
 * - context - *[ resource, subresource ]*
 */
export async function sendBatchListEvent(workspaceId, event) {
  try {
    const { tab, context, ids } = event
    const userId = store.getters.currentUser.uuid
    await client.post(
      `/${userId}/events/`,
      ids.map((entry) => ({
        workspace_id: workspaceId,
        tab: tabMapping[tab],
        context,
        rank: entry.rank,
        ...(entry.resource_trace_id
          ? { resource_trace_id: entry.resource_trace_id }
          : {}),
        ...(entry.subresource_trace_id
          ? { subresource_trace_id: entry.subresource_trace_id }
          : {}),
        search_id: store.getters.lastSearchId,
        event: eventType.list,
        page: eventPage.search_results,
        date_created: new Date().toISOString()
      }))
    )
  } catch (error) {
    Console.debug('Something went wrong when sending a clickthrough event')
  }
}
