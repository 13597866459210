var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-4 text-container" }, [
        _c(
          "div",
          {
            staticClass: "u-columns is-vcentered space-between",
            style: { "justify-content": _vm.center ? "center" : undefined }
          },
          [
            _c(
              "div",
              {
                staticClass: "u-column is-narrow text-container",
                staticStyle: { "max-width": "100%" }
              },
              [
                _c(
                  "b-tooltip",
                  {
                    staticClass: "tooltip-body-text",
                    attrs: {
                      label: _vm.tooltip,
                      dashed: _vm.tooltip !== undefined,
                      type: "is-dark",
                      position: "is-right",
                      multilined: "",
                      active: _vm.tooltip !== undefined
                    }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "body bold header title",
                        class:
                          ((_obj = {
                            "has-text-centered": _vm.center,
                            "has-text-white": _vm.dark,
                            caps: _vm.type === "capped-title",
                            "mb-3": _vm.type === "black-spaced"
                          }),
                          (_obj[_vm.titleSize] = true),
                          _obj),
                        staticStyle: { "line-height": "1rem" }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.title) + " "),
                        _vm.tooltip !== undefined
                          ? _c("b-icon", {
                              staticClass: "tooltip-icon-body-text",
                              attrs: {
                                size: "is-small",
                                icon: "help-circle-outline"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                ),
                _vm.subtitle
                  ? _c(
                      "p",
                      {
                        staticClass: "body",
                        class:
                          ((_obj$1 = {
                            "has-text-centered": _vm.center
                          }),
                          (_obj$1[_vm.subtitleSize] = true),
                          (_obj$1["body-text"] = _vm.type !== "black-spaced"),
                          _obj$1)
                      },
                      [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column is-narrow no-padding action-container" },
              [_vm._t("action")],
              2
            )
          ]
        )
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }