var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "InviteTab",
    _vm._g(
      {
        attrs: {
          "current-user-role": _vm.workspaceRoles.Admin,
          "no-integrations": "",
          workspace: _vm.workspace
        }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }