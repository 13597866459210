<template>
  <div
    class="utable-wrapper"
    :class="{ scrollable: maxHeight }"
    :style="{
      ...(maxHeight ? { maxHeight } : {}),
      ...(height ? { height } : {}),
      ...(padding ? { '--table-padding': padding } : {})
    }"
  >
    <table
      class="utable"
      :class="{ bordered }"
      :style="{ ...(background ? { background } : {}) }"
    >
      <tr v-if="showHeader" class="utable-headers">
        <th
          v-for="header in headers"
          :key="getId(header)"
          class="utable-headers-title"
          :class="{ 'has-table-padding': padding }"
          :style="{ ...(background ? { background } : {}) }"
        >
          <slot name="header" :header="header">
            <div
              v-if="header.title"
              class="utable-headers-title-wrapper"
              :class="{ sortable: isSortable(header) }"
              @click="handleSortBy(header)"
            >
              {{ header.title }}
              <img
                v-if="isSortable(header)"
                :src="
                  require(`@/assets/icons/table_sort${
                    sortBy === header.id ? '_desc' : ''
                  }.svg`)
                "
                alt=""
                class="utable-headers-title-wrapper-sort"
                :class="{ rotated: sortBy === header.id && !sortDesc }"
              />
            </div>
            <div v-else></div>
          </slot>
        </th>
      </tr>
      <tr v-for="(item, idx) in itemsSorted" :key="getId(item)" class="utable-item">
        <td
          v-for="header in headers"
          :key="`${getId(item)}-${getId(header)}`"
          class="utable-item-value"
          :class="{
            clickable: isClickable(header, item),
            'has-table-padding': padding
          }"
          @click="() => handleClick(item, header)"
        >
          <slot name="item" :item="item" :header="header" :index="idx">
            <div v-if="header.id === 'user'" class="utable-item-value-avatar">
              <UserAvatarWithText
                :username="$umodel.full_name(item)"
                :avatar="$umodel.avatar(item)"
              />
            </div>
            <p v-else class="utable-item-value-text" :title="header.key(item)">
              {{ header.key(item) }}
            </p>
          </slot>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import UserAvatarWithText from '@c/shared/molecules/object-visualisations/user/UserAvatarWithText.vue'

export default {
  name: 'Table',
  components: { UserAvatarWithText },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    sortable: {
      type: [Boolean, Array],
      default: true
    },
    emitSort: {
      type: Boolean,
      default: false
    },
    defaultSortBy: {
      type: String,
      default: ''
    },
    defaultSortDesc: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: [Boolean, Array, Function],
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    padding: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    sortBy: '',
    sortDesc: true
  }),
  computed: {
    itemsSorted() {
      if (!this.sortBy || this.emitSort) return this.items
      const header = this.headers.find(h => h.id === this.sortBy)
      return [...this.items].sort(
        this.sortDesc ? header.sortDesc : header.sortAsc
      )
    }
  },
  created() {
    if (this.defaultSortBy) this.sortBy = this.defaultSortBy
    this.sortDesc = this.defaultSortDesc
  },
  methods: {
    isClickable(header, item) {
      if (Array.isArray(this.clickable))
        return this.clickable.includes(header.id)
      if (typeof this.clickable === 'function')
        return this.clickable(header, item)
      return this.clickable
    },
    isSortable(header) {
      if (Array.isArray(this.sortable)) return this.sortable.includes(header.id)
      return this.sortable
    },
    getId(item) {
      if (item.uuid || item.id || item.name || item.title)
        return item.uuid || item.id || item.name || item.title
      const uuidKey = Object.keys(item).find(key => !!item[key].uuid)
      return item[uuidKey].uuid
    },
    handleSortBy(header) {
      if (!this.isSortable(header)) return
      let sortBy = this.sortBy
      let sortDesc = this.sortDesc
      if (this.sortBy === header.id) {
        sortDesc = !this.sortDesc
      } else {
        sortBy = header.id
        sortDesc = true
      }
      if (this.emitSort) {
        this.$emit('sort', sortBy, sortDesc)
        return
      }
      this.sortBy = sortBy
      this.sortDesc = sortDesc
    },
    handleClick(item, header) {
      if (this.isClickable(header, item)) this.$emit('select', item, header)
    }
  }
}
</script>

<style lang="scss" scoped>
.utable {
  width: 100%;
  border-spacing: 0.5rem;

  &-headers {
    &-title {
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;

      &.has-table-padding {
        &:first-child {
          padding-left: var(--table-padding, 0);
        }

        &:last-child {
          padding-right: var(--table-padding, 0);
        }
      }

      &-wrapper {
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: fit-content;
        padding-bottom: 0.25rem;
        gap: 0.5rem;

        &.sortable {
          cursor: pointer;
        }

        &:not(.sortable) {
          & .utable-headers-title-wrapper-sort {
            display: none;
          }
        }

        &-sort {
          height: 1.5rem;
          transition: transform 0.2s ease-in-out;

          &.rotated {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &-item {
    height: 1px; // Hack to make it possible to set height: 100% on the item in the slot

    &:hover {
      background: rgba(#000, 4%);
    }

    &.clickable {
      cursor: pointer;
    }

    &-value {
      height: inherit; // Hack to make it possible to set height: 100% on the item in the slot
      vertical-align: middle;
      padding: 0.5rem 0;

      &.has-table-padding {
        &:first-child {
          padding-left: var(--table-padding, 0);
        }

        &:last-child {
          padding-right: var(--table-padding, 0);
        }
      }

      &-text {
        max-width: 20ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-label {
      &-wrapper {
        position: relative;
      }
    }
  }

  &-wrapper {
    position: relative;

    &.scrollable {
      overflow-y: auto;
    }
  }
}

.centered {
  vertical-align: middle;
}

.utable {
  &.bordered {
    & tr {
      border-bottom: 1px solid #ebebeb;
    }
  }

  &:not(.bordered) {
    & tr,
    & td {
      border: none;
    }
  }
}
</style>
