<template>
  <b-tooltip
    v-if="showCollectButton"
    :label="slideCollected ? 'Remove from collection' : 'Collect'"
    type="is-dark"
    position="is-top"
    append-to-body
    :active="hasTooltip"
  >
    <b-button
      type="is-primary"
      :size="big ? 'is-big' : 'is-small'"
      rounded
      :icon-left="slideCollected ? (hover ? 'minus' : 'check') : 'plus'"
      class="btn-collect"
      :class="{ collected: slideCollected }"
      :disabled="isDownloadingSlides"
      @click.stop="onCollect"
      @mouseenter.native="hover = true"
      @mouseleave.native="hover = false"
    />
  </b-tooltip>
</template>

<script>
import { sendCollectEvent } from '@/services/feedbackService'
import { downloadableMimetypes } from '@c/mimetypes'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CollectSlideButton',
  props: {
    subresource: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      required: true
    },
    location: {
      type: String,
      default: 'search results'
    },
    searchId: {
      type: String,
      default: undefined
    },
    mimetype: {
      type: String,
      default: undefined
    },
    resourceTraceId: {
      type: String,
      default: undefined
    },
    big: {
      type: Boolean,
      default: false
    },
    hasTooltip: {
      type: Boolean,
      default: true
    },
    emitCollect: {
      type: Boolean,
      default: false
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hover: false
  }),
  computed: {
    ...mapGetters([
      'selectedSlides',
      'showCollectSlides',
      'isDownloadingSlides'
    ]),
    showCollectButton() {
      return (
        (this.alwaysShow || this.showCollectSlides) &&
        this.hasCollectableMimetype
      )
    },
    hasCollectableMimetype() {
      return this.mimetype
        ? downloadableMimetypes.includes(this.mimetype)
        : this.subresource.references.some((ref) =>
            downloadableMimetypes.includes(
              ref.resource.integrationfile.mimetype
            )
          )
    },
    slideCollected() {
      return this.selectedSlides
        .map((ss) => ss.trace_id || ss.subresource_trace_id)
        .includes(this.subresource.trace_id)
    }
  },
  methods: {
    ...mapActions(['deleteSlide', 'addSlide']),
    onCollect() {
      if (!this.emitCollect) {
        sendCollectEvent(this.$route.params.workspace_id, {
          subresource_trace_id: this.subresource.trace_id,
          resource_trace_id:
            this.resourceTraceId ||
            this.subresource.references[0].resource.trace_id,
          tab: this.$route.params.tab || 'all',
          comment: `${
            this.slideCollected ? 'remove collected' : 'collect'
          } from slide preview in ${this.location}`,
          rank: this.rank,
          ...(this.searchId ? { search_id: this.searchId } : {})
        })
      }

      if (
        !this.slideCollected &&
        (['negative', 'warning'].includes(this.subresource?.label?.type) ||
          (this.subresource?.labels || []).some((s) =>
            ['negative', 'warning'].includes(s.type)
          ))
      ) {
        const labelName = this.subresource?.label?.name
          ? this.subresource.label.name
          : this.subresource?.labels?.find((s) =>
              ['negative', 'warning'].includes(s.type)
            ).name
        this.$buefy.toast.open({
          message: `You have collected a slide with a <b>${labelName}</b> label.`,
          type: 'is-danger',
          position: 'is-top',
          duration: 5000
        })
      }

      if (this.emitCollect) {
        this.$emit('collect', this.subresource)
      } else {
        return this.slideCollected
          ? this.deleteSlide(this.subresource.uuid)
          : this.addSlide(
              this.resourceTraceId && this.mimetype
                ? {
                    ...this.subresource,
                    references: [
                      {
                        resource: {
                          trace_id: this.resourceTraceId,
                          integrationfile: { mimetype: this.mimetype }
                        },
                        uuid: this.subresource.uuid
                      }
                    ]
                  }
                : this.subresource
            )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-collect {
  aspect-ratio: 1 / 1;
  padding: 0;
  transition: background-color 0.15s ease-in-out;
  &.collected {
    &:hover {
      background-color: $collection-red !important;
    }
  }
  &:hover {
    border-color: transparent !important;
    color: #fff !important;
  }
}
</style>
