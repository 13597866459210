var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "udropdown", class: { "full-width": _vm.fullWidth } },
    [
      _c(
        "div",
        {
          ref: "dropdowntrigger",
          staticClass: "udropdown-trigger",
          class: {
            "full-width": _vm.fullWidth,
            disabled: _vm.disabled,
            "visualise-disabled": _vm.visualiseDisabled
          },
          on: { click: _vm.toggle }
        },
        [
          _vm._t("trigger", function() {
            return [_vm._m(0)]
          })
        ],
        2
      ),
      _c(
        "div",
        {
          ref: "dropdownwrapper",
          staticClass: "udropdown-wrapper",
          class: {
            visible: _vm.toggled,
            "position-fixed": _vm.positionFixed,
            leftaligned: _vm.leftaligned,
            topaligned: _vm.topaligned
          },
          style: Object.assign({}, _vm.offset, _vm.contentStyle)
        },
        [
          _vm._t("content", function() {
            return [
              _c(
                "div",
                {
                  staticClass: "udropdown-content",
                  style: Object.assign(
                    {},
                    _vm.maxHeight
                      ? { maxHeight: _vm.maxHeight, overflowY: "auto" }
                      : {}
                  )
                },
                _vm._l(_vm.items, function(item) {
                  var _obj
                  return _c(
                    "div",
                    {
                      key: item.text,
                      staticClass: "udropdown-content-item",
                      class: Object.assign(
                        {},
                        item.filter
                          ? ((_obj = {}),
                            (_obj["filter-" + item.filter] = true),
                            _obj)
                          : {}
                      ),
                      on: {
                        click: function($event) {
                          return _vm.handleItemClick(item)
                        }
                      }
                    },
                    [
                      _vm._t(
                        "item",
                        function() {
                          return [
                            item.icon
                              ? _c("img", {
                                  staticClass: "udropdown-content-item-icon",
                                  attrs: {
                                    src: require("@/assets/icons/" +
                                      item.icon +
                                      ".svg"),
                                    alt: ""
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(item.text) + " ")
                          ]
                        },
                        { item: item }
                      )
                    ],
                    2
                  )
                }),
                0
              )
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "udropdown-trigger-content" }, [
      _c("img", {
        staticClass: "udropdown-trigger-content-icon",
        attrs: { src: require("@/assets/icons/kebab.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }