<template>
  <div class="summary">
    <div class="summary-text">
      <Tag text="Summary" type="white" />
      <LoadingDots v-if="summaryLoading" />
      <MarkdownEdit v-else ref="summarymarkdown" :value="summary" />
      <Button
        v-if="summary"
        text="Copy to clipboard"
        icon="copy-medium"
        :icon-left="true"
        type="light"
        class="summary-text-copy"
        @click="copySummary"
      />
    </div>
  </div>
</template>

<script>
import LoadingDots from '@c/library/LoadingDots.vue'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'
import Button from '@c/library/Button.vue'
import Tag from '@c/library/Tag.vue'
import { mapActions } from 'vuex'

export default {
  name: 'SummarizeResource',
  components: { LoadingDots, MarkdownEdit, Button, Tag },
  props: {
    resourceId: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      summary: '',
      summaryLoading: false
    }
  },
  watch: {
    async visible(newVal) {
      try {
        if (newVal && !this.summary) {
          this.summaryLoading = true
          this.summary = (
            await this.getResourceSummarization({
              workspace_id: this.$route.params.workspace_id,
              resource_id: this.resourceId,
              act_as: this.$route.query.act_as
            })
          ).text
        }
      } catch (error) {
        this.$toast.error(error, 'trying to summarize')
      } finally {
        this.summaryLoading = false
      }
    }
  },
  methods: {
    ...mapActions(['getResourceSummarization']),
    copySummary() {
      navigator.clipboard.writeText(this.$refs.summarymarkdown.htmlText)
      this.$toast.success('Summarization copied')
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  padding: 1rem;

  &-text {
    position: relative;
    min-height: 10rem;
    padding: 1.5rem 2rem;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(#000, 16%);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 1rem;

    &-copy {
      align-self: flex-end;
    }
  }
}
</style>
