<template>
  <div class="chat">
    <ul ref="chatlog" class="chat-log">
      <li v-if="answerLoading" class="chat-log-item answer">
        <img :src="avatarByAuthor.bot" alt="" class="chat-log-item-avatar" />
        <LoadingDots class="chat-log-item-loading" color="white" />
      </li>
      <li
        v-for="(item, idx) in history"
        :key="`${item.author}-${idx}`"
        class="chat-log-item"
        :class="{
          question: item.author.toLowerCase() === 'user',
          answer: item.author.toLowerCase() === 'bot'
        }"
      >
        <img
          v-if="avatarByAuthor[item.author.toLowerCase()] && !avatarErrored"
          :src="avatarByAuthor[item.author.toLowerCase()]"
          alt=""
          class="chat-log-item-avatar"
          @error="avatarErrored = true"
        />
        <div
          v-else
          class="chat-log-item-avatar"
          :style="{ background: $umodel.user_color(currentUser) }"
        >
          {{ $umodel.initials(currentUser) }}
        </div>
        <span
          v-if="item.author.toLowerCase() === 'user'"
          class="chat-log-item-text"
          >{{ item.content }}</span
        >
        <MarkdownEdit
          v-else
          :value="item.content"
          class="chat-log-item-text answer"
        />
      </li>
    </ul>
    <div class="chat-input">
      <span
        ref="questioninput"
        role="textbox"
        :contenteditable="!answerLoading"
        placeholder="Ask anything about this document..."
        class="chat-input-input is-textarea"
        @input="handleQuestionInput"
        @paste.prevent="handleQuestionPaste"
        @keydown.enter="handleEnter"
      ></span>
      <img
        src="@/assets/icons/send.svg"
        alt=""
        class="chat-input-icon"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script>
import LoadingDots from '@c/library/LoadingDots.vue'
import { askQuestion } from '@/services/chatService'
import { mapGetters } from 'vuex'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'

export default {
  name: 'QuestionAnswerResource',
  components: { LoadingDots, MarkdownEdit },
  props: {
    resourceId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    question: '',
    chatStarted: false,
    chatId: '',
    history: [],
    avatarErrored: false,
    answerLoading: false
  }),
  computed: {
    ...mapGetters(['currentUser']),
    avatarByAuthor() {
      return {
        user: this.currentUser.avatar,
        bot: require('@/assets/logo.svg')
      }
    }
  },
  mounted() {
    this.$refs.questioninput.focus()
    if (this.chatUuid) {
      this.chatId = this.chatUuid
    }
  },
  methods: {
    handleEnter(e) {
      if (e.shiftKey) return
      e.preventDefault()
      this.sendMessage(e)
    },
    handleQuestionInput(e) {
      this.question = e.target.innerText
    },
    handleQuestionPaste(e) {
      let paste = (e.clipboardData || window.clipboardData).getData(
        'text/plain'
      )
      this.question = paste
      const selection = window.getSelection()
      if (!selection.rangeCount) return
      selection.deleteFromDocument()
      selection.getRangeAt(0).insertNode(document.createTextNode(paste))
      selection.collapseToEnd()
    },
    sendMessage() {
      const q = this.question.trim()
      if (q) {
        this.history.unshift({
          author: 'user',
          content: q
        })
        this.$refs.questioninput.innerText = ''
        this.$refs.questioninput.blur()
        this.question = ''
        this.getAnswer()
      }
    },
    async getAnswer() {
      try {
        this.answerLoading = true
        const data = await askQuestion({
          workspace_id: this.$route.params.workspace_id,
          question: this.history[0].content,
          act_as: this.$route.query.act_as,
          sources: { resource_ids: [this.resourceId] },
          ...(this.chatId && { chat_id: this.chatId })
        })
        this.chatId = data.uuid
        this.history.unshift({
          author: 'bot',
          id: data.id,
          content: data.content
        })
      } catch (e) {
        this.$console.debug('Chat question failed', e)
        this.history.unshift({
          author: 'bot',
          id: 'error',
          content:
            'An error occured while trying to ask a question. Please try again later or contact support.'
        })
      } finally {
        this.answerLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem 2rem;

  &-log {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    display: flex;
    flex-flow: column-reverse nowrap;
    gap: 1rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      gap: 1rem;
      max-width: 65%;
      position: relative;

      &.question {
        align-self: flex-end;
        flex-flow: row-reverse nowrap;

        & > .chat-log-item-text {
          background: white;
          border: 1px solid rgba(#000, 8%);
        }
      }

      &.answer {
        align-self: flex-start;

        & > .chat-log-item-text {
          background: #303032;
          color: white;
        }
      }

      &.content {
        align-self: flex-start;
      }

      &-avatar {
        width: 2.5rem;
        min-width: 2.5rem;
        height: 2.5rem;
        min-height: 2.5rem;
        border-radius: 999rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: white;
      }

      &-text {
        min-height: 2.2rem;
        color: inherit;
        border-radius: 16px;
        padding: 0.5rem 1rem;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 8%);
        width: fit-content;
        line-height: 1.2rem;

        &:not(.answer) {
          white-space: pre-wrap;
        }
      }

      &-loading {
        height: 2.2rem;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 8%);
        width: fit-content;
        background: #303032;
      }
    }
  }

  &-input {
    position: relative;
    margin: 0 5vw;

    &-input {
      padding: 0.25rem 0.5rem;
      background: #f1f2f3;
      border-radius: 4px;
      border: 1px solid rgba(#000, 8%);
      width: 100%;

      &:focus,
      &:active,
      &:focus-visible,
      &:focus-within {
        border: 1px solid $primary;
        background: white;
      }

      &.is-textarea {
        min-height: 2.2rem;
        display: block;
        max-height: 40vh;
        overflow: auto;
        white-space: pre-wrap;
        padding: 0.5rem 2.5rem 0.5rem 0.5rem;

        &:empty:not(:focus):before {
          content: attr(placeholder);
          pointer-events: none;
          display: block;
        }
      }
    }

    &-icon {
      position: absolute;
      bottom: 1.25rem;
      right: 1.25rem;
      transform: translateY(50%);
      height: 1.5rem;
      cursor: pointer;
      filter: invert(58%) sepia(95%) saturate(24%) hue-rotate(177deg)
        brightness(84%) contrast(84%);
    }
  }
}

::v-deep .markdown-edit-view-content {
  & * {
    color: white !important;
  }
}
</style>
