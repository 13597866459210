import { getClient, getResponseData } from '../../axios'
import Console from '../../console'
import { durationToString, parseJWT } from '../../util'
import Mutations from '../mutation-types'

/** This module deals with the bearer tokens used for authentication */

const client = getClient('/users', false)

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
let timeout = 1

export default {
  state: {
    accessToken: null,
    refreshToken: null,
    refreshTimeout: null,
    refreshMargin: 60e3
  },

  mutations: {
    [Mutations.SET_ACCESS_TOKEN](state, token) {
      state.accessToken = token
    },

    [Mutations.SET_REFRESH_TOKEN](state, token) {
      state.refreshToken = token
    },

    [Mutations.STOP_AUTH_CYCLE](state) {
      clearTimeout(state.refreshTimeout)
    },

    [Mutations.SET_REFRESH_MARGIN](state, margin) {
      state.refreshMargin = margin
    }
  },

  actions: {
    async authenticateSSO({ commit }, { provider, state, code }) {
      const res = await client.post('/oauth_login/', {
        provider,
        state,
        code
      })

      const data = getResponseData(res)
      commit(Mutations.SET_ACCESS_TOKEN, data.access)
      commit(Mutations.SET_REFRESH_TOKEN, data.refresh)
      return data
    },

    async logout({ commit }) {
      commit(Mutations.STOP_AUTH_CYCLE)
      commit(Mutations.SET_ACCESS_TOKEN, null)
      commit(Mutations.SET_REFRESH_TOKEN, null)
      // commit(Mutations.SET_CURRENT_WORKSPACEMEMBER, null)
      commit(Mutations.SET_CURRENT_USER, null)
      // reset activeWorkspace, joined workspaces and joinable workspaces
      commit(Mutations.RESET_WORKSPACES)
      commit(Mutations.SET_ACTIVE_WORKSPACE, null)
    },

    async changeUserPassword(_, { password }) {
      return await client.put('/password/', {
        password
      })
    },

    async refresh({ state, commit, dispatch }) {
      if (state.refreshToken == null) {
        throw Error('Cannot refresh without refresh token')
      }

      await sleep(timeout)
      let res = {}
      try {
        res = await client.post('/token/refresh/', {
          refresh: state.refreshToken
        })
      } catch (e) {
        timeout = Math.max(timeout * 10, 10 * 60 * 1000)
        return await dispatch('refresh')
      }

      commit(Mutations.SET_ACCESS_TOKEN, res.data.access)
      if ('refresh' in res.data) {
        commit(Mutations.SET_REFRESH_TOKEN, res.data.refresh)
        timeout = 1
      } else {
        timeout = Math.max(timeout * 10, 10 * 60 * 1000)
        return await dispatch('refresh')
      }
      return getResponseData(res)
    },

    async startCycle(context) {
      context.commit(Mutations.STOP_AUTH_CYCLE)

      /** getTimeout returns the time to next refresh in ms */
      const getTimeout = () => {
        const expiry = context.getters.accessTokenExpiryDate
        return expiry * 1000 - Date.now() - context.state.refreshMargin
      }

      /** newTimeout schedules a new timeout and automatically schedules the following ones */
      const newTimeout = (timeout) => {
        Console.info('Refreshing in', durationToString(timeout))
        if (context.state.refreshTimeout) {
          clearTimeout(context.state.refreshTimeout)
        }

        context.state.refreshTimeout = setTimeout(async function () {
          try {
            Console.debug('Refreshing')
            await context.dispatch('refresh')
            newTimeout(Math.max(getTimeout(), 10000)) // minimum 10 seconds between refresh calls
          } catch (err) {
            Console.debug('Error during refresh cycle', err)
            if (context.state.refreshToken != null) {
              // Double the timeout each time with a max timeout of 5 minutes
              newTimeout(Math.min(timeout * 2, 5 * 60e3))
            }
          }
        }, timeout)
      }

      // get the initial time until refresh, minimum 0 ms
      const timeout = Math.max(getTimeout(), 0)

      newTimeout(timeout)
    },

    /** Get a state parameter for an OAuth signing url that's valid for one hour */
    async getOAuthState() {
      const res = await client.get('/oauth_state/')
      return getResponseData(res)
    }
  },

  getters: {
    accessTokenContents(state) {
      if (state.accessToken != null) {
        return parseJWT(state.accessToken)
      }
      return null
    },

    accessTokenExpiryDate(_, getters) {
      const contents = getters.accessTokenContents
      if (contents != null) {
        return contents.exp
      }
      return 0
    },

    accessTokenExpired(_, getters) {
      const expiry = getters.accessTokenExpiryDate
      return expiry * 1000 < Date.now()
    },

    authenticated(_, getters) {
      return !getters.accessTokenExpired && !getters.refreshTokenExpired
    },

    refreshTokenContents(state) {
      if (state.refreshToken != null) {
        return parseJWT(state.refreshToken)
      }
      return null
    },

    refreshTokenExpiryDate(_, getters) {
      const contents = getters.refreshTokenContents
      if (contents != null) {
        return contents.exp
      }
      return 0
    },

    refreshTokenExpired(_, getters) {
      const expiry = getters.refreshTokenExpiryDate
      return expiry * 1000 < Date.now()
    }
  }
}
