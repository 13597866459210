

























import IntegrationUserTable from '@c/features/invite-from-integration/organisms/IntegrationUserTable.vue'
import { Integration } from '@c/integrations'
import { IntegrationUser } from '@c/models/IntegrationUser'
import Workspace from '@c/models/Workspace'
import WorkspaceHeaderModal from '@c/shared/molecules/object-visualisations/workspace/WorkspaceHeaderModal.vue'
import ModalActionFooter from '@c/shared/molecules/structural/blocks/ModalActionFooter.vue'

import Vue from 'vue'

export default Vue.extend({
  name: 'InviteFromIntegrationModal',
  components: { WorkspaceHeaderModal, IntegrationUserTable, ModalActionFooter },
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    },
    integrationId: {
      type: String,
      required: true
    },
    workspace: {
      type: Object as () => Workspace,
      required: true
    },
    integrationUsers: {
      type: Array as () => IntegrationUser[],
      default: function (): IntegrationUser[] {
        return []
      }
    },
    amountOfIntegrationUsers: {
      type: Number,
      default: 0
    },
    loadingMore: {
      type: Boolean,
      default: false
    },
    loadingSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkedUsers: []
    }
  }
})
