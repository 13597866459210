import Vue from 'vue'
import VueRouter from 'vue-router'
import { wrapView } from './router-utils'
import AuthRoutes from './routes/auth-routes'
import DataRoutes from './routes/data-routes'
import { v2BareComponents } from '@/router/shared'
import addRouterGuards from '@/router/all-guards'

// TODO: change this such that it is part of the typescript typings!
/**
 * Allowed meta options:
 * - load: bool => Start a loading animation on entry
 * - authentication: bool => Allow or disallow (un)authenticated users
 * - allowRedirect: bool => Allow a login redirection to this component, defaults to true
 */

const HTMLRenderer = wrapView(import('@/views/HTMLRenderer.vue'))

Vue.use(VueRouter)

const routes = [
  ...AuthRoutes,
  {
    path: '/html_renderer',
    name: 'htmlRenderer',
    components: {
      ...v2BareComponents,
      default: HTMLRenderer
    }
  },
  // As there is a catch-all for the homefeed, import these as last
  ...DataRoutes
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// Add Guards
addRouterGuards(router)

export default router
