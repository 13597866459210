var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "slide-download-progress-bar",
      style: "width: calc(100% - " + _vm.scrollbarWidth + "px"
    },
    [
      _c("h1", [_vm._v("Downloading presentation")]),
      _c("p", [
        _vm._v("Please wait a moment while we prepare your presentation.")
      ]),
      _c("b-progress", {
        staticClass: "progress-bar",
        attrs: {
          value: _vm.downloadSlideProgress,
          size: "is-small",
          type: "is-primary"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }