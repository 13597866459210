var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quality-labels" },
    _vm._l(_vm.labels, function(label) {
      return _c(
        "div",
        {
          key: label.uuid,
          staticClass: "quality-labels-label",
          class: { bordered: _vm.bordered || _vm.big, pill: _vm.big },
          on: {
            mouseenter: function(e) {
              return _vm.handleHoverEnter(e, label)
            },
            mouseleave: _vm.handleHoverLeave
          }
        },
        [
          _c("img", {
            staticClass: "quality-labels-label-img",
            attrs: {
              src: require("@/assets/icons/" + label.type + "QualityLabel.svg"),
              alt: ""
            }
          }),
          _vm.big
            ? _c("span", { staticClass: "quality-labels-label-text" }, [
                _vm._v(_vm._s(label.name))
              ])
            : _vm._e(),
          _vm.showHoverInfo
            ? _c("QualityLabelInfo", {
                attrs: {
                  label: label,
                  hovered: _vm.labelHovered === label.uuid,
                  "align-left": _vm.infoLeftAligned
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }