import Console from '@/console'
import store, { Mutations } from '@/store'

function getRouteComponent(route, name) {
  if (route.matched.length > 0 && route.matched[0] != null) {
    if (route.matched[0].components && name in route.matched[0].components) {
      return route.matched[0].components[name]
    }
  }
  return null
}

export function addLoadingGuard(router) {
  router.beforeEach((to, from, next) => {
    if (store.getters.debugGuards)
      Console.debug('Loading guard (to, from)', to, from)
    // apply loading animation if meta.load is true
    if (
      getRouteComponent(from, 'default') != getRouteComponent(to, 'default')
    ) {
      if ('load' in to.meta && to.meta['load'] === true) {
        Console.debug('Starting load animation')
        store.commit(Mutations.START_LOADING)
      } else {
        store.commit(Mutations.STOP_LOADING)
      }
    }
    next()
  })
}
