var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "content",
      class: {
        "resource-detail-container": true,
        "scrollbar-visible": _vm.scrolling,
        "pa-5": true
      },
      on: { scroll: _vm.handleScrolling }
    },
    [
      _c(
        "div",
        { staticClass: "resource-header pb-4" },
        [
          _c(
            "ResourceDetailHeader",
            _vm._g(
              {
                attrs: {
                  "resource-id": _vm.resourceId,
                  title: _vm.title,
                  "resource-integration": _vm.resourceIntegration,
                  bookmark: _vm.bookmark,
                  mimetype: _vm.mimetype
                }
              },
              Object.assign({}, _vm.$listeners, {
                toggleBookmark: _vm.toggleBookmark,
                gotoResource: _vm.gotoResource
              })
            )
          )
        ],
        1
      ),
      _vm._t("subHeader"),
      _c(
        "div",
        { staticClass: "slide-deck" },
        [
          _c(
            "SubResourceSlideDeck",
            _vm._g(
              {
                attrs: {
                  subresources: _vm.paginatorData,
                  "subresource-amount": _vm.paginatorDataCount,
                  "subresources-loading": _vm.consumptionLoading,
                  "selected-page": _vm.selectedPage,
                  "fallback-subresources": _vm.relevantSubresources,
                  "resource-id": _vm.resourceId,
                  "resource-trace-id": _vm.resourceTraceId,
                  selectable: _vm.selectable,
                  mimetype: _vm.mimetype,
                  unit: _vm.subResourceUnit,
                  label: _vm.label,
                  "force-show-collect": _vm.forceShowCollect
                },
                on: { changePage: _vm.subresourcePageChanged }
              },
              _vm.$listeners
            )
          )
        ],
        1
      ),
      !_vm.curationModal
        ? _c(
            "div",
            [
              _c("div", { staticClass: "subresource-carousel-container" }, [
                _c("div", { staticClass: "modal-subheader mb-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.subresourcesRelevant ? "Relevant " : "") +
                      _vm._s(
                        _vm.subresourcesRelevant
                          ? _vm.subResourceUnit
                          : _vm.subResourceUnit[0].toUpperCase() +
                              _vm.subResourceUnit.slice(1)
                      ) +
                      _vm._s(_vm.relevantSubresources.length > 1 ? "s" : "") +
                      " "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "subresource-carousel" },
                  [
                    _c(
                      "SubResourceList",
                      _vm._g(
                        {
                          attrs: {
                            subresources: _vm.relevantSubresources,
                            mimetype: _vm.mimetype,
                            "resource-id": _vm.resourceId,
                            "resource-type": _vm.resourceType,
                            "resource-trace-id": _vm.resourceTraceId,
                            "force-show-collect": _vm.forceShowCollect
                          },
                          on: { slideClicked: _vm.relevantSlideClicked }
                        },
                        _vm.$listeners
                      )
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                [
                  _c("div", { staticClass: "modal-subheader" }, [
                    _vm._v("Quality labels")
                  ]),
                  _c("QualityLabels", {
                    attrs: {
                      "active-label": _vm.label,
                      "source-id": _vm.resourceId,
                      "bordered-icons": true
                    }
                  })
                ],
                1
              ),
              _vm.activity
                ? _c(
                    "div",
                    { ref: "activityGrid", staticClass: "activity" },
                    [
                      _c("div", { staticClass: "activity-header" }, [
                        _c("p", { staticClass: "activity-header-title" }, [
                          _vm._v("Activity in the last year")
                        ]),
                        _c("p", { staticClass: "activity-header-subtitle" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.activity.length) +
                              " " +
                              _vm._s(
                                _vm.activity.length === 1 ? "change" : "changes"
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("ActivityGrid", {
                        attrs: {
                          activity: _vm.activity,
                          big: "",
                          "month-labels": "",
                          "day-labels": ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._m(0),
              _c("p", { staticClass: "aitab-title" }, [_vm._v("AI Assist")]),
              _c(
                "b-tabs",
                {
                  staticClass: "aitab",
                  model: {
                    value: _vm.AITab,
                    callback: function($$v) {
                      _vm.AITab = $$v
                    },
                    expression: "AITab"
                  }
                },
                [
                  _c(
                    "b-tab-item",
                    {
                      attrs: { disabled: _vm.similarPages.length === 0 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c("p", { staticClass: "aitab-header" }, [
                                  _c("img", {
                                    staticClass: "aitab-header-icon",
                                    attrs: {
                                      src: require("@/assets/icons/similar.svg"),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" Similar ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        178456226
                      )
                    },
                    [
                      _vm.similarPages.length > 0
                        ? _c(
                            "div",
                            [
                              _c("ResourceIntersections", {
                                attrs: {
                                  "similar-pages": _vm.similarPages,
                                  "show-header": false
                                }
                              })
                            ],
                            1
                          )
                        : _c("p", { staticClass: "aitab-similar-empty" }, [
                            _vm._v(" No similar resources were found ")
                          ])
                    ]
                  ),
                  _c(
                    "b-tab-item",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c("p", { staticClass: "aitab-header" }, [
                                  _c("img", {
                                    staticClass: "aitab-header-icon",
                                    attrs: {
                                      src: require("@/assets/icons/notepad.svg"),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" Summarize ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1325428932
                      )
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("SummarizeResource", {
                            attrs: {
                              "resource-id": _vm.resourceId,
                              visible: _vm.AITab === 1
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab-item",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c("p", { staticClass: "aitab-header" }, [
                                  _c("img", {
                                    staticClass: "aitab-header-icon",
                                    attrs: {
                                      src: require("@/assets/icons/chat.svg"),
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" AI chat ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2081354890
                      )
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("QuestionAnswerResource", {
                            attrs: { "resource-id": _vm.resourceId }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", { ref: "aitabs" })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "resource-curation" },
            [
              _c("ResourceModalCuration", {
                attrs: { label: _vm.label, "resource-id": _vm.resourceId },
                on: {
                  close: function($event) {
                    return _vm.$emit("close")
                  }
                }
              })
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "aitab-separator-wrapper" }, [
      _c("div", { staticClass: "aitab-separator" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }