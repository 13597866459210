import Console from '@/console'
import store from '@/store'

export function addWorkspaceGuard(router) {
  router.beforeEach((to, from, next) => {
    const workspaces = store.getters.workspaces
    if (store.getters.debugGuards)
      Console.debug('Workspace id guard (to, from)', to, from)
    if (to.params.workspace_id) {
      if (
        workspaces.length > 0 &&
        !workspaces.map((w) => w.uuid).includes(to.params.workspace_id)
      ) {
        next({ name: 'home', params: { workspace_id: workspaces[0].uuid } })
      } else if (workspaces.length === 0) {
        next({ name: 'workspace-list' })
      }
      next()
    }
    next()
  })
}
