var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditTab",
    _vm._g(
      {
        attrs: {
          creating: _vm.creating,
          icon: _vm.avatarURL,
          "icon-size": "xxl"
        },
        on: {
          newAvatar: function(val) {
            return _vm.newAvatar(val)
          },
          onEdit: function(ref) {
            var name = ref.name
            var description = ref.description
            var is_open = ref.is_open
            var avatar = ref.avatar

            return _vm.$emit("next", {
              next: _vm.next,
              data: {
                name: name,
                description: description,
                is_open: is_open,
                avatar: avatar
              }
            })
          }
        }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }