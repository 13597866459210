var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g({ staticClass: "u-columns is-vcentered pv-2" }, _vm.$listeners),
    [
      _c(
        "div",
        { staticClass: "u-column mh-2 is-narrow" },
        [_vm._t("avatar")],
        2
      ),
      _c(
        "div",
        { staticClass: "u-column mh-2 is-narrow" },
        [_vm._t("text"), _vm._t("subtext")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }