












































































import RightArrow from '@c/assets/icons/carousel-arrow-right.svg'
import RightArrowHover from '@c/assets/icons/carousel-arrow-right-hover.svg'
import { SubResource } from '@c/models/Resource'
import SubResourceVisualisation from './SubResourceVisualisation.vue'
import {
  mimetypeToType,
  SubResourceUnits,
  typeToUnit
} from '../../../../../mimetypes'
import Vue from 'vue'

export default Vue.extend({
  name: 'SubResourceList',
  components: { SubResourceVisualisation },
  props: {
    subresources: {
      type: Array as () => SubResource[],
      default: undefined
    },
    marginBetween: {
      type: Number,
      default: 4
    },
    mimetype: {
      type: String,
      default: undefined
    },
    showPageButton: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean as () => boolean,
      default: true
    },
    zoomCursor: {
      type: Boolean as () => boolean,
      default: true
    },
    resourceId: {
      type: String,
      default: ''
    },
    resourceTraceId: {
      type: String,
      default: ''
    },
    resourceType: {
      type: String,
      required: true
    },
    forceShowCollect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      RightArrow: RightArrow,
      RightArrowHover: RightArrowHover,
      arrowHovered: {
        left: false,
        right: false
      },
      marginScales: [0, 4, 8, 16, 24, 32, 48, 64, 128],
      isStart: true,
      isEnd: true,
      loadAll: false,
      initialLoad: 1,
      resourcesLoaded: 0,
      isImageModalActive: false,
      imagesNotLoading: 0
    }
  },
  computed: {
    inferredMimetype() {
      return mimetypeToType[this.mimetype]
    },
    subResourceUnit(): SubResourceUnits {
      return typeToUnit[this.inferredMimetype] || 'page'
    }
  },
  methods: {
    getChunkId(idx: Number) {
      return `chunk-${idx}`
    },
    innerKey(idx: Number, innerIdx: Number) {
      return `${idx}-${innerIdx}`
    },
    checkLoadMore() {
      if (!this.loadAll) {
        this.loadAll = true
      }
    },
    scroll(direction: number) {
      this.checkLoadMore()
      let container = this.$el.querySelector('#subresources')
      container?.scrollBy({
        top: 0,
        left: direction * 750,
        behavior: 'smooth'
      })
      setTimeout(() => {
        this.isStart = container?.scrollLeft === 0
        this.isEnd =
          container?.scrollLeft >=
          container?.scrollWidth - container?.clientWidth - 3
      }, 500)
    },
    handleScrollEnd() {
      var container = this.$el.querySelector('#subresources')
      setTimeout(() => {
        this.isStart = container?.scrollLeft === 0
        this.isEnd =
          container?.scrollLeft >=
          container?.scrollWidth - container?.clientWidth - 3
      }, 500)
    },
    handleScroll(event: any) {
      if (event.shiftKey || event.wheelDeltaX !== 0) {
        this.checkLoadMore()
        this.handleScrollEnd()
      }
    },
    handleImageLoaded(idx: number) {
      // This function dynamically loads the images.
      // It makes sure enough images are loaded to fill the width of the carousel initially, then when the user scrolls, all other images are loaded.
      if (idx < this.initialLoad && !this.loadAll) {
        this.resourcesLoaded += 1
        if (
          this.resourcesLoaded === this.initialLoad ||
          this.resourcesLoaded === this.subresources.length
        ) {
          const container = this.$refs.subresources
          const children = container.children
          const lastChildRect =
            children[this.initialLoad - 1].getBoundingClientRect()
          const parentRect = container?.getBoundingClientRect()
          if (lastChildRect.left < parentRect.left + parentRect.width) {
            if (!(this.resourcesLoaded === this.subresources.length)) {
              this.initialLoad += 1
            }
          }
          if (lastChildRect.right > parentRect.left + parentRect.width) {
            this.isEnd = false
          }
        }
      }
    },
    emitSlideClicked(subresource: SubResource) {
      if (this.clickable) this.$emit('slideClicked', subresource.page)
    },
    handleImageNotLoading() {
      this.imagesNotLoading += 1
      if (this.imagesNotLoading === this.subresources.length) {
        this.$emit('cantLoad')
      }
    }
  }
})
