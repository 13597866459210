import Console from '@/console'

export async function fetchUserData(store) {
  // start token refresh cycle
  await store.dispatch('startCycle')
  try {
    const [user] = await Promise.all([
      // Get current user
      await store.dispatch('getCurrentUser'),
      // workspace work
      await store.dispatch('getWorkspaces')
    ])
    return user
  } catch (error) {
    Console.warning(error)
    store.dispatch('logout')
  }
}
