






























































































































import WorkspaceMember from '@c/models/WorkspaceMember'
import DefaultTreeView from '@c/shared/molecules/structural/trees/DefaultTreeView.vue'
import {
  FileMetadata,
  TreeNode
} from '@c/shared/molecules/structural/trees/interfaces'
import {
  findBranches,
  isPromise,
  traverseNodes,
  traverseUpward
} from '@c/shared/molecules/structural/trees/tree-utils'
import { flatten } from 'lodash-es'
import Vue from 'vue'

export default Vue.extend({
  name: 'FileSelectTree',
  components: { DefaultTreeView },

  props: {
    directoryStructure: {
      type: Array,
      default: function() {
        return []
      }
    },

    currentWorkspaceMember: {
      type: Object as () => WorkspaceMember | undefined,
      default: undefined
    },

    value: {
      type: Array as () => TreeNode<FileMetadata>[],
      default: function(): TreeNode<FileMetadata>[] {
        return []
      }
    },

    needsMapping: {
      type: Boolean,
      default: false
    },

    noExpands: {
      type: Boolean,
      default: false
    },

    iconOverwrite: {
      type: String,
      default: undefined
    },

    textOverwrite: {
      type: String,
      default: undefined
    },

    integrationType: {
      type: String,
      required: true
    }
  },

  data(): {
    dirStructure?: TreeNode<FileMetadata>[]
    checkedList: boolean[]
    publicList: boolean[]
  } {
    return {
      dirStructure: [],
      checkedList: [],
      publicList: []
    }
  },

  watch: {
    directoryStructure(newVal): void {
      this.dirStructure = this.mapDirectoryStructure(newVal) || []
    },

    async dirStructure() {
      await this.setCheckedList()
      await this.setPublicList()
    }
  },

  created(): void {
    this.dirStructure =
      this.mapDirectoryStructure(this.directoryStructure) || []
  },

  methods: {
    async setCheckedList() {
      const checkboxes = [] as boolean[]
      await traverseNodes(
        { children: this.dirStructure },
        () => true,
        node => {
          const checked = node?.metadata?.checked
          if (checked !== undefined) {
            checkboxes.push(checked)
          }
        }
      )
      this.checkedList = [...checkboxes] as boolean[]
    },

    async setPublicList() {
      const checkboxes = [] as boolean[]
      await traverseNodes(
        { children: this.dirStructure },
        () => true,
        node => {
          const is_public = node?.metadata?.is_public
          if (is_public !== undefined) {
            checkboxes.push(is_public)
          }
        }
      )
      this.publicList = [...checkboxes] as boolean[]
    },

    mapDirectoryStructure(dirStructure): TreeNode<FileMetadata>[] {
      if (this.needsMapping) {
        return dirStructure.map(x => ({
          children: undefined,
          expandable: x.is_directory,
          expanded: false,
          id: x.path,
          metadata: {
            ...x,
            checked: false
          }
        }))
      } else {
        return dirStructure as any
      }
    },

    getNodeIcon(node: TreeNode<FileMetadata>): string {
      if (this.iconOverwrite) {
        return this.iconOverwrite
      }
      return node.metadata.is_directory ? 'folder' : 'file-document'
    },

    setChecked(
      checked: boolean | 'indeterminate',
      node: TreeNode<FileMetadata>
    ) {
      Vue.set(node, 'metadata', { ...node.metadata, checked })
      if (!isPromise(node.children) && node.children !== undefined) {
        if (checked === true || checked === false) {
          // Propagate select downward
          ;(node.children as TreeNode<FileMetadata>[]).forEach(subNode =>
            this.setChecked(checked, subNode)
          )
        }
      }
      // Propagate states upward
      traverseUpward(
        subNode => {
          // Don't include the node itself
          // Check for selections under the node
          const childrenSelected =
            findBranches(
              x => x.metadata.checked === true && x.id !== subNode.id,
              subNode
            ).length > 0
          const childrenUnselected =
            findBranches(
              x => x.metadata.checked !== true && x.id !== subNode.id,
              subNode
            ).length > 0

          // If some are selected, then give indeterminate state
          if (childrenSelected && childrenUnselected) {
            subNode.metadata.checked = 'indeterminate'
          } else if (childrenUnselected) {
            // if only unselected, set false
            subNode.metadata.checked = false
          } else if (!childrenUnselected && childrenSelected) {
            // if only selected, set true
            subNode.metadata.checked = true
          }
        },
        node,
        false
      )
    },

    setPublic(
      is_public: boolean | 'indeterminate',
      node: TreeNode<FileMetadata>
    ) {
      Vue.set(node, 'metadata', { ...node.metadata, is_public })
      if (!isPromise(node.children) && node.children !== undefined) {
        if (is_public === true || is_public === false) {
          // Propagate select downward
          ;(node.children as TreeNode<FileMetadata>[]).forEach(subNode =>
            this.setPublic(is_public, subNode)
          )
        }
      }
    },

    async emitInput({
      checked,
      is_public,
      node
    }: {
      checked: boolean
      is_public: boolean
      node: TreeNode<FileMetadata>
    }): void {
      if (!node.metadata.disabled) {
        this.setChecked(checked, node)
        this.setPublic(is_public, node)
        const abstractRoot = {
          children: this.dirStructure
        }
        this.$emit(
          'input',
          flatten(
            findBranches(x => {
              return x?.metadata?.checked === true
            }, abstractRoot as TreeNode)
          )
        )
      }
    }
  }
})
