import Console from '@/console'
import store from '@/store'

export function addSearchTransformer(router) {
  router.beforeEach((to, from, next) => {
    if (store.getters.debugGuards)
      Console.debug('Search transform guard (to, from)', to, from)
    if (!to?.meta?.keepSearch) {
      if (to?.params?.search) {
        next({ ...to, params: { ...to?.params, query: undefined } })
      } else {
        next()
      }
    } else {
      next()
    }
  })
}
