var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tooltip",
    _vm._b(
      {
        attrs: {
          type: _vm.tooltipType,
          "content-class": "body bold xxs",
          multilined: ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [
                  _vm._t("content", function() {
                    var _obj
                    return [
                      _c("div", [
                        _vm.prefixMessage
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "body has-text-white text-start bold xxs"
                              },
                              [_vm._v(" " + _vm._s(_vm.prefixMessage) + " ")]
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            staticClass:
                              "body bold has-text-white text-start xxs"
                          },
                          [_vm._v(" Status: " + _vm._s(_vm.tooltipLabel) + " ")]
                        ),
                        _vm.suffixMessage
                          ? _c(
                              "p",
                              {
                                staticClass: "body bold text-start xxs",
                                class:
                                  ((_obj = {}),
                                  (_obj[_vm.iconColor] = true),
                                  _obj)
                              },
                              [_vm._v(" " + _vm._s(_vm.suffixMessage) + " ")]
                            )
                          : _vm._e()
                      ])
                    ]
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "b-tooltip",
      Object.assign({}, { position: "is-left" }, _vm.tooltipProps),
      false
    ),
    [
      _c(
        "div",
        { staticClass: "fit" },
        [
          _c("b-icon", {
            class:
              ((_obj = {}),
              (_obj[_vm.iconColor] = true),
              (_obj.rotating = _vm.status === _vm.syncStatus.PENDING),
              (_obj.fit = true),
              _obj),
            attrs: { "custom-class": "mdi-18", icon: _vm.icon }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }