var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultModal",
    _vm._g(
      {
        attrs: { "no-padding": "", "with-close-icon": "" },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("div", { staticClass: "pt-5 pl-5" }, [
                  _c(
                    "p",
                    { staticClass: "body xs bold modal-title has-text-danger" },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "mv-5" }, [
        _c("div", { staticClass: "divider no-margin semi-hard" })
      ]),
      _c("div", { staticClass: "ph-5 pb-5" }, [
        _c("p", { staticClass: "header bold" }, [
          _vm._v(" " + _vm._s(_vm.subtitle) + " ")
        ]),
        _c("p", {
          staticClass: "mt-2 body body-text",
          domProps: { innerHTML: _vm._s(_vm.message) }
        }),
        _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c(
              "b-checkbox",
              {
                model: {
                  value: _vm.dontShowAgain,
                  callback: function($$v) {
                    _vm.dontShowAgain = $$v
                  },
                  expression: "dontShowAgain"
                }
              },
              [_vm._v("Don't show me again")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "pt-3 u-columns space-between" }, [
          _c(
            "div",
            { staticClass: "u-column is-narrow" },
            [
              _c(
                "b-button",
                { attrs: { type: "is-danger" }, on: { click: _vm.confirm } },
                [_vm._v(" " + _vm._s(_vm.confirmText) + " ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "u-column is-narrow" },
            [
              _c(
                "b-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }