<template>
  <div v-on-clickaway="closeCurateEdit" class="curate">
    <slot name="trigger" :onClick="openCurateEdit">
      <div class="curate-trigger" @click.stop="openCurateEdit">
        <span class="curate-trigger-text">{{
          isEmpty(activeLabel) ? 'Curate' : 'Edit'
        }}</span>
        <img
          src="@c/assets/icons/dropdown-chevron.svg"
          alt=""
          class="curate-trigger-chevron"
        />
      </div>
    </slot>
    <transition name="fade">
      <div
        v-show="curateOpen"
        class="curate-edit"
        :class="{ leftaligned: alignLeft, rightaligned: !alignLeft }"
        :style="
          `top: ${mouseY < 0.6 ? '100%' : 'unset'}; bottom: ${
            mouseY >= 0.6 ? '100%' : 'unset'
          }`
        "
        @click.stop
      >
        <div class="curate-edit-label">
          <p class="curate-edit-header">Curation label</p>
          <div
            v-on-clickaway="closeLabelEdit"
            class="label-dropdown"
            @click="openLabelEdit"
          >
            <div class="label-dropdown-label">
              <img
                v-if="!isEmpty(activeLabelValue)"
                :src="
                  require(`@c/assets/icons/${activeLabelValue.type}QualityLabel.svg`)
                "
                class="label-dropdown-label-icon"
              />
              <span class="label-dropdown-label-text">
                {{
                  isEmpty(activeLabelValue)
                    ? 'Select...'
                    : activeLabelValue.name
                }}
              </span>
            </div>
            <img
              src="@c/assets/icons/dropdown-chevron.svg"
              alt=""
              class="curate-trigger-chevron"
            />
            <EditLabelsList
              :edit-open="editOpen"
              :mouse-y="mouseYLabel"
              :label-types="labelTypes"
              :labels-by-type="labelsByType"
              :active-label-value="activeLabelValue"
              :align-left="alignLeft"
              @toggleLabel="toggleLabel"
            />
          </div>
        </div>
        <div class="curate-edit-comment">
          <p class="curate-edit-header">Remark</p>
          <textarea
            id="comment"
            v-model="comment"
            name="comment"
            maxlength="200"
            :disabled="inputsDisabled"
            class="curate-edit-comment-text"
          ></textarea>
          <p class="curate-edit-comment-counter">{{ comment.length }}/200</p>
        </div>
        <div class="curate-edit-btn">
          <div class="curate-edit-btn-border"></div>
          <p
            class="curate-edit-btn-clear"
            :class="{ disabled: inputsDisabled }"
            @click="clear()"
          >
            Clear
          </p>
          <p class="curate-edit-btn-save" @click="saveLabel()">Save curation</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { updateResourceQualityLabel } from '@/services/qualityLabelService'
import { isEmpty } from 'lodash'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import EditLabelsList from './EditLabelsList.vue'

export default {
  name: 'EditLabels',
  components: { EditLabelsList },
  mixins: [clickaway],
  props: {
    hovering: {
      type: Boolean,
      default: false
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
    activeLabel: {
      type: Object,
      required: true
    },
    sourceId: {
      type: String,
      default: undefined
    },
    alignLeft: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      curateOpen: false,
      editOpen: false,
      comment: '',
      activeLabelValue: this.activeLabel,
      mouseY: 0,
      mouseYLabel: 0,
      labelTypes: ['positive', 'informative', 'negative', 'warning'],
      labelSimilarTimer: undefined
    }
  },
  computed: {
    ...mapGetters([
      'informativeQualityLabels',
      'positiveQualityLabels',
      'warningQualityLabels',
      'negativeQualityLabels'
    ]),
    labelsByType() {
      return {
        informative: this.informativeQualityLabels,
        positive: this.positiveQualityLabels,
        negative: this.negativeQualityLabels,
        warning: this.warningQualityLabels
      }
    },
    inputsDisabled() {
      return isEmpty(this.activeLabelValue)
    }
  },
  methods: {
    clear() {
      this.comment = ''
      this.activeLabelValue = {}
    },
    toggleLabel(label) {
      if (this.activeLabelValue.name === label.name) {
        this.activeLabelValue = {}
        this.comment = ''
      } else {
        this.activeLabelValue = label
      }
    },
    async saveLabel() {
      try {
        const isDelete =
          !isEmpty(this.activeLabel) && isEmpty(this.activeLabelValue)
        await updateResourceQualityLabel(
          this.$route.params.workspace_id,
          this.sourceId,
          this.$route.query.act_as,
          ...(isDelete ? [] : [this.activeLabelValue.uuid, this.comment])
        )
        this.editOpen = false
        this.curateOpen = false
        this.$emit('label-updated', {
          id: this.sourceId,
          label: this.activeLabelValue,
          comment: this.comment
        })
        this.$toast.success(
          'Updated labels',
          'Labels saved successfully, changes will apply within one minute.'
        )
      } catch (error) {
        this.$console.log(error)
        this.$toast.error(error, 'saving the labels')
      }
    },
    openCurateEdit(e) {
      this.activeLabelValue = this.activeLabel
      this.mouseY = e.clientY / window.innerHeight
      this.curateOpen = !this.curateOpen
    },
    closeCurateEdit() {
      this.curateOpen = false
      setTimeout(() => {
        this.activeLabelValue = {}
        this.comment = ''
      }, 500)
    },
    openLabelEdit(e) {
      this.mouseYLabel = e.clientY / window.innerHeight
      this.editOpen = !this.editOpen
    },
    closeLabelEdit() {
      this.editOpen = false
    },
    isEmpty(val) {
      return isEmpty(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.curate {
  position: relative;

  &-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    width: fit-content;

    &:hover {
      background: #f1f2f3;
    }

    &-text {
      font-weight: 600;
    }

    &-chevron {
      width: 0.75rem;
    }
  }

  &-edit {
    position: absolute;
    z-index: 99;
    min-width: 25ch;
    background: white;
    padding: 0.75rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    &.leftaligned {
      left: 0;
    }

    &.rightaligned {
      right: 0;
    }

    &-header {
      font-weight: 700;
      padding-bottom: 0.5rem;
    }

    &-label {
      .label-dropdown {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.2rem 0.5rem;
        margin-bottom: 1rem;
        border: 1px solid rgba(#000, 8%);
        border-radius: 4px;
        cursor: pointer;

        &-label {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;

          &-icon {
            height: 1rem;
          }

          &-text {
            color: #60666b;
          }
        }
      }
    }

    &-comment {
      display: flex;
      flex-flow: column;
      padding-bottom: 1rem;
      &-text {
        background: #f1f2f3;
        resize: none;
        border-radius: 4px;
        border: 1px solid #e9ebed;
        padding: 0.5rem;
        min-height: 5rem;

        &:focus,
        &:focus-within {
          background: white;
        }
      }

      &-counter {
        align-self: flex-end;
        color: #8f9399;
        font-size: 0.8rem;
        padding: 0.2rem;
      }
    }

    &-btn {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;

      &-border {
        border-top: 1px solid rgba(#000, 8%);
        position: absolute;
        top: 0;
        left: -0.75rem;
        right: -0.75rem;
      }

      &-clear {
        color: $primary;
        user-select: none;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          opacity: 48%;
          filter: none; // overwrite buefy styling
        }
      }

      &-save {
        color: white;
        background: $primary;
        padding: 0.25rem 1rem;
        border-radius: 999rem;
        cursor: pointer;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
