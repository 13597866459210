import { Mutations as DefaultMutations } from 'umanai-vuex'

export default {
  ...DefaultMutations,
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  SET_SIDEBAROPEN: 'SET_SIDEBAROPEN',
  SET_CARD_TYPE: 'SET_CARD_TYPE',
  SET_REMEMBER_DIALOG_KEY: 'SET_REMEMBER_DIALOG_KEY'
}
