var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-grid-container", style: _vm.sizeStyle },
    _vm._l(_vm.weeks, function(week, weekIndex) {
      return _c(
        "div",
        { key: weekIndex, staticClass: "activity-grid-col" },
        [
          _vm.monthStarts[weekIndex]
            ? _c(
                "div",
                { staticClass: "activity-grid-label", class: { big: _vm.big } },
                [_vm._v(" " + _vm._s(_vm.monthStarts[weekIndex]) + " ")]
              )
            : _vm._e(),
          _vm._l(week, function(day, dayIndex) {
            return _c(
              "div",
              {
                key: weekIndex + "-" + dayIndex,
                staticClass: "activity-grid-cell",
                class: { active: day.count > 0 },
                on: {
                  mouseenter: function($event) {
                    _vm.hovered = weekIndex + "-" + dayIndex
                  },
                  mouseleave: function($event) {
                    _vm.hovered = ""
                  }
                }
              },
              [
                _vm.hovered === weekIndex + "-" + dayIndex
                  ? _c(
                      "div",
                      {
                        staticClass: "activity-grid-cell-hover",
                        class: { leftaligned: weekIndex < 26 }
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "activity-grid-cell-hover-day" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getFormattedDate(weekIndex, dayIndex)
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "activity-grid-cell-hover-count" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(day.count) +
                                " change" +
                                _vm._s(day.count === 1 ? "" : "s") +
                                _vm._s(
                                  Object.keys(day.users).length > 0 ? " by" : ""
                                ) +
                                " "
                            )
                          ]
                        ),
                        Object.keys(day.users).length > 0
                          ? _c(
                              "ul",
                              {
                                staticClass:
                                  "activity-grid-cell-hover-user-list"
                              },
                              _vm._l(Object.values(day.users), function(user) {
                                return _c(
                                  "li",
                                  {
                                    key: user.member.uuid,
                                    staticClass: "activity-grid-cell-hover-user"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$umodel.full_name(user)) +
                                        " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }