
























































import FileIntegrationDropdown from '@c/features/workspace-settings-modal/organisms/FileIntegrationDropdown.vue'
import { Integration } from '@c/integrations'
import Workspace from '@c/models/Workspace'
import DropdownButton from '@c/shared/molecules/buttons/DropdownButton.vue'
import IntegrationRow from '@c/shared/molecules/object-visualisations/integration/IntegrationRow.vue'
import { crmIntegrations } from '@c/integrations'
import Vue from 'vue'

export default Vue.extend({
  name: 'FileAppIntegrations',
  components: {
    IntegrationRow,
    DropdownButton,
    FileIntegrationDropdown
  },
  props: {
    workspace: {
      type: Object as () => Workspace,
      required: true
    },
    allIntegrations: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeIntegrations: {
      type: Array,
      default: function () {
        return []
      }
    },
    activeSyncs: {
      type: Array as () => any[],
      default: function () {
        return []
      }
    },
    syncObjectName: {
      type: String,
      default: 'objects'
    }
  },
  methods: {
    getIntegrationObject(integration: Integration): any | undefined {
      return this.activeIntegrations.find(
        (x: any) => x.type === integration.key
      )
    },
    goToSyncPage(integration: Integration) {
      const integrationObject = this.getIntegrationObject(integration)
      this.$emit('close')
      if (integrationObject) {
        this.$modals.integration.syncFolderSelectModal(
          this,
          integrationObject || { type: integration.key, id: '' },
          this.workspace
        )
      } else {
        this.$modals.integration.connectToIntegration(
          this,
          integration.key,
          this.workspace
        )
      }
    },
    isCrmIntegration(integration: Integration): boolean {
      return Object.values(crmIntegrations).indexOf(integration) != -1
    }
  }
})
