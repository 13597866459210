var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "intersections" }, [
    _vm.showHeader
      ? _c("h2", { staticClass: "modal-subheader" }, [
          _vm._v("Similar search results")
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "intersections-documents" },
      [
        _vm._l(_vm.intersectionsToShow, function(int, index) {
          return _c(
            "div",
            {
              key: int.resource.uuid,
              staticClass: "intersections-document",
              on: {
                mouseenter: function($event) {
                  _vm.hover = int.resource.uuid
                },
                mouseleave: function($event) {
                  _vm.hover = ""
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "intersections-document-header" },
                [
                  _c("DocumentTag", {
                    attrs: { mimetype: int.resource.integrationfile.mimetype }
                  }),
                  _c("HighlightedTextComponent", {
                    staticClass: "intersections-document-header-title",
                    attrs: {
                      "split-text": int.resource.title,
                      "base-class": _vm.titleClass,
                      "custom-highlight": {}
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.goToIntersectedResource(int, index + 1)
                      }
                    }
                  }),
                  _vm.getIntersectionTypeText(int) !== ""
                    ? _c(
                        "p",
                        { staticClass: "intersections-document-header-count" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.getIntersectionTypeText(int)) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.downloadFilesEnabled
                    ? _c("UploadDownloadDropdown", {
                        attrs: {
                          "props-call": function() {
                            return _vm.getDownloadProps(int.resource)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.highlightsEnabled &&
                  (_vm.canWriteLabels ||
                    _vm.highlighted.includes(int.resource.uuid))
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "intersections-document-header-highlight",
                          class: { clickable: _vm.canWriteLabels },
                          on: {
                            mouseenter: function($event) {
                              _vm.highlightHovered = int.resource.uuid
                            },
                            mouseleave: function($event) {
                              _vm.highlightHovered = ""
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass:
                              "intersections-document-header-highlight-icon",
                            attrs: {
                              src: require("@/assets/icons/star" +
                                (_vm.highlighted.includes(int.resource.uuid)
                                  ? "-filled"
                                  : "") +
                                ".svg"),
                              alt: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleHighlight(int.resource.uuid)
                              }
                            }
                          }),
                          _vm.highlighted.includes(int.resource.uuid)
                            ? _c("HighlightHoverInfo", {
                                attrs: {
                                  highlight:
                                    _vm.highlightValues[int.resource.uuid],
                                  hovered:
                                    _vm.highlightHovered === int.resource.uuid
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("QualityLabels", {
                    attrs: {
                      "active-label": int.resource.label,
                      "source-id": int.resource.uuid,
                      "small-labels": true,
                      "bordered-icons": false
                    }
                  })
                ],
                1
              ),
              _c(
                "ResourceInfo",
                _vm._g(
                  {
                    attrs: {
                      "resource-integration": int.resource.integrationfile,
                      hover: _vm.hover === int.resource.uuid
                    },
                    on: {
                      "on-path-click": function($event) {
                        return _vm.pathClick(int.resource.trace_id, index)
                      }
                    }
                  },
                  _vm.$listeners
                )
              )
            ],
            1
          )
        }),
        _vm.showShowMore
          ? _c(
              "div",
              {
                staticClass: "link bold blue intersections-showmore",
                on: {
                  click: function($event) {
                    _vm.showMore = !_vm.showMore
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.showMore ? "Show less" : "Show more") + " "
                )
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }