var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "uavatar",
      class:
        ((_obj = {}),
        (_obj[_vm.size] = true),
        (_obj.resizable = _vm.resizable),
        _obj),
      style: "--fallback-bg: " + _vm.fallbackBg + ";"
    },
    [
      _vm.$umodel.avatar(_vm.user) && !_vm.error
        ? _c("img", {
            staticClass: "uavatar-img",
            attrs: { src: _vm.$umodel.avatar(_vm.user), draggable: "false" },
            on: {
              error: function() {
                return (_vm.error = true)
              }
            }
          })
        : _vm.fallbackIcon
        ? _c("div", { staticClass: "uavatar-img" }, [
            _c("img", {
              staticClass: "uavatar-img-icon",
              style: _vm.filterFallback,
              attrs: {
                src: require("@/assets/icons/" + _vm.fallbackIcon + ".svg"),
                alt: "",
                draggable: "false"
              }
            })
          ])
        : _c(
            "div",
            {
              staticClass: "uavatar-img",
              style: { background: _vm.$umodel.user_color(_vm.user) }
            },
            [_vm._v(" " + _vm._s(_vm.$umodel.initials(_vm.user)) + " ")]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }