import SettingsModal from '@c/features/workspace-settings-modal/pages/SettingsModal.vue'
import Workspace from '@c/models/Workspace'
import CreateWorkspaceModal from '@c/features/create-workspace-modal/pages/CreateWorkspaceModal.vue'
import { openModal } from './BaseModals'

export function openSettings(
  parent: any,
  workspace: Workspace,
  initialTab: number,
  onCancel?: () => void
) {
  openModal(
    parent,
    SettingsModal,
    {
      props: { workspace, initialTab }
    },
    {},
    onCancel
  )
}
export function createWorkspace(parent: any) {
  openModal(parent, CreateWorkspaceModal, { props: {} })
}
