import { wrapView } from '../router-utils'
import { v2BareComponents } from '../shared'

const Login = wrapView(import('@/views/authentication/Login'))
const RegisterDone = wrapView(import('@/views/user/RegisterDone'))
const Onboarding = wrapView(import('@/views/authentication/Onboarding'))
const VerifyAccount = wrapView(import('@/views/user/VerifyAccount'))
const Logout = wrapView(import('@/views/authentication/Logout'))
const ProviderOAuth = wrapView(import('@/views/authentication/ProviderOAuth'))
const IntegrationAuth = wrapView(import('@/views/integrations/IntegrationAuth'))

export default [
  {
    path: '/register',
    redirect: '/signup',
    meta: {
      authentication: false
    }
  },
  {
    path: '/verifyaccount/:code',
    name: 'verifyAccount',
    components: {
      ...v2BareComponents,
      default: VerifyAccount
    },
    props: {
      default: true
    },
    meta: {
      load: true,
      authentication: false
    }
  },
  {
    path: '/login',
    name: 'login',
    components: {
      ...v2BareComponents,
      default: Login
    },
    meta: {
      authentication: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      ...v2BareComponents,
      default: Logout
    },
    meta: {
      authentication: true,
      allowRedirect: false,
      skipGuards: true
    }
  },
  {
    path: '/signup',
    name: 'register',
    components: {
      ...v2BareComponents,
      default: Login
    },
    meta: {
      authentication: false
    }
  },
  {
    path: '/welcome',
    name: 'onboarding',
    components: {
      ...v2BareComponents,
      default: Onboarding
    }
  },
  {
    path: '/registerdone',
    name: 'registerDone',
    components: {
      ...v2BareComponents,
      default: RegisterDone
    },
    meta: {
      authentication: false
    }
  },
  {
    path: '/integrations/authenticate/:integration',
    name: 'authenticateIntegration',
    components: {
      ...v2BareComponents,
      default: IntegrationAuth
    },
    props: {
      default: true
    }
  },
  {
    path: '/oauth2/callback/google',
    name: 'googleOauth',
    components: {
      ...v2BareComponents,
      default: ProviderOAuth
    },
    meta: {
      authentication: false
    }
  },
  {
    path: '/oauth2/callback/microsoft',
    name: 'microsoftOauth',
    components: {
      ...v2BareComponents,
      default: ProviderOAuth
    },
    meta: {
      authentication: false
    }
  }
]
