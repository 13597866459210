export default {
  state: {
    filterBlocks: [],
    filtersLoading: false,
    resourceTypeFilter: '',
    activeFilters: [],
    filtersOpenState: {},
    availableViews: [
      'allContent',
      'year',
      'sixMonths',
      'month',
      'bookmarks',
      'myContent'
    ],
    dateFilters: {
      modified_dates: null,
      created_dates: null
    },
    extractedEntities: {},
    insights: {},
    contentClasses: {},
    similarQueries: {},
    spellcheck: '',
    searchEmpty: false,
    searchErrored: false,
    selectedFilter: undefined
  },
  getters: {
    resourceTypeFilter: (state) => state.resourceTypeFilter,
    filtersOpenState: (state) => state.filtersOpenState,
    dateFilters: (state) => state.dateFilters,
    availableViews: (state) => state.availableViews,
    spellcheck: (state) => state.spellcheck,
    selectedFilter: (state) => state.selectedFilter,
    activeFilters: (state) => state.activeFilters
  },
  mutations: {
    setFilterBlocks(state, blocks) {
      state.filterBlocks = blocks
    },
    setFiltersLoading(state, loading) {
      state.filtersLoading = loading
    },
    setResourceTypeFilter(state, resourceType) {
      state.resourceTypeFilter = resourceType
    },
    setFiltersOpenState(state, filterData) {
      const clone = { ...state.filtersOpenState }
      clone[filterData.name] = filterData.open
      state.filtersOpenState = { ...clone }
    },
    setActiveFilters(state, filters) {
      state.activeFilters = filters
    },
    setDateFilter(state, dateFilterData) {
      state.dateFilters[dateFilterData.filterType] = dateFilterData.value
    },
    resetDateFilters(state) {
      state.dateFilters = {
        modified_dates: null,
        created_dates: null
      }
    },
    setExtractedEntities(state, entities) {
      state.extractedEntities = entities
    },
    setInsights(state, insights) {
      state.insights = insights
    },
    setContentClasses(state, contentClasses) {
      state.contentClasses = contentClasses
    },
    setSimilarQueries(state, queries) {
      state.similarQueries = queries
    },
    setSpellcheck(state, spellcheck) {
      state.spellcheck = spellcheck
    },
    setSearchEmpty(state, empty) {
      state.searchEmpty = empty
    },
    setSearchErrored(state, errored) {
      state.searchErrored = errored
    },
    setSelectedFilter(state, filter) {
      state.selectedFilter = filter
    }
  },
  actions: {
    setFilterBlocks({ commit }, blocks) {
      commit('setFilterBlocks', blocks)
    },
    setFiltersLoading({ commit }, loading) {
      commit('setFiltersLoading', loading)
    },
    setResourceTypeFilter({ commit }, resourceType) {
      commit('setResourceTypeFilter', resourceType)
    },
    setFiltersOpenState({ commit }, filterData) {
      commit('setFiltersOpenState', filterData)
    },
    setActiveFilters({ commit }, filters) {
      commit('setActiveFilters', filters)
    },
    setDateFilter({ commit }, dateFilterData) {
      commit('setDateFilter', dateFilterData)
    },
    resetDateFilters({ commit }) {
      commit('resetDateFilters')
    },
    setExtractedEntities({ commit }, entities) {
      commit('setExtractedEntities', entities)
    },
    setInsights({ commit }, insights) {
      commit('setInsights', insights)
    },
    setContentClasses({ commit }, contentClasses) {
      commit('setContentClasses', contentClasses)
    },
    setSimilarQueries({ commit }, queries) {
      commit('setSimilarQueries', queries)
    },
    setSpellcheck({ commit }, spellcheck) {
      commit('setSpellcheck', spellcheck)
    },
    setSearchEmpty({ commit }, empty) {
      commit('setSearchEmpty', empty)
    },
    setSearchErrored({ commit }, errored) {
      commit('setSearchErrored', errored)
    },
    resetSearchInsights({ commit }) {
      commit('setExtractedEntities', {})
      commit('setInsights', {})
      commit('setContentClasses', {})
      commit('setSimilarQueries', {})
      commit('setSpellcheck', '')
      commit('setSearchEmpty', false)
      commit('setSearchErrored', false)
    },
    setSelectedFilter({ commit }, filter) {
      commit('setSelectedFilter', filter)
    }
  }
}
