<template>
  <div>
    <div v-if="browserNotSupported" class="browser-not-supported">
      <img :src="logo" alt="" class="browser-not-supported-img" />
      <p class="browser-not-supported-header">
        We currently don't support this browser
      </p>
      <p class="browser-not-supported-subtext">
        Install one of these modern browsers to use the uman.ai app
      </p>
      <div class="modern-browsers">
        <a href="https://www.google.com/chrome/">
          <b-icon
            icon="google-chrome"
            size="is-large"
            style="color: #d94734"
          ></b-icon>
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/new/">
          <b-icon
            icon="firefox"
            size="is-large"
            style="color: #f6710b"
          ></b-icon>
        </a>
        <a href="https://www.microsoft.com/en-us/edge">
          <b-icon
            icon="microsoft-edge"
            size="is-large"
            style="color: #0881c6"
          ></b-icon>
        </a>
        <a href="https://www.opera.com/">
          <b-icon icon="opera" size="is-large" style="color: #e21327"></b-icon>
        </a>
      </div>
    </div>
    <router-view v-else name="shell">
      <template>
        <div style="position: relative" class="full-height">
          <b-loading v-if="loading" :is-full-page="false" :can-cancel="false" />
          <HomePage v-if="isHomePage" />
          <router-view
            v-else-if="
              $route.name !== 'search' ||
              (!!resourceTypeFilter && !!workspaceId)
            "
            :key="routerViewKey"
            name="default"
          />
        </div>
      </template>
    </router-view>
    <Toast ref="roottoastcomponent" />
  </div>
</template>

<script>
import ChatIntercom from '@/mixins/chat/ChatIntercom'
import { Mutations } from '@/store/index'
import Logo from '@c/assets/logo.svg'
import Toast from '@c/library/Toast.vue'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import HomePage from './views-v2/HomePage.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Toast,
    HomePage
  },
  mixins: [ChatIntercom],

  data() {
    return {
      windowWidth: window.innerWidth,
      showFeedbackModal: false,
      activeModal: '',
      modalOpen: false,
      modalContext: null,
      logo: Logo,
      showRouterView: true
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      workspaces: (state) => state.workspaces.workspaces,
      loading: (state) => state.webapp.loading,
      snake: (state) => state.webapp.snake,
      isMobile: (state) => state.webapp.isMobile,
      forceAppRefresh: (state) => state.webapp.forceAppRefresh
    }),
    ...mapGetters(['authenticated', 'resourceTypeFilter']),
    workspaceId() {
      return this.$route.params.workspace_id
    },
    activeView() {
      return this.$route.query.view || 'allContent'
    },
    routerViewKey() {
      return this.forceAppRefresh
        ? 'refreshing'
        : this.resourceTypeFilter + this.activeView + this.workspaceId
    },

    fullscreenParameter() {
      return this.$route.query.fullscreen === 'true'
    },
    browserNotSupported() {
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]'
        })(
          !window['safari'] ||
            (typeof safari !== 'undefined' && window['safari'].pushNotification)
        )
      var isIE = /*@cc_on!@*/ false || !!document.documentMode
      return isSafari || isIE
    },
    isHomePage() {
      return this.$route.name === 'home'
    },
    currentWorkspace() {
      return this.workspaces[this.workspaceId]
    }
  },
  async created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.$toast.register(this.$refs.roottoastcomponent)
  },

  methods: {
    ...mapActions(['setWindowWidth']),
    handleResize() {
      this.windowWidth = window.innerWidth
      let mobile = this.windowWidth <= 768
      this.$store.commit(Mutations.SET_IS_MOBILE, mobile)
      this.setWindowWidth(this.windowWidth)
    }
  },
  watch: {
    forceAppRefresh(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$store.commit('forceAppRefresh', false)
        })
      }
    }
  }
})
</script>

<style lang="scss">
main::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
}

#uman-app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303032;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    header {
      height: 4rem;
    }

    main {
      position: relative;
      height: 100%;
      width: 100%;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
    }
  }

  main::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  #um-feedback-button {
    position: fixed;
    z-index: 99999;
    right: 0;
    bottom: 0;
    background-color: #2f80ed;
    border: none;
    color: white;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    span {
      display: flex;
      align-items: center;
      img {
        margin-right: 1rem;
      }
    }
  }
}

#uman-app.isMobile {
  #page-content {
    padding-bottom: 4rem;
  }
  .animation-content {
    display: flex;
    justify-content: center;
  }
  // on mobile view, clean modals
  .modal-close::after,
  .modal-close::before {
    background-color: #303032;
  }
  .modal-card {
    padding: 0;
  }
}

.browser-not-supported {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100vh;
  width: 100vw;
}

.browser-not-supported-header {
  font-size: 2rem;
  font-weight: 700;
}

.browser-not-supported-subtext {
  font-size: 1.5rem;
  font-weight: 500;
}

.browser-not-supported-img {
  width: 5vw;
  height: auto;
}

.modern-browsers {
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
}

.modal-content {
  overflow: unset;
}
</style>
