<template>
  <div
    class="slide-download-progress-bar"
    :style="`width: calc(100% - ${scrollbarWidth}px`"
  >
    <h1>Downloading presentation</h1>
    <p>Please wait a moment while we prepare your presentation.</p>
    <b-progress
      :value="downloadSlideProgress"
      size="is-small"
      type="is-primary"
      class="progress-bar"
    ></b-progress>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SlideDownloadProgressBar',
  data() {
    return {
      scrollbarWidth: 0,
      interval: null
    }
  },
  computed: {
    ...mapGetters(['downloadSlideProgress', 'slideCollectorOpen'])
  },
  mounted() {
    this.scrollbarWidth = this.getScrollbarWidth()
  },
  methods: {
    getScrollbarWidth() {
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.overflow = 'scroll'
      document.body.appendChild(outer)

      const inner = document.createElement('div')
      outer.appendChild(inner)

      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

      outer.parentNode.removeChild(outer)

      return scrollbarWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-download-progress-bar {
  padding: 1rem 2rem;
  background: white;
  max-height: 7.5rem;

  h1 {
    font-weight: 700;
    font-size: 1.25rem;
    padding-bottom: 10px;
    padding-bottom: 0.5rem;
  }
  p {
    margin-bottom: 1rem;
  }
}
</style>
