var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "--sidebar-width: " +
        _vm.sidebarWidth +
        "px; --scrollbar-width: " +
        _vm.scrollbarWidth +
        "px;",
      attrs: { id: "slidecreator-container" }
    },
    [
      _c(
        "div",
        {
          staticClass: "slidecreator-collapsed",
          class: {
            active: !_vm.slideCollectorOpen && _vm.selectedSlides.length
          }
        },
        [
          _c("img", {
            staticClass: "slidecreator-collapsed-icon",
            attrs: { src: require("@/assets/icons/slides.svg"), alt: "" }
          }),
          _c("span", { staticClass: "slidecreator-collapsed-text" }, [
            _vm._v(_vm._s(_vm.collectedText))
          ]),
          _c("Button", {
            attrs: { text: "View" },
            on: { click: _vm.openSlideCreator }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "slidecreator",
          class: { open: _vm.slideCollectorOpen },
          attrs: { id: "slidecreator" }
        },
        [
          _c("div", { staticClass: "slidecreator-header" }, [
            _c("img", {
              staticClass: "slidecreator-header-icon",
              attrs: {
                src: require("@/assets/icons/presentation.svg"),
                alt: ""
              }
            }),
            _c(
              "div",
              { staticClass: "slidecreator-header-name-wrapper" },
              [
                _c(
                  "p",
                  {
                    staticClass: "slidecreator-header-name",
                    class: { hidden: _vm.editName },
                    on: { click: _vm.toggleNameEdit }
                  },
                  [_vm._v(" " + _vm._s(_vm.name) + " ")]
                ),
                _c("TextInput", {
                  staticClass: "slidecreator-header-name-input",
                  class: { hidden: !_vm.editName },
                  attrs: {
                    id: "slidecreator-nameinput",
                    "button-icon": "check-medium"
                  },
                  on: {
                    submit: function() {
                      return _vm.toggleNameEdit(false)
                    },
                    blur: function() {
                      return _vm.toggleNameEdit(false)
                    }
                  },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "slidecreator-header-btns" },
              _vm._l(_vm.buttons, function(button) {
                return _c(
                  "div",
                  { key: button.key },
                  [
                    button.key === "export"
                      ? _c("UploadDownloadDropdown", {
                          attrs: {
                            "props-call": _vm.getUploadDownloadProps,
                            callback: _vm.uploadDownloadCallback,
                            disabled: button.disabled,
                            trigger: "icon",
                            "button-type": "grey",
                            "button-size": "m"
                          }
                        })
                      : _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: button.text,
                              active: !button.disabled,
                              position: "is-top",
                              type: "is-dark"
                            }
                          },
                          [
                            _c("Button", {
                              attrs: {
                                type: button.type,
                                icon: button.icon,
                                disabled: button.disabled,
                                size: "m"
                              },
                              on: { click: button.click }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "slidecreator-header-close" },
              [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: "Close editor",
                      position: "is-top",
                      type: "is-dark"
                    }
                  },
                  [
                    _c("Button", {
                      attrs: { icon: "minimize", type: "grey", size: "m" },
                      on: { click: _vm.closeSlideCreator }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "slidecreator-content", attrs: { id: "slides" } },
            [
              _vm.selectedSlides.length > 0
                ? _c("CustomSlideCreatorCarousel", {
                    attrs: { "is-downloading": _vm.finishingExport }
                  })
                : _c("div", { staticClass: "slidecreator-content-empty" }, [
                    _c("img", {
                      staticClass: "slidecreator-content-empty-icon",
                      attrs: {
                        src: require("@/assets/icons/slide-selection.svg")
                      }
                    }),
                    _c(
                      "span",
                      { staticClass: "slidecreator-content-empty-text" },
                      [
                        _vm._v(
                          "Click the collect button on a slide to add it to your deck."
                        )
                      ]
                    )
                  ])
            ],
            1
          ),
          _c("div", { staticClass: "slidecreator-footer" }, [
            _vm._v(_vm._s(_vm.collectedText))
          ]),
          _vm.isDownloadingSlides ? _c("SlideDownloadProgressBar") : _vm._e(),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.saveLoading
              ? _c("div", { staticClass: "slidecreator-save-overlay" }, [
                  _c(
                    "div",
                    { staticClass: "slidecreator-save-overlay-container" },
                    [
                      _c("img", {
                        staticClass: "slidecreator-save-overlay-icon",
                        attrs: {
                          src: require("@/assets/icons/spinner.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v("Saving your presentation")])
                    ]
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }