<template>
  <p
    class="tag"
    :class="{
      [type]: true,
      [size]: true,
      rounded,
      overflowing,
      'icon-only': !text
    }"
  >
    <img
      v-if="icon && iconLeft"
      :src="require(`@/assets/icons/${icon}.svg`)"
      alt=""
      class="tag-icon"
      :class="{ 'apply-filter': filterIcon }"
    />
    <slot name="left"></slot>
    <span v-if="text" class="tag-text">{{ text }}</span>
    <slot name="right"></slot>
    <img
      v-if="icon && !iconLeft"
      :src="require(`@/assets/icons/${icon}.svg`)"
      alt=""
      class="tag-icon"
      :class="{ 'apply-filter': filterIcon }"
    />
  </p>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: (s) =>
        [
          'primary',
          'light',
          'light-solid',
          'white',
          'grey',
          'grey-solid',
          'black'
        ].includes(s)
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l'].includes(s)
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    filterIcon: {
      type: Boolean,
      default: true
    },
    overflowing: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.tag {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  outline: inherit;
  position: relative;
  height: fit-content;
  width: fit-content;

  &.rounded {
    border-radius: 999rem;
  }

  &:not(.rounded) {
    border-radius: 0.25rem;
  }

  &.overflowing {
    &, & > .tag-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-text {
    transition: all 0.2s ease;
    user-select: none;
    white-space: nowrap;
  }

  &.primary {
    background: $primary;
    color: white;
    border: none;
    & > .tag-text {
      color: white;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(100%);
    }
  }

  &.light {
    background: #ebf1ff;
    border: 1px solid $primary;

    & > .tag-text {
      color: $primary;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }
  }

  &.light-solid {
    background: #ebf1ff;
    border: none;

    & > .tag-text {
      color: $primary;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }
  }

  &.white {
    background: white;
    border: 1px solid #dddfe2;

    & > .tag-text {
      color: #60666b;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }
  }

  &.grey {
    border: none;
    background: rgba(#000, 6%);
    & > .tag-text {
      color: #303032;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(11%) sepia(4%) saturate(615%)
        hue-rotate(202deg) brightness(101%) contrast(81%);
    }
  }

  &.grey-solid {
    background: #f9f9fa;
    border: none;

    & > .tag-text {
      color: #8f9399;
      text-transform: uppercase;
      font-weight: 600;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }
  }

  &.black {
    background: rgba(#000, 64%);
    border: none;

    & > .tag-text {
      color: white;
    }

    & > .tag-icon.apply-filter {
      filter: brightness(0) saturate(100%) invert(100%);
    }
  }

  &.icon-only {
    justify-content: center;
    padding: unset !important;

    &.xxs {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
    }

    &.xs {
      width: 1.85rem;
      height: 1.85rem;
      min-width: 1.85rem;
      min-height: 1.85rem;
    }

    &.s {
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
    }

    &.m {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
    }

    &.l {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
    }
  }

  &.xxs {
    padding: 0.15rem 0.5rem;

    & > .tag-text {
      font-size: 0.85rem;
    }

    & > .tag-icon {
      height: 0.9rem;
      width: 0.9rem;
      min-width: 0.9rem;
      min-height: 0.9rem;
      object-fit: contain;
    }
  }

  &.xs {
    padding: 0.35rem 0.85rem;

    & > .tag-text {
      font-size: 0.85rem;
    }

    & > .tag-icon {
      height: 1.1rem;
    }
  }

  &.s {
    padding: 0.35rem 1.15rem;

    & > .tag-text {
      font-size: 1rem;
    }

    & > .tag-icon {
      height: 1.2rem;
    }
  }

  &.m {
    padding: 0.8rem 1.45rem;

    & > .tag-text {
      font-size: 1rem;
    }

    & > .tag-icon {
      height: 1.2rem;
    }
  }

  &.l {
    padding: 0.95rem 1.7rem;

    & > .tag-text {
      font-size: 1.15rem;
    }

    & > .tag-icon {
      height: 1.2rem;
    }
  }
}
</style>
