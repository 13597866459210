export function durationToString(ms) {
  if (ms > 3600000) {
    return Math.round(ms / 3600000) + ' hours'
  }
  if (ms > 60000) {
    return Math.round(ms / 60000) + ' minutes'
  }
  if (ms > 1000) {
    return Math.round(ms / 1000) + ' seconds'
  }
  return Math.round(ms) + ' milliseconds'
}

export function parseJWT(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
