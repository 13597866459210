











































import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'DarkDialogContainer',
  props: {
    noArrow: {
      type: Boolean,
      default: false
    },
    verticalAlign: {
      type: String as PropType<'bottom' | 'top'>,
      default: 'bottom'
    },
    horizontalAlign: {
      type: String as PropType<'left' | 'right'>,
      default: 'left'
    },
    type: {
      type: String,
      default: 'dark',
      validator: (val: string) => ['dark', 'light', 'transparent'].includes(val)
    }
  }
})
