<template>
  <AvatarWithText v-on="$listeners">
    <template v-slot:avatar>
      <slot
        name="avatar"
        :hoverable="avatarHoverable"
        :avatar="avatar"
        :height="size"
        :width="size"
      >
        <UserAvatar
          class="user-avatar"
          :hoverable="avatarHoverable"
          :avatar="avatar"
          :height="size"
          :width="size"
        >
          <template v-slot:dropdown>
            <slot name="dropdown"></slot>
          </template>
        </UserAvatar>
      </slot>
    </template>
    <template v-slot:text>
      <slot name="text" v-bind="{ username }">
        <p class="body xs username" style="line-height: 0.75rem">
          {{ username }}
        </p>
      </slot>
    </template>
    <template v-slot:subtext>
      <slot name="subtext">
        <p
          v-if="subtext"
          class="subtext body body-text xxs"
          style="text-align: start"
        >
          {{ subtext }}
        </p>
      </slot>
    </template>
  </AvatarWithText>
</template>

<script>
import AvatarWithText from '../../structural/blocks/AvatarWithText'
import UserAvatar from './UserAvatar'
export default {
  name: 'UserAvatarWithText',
  components: { AvatarWithText, UserAvatar },
  props: {
    avatar: {
      type: String,
      default: undefined
    },
    orb: {
      type: Boolean,
      default: false
    },
    username: {
      type: String,
      default: undefined
    },
    subtext: {
      type: String,
      default: undefined
    },
    size: {
      type: Number,
      default: 30
    },
    avatarHoverable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped></style>
