







































































import allIntegrations from '@c/integrations'
import chevronRight from '../../../../../assets/icons/chevron-right.svg'

export default {
  name: 'IntegrationLink',
  props: {
    integration: {
      type: String,
      default: undefined
    },

    integrationPath: {
      type: Array as () => string[],
      default: undefined
    },

    noIntegrationPathTooltip: {
      type: Boolean,
      default: false
    },

    hover: {
      type: Boolean,
      default: false
    },

    condensed: {
      type: Boolean as () => boolean,
      default: false
    }
  },
  data() {
    return {
      maxPathLength: 4,
      chevronRight: chevronRight,
      maxPathEntryLength: 30
    }
  },
  computed: {
    integrationData() {
      return allIntegrations[this.integration]
    },

    pathRoot() {
      return this.integrationPath?.[0]
    },

    pathBody() {
      const pathLength =
        this.integrationPath?.length > this.maxPathLength ? 2 : 3
      return this.integrationPath.slice(-1 - pathLength, -1)
    },

    pathHeader() {
      return this.integrationPath?.[this.integrationPath.length - 1]
    },

    showTail() {
      return !this.pathHeader?.toLowerCase().includes('message')
    }
  },
  methods: {
    abbreviate(text) {
      return text.length > this.maxPathEntryLength
        ? `${text.slice(0, this.maxPathEntryLength - 3)}...`
        : text
    }
  }
}
