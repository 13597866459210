var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SyncFolderSelectModal",
    _vm._g(
      {
        staticStyle: { "max-height": "90vh", "min-width": "50vw" },
        attrs: {
          "directory-structure": _vm.directoryStructure,
          workspace: _vm.workspaceObject,
          integration: _vm.integration,
          "populated-workspaces": _vm.populatedWorkspaces.filter(function(x) {
            return x.uuid === _vm.workspaceObject.uuid
          }),
          "current-workspace-member": _vm.currentWorkspaceMember,
          "sync-stats": _vm.syncStats,
          "loading-syncs": _vm.loadingSyncs,
          "loading-dirs": _vm.loadingDirs
        },
        on: { getChildren: _vm.onExpand, sync: _vm.postSync }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }