import { Store } from 'vuex'
import { SensorType } from '../sensors'
import StoreActionSensor from '../StoreActionSensor'

export default class LogoutSensor extends StoreActionSensor<any> {
  constructor(store: Store<any>) {
    super(SensorType.LOGOUT, 'logout', store)
  }

  protected transformState(): any {
    return {}
  }
}
