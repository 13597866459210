














import { HydrationMixinNoWatch } from '@/mixins/HydrationMixin'
import { Mutations } from '@/store'
import { ProfileSettingTab } from '@c/features/profile-settings-modal/interfaces'
import ProfileSettingsModal from '@c/features/profile-settings-modal/pages/ProfileSettingsModal.vue'
import Vue from 'vue'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'HydratedProfileSettings',
  components: { ProfileSettingsModal },
  mixins: [HydrationMixinNoWatch],
  props: {
    initialActiveTab: {
      type: Number as () => ProfileSettingTab,
      default: ProfileSettingTab.EDIT
    }
  },
  data() {
    return {
      loadingPasswordReset: false,
      loadingSettings: false
    }
  },
  computed: {
    ...mapState({
      currentUser: (state: any) => state.users.currentUser
    } as any),
    ...mapGetters(['currentWorkspaceMember'])
  },
  methods: {
    ...mapActions([
      'setPassword',
      'patchUser',
      'updateAvatar',
      'patchWorkspaceMember'
    ]),
    ...mapMutations([Mutations.SET_CURRENT_WORKSPACEMEMBER]),
    async setPasswordValue(val) {
      this.loadingPasswordReset = true
      try {
        await this.setPassword({ password: val })
        this.$toast.success(
          'Password changed',
          'Your password was successfully changed.'
        )
      } catch (e) {
        this.$console.debug(e)
        this.$toast.error(e, 'changing your password')
      }
      this.loadingPasswordReset = false
    },
    async setNewData({ image, role, ...data }: { image: any; role: any }) {
      this.loadingSettings = true
      const promises = []
      if (image) {
        promises.push(this.updateAvatar(image))
      }
      if (role) {
        const member = this.currentWorkspaceMember as any
        promises.push(
          this.patchWorkspaceMember({
            workspace_id: this.$route.params.workspace_id,
            member_id: member.uuid,
            data: { role }
          })
        )
      }
      promises.push(this.patchUser({ id: 'me', data }))
      try {
        await Promise.all(promises)
        this.$toast.success(
          'Saved successfully',
          'Your changes have been saved successfully!'
        )
        if (role) {
          this[Mutations.SET_CURRENT_WORKSPACEMEMBER]({
            member: {
              ...(this.currentWorkspaceMember as any),
              role
            },
            workspace_id: this.$route.params.workspace_id
          })
        }
      } catch (e) {
        this.$console.debug(e)
        this.$toast.error(e, 'saving your changes')
      }

      this.loadingSettings = false
    }
  }
})
