var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultModal",
    _vm._g(
      {
        attrs: {
          "no-padding": _vm.noPadding,
          "with-close-icon": _vm.withCloseIcon,
          loading: _vm.loading
        },
        scopedSlots: _vm._u(
          [
            {
              key: "icon",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "fit",
                      class: { "mt-5": _vm.noPadding, "ml-5": _vm.noPadding }
                    },
                    [
                      _vm.icon
                        ? _c("img", {
                            staticClass: "vertical-centered",
                            class: _vm.iconClasses,
                            attrs: { src: _vm.icon }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "title",
              fn: function() {
                return [
                  _vm._t("title", function() {
                    return [
                      _c("p", { staticClass: "header sm bold modal-title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _vm.subtitle
                        ? _c(
                            "p",
                            {
                              staticClass: "body xxs body-text modal-subtitle"
                            },
                            [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                          )
                        : _vm._e()
                    ]
                  })
                ]
              },
              proxy: true
            },
            {
              key: "title-right",
              fn: function() {
                return [_vm._t("title-right")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }