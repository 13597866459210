import { getClient } from 'umanai-vuex/src/axios'
const client = getClient('/workspaces')

export async function getSearchSearchSuggestion(
  workspaceId,
  memberId,
  search,
  tab
) {
  try {
    const { data } = await client.get(
      `/${workspaceId}/members/${memberId}/suggestions/`,
      {
        params: {
          search,
          tab
        }
      }
    )
    return data
  } catch (error) {
    //TODO log error?
  }
}

export async function deleteSearchHistoryItem(
  workspaceId,
  memberId,
  historyItemId
) {
  try {
    await client.delete(
      `/${workspaceId}/members/${memberId}/suggestions/${historyItemId}/`
    )
  } catch (error) {
    //TODO log error?
  }
}
