











































import { FormField, Integration } from '@c/integrations'
import ModalActionFooter from '@c/shared/molecules/structural/blocks/ModalActionFooter.vue'
import Vue from 'vue'
export default Vue.extend({
  name: 'ConnectForm',
  components: { ModalActionFooter },
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    }
  },
  data() {
    const data = {
      token: undefined
    } as any
    ;((this.integration as Integration).setup.metaFormFields || []).forEach(
      (x: FormField) => {
        data[x.key] = undefined
      }
    )
    return data
  }
})
