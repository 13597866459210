import DialogModal from '@c/shared/molecules/structural/modals/DialogModal.vue'

export function infoDialog(
  parent: any,
  title: string,
  header: string,
  message: string,
  action: string,
  onConfirm: () => boolean | Promise<boolean>,
  propsOverwrites?: any,
  modalPropsOverwrites?: any
): any {
  return parent.$buefy.modal.open({
    parent: parent,
    canCancel: ['escape', 'outside'],
    component: DialogModal,
    width: 400,
    trapFocus: true,
    props: {
      title,
      header,
      message,
      action,
      onConfirm,
      type: 'primary',
      ...(propsOverwrites || {})
    },
    events: {},
    ...(modalPropsOverwrites || {})
  })
}
